<template>
  <span>
    <span class="field-title">{{ configs_table.title }}:</span>
    <span
      v-for="selected in config"
      :key.prop="selected"
      class="field-value"
    >
      <span
        v-if="selected.algorithm !== null"
        class="condition"
      >
        {{ selected.algorithm ? 'или' : 'и' }}
      </span>
      <span class="value">{{ getTextByKey(selected.select, selected.input) }}</span>
    </span>
  </span>
</template>

<script>
import DateFilter from '@/components/table/filters/DateFilter';
import { DateTime } from 'luxon';

export default {
  name: 'DateFilterBadge',
  props: ['key_column', 'config', 'configs_table'],
  data: () => ({
    DateFilter,
  }),
  methods: {
    getTextByKey(selected, input) {
      const data = DateFilter.data().options.find((obg) => {
        return obg.code === selected.code;
      });
      if (data.code === 'between' && data.startDate !== '' && data.endDate !== '') {
        return `от ${DateTime.fromJSDate(new Date(input.startDate)).toFormat(
          'dd.MM.yyyy',
        )} до ${DateTime.fromJSDate(new Date(input.endDate)).toFormat('dd.MM.yyyy')}`;
      } else {
        return data.label;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.field-title,
.condition,
.value {
  margin-right: 4px;
}
</style>
