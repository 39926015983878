import { DOC_ROUTESPOINTS_DIRECTORY_GET } from '@/configs/end-points.js';
import Api from '@/services/api/api.js';
import routePointStatuses from '@/configs/route-point-statuses.js';
import { defineStore } from 'pinia';
import { useSystemStore } from '@/stores/system.js';
import StoreId from '@/common/enums/store-id.js';

export const useAfterRunRoutePointStore = defineStore(StoreId.AfterRunRoutePoint, {
  state: () => ({
    loadingAfterRunRoutePoint: true,
    listAfterRunRoutePoint: [],
  }),
  actions: {
    async fetchAfterRunRoutePointAction({ doc_id, rp_id = 0 }) {
      const statuses = (
        !rp_id
          ? [
              routePointStatuses.rp_status_type_approved_id,
              routePointStatuses.rp_status_type_rejected_id,
              routePointStatuses.rp_status_type_completed_id,
              routePointStatuses.rp_status_type_completion_confirmed_id,
              routePointStatuses.rp_status_type_stopped_id,
              routePointStatuses.rp_status_type_accepted_id,
            ]
          : [routePointStatuses.rp_status_type_stopped_id]
      ).join(',');

      let where = `DOC_ID = ${doc_id} AND RPS_ID NOT IN (${statuses}) AND RP_ID <> ${rp_id} AND `;

      const systemStore = useSystemStore();

      // если понадобится фильтровать по дате, логику можно найти в истории коммитов
      if (systemStore.isMssql) {
        where += `dbo.CountLevel(PARENTS) = 1  AND (LINKS NOT LIKE '%%#${rp_id}#%%' OR LINKS IS NULL) `;
      } else {
        where += `CountLevel(PARENTS) = 1 AND (LINKS NOT ILIKE '%%#${rp_id}#%%' OR LINKS IS NULL) `;
      }

      this.loadingAfterRunRoutePoint = true;

      return Api.post(DOC_ROUTESPOINTS_DIRECTORY_GET, { doc_id, where })
        .then((response) => {
          this.listAfterRunRoutePoint = [...response.items];
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.loadingAfterRunRoutePoint = false;
        });
    },
  },
});
