<template>
  <div class="radio-button-form">
    <div class="radio-button-form__container">
      <div
        v-for="option in options"
        :key="option.code"
        class="radio-button-form__container-item"
        :class="{
          'radio-button-form__container-item_disabled': disabled,
        }"
        :style="optionStyle(option)"
        @click="select(option)"
      >
        <input
          :id="`radio-button-form__container-input-${option.code}`"
          class="radio-button-form__container-input"
          :class="{ _active: modelProxy === option.code }"
          type="radio"
          :label="option.label"
          :value="option.code"
          :checked="modelProxy === option.code"
        />
        <label :for="`radio-button-form__container-input-${option.code}`">{{ option.label }}</label>
      </div>
    </div>
    <span
      v-if="hint || error"
      class="form--input-caption"
      :class="error ? 'form--input-caption__error' : ''"
    >
      {{ error ? error : hint }}
    </span>
  </div>
</template>

<script>
/** @deprecated Используйте ui-компоненты */
export default {
  name: 'RadioButtonsForm',
  props: {
    /**
     * Значение поля
     * @type {any}
     */
    modelValue: {},
    /**
     * Сообщение об ошибке.
     */
    error: {
      type: String,
      default: '',
    },
    /**
     * Подсказка.
     */
    hint: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      modelProxy: null,
      activeColor: '#daf6ca', // FIXME: Использовать цвет из палитры
    };
  },
  mounted() {
    this.modelProxy = this.modelValue;
  },
  methods: {
    select(option) {
      if (this.disabled) {
        return;
      }
      this.modelProxy = option.code;
      this.$emit('update:modelValue', this.modelProxy);
    },
    optionStyle(option) {
      const styles = {};
      if (option.code === this.modelProxy) {
        styles['background-color'] = option?.activeColor || this.activeColor;
      }

      if (option?.full) {
        styles['grid-column'] = 'span 2';
      }

      return styles;
    },
  },
};
</script>

<style scoped lang="scss">
.radio-button-form {
  &__container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);

    &-item {
      display: flex;
      align-items: center;
      justify-content: left;
      min-height: 36px;
      background-color: var(--color-gray-075);
      border-radius: 8px;
      cursor: pointer;
      font-size: var(--font-size-l);
      line-height: var(--line-height-l);
      color: var(--color-gray-700);
      padding-left: 16px;
      margin-bottom: 8px;

      .radio-button-form__container-input {
        display: none;

        & + label::before {
          content: '';
          display: inline-block;
          width: 16px;
          height: 16px;
          position: relative;
          top: 3px;
          margin-right: 8px;
          background: url('@/assets/icons/radio.svg') 0 0 no-repeat;
        }

        &._active {
          & + label::before {
            background: url('@/assets/icons/radio-checked.svg') 0 0 no-repeat;
          }
        }
        & + label {
          margin-bottom: 0.5rem;
          cursor: pointer;
          color: #1d1728;
        }
      }

      &_disabled {
        cursor: default !important;
      }

      @media (hover: hover) {
        &:hover {
          background-color: var(--color-gray-075);
        }
      }
    }
  }
}
</style>
