<script setup>
import { useVersionStore } from '@/stores/version.js';
import { storeToRefs } from 'pinia';

const versionStore = useVersionStore();
const { current } = storeToRefs(versionStore);
</script>

<template>
  <div v-if="current">
    <span class="gitReleaseVersion">
      Версия <strong>{{ current.version }}</strong>
    </span>
    <span class="gitReleaseDate"> Дата релиза: {{ current.date }} </span>
    <span class="gitReleaseCommitHash">
      {{ current.hash }}
    </span>
  </div>
</template>
