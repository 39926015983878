import Api from '@/services/api/api.js';
import { CONTROL_COUNT } from '@/configs/end-points.js';
import { defineStore } from 'pinia';
import { useTableStore } from '@/stores/table.js';
import { CONTROLS_TABLE_NAME } from '@/configs/tables/table.js';
import StoreId from '@/common/enums/store-id.js';

// FIXME: Дубль @/stores/commissions.js
export const useControlsStore = defineStore(StoreId.Controls, {
  state: () => ({
    controlsCount: {
      count: 0,
      count_new: 0,
      count_expired: 0,
      ids: null,
      ids_new: null,
    },
  }),
  actions: {
    /** Счетчик документов на контроле */
    async getControlsCountAction(data = {}) {
      return await Api.post(CONTROL_COUNT, data).then((response) => {
        if (
          (this.controlsCount.ids !== null && response.ids !== this.controlsCount.ids) ||
          (this.controlsCount.ids_new !== null && response.ids_new !== this.controlsCount.ids_new)
        ) {
          const tableStore = useTableStore();
          tableStore.refreshDataTable(CONTROLS_TABLE_NAME);
        }
        // TODO: Нужно ли условие?
        if (response) {
          this.controlsCount = {
            count: response.count,
            count_new: response.count_new,
            count_expired: response.count_expired,
            ids: response.ids,
            ids_new: response.ids_new,
          };
        }
      });
    },
  },
});
