<script setup lang="ts">
import BadgeUi, { type BadgeColor, type BadgeSize } from '@/components/ui/BadgeUi.vue';
import { computed } from 'vue';

const { value } = defineProps<{
  value: number;
  size?: BadgeSize;
  color?: BadgeColor;
  pulse?: boolean;
}>();

const formatted = computed(() => (value > 99 ? '99+' : value));
</script>

<template>
  <BadgeUi
    v-if="value > 0"
    class="reduced-badge"
    :value="formatted"
    :size="size"
    :color="color"
    :pulse="pulse"
  />
</template>
