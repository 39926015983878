<template>
  <div class="progress-bar-ui" />
</template>

<style scoped lang="scss">
.progress-bar-ui {
  width: 100%;
  height: 5px;

  background-color: #ffc107;
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 1rem 1rem;

  animation: 1s linear infinite progress-bar-stripes;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
</style>
