<template>
  <UploadUi v-model="model" />
</template>

<script>
import { defineComponent } from 'vue';
import StepMixin from '@/mixins/document-master/step-mixin.js';
import UploadUi from '@/components/ui/UploadUi.vue';
import { mapActions, mapState } from 'pinia';
import { useMasterFilesStore } from '@/stores/master-files.js';

export default defineComponent({
  name: 'StepFiles',
  components: {
    UploadUi,
  },
  mixins: [StepMixin],
  computed: {
    ...mapState(useMasterFilesStore, ['files']),
    model: {
      get() {
        return this.files;
      },
      set(files) {
        this.set(files);
      },
    },
  },
  methods: {
    ...mapActions(useMasterFilesStore, ['set']),
  },
});
</script>

<style scoped lang="scss">
.upload-ui {
  height: 100%;
}
</style>
