import { Preview } from '@/common/models/preview/preview.js';
import VuexAdapter from '@/services/vuex-adapter.js';
import {
  CLIENTS_ADDRESSES_GET,
  CLIENTS_BANK_DETAILS_GET,
  CLIENTS_COMMENTS_GET,
  CLIENTS_CONTACTS_GET,
  CLIENTS_DOCS_GET,
  CLIENTS_GET,
} from '@/configs/end-points.js';
import { COUNTERPARTIES__BANK_DETAILS } from '@/configs/events.js';
import { useRowStore } from '@/stores/row.js';
import { useRolesStore } from '@/stores/roles.js';
import { useListsStore } from '@/stores/lists.js';

export class ClientPreview extends Preview {
  static intefaceIdent = 'CLIENT_PREVIEW';

  fetchDataPreview() {
    return new Promise((resolve, reject) => {
      this._abort().finally(() => {
        this._fetch()
          .then(() => resolve())
          .catch((e) => reject(e));
      });
    });
  }

  fetchPartlyPreview(endPoint) {
    const listsStore = useListsStore();
    switch (endPoint) {
      case CLIENTS_ADDRESSES_GET:
      case CLIENTS_CONTACTS_GET:
      case CLIENTS_COMMENTS_GET:
      case CLIENTS_BANK_DETAILS_GET:
        return listsStore[VuexAdapter.getNameAction(endPoint)]({
          where: 'CLIENT_ID = ' + this._entityId,
          limit: this._limitLoad,
        });
    }
  }

  _fetch() {
    const data = {
      where: 'CLIENT_ID = ' + this._entityId,
      limit: this._limitLoad,
    };

    const rowStore = useRowStore();
    const listsStore = useListsStore();

    const promices = [
      rowStore[VuexAdapter.getNameRowAction(CLIENTS_GET)](this._entityId),
      listsStore[VuexAdapter.getNameAction(CLIENTS_ADDRESSES_GET)](data),
      listsStore[VuexAdapter.getNameAction(CLIENTS_COMMENTS_GET)](data),
      listsStore[VuexAdapter.getNameAction(CLIENTS_CONTACTS_GET)](data),
      listsStore[VuexAdapter.getNameAction(CLIENTS_DOCS_GET)]({ ...data, orderby: 'DOC_ID DESC' }),
    ];

    const rolesStore = useRolesStore();

    if (rolesStore.accessToEvent(COUNTERPARTIES__BANK_DETAILS)) {
      promices.push(listsStore[VuexAdapter.getNameAction(CLIENTS_BANK_DETAILS_GET)](data));
    }

    return Promise.all(promices);
  }

  _abort() {
    const rowStore = useRowStore();
    const listsStore = useListsStore();
    const promices = [
      rowStore[VuexAdapter.getNameAbortRowAction(CLIENTS_GET)](),
      listsStore[VuexAdapter.getNameAbortAction(CLIENTS_ADDRESSES_GET)](),
      listsStore[VuexAdapter.getNameAbortAction(CLIENTS_COMMENTS_GET)](),
      listsStore[VuexAdapter.getNameAbortAction(CLIENTS_CONTACTS_GET)](),
      listsStore[VuexAdapter.getNameAbortAction(CLIENTS_DOCS_GET)](),
    ];

    const rolesStore = useRolesStore();

    if (rolesStore.accessToEvent(COUNTERPARTIES__BANK_DETAILS)) {
      promices.push(listsStore[VuexAdapter.getNameAbortAction(CLIENTS_BANK_DETAILS_GET)]());
    }

    return Promise.all(promices);
  }
}
