<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import FilePreviewPage from '@/components/doc/file-preview/FilePreviewPage.vue';
import FileError from '@/components/doc/files/FileError.vue';
import FileApi from '@/services/api/file-api.js';
import DocumentFile from '@/common/models/file/document-file';
import State from '@/common/enums/state.js';
import PreviewBlock from '@/components/doc/preview/PreviewBlock.vue';
import FileTruncatedLink from '@/components/doc/files/FileTruncatedLink.vue';
import FileReviewModal from '@/components/modals/File/FileReviewModal.vue';
import FilePreviewPagination from '@/components/doc/file-preview/FilePreviewPagination.vue';
import VuexAdapter from '@/services/vuex-adapter.js';
import { DOCS_GET } from '@/configs/end-points.js';
import { DOCUMENTS__EDIT } from '@/configs/events';
import LoaderUi from '@/components/ui/LoaderUi.vue';
import CarouselUi from '@/components/ui/CarouselUi.vue';
import { range } from 'lodash-es';
import { PRELOAD_IMAGES_COUNT } from '@/common/consts/preview.js';
import StorageApi from '@/services/api/storage-api.js';
import EditReviewButton from '@/components/buttons/EditReviewButton.vue';
import useAbort from '@/composables/use-abort.js';
import usePreviewBlock from '@/composables/use-preview-block.js';
import { usePreviewFilesStore } from '@/stores/preview-files.js';
import { useSystemStore } from '@/stores/system.js';
import { useRowStore } from '@/stores/row.js';
import { useRolesStore } from '@/stores/roles.js';
import { useFileEditorStore } from '@/stores/file-editor.js';
import { useRouter } from 'vue-router';

const props = defineProps({
  file: {
    type: DocumentFile,
    required: true,
  },
});

const router = useRouter();

const systemStore = useSystemStore();
const rowStore = useRowStore();
const rolesStore = useRolesStore();
const previewFilesStore = usePreviewFilesStore();
const fileEditorStore = useFileEditorStore();

const { signal } = useAbort();
const { isCompact, onResize } = usePreviewBlock();

const carousel = ref();
const fileReviewModal = ref();

const pages = ref([]);
const currentPage = ref(1);
const state = ref(State.Undefined);

const isDocumentStopped = computed(() => rowStore[VuexAdapter.getNameRowGetter(DOCS_GET)]['DS_ID'] === 5);
const accessToDocumentEdit = computed(() => rolesStore.accessToEvent(DOCUMENTS__EDIT));
const permissions = computed(() => ({
  edit: accessToDocumentEdit.value && props.file.canEdit,
  review: accessToDocumentEdit.value && !isDocumentStopped.value,
}));
const slides = computed(() =>
  pages.value.map((page, index) => ({
    ...page,
    key: page.url,
    alt: `${props.file.name}. Страница ${index + 1}`,
    icon: props.file.icon,
  })),
);

onMounted(loadFile);

watch(currentPage, loadRange);

function createPages(pagesCount) {
  return range(1, pagesCount + 1)
    .map((page) => `${systemStore.storageUrl}${props.file.hash}_page${page.toString().padStart(4, '0')}.jpg`)
    .map((url) => ({ state: State.Undefined, url }));
}

function setPageState(state, pageNumber) {
  pages.value = pages.value.map((page, i) => (i === pageNumber - 1 ? { ...page, state } : page));
}

async function loadPage(pageNumber) {
  const page = pages.value[pageNumber - 1];
  if (page.state === State.Defined || page.state === State.Loading) {
    return;
  }

  setPageState(State.Loading, pageNumber);
  try {
    await StorageApi.load({ url: page.url }, signal.value);
    setPageState(State.Defined, pageNumber);
  } catch {
    setPageState(State.Error, pageNumber);
  }
}

function loadRange(pageNumber) {
  const start = Math.max(1, pageNumber - PRELOAD_IMAGES_COUNT);
  const end = Math.min(pageNumber + PRELOAD_IMAGES_COUNT + 1, pages.value.length + 1);

  const part = range(start, end);
  const hasError = part.some((pageNumber) => pages.value[pageNumber - 1].state === State.Error);

  if (hasError) {
    FileApi.addView(props.file.fileId, signal.value);
  }

  part.forEach(loadPage);
}

async function loadFile() {
  state.value = State.Loading;
  previewFilesStore.updateLastRevisionFilesState({ fileId: props.file.id, state: state.value });

  try {
    void FileApi.addView(props.file.fileId, signal.value);
    const pagesCount = await FileApi.getPagesCount(props.file.fileId, props.file.hash, signal.value);
    pages.value = createPages(pagesCount);
    state.value = State.Defined;
    void loadRange(1);
  } catch {
    state.value = State.Error;
  } finally {
    previewFilesStore.updateLastRevisionFilesState({ fileId: props.file.id, state: state.value });
  }
}

function onImageLoad(pageNumber) {
  if (pageNumber === currentPage.value) {
    carousel.value.recalculate();
  }
}

function onRetry(pageNumber) {
  if (state.value === State.Defined) {
    loadRange(pageNumber);
  } else {
    loadFile();
  }
}

function openReviewModal() {
  fileReviewModal.value.show();
}

function openFileEditor() {
  fileEditorStore.addFile({ file: props.file, router });
}
</script>

<template>
  <PreviewBlock
    class="file-preview"
    title="Просмотр"
    no-padding
    @resize="onResize"
  >
    <template #title-postfix>
      <FileTruncatedLink
        :file="file"
        is-muted
      />
    </template>

    <template #header-postfix="{ isOpen }">
      <div class="header-postfix">
        <Transition name="opacity-fast">
          <FilePreviewPagination
            v-if="isOpen && state === State.Defined && pages.length > 1"
            :current="currentPage"
            :count="pages.length"
            @prev="$refs.carousel.goToPrev()"
            @next="$refs.carousel.goToNext()"
          />
        </Transition>

        <EditReviewButton
          :compact="isCompact"
          :permissions="permissions"
          @edit="openFileEditor"
          @review="openReviewModal"
        />

        <FileReviewModal
          ref="fileReviewModal"
          :file="file"
          :pages="pages"
          :state="state"
          :initial="currentPage"
          @fetch="loadRange"
          @retry="onRetry"
        />
      </div>
    </template>

    <div class="body">
      <LoaderUi
        v-if="state === State.Loading"
        position="static"
        color="white"
        text="Загрузка данных"
      />

      <FileError
        v-else-if="state === State.Error"
        @retry="loadFile"
      />

      <CarouselUi
        v-else-if="state === State.Defined"
        ref="carousel"
        v-model="currentPage"
        :slides="slides"
      >
        <template #default="{ item, index }">
          <FilePreviewPage
            size="l"
            :state="item.state"
            :url="item.url"
            :alt="item.alt"
            :icon="item.icon"
            @load="onImageLoad(index + 1)"
            @retry="onRetry(index + 1)"
          />
        </template>
      </CarouselUi>
    </div>
  </PreviewBlock>
</template>

<style scoped lang="scss">
.file-truncated-link {
  transform: translateY(1.5px);
}

.header-postfix {
  display: flex;
}

.loader-ui,
.file-error {
  aspect-ratio: 1 / 1.414;
}

.body {
  overflow: hidden;
  border-radius: 0 0 8px 8px;
}
</style>
