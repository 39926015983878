<template>
  <div class="sheet-document-preview--common">
    <div class="sheet-document-preview--common--title">
      {{ doc['Тип'] }}
    </div>
    <p class="sheet-document-preview--common--info _tight">
      <span class="label _muted">Проект:</span>
      <b>{{ doc['Проект'] }}</b>
    </p>
    <p class="sheet-document-preview--common--info">
      <span class="label _muted">Содержание:</span>
      <b v-html="content" />
    </p>
    <p
      v-if="doc['Комментарий']"
      class="sheet-document-preview--common--info"
    >
      <span class="label _muted">Комментарий:</span>
      <span>{{ doc['Комментарий'] }}</span>
    </p>
    <p class="sheet-document-preview--common--info">
      <span class="label _muted">Регистратор:</span>
      <span>{{ doc['Регистратор'] }}</span>
    </p>
    <p class="sheet-document-preview--common--info">
      <span class="label _muted">Контролер:</span>
      <span>{{ doc['Контролер'] }}</span>
    </p>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { useMaskStore } from '@/stores/mask.js';

export default {
  name: 'CommonDocumentPreview',
  props: ['doc'],
  mounted() {
    this.fetchMaskAction(this.doc['Содержание_MASK']);
  },
  updated() {
    this.fetchMaskAction(this.doc['Содержание_MASK']);
  },
  computed: {
    ...mapState(useMaskStore, ['maskStringGetter']),
    content() {
      return this.maskStringGetter(this.doc['Содержание_MASK'], this.doc['Содержание']);
    },
  },
  methods: {
    ...mapActions(useMaskStore, ['fetchMaskAction']),
  },
};
</script>

<style scoped lang="scss">
.sheet-document-preview--common--info {
  &:not(:last-child) {
    margin-bottom: 12px;

    &._tight {
      margin-bottom: 4px;
    }
  }
}

.label {
  min-width: 100px;
}
</style>
