import axios from 'axios';
import { IgnorableAxiosException } from '@/common/models/exception/ignorable-axios-exception.js';
import { IgnorableApiException } from '@/common/models/exception/ignorable-api-exception.js';
import { useAuthStore } from '@/stores/auth.js';
import { useCacheStore } from '@/stores/cache.js';

const instance = axios.create({
  baseURL: 'https://api.alpha-doc.ru/v1.1/',
  headers: { 'Content-Type': 'text/plain' },
});

export default class Api {
  static async post(url, request = {}, signal, mapData = true, cacheConfig) {
    const cacheStore = useCacheStore();

    if (cacheConfig) {
      const cache = cacheStore.get(cacheConfig);

      if (cache) {
        return cache;
      }
    }

    const authStore = useAuthStore();
    const accessToken = authStore.accessToken;

    const modifiedRequest = {
      ...request,
      token: accessToken,
    };

    const response = await instance.post(url, modifiedRequest, { signal }).catch((error) => {
      // TODO: Убрать игнорирование ошибки, после того как очистка стора перестанет приводить к отправке запросов
      if (error.response?.status === 403) {
        throw new IgnorableAxiosException(error);
      }
      throw error;
    });

    const data = response.data;

    if (!data || typeof data.error !== 'boolean') {
      throw {
        error: true,
        error_code: 500,
        error_message: 'Ошибка сервера',
      };
    }

    if (!data.error) {
      const value = mapData ? data.data : data;

      if (cacheConfig) {
        cacheStore.add({ ...cacheConfig, value });
      }

      return value;
    }

    if (data.error_code !== 401) {
      throw data;
    }

    if (accessToken === authStore.accessToken) {
      try {
        await authStore.refresh();
      } catch (error) {
        throw new IgnorableApiException(error);
      }
    }

    return Api.post(url, request, signal, mapData);
  }
}
