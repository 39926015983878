<template>
  <div class="table-filters-header">
    <div>Фильтр</div>
    <div v-if="showAdd">
      <a
        v-show="!conditionButtonDisabled"
        href="#"
        @click.prevent="$parent.addConditions"
      >
        Добавить условие
      </a>
      <a v-show="conditionButtonDisabled"> Добавить условие </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FiltersHeader',
  props: {
    showAdd: {
      type: Boolean,
      default: false,
    },
    conditionButtonDisabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
