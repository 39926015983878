import { createPinia } from 'pinia';
import { createPersistedState } from 'pinia-plugin-persistedstate';
import SecureLS from 'secure-ls';
import StoreId from '@/common/enums/store-id.js';

const pinia = createPinia();

// TODO: Есть ли необходимость в шифровании?
const ls = new SecureLS({
  encodingType: 'aes',
  encryptionSecret: '3jh38353hiiw8o4hh333',
  isCompression: false,
});

const KEY_PREFIX = 'pinia-';

const persistedState = createPersistedState({
  key: (id) => KEY_PREFIX + btoa(id),
  storage: {
    getItem: (key) => ls.get(key),
    setItem: (key, value) => ls.set(key, value),
  },
});

pinia.use(persistedState);

const stores = new Map();
pinia.use(({ store }) => void stores.set(store.$id, store));

// TODO: Уточнить, какие сторы требуют синхронизации
const SYNC_STORE_IDS = [
  StoreId.Auth,
  StoreId.Autocorrect,
  StoreId.Roles,
  StoreId.System,
  StoreId.User,
  StoreId.FileEditor,
];

window.addEventListener('storage', ({ key }) => {
  if (!key?.startsWith(KEY_PREFIX)) {
    return;
  }

  const id = atob(key.replace(KEY_PREFIX, ''));

  if (!SYNC_STORE_IDS.includes(id)) {
    return;
  }

  const store = stores.get(id);

  if (JSON.stringify(store.$state) === ls.get(key)) {
    return;
  }

  store.$hydrate();
});

export default pinia;
