<template>
  <div class="document-page-container">
    <div
      v-if="showDocument"
      class="document-page"
    >
      <DocumentPreviewComponent
        :init_doc_id="docGetter.DOC_ID"
        :component-width="componentWidth"
      />
    </div>
    <LoaderUi v-else />
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import VuexAdapter from '@/services/vuex-adapter.js';
import { DOC_MARK_VIEW, DOCS_GET } from '@/configs/end-points.js';
import DocumentPreviewComponent from '@/components/doc/preview/DocumentPreview.vue';
import { getDate } from '@/common/utils/utils.js';
import { DocumentPreview } from '@/common/models/preview/document-preview.js';
import LoaderUi from '@/components/ui/LoaderUi.vue';
import { useActionsStore } from '@/stores/actions.js';
import { usePreviewStore } from '@/stores/preview.js';
import { useRowStore } from '@/stores/row.js';
import { useTitle } from '@vueuse/core';

export default {
  name: 'DocumentPage',
  components: {
    LoaderUi,
    DocumentPreviewComponent,
  },
  props: ['componentWidth'],
  setup() {
    const title = useTitle();
    return { title };
  },
  data: () => ({
    time_mark_view: 3 * 1000,
    timeout_mark_view: null,
    error_message: false,
  }),
  mounted() {
    this.initData();
  },
  unmounted() {
    this.clearTimeoutMarkView();
    this.clearDocAction();
  },
  computed: {
    ...mapState(usePreviewStore, {
      hasEntityIdPreview: 'hasEntityIdPreviewGetter',
    }),
    ...mapState(useRowStore, {
      docGetter: VuexAdapter.getNameRowGetter(DOCS_GET),
      docLoaderStatusGetter: VuexAdapter.getNameRowLoaderGeneralGetter(DOCS_GET),
    }),
    docId() {
      return Number(this.$route.params.id);
    },
    showDocument() {
      return !this.docNotFound && this.docGetter.DOC_ID === this.docId && this.hasEntityIdPreview;
    },
    validDocumenId() {
      return this.docId && this.docId > 0;
    },
    docNotFound() {
      return !this.validDocumenId || (!this.docLoaderStatusGetter && !this.docGetter?.DOC_ID);
    },
    formattedTitle() {
      if (this.validDocumenId && this.showDocument) {
        const docNumber = this.docGetter['Рег.номер'] ?? this.docGetter['Номер'],
          docDate =
            this.docGetter['Дата'] !== undefined
              ? ' от ' + this.getDate(this.docGetter['Дата'], 'dd.MM.yyyy, HH:mm')
              : '';
        return `${this.docGetter['Тип']} ${docNumber} ${docDate} ${this.docGetter['Содержание']} - АЛЬФА ДОК`;
      }
      return 'АЛЬФА ДОК - Автоматизация бизнес-процессов';
    },
  },
  methods: {
    ...mapActions(useActionsStore, {
      docMarkViewAction: VuexAdapter.getNameAction(DOC_MARK_VIEW),
    }),
    ...mapActions(usePreviewStore, {
      initPreview: 'initPreviewAction',
      setEntityIdPreview: 'setEntityIdPreviewAction',
    }),
    ...mapActions(useRowStore, {
      clearDocAction: VuexAdapter.getNameClearRowMutation(DOCS_GET),
    }),
    to404() {
      this.$router
        .push({ path: '/404', query: { redirectFrom: this.$route.fullPath } })
        .catch((e) => (this.error_message = e.error_message));
    },
    initData() {
      if (!this.validDocumenId) {
        this.to404();
      }
      this.setEntityIdPreview(this.docId);
      this.initPreview(DocumentPreview.intefaceIdent)
        .then(() => {
          this.timeout_mark_view = setTimeout(() => {
            this.docMarkViewAction({ doc_id: this.docId });
          }, this.time_mark_view);
        })
        .catch((e) => {
          console.error(e);
          this.setEntityIdPreview(null);
          this.to404();
        });
    },
    clearTimeoutMarkView() {
      if (this.timeout_mark_view) {
        clearTimeout(this.timeout_mark_view);
      }
    },
    getDate,
  },
  watch: {
    docId() {
      this.clearTimeoutMarkView();
      this.clearDocAction();
      this.initData();
    },
    docNotFound(val) {
      if (val) {
        this.to404();
      }
    },
    formattedTitle: {
      handler(tabTitle) {
        this.title = tabTitle;
      },
      immediate: true,
    },
  },
};
</script>
