export const COUNTERPARTIES_REGIONS_SEGMENTS__VIEW = 'counterparties_regions_segments.view';
export const COUNTERPARTIES__EDIT = 'counterparties.edit';
export const COUNTERPARTIES__ACCESS_CONTROL = 'counterparties.access_control';
export const COUNTERPARTIES__BANK_DETAILS = 'counterparties.bank_details';
export const COUNTERPARTIES__EDITING_ADDRESS_AND_CONTACT_MASKS = 'counterparties.editing_address_and_contact_masks';
export const COUNTERPARTIES__CITY_FIELD = 'counterparties.city_field';
export const COUNTERPARTIES__CODE_FIELD = 'counterparties.code_field';
export const REGIONS__ADD = 'regions.add';
export const REGIONS__EDIT = 'regions.edit';
export const SEGMENTS__ADD = 'segments.add';
export const SEGMENTS__EDIT = 'segments.edit';
export const BANKS__EDIT = 'banks.edit';
export const LEGAL_FORMS__EDIT = 'legal_forms.edit';
export const DOCUMENTS__CONTROLS = 'documents.controls';
export const DOCUMENTS__VIEW = 'documents.view';
export const DOCUMENTS__EDIT = 'documents.edit';
export const DOCUMENTS__ACCESS = 'documents.access';
export const DOCUMENTS__FILES__DELETED = 'documents.files.deleted';
export const DOCUMENTS__RESOLUTIONS__DELETED = 'documents.resolutions.deleted';
export const DOCUMENTS__VIEWS = 'documents.views';
export const DOCUMENTS__QUALIFIED_ES_SYSTEM = 'documents.qualified_es_system';
export const DOCUMENTS__REGISTER = 'documents.register';
export const DOCUMENTS__TAKE_CONTROL = 'documents.take_control';
export const DOCUMENTS__REGISTRATION_DATA = 'documents.registration_data';
export const DOCUMENTS__DELETE = 'documents.delete';
export const DASHBOARD = 'dashboard';
export const SYSTEM__USERS_GROUPS_ROLES_AUDIT = 'system.users_groups_roles_audit';
export const SYSTEM__COLOR_TAGS = 'system.color_tags';
export const SYSTEM__INFORMATION_EXCHANGE_MASTER = 'system.information_exchange_master';
export const POSITIONS__VIEW = 'positions.view';
export const POSITIONS__EDIT = 'positions.edit';
export const POSITIONS__ACCESS_CONTROL = 'positions.access_control';
export const PROJECTS__VIEW = 'projects.view';
export const PROJECTS__EDIT = 'projects.edit';
export const PROJECTS__ACCESS_CONTROL = 'projects.access_control';
export const CALENDAR__VIEW = 'calendar.view';
export const CALENDAR__EDIT = 'calendar.edit';

export const EVENTS = {
  [COUNTERPARTIES_REGIONS_SEGMENTS__VIEW]: 1, // Контрагенты, Регионы, Сегменты. Просмотр +++
  [COUNTERPARTIES__EDIT]: 2, // Контрагенты. Редактирование
  [COUNTERPARTIES__ACCESS_CONTROL]: 3, // Контрагенты. Управление доступом
  [COUNTERPARTIES__BANK_DETAILS]: 30, // Контрагенты. Банковские реквизиты
  [COUNTERPARTIES__EDITING_ADDRESS_AND_CONTACT_MASKS]: 32, // Контрагенты. Редактирование масок адресов и контактов
  [COUNTERPARTIES__CITY_FIELD]: 33, // Контрагенты. Поле город
  [COUNTERPARTIES__CODE_FIELD]: 34, // Контрагенты. Поле код

  [REGIONS__ADD]: 4, // Регионы. Добавлять элементы
  [REGIONS__EDIT]: 5, // Регионы. Редактировать элементы

  [SEGMENTS__ADD]: 6, // Сегменты. Добавлять элементы
  [SEGMENTS__EDIT]: 7, // Сегменты. Редактировать элементы

  [BANKS__EDIT]: 8, // Банки. Редактирование

  [LEGAL_FORMS__EDIT]: 9, // Правовые формы. Редактирование

  [DOCUMENTS__CONTROLS]: 11, // Документы. На контроле +++
  [DOCUMENTS__VIEW]: 12, // Документы. Просмотр +++
  [DOCUMENTS__EDIT]: 23, // Документы. Редактирование
  [DOCUMENTS__ACCESS]: 24, // Документы. Доступ +++
  [DOCUMENTS__FILES__DELETED]: 25, // Документы. Файлы. Удалять
  [DOCUMENTS__RESOLUTIONS__DELETED]: 26, // Документы. Резолюции. Удалять
  [DOCUMENTS__VIEWS]: 28, // Документы. Просмотры +++
  [DOCUMENTS__QUALIFIED_ES_SYSTEM]: 37, // Документы. Система квалифицированной ЭП
  [DOCUMENTS__REGISTER]: 38, // Документы. Регистрировать
  [DOCUMENTS__TAKE_CONTROL]: 40, // Документы. Брать на контроль
  [DOCUMENTS__REGISTRATION_DATA]: 31, // Документы. Регистрационные данные
  [DOCUMENTS__DELETE]: 35, // Документы. Удалять

  [DASHBOARD]: 29, // Приборка

  [SYSTEM__USERS_GROUPS_ROLES_AUDIT]: 13, // Система. Пользователи,Группы,Роли,Аудит
  [SYSTEM__COLOR_TAGS]: 36, // Система цветовых тегов
  [SYSTEM__INFORMATION_EXCHANGE_MASTER]: 39, // Система. Мастер информационного обмена

  [POSITIONS__VIEW]: 14, // Должности. Просмотр
  [POSITIONS__EDIT]: 15, // Должности. Редактирование
  [POSITIONS__ACCESS_CONTROL]: 16, // Должности. Управление доступом

  [PROJECTS__VIEW]: 17, // Проекты. Просмотр
  [PROJECTS__EDIT]: 18, // Проекты. Редактирование
  [PROJECTS__ACCESS_CONTROL]: 19, // Проекты. Управление доступом

  [CALENDAR__VIEW]: 20, // Календарь. Просмотр
  [CALENDAR__EDIT]: 21, // Календарь. Редактирование
};
