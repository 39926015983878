import { defineStore } from 'pinia';
import { useAuthStore } from '@/stores/auth.js';
import { useUserStore } from '@/stores/user.js';
import { useCacheStore } from '@/stores/cache.js';
import { useSystemStore } from '@/stores/system.js';
import { useAutocorrectStore } from '@/stores/autocorrect.js';
import { usePreviewStore } from '@/stores/preview.js';
import { useCommissionsStore } from '@/stores/commissions.js';
import { useControlsStore } from '@/stores/controls.js';
import { useRolesStore } from '@/stores/roles.js';
import { useSignStore } from '@/stores/sign.js';
import { useListsStore } from '@/stores/lists.js';
import { useRowStore } from '@/stores/row.js';
import { useTableStore } from '@/stores/table.js';
import { useColorsStore } from '@/stores/colors.js';
import StoreId from '@/common/enums/store-id.js';
import { useFileEditorStore } from '@/stores/file-editor.js';

/** @deprecated Наследие от Vuex, требует пересмотра */
export const useCommonStore = defineStore(StoreId.Common, {
  actions: {
    /*
      TODO:
       1. Очистка некоторых модулей приводит к повторной отправке запросов,
        а так как модуль авторизации к тому времени будет уже очищен, то будет получена 403.
       2. В методе очищаются не все сторы
       3. Уточнить какие сторы должны очищаться при выходе из аккаунта, а какие при обновлении (условие clearAuth)
       4. Учитывать синхронизацию состояния между вкладками
    */
    cleanAllStoresAction(clearAuth = true) {
      if (clearAuth) {
        [useAuthStore(), useUserStore(), useSystemStore(), useRolesStore()].forEach((store) => store.$reset());
      }

      [
        useCacheStore(),
        usePreviewStore(),
        useCommissionsStore(),
        useControlsStore(),
        useColorsStore(),
        useAutocorrectStore(),
        useFileEditorStore(),
      ].forEach((store) => store.$reset());

      [useSignStore(), useListsStore(), useRowStore(), useTableStore()].forEach((store) => store.clear());
    },
  },
});
