<template>
  <div
    ref="resize"
    class="preview-block"
  >
    <header class="header">
      <div class="left">
        <button
          class="button-toggle"
          @click="isOpen = !isOpen"
        >
          <ChevronIcon
            v-rotate="{ isRotated: isOpen, from: -90, to: 0 }"
            class="chevron"
          />

          <span class="title">{{ title }}</span>

          <ReducedBadge :value="amount" />
        </button>

        <slot
          name="title-postfix"
          :is-open="isOpen"
        />
      </div>

      <slot
        name="header-postfix"
        :is-open="isOpen"
      />
    </header>

    <Transition name="content">
      <div
        v-if="isOpen"
        class="content"
        :class="{ '_no-padding': noPadding }"
      >
        <slot />
      </div>
    </Transition>

    <LoaderUi v-if="loading" />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import ChevronIcon from '@/assets/icons/chevron.svg';
import ReducedBadge from '@/components/common/ReducedBadge.vue';
import Rotate from '@/directives/rotate';
import resize from '@/mixins/resize-mixin.js';
import ResizeMixin from '@/mixins/resize-mixin.js';
import LoaderUi from '@/components/ui/LoaderUi.vue';

export default defineComponent({
  name: 'PreviewBlock',
  components: {
    LoaderUi,
    ReducedBadge,
    ChevronIcon,
  },
  directives: {
    Rotate,
  },
  mixins: [ResizeMixin],
  props: {
    title: {
      type: String,
      required: true,
    },
    amount: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['resize'],
  data() {
    return {
      isOpen: true,
      maxHeight: 0,
    };
  },
  mounted() {
    this.setMaxHeight();
  },
  methods: {
    setMaxHeight() {
      const content = this.$refs.resize.children[1];

      if (
        !content ||
        content.classList.contains('content-enter-active') ||
        content.classList.contains('content-leave-active')
      ) {
        return;
      }

      this.maxHeight = `${content.getBoundingClientRect().height}px`;
    },
    onResize(sizes) {
      this.$emit('resize', sizes);
      this.setMaxHeight();
    },
  },
});
</script>

<style scoped lang="scss">
.preview-block {
  position: relative;
  background-color: var(--color-white);
  border-radius: 8px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 16px;
}

.left {
  min-width: 0;

  display: flex;
  align-items: center;

  color: var(--color-gray-1000);

  &:not(:last-child) {
    margin-right: 8px;
  }
}

.button-toggle {
  padding: 8px;
  margin: -8px;

  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-right: 0;
  }
}

.chevron {
  margin-right: 8px;
  fill: var(--color-gray-700);
}

.title {
  color: var(--color-gray-1000);
  font-size: var(--font-size-xl);
  line-height: var(--line-height-xl);
  font-weight: var(--font-weight-bold);

  &:not(:last-child) {
    margin-right: 8px;
  }
}

.content {
  &:not(._no-padding) {
    padding: 0 16px 16px;
  }

  &-enter-active,
  &-leave-active {
    max-height: v-bind(maxHeight);
    transition:
      opacity var(--transition),
      max-height var(--transition),
      padding var(--transition);
  }

  &-enter-from,
  &-leave-to {
    max-height: 0;
    opacity: 0;

    &:not(._no-padding) {
      padding-bottom: 0;
    }
  }
}

.loader-ui {
  border-radius: 8px;
}
</style>
