<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import { DocumentEditor } from '@onlyoffice/document-editor-vue';
import FileApi from '@/services/api/file-api.js';
import { useRoute } from 'vue-router';
import LoaderUi from '@/components/ui/LoaderUi.vue';
import FatalError from '@/components/general/FatalError.vue';
import { useTitle } from '@vueuse/core';
import { useFileEditorStore } from '@/stores/file-editor.js';

const ID = 'file-editor';

const fileEditorStore = useFileEditorStore();

const title = useTitle();
const router = useRoute();

const isLoading = ref(false);
const error = ref();
const editorData = ref();

const file = computed(() => fileEditorStore.getFile(router.params.id));

watch(file, (file) => {
  if (!file) {
    showNotFoundError();
  }
});

onMounted(async () => {
  if (!file.value) {
    showNotFoundError();
    return;
  }

  if (file.value.isOpen) {
    showDuplicateError();
    return;
  }

  fileEditorStore.setFileState({ id: file.value.id, isOpen: true });
  title.value = (file.value.documentId ? `${file.value.documentId} - ` : '') + `«${file.value.name}» редактирование`;
  window.addEventListener('beforeunload', onBeforeUnload);

  isLoading.value = true;
  try {
    editorData.value = await FileApi.getEditConfig(file.value.fileId, file.value.timestamp, {
      onAppReady,
      onDocumentStateChange,
      onDownloadAs,
    });
  } catch (e) {
    showApiError(e?.error_code, e?.error_message);
  }
});

function showNotFoundError() {
  error.value = {
    title: 404,
    message:
      'Файл, который Вы пытаетесь отредактировать, не существует!<br/>Вернитесь назад или перейдите на главную страницу',
  };
}

function showDuplicateError() {
  error.value = {
    title: 'Ошибка',
    message:
      'Файл, который Вы пытаетесь отредактировать, открыт в другом окне!<br/>Вернитесь назад или перейдите на главную страницу',
  };
}

function showApiError(code, message) {
  error.value = {
    title: code || 500,
    message: `При загрузке конфигурации возникла ошибка<br>Вернитесь назад или перейдите на главную страницу${message ? `<br>${message}` : ''}`,
  };
}

function showEditorError() {
  error.value = {
    title: 'Ошибка',
    message: `При загрузке редактора возникла ошибка<br>Вернитесь назад или перейдите на главную страницу`,
  };
}

function onAppReady() {
  isLoading.value = false;
}

function onDocumentStateChange({ data: isMutated }) {
  if (!isMutated) {
    window.DocEditor.instances[ID].downloadAs();
  }
}

function onLoadComponentError(code, message) {
  console.error(`Код ошибки: ${code}. Сообщение: ${message}`);
  showEditorError();
}

function onDownloadAs(event) {
  fileEditorStore.setFileUrl({ id: file.value.id, url: event.data.url });
}

function onBeforeUnload() {
  if (file.value) {
    fileEditorStore.setFileState({ id: file.value.id, isOpen: false });
  }
}
</script>

<template>
  <div class="file-editor-page">
    <FatalError
      v-if="error"
      :title="error.title"
      sub-title="Упс, что-то пошло не так..."
      :description="error.message"
    />

    <div
      v-else
      class="wrapper"
    >
      <LoaderUi
        v-if="isLoading"
        text="Загрузка конфигурации"
      />

      <DocumentEditor
        v-if="editorData"
        :id="ID"
        :document-server-url="editorData.url"
        :config="editorData.config"
        :on-load-component-error="onLoadComponentError"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.file-editor-page,
.wrapper {
  height: 100%;
}
</style>
