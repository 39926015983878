<template>
  <ModalUi
    ref="modal"
    title="Редактирование адреса"
    :error-message="errorMessage"
  >
    <template #body>
      <FormBuilder
        ref="formBuilder"
        v-model="form"
        margins
        :fields="fields"
      />
    </template>

    <template #footer>
      <CancelButton @click="hide" />
      <SaveButton @click="apply" />
    </template>
  </ModalUi>
</template>

<script>
import { defineComponent } from 'vue';
import { mapActions, mapState } from 'pinia';
import CancelButton from '@/components/buttons/CancelButton.vue';
import VuexAdapter from '@/services/vuex-adapter.js';
import { ADDRESS_EDIT, ADDRESS_TYPES_GET, CLIENTS_ADDRESSES_GET } from '@/configs/end-points.js';
import FormBuilder from '@/components/form/FormBuilder.vue';
import addressClientsForm from '@/configs/forms/clients/address';
import SaveButton from '@/components/buttons/SaveButton';
import ModalUi from '@/components/ui/ModalUi.vue';
import ModalMixin from '@/mixins/modal-mixin.js';
import { useActionsStore } from '@/stores/actions.js';
import { usePreviewStore } from '@/stores/preview.js';
import { useListsStore } from '@/stores/lists.js';

export default defineComponent({
  name: 'EditAddressModal',
  components: {
    ModalUi,
    SaveButton,
    FormBuilder,
    CancelButton,
  },
  mixins: [ModalMixin],
  props: {
    config: {
      type: Object,
      required: true,
    },
    parentTableId: {
      type: Number,
      default: 6,
    },
  },
  data() {
    return {
      apply_disabled: false,
      errorMessage: null,
      form: {
        address_id: this.config['ADDRESS_ID'],
        address_type: this.config['Тип'],
        address: this.config['Адрес'],
        comment: this.config['Примечание'],
      },
      fields: addressClientsForm(this),
    };
  },
  mounted() {
    this.getClientAddressTypesAction({
      where: 'PARENT_TABLE_ID = ' + this.parentTableId,
    }).then(() => {
      this.form.address_type =
        this.clientAddressTypesGetter.filter((r) => r.code === this.form.address_type)[0] || null;
    });
  },
  computed: {
    ...mapState(useListsStore, {
      clientAddressTypesGetter: VuexAdapter.getNameSelectGetter(ADDRESS_TYPES_GET),
    }),
  },
  methods: {
    ...mapActions(useActionsStore, {
      editAddressAction: VuexAdapter.getNameAction(ADDRESS_EDIT),
    }),
    ...mapActions(useListsStore, {
      getClientAddressTypesAction: VuexAdapter.getNameAction(ADDRESS_TYPES_GET),
    }),
    ...mapActions(usePreviewStore, ['refreshPartlyPreviewAction']),
    apply() {
      this.errorMessage = null;

      if (!this.$refs.formBuilder.validate()) {
        return;
      }

      this.showLoader();

      const data = {
        ...this.form,
        address_type: this.form.address_type?.label,
      };

      this.editAddressAction(data)
        .then(() => {
          this.refreshPartlyPreviewAction(CLIENTS_ADDRESSES_GET);
          this.hide();
        })
        .catch((e) => {
          this.errorMessage = e.error_message;
          this.hideLoader();
        });
    },
  },
});
</script>
