import { defineComponent } from 'vue';
import Constants from '@/configs/constants';
import { getDate } from '@/common/utils/utils.js';
import { mapActions, mapState } from 'pinia';
import { usePreviewStore } from '@/stores/preview.js';
import { useRolesStore } from '@/stores/roles.js';
import { useListsStore } from '@/stores/lists.js';

/** @deprecated Используйте composables */
export default defineComponent({
  props: ['componentWidth'],
  methods: {
    ...mapActions(usePreviewStore, ['refreshPreviewAction', 'initPreviewAction', 'setEntityIdPreviewAction']),
    getDate,
  },
  computed: {
    ...mapState(useRolesStore, ['accessToEvent']),
    ...mapState(useListsStore, ['docLastRoutePointDeclineGetter']),
    ...mapState(usePreviewStore, ['entityIdPreview', 'initFirstDataPreview']),
    classDocumentSheetContainer: function () {
      return {
        'sheet-document-preview-container': true,
        'sheet-document-preview-container-md':
          this.componentWidth > Constants.resolutionPoints.tiny &&
          this.componentWidth <= Constants.resolutionPoints.small,
        'sheet-document-preview-container-sm': this.componentWidth <= Constants.resolutionPoints.tiny,
      };
    },
  },
  watch: {
    $route() {
      this.setEntityIdPreviewAction(null);
    },
    entityIdPreview(val) {
      if (val) {
        this.refreshPreviewAction();
      }
    },
  },
  unmounted() {
    this.setEntityIdPreviewAction(null);
  },
});
