import { StepCode } from '@/common/consts/document-master/common';

export default class StepContractorCreator {
  static createList(response) {
    return response.map((item, index) => StepContractorCreator._create(index, response.length, item));
  }

  static _create(number, count, response) {
    return {
      code: StepCode.Contractor,
      title: `Выбор исполнителей ${number + 1} из ${count}`,
      menuTitle: `Исполнитель ${number + 1}/${count}`,
      tooltip: StepContractorCreator._getTooltip(response.RPT_ID, response['Примечание']),
      dot: StepContractorCreator._getColor(response.RPT_ID),
      item: response,
      number,
    };
  }

  static _getTooltip(typeId, note) {
    const choice = StepContractorCreator._getChoice(typeId);
    return `Выберите ${choice}.${note ? ` Примечание: ${note}` : ''}`;
  }

  static _getChoice(typeId) {
    switch (typeId) {
      case 1:
        return 'исполнителя для точки маршрута';
      case 2:
        return 'согласующего для точки маршрута';
      case 3:
        return 'пользователя для отправки уведомления';
    }
  }

  static _getColor(typeId) {
    switch (typeId) {
      case 1:
        return 'green';
      case 2:
        return 'blue';
      case 3:
        return 'light-blue';
    }
  }
}
