<template>
  <div class="page-wrapper">
    <div class="page-alpha">
      <div class="block">
        <LogoIcon
          class="logo"
          alt="АЛЬФАДОК"
        />
        <div class="credits">
          <div class="title">АЛЬФАДОК</div>
          <div class="sub-title">Система электронного документооборота</div>
          <div class="release">
            <GitReleaseData />
          </div>
        </div>

        <UpdateButton />
      </div>

      <div class="contacts">
        <a
          href="//www.alpha-doc.ru"
          target="_blank"
        >
          <span class="title">www.alpha-doc.ru</span>
        </a>
        <a href="mailto:info@alpha-doc.ru">
          <span class="title">info@alpha-doc.ru</span>
        </a>
        <a href="tel:+74956618199">
          <span class="title">+7 495 661-81-99</span>
        </a>
      </div>
    </div>

    <div class="page-form">
      <div class="block">
        <div class="header">Авторизация</div>
        <form @submit.prevent="onSubmit">
          <div
            class="error"
            :class="{ _pale: form.loading, _red: error_message }"
          >
            {{ getErrorMessage() }}
          </div>
          <div class="field-login">
            <UserIcon class="prepend" />
            <input
              ref="test"
              v-model="form.user_login"
              class="field"
              type="text"
              placeholder="Логин"
              required="required"
              :disabled="form.loading"
            />
          </div>

          <div class="field-password">
            <LockIcon class="prepend" />
            <input
              v-model="form.user_password"
              class="field"
              :type="form.field_user_password_type_is_password ? 'password' : 'text'"
              placeholder="Пароль"
              required="required"
              :disabled="form.loading"
            />
            <VisibilityIcon
              v-if="form.field_user_password_type_is_password"
              class="append"
              @click="changePasswordFieldType"
            />
            <VisibilityOffIcon
              v-else
              class="append"
              @click="changePasswordFieldType"
            />
          </div>

          <ButtonUi
            type="submit"
            :disabled="form.loading || !formFilled"
          >
            <SpinnerUi
              v-if="form.loading"
              v-prefix
              size="s"
              color="white"
            />
            <LockIcon
              v-else
              v-prefix
            />
            <span>Войти</span>
          </ButtonUi>

          <div class="terms">
            Нажимая кнопку «Войти», вы принимаете<br />
            условия <a href="//www.alpha-doc.ru">пользовательского соглашения</a>
          </div>
        </form>
      </div>
    </div>

    <div class="page-contacts-buttons">
      <a
        href="//www.alpha-doc.ru"
        target="_blank"
      >
        <LinkIcon class="icon" />
      </a>
      <a href="mailto:info@alpha-doc.ru">
        <MailIcon class="icon" />
      </a>
      <a href="tel:+74956618199">
        <PhoneIcon class="icon" />
      </a>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapActions, mapState } from 'pinia';
import GitReleaseData from '@/components/common/GitReleaseData.vue';
import ButtonUi from '@/components/ui/ButtonUi.vue';
import SpinnerUi from '@/components/ui/SpinnerUi.vue';
import LockIcon from '@/assets/icons/lock.svg';
import UpdateButton from '@/components/buttons/UpdateButton.vue';
import LogoIcon from '@/assets/icons/logo.svg';
import UserIcon from '@/assets/icons/user.svg';
import VisibilityIcon from '@/assets/icons/visibility.svg';
import VisibilityOffIcon from '@/assets/icons/visibility-off.svg';
import LinkIcon from '@/assets/icons/link-s.svg';
import MailIcon from '@/assets/icons/mail.svg';
import PhoneIcon from '@/assets/icons/phone.svg';
import { useAuthStore } from '@/stores/auth.js';

export default defineComponent({
  name: 'LoginPage',
  components: {
    PhoneIcon,
    MailIcon,
    LinkIcon,
    VisibilityIcon,
    VisibilityOffIcon,
    UserIcon,
    LogoIcon,
    UpdateButton,
    LockIcon,
    SpinnerUi,
    ButtonUi,
    GitReleaseData,
  },
  data: () => ({
    form: {
      user_login: '',
      user_password: '',
      loading: false,
      field_user_password_type_is_password: true,
    },
    error_message: false,
  }),
  mounted() {
    if (this.isAuthorized) {
      this.$router.replace('/').catch((e) => (this.error_message = e.error_message));
    }
  },
  computed: {
    ...mapState(useAuthStore, ['isAuthorized']),
    formFilled() {
      return this.form.user_login && this.form.user_password;
    },
  },
  methods: {
    ...mapActions(useAuthStore, ['login']),
    onSubmit() {
      this.form.loading = true;
      const request = this.getLoginRequest(this.form);

      this.login(request)
        .then(() => {
          this.$router.replace('/').catch((e) => (this.error_message = e.error_message));
        })
        .catch((e) => {
          this.form.loading = false;
          this.error_message = e.error_message;
        });
    },
    getLoginRequest(data) {
      const [user_login, org_ident] = data.user_login.split('@');

      return {
        user_login,
        user_password: data.user_password,
        org_ident,
      };
    },
    changePasswordFieldType: function () {
      this.form.field_user_password_type_is_password = !this.form.field_user_password_type_is_password;
    },
    getErrorMessage() {
      if (this.error_message) {
        return this.error_message;
      } else {
        return 'Введите логин и пароль';
      }
    },
  },
});
</script>

<style scoped lang="scss">
.error {
  margin-top: 1.5rem;

  &._pale {
    opacity: 0.5;
  }

  &._red {
    color: var(--color-red-500) !important;
  }
}

.field-password {
  margin-bottom: 1rem;
}

.button-ui {
  width: 100%;
}
</style>
