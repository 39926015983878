import { defineStore } from 'pinia';
import StoreId from '@/common/enums/store-id.js';

export const useMasterContentStore = defineStore(StoreId.MasterContent, {
  state: () => ({
    content: null,
  }),
  actions: {
    set(payload = null) {
      this.content = payload;
    },
  },
});
