<script setup>
import { nextTick, ref } from 'vue';
import FileChangesConfirmModal from '@/components/modals/File/FileChangesConfirmModal.vue';
import FileUploadModal from '@/components/modals/File/FileUploadModal.vue';
import useFileEditor from '@/composables/use-file-editor.js';
import TransitionDuration from '@/common/enums/transition-duration.js';
import { useFileEditorStore } from '@/stores/file-editor.js';

const fileEditorStore = useFileEditorStore();

const uploadModal = ref();
const confirmModal = ref();

const file = ref();
const isRunning = ref(false);

useFileEditor(onFilesChange);

function switchToUpload() {
  confirmModal.value.hide();
  uploadModal.value.show(file.value);
}

function discard() {
  confirmModal.value.hide();
  stopFileWatching();
}

function cancel() {
  confirmModal.value.hide();
  isRunning.value = false;
}

function onFilesChange(files) {
  if (files.length) {
    file.value = files[0];

    if (!isRunning.value) {
      isRunning.value = true;
      nextTick(() => confirmModal.value.show());
    }
  } else {
    if (isRunning.value) {
      isRunning.value = false;
      confirmModal.value.hide();
    }
  }
}

function stopFileWatching() {
  setTimeout(() => {
    fileEditorStore.clearFileUrl(file.value.id);
    isRunning.value = false;
  }, TransitionDuration.Normal);
}
</script>

<template>
  <div
    v-if="file"
    hidden
  >
    <FileChangesConfirmModal
      ref="confirmModal"
      :file-name="file.name"
      @save="switchToUpload"
      @discard="discard"
      @cancel="cancel"
    />
    <FileUploadModal
      ref="uploadModal"
      :document-id="file.documentId"
      @hidden="stopFileWatching"
    />
  </div>
</template>
