import Api from '@/services/api/api.js';
import { SYSTEM_INTERFACE_ELEMENTS_GET, USER_ROLES_GET } from '@/configs/end-points.js';
import { EVENTS } from '@/configs/events';
import { ROLES } from '@/configs/roles';
import { defineStore } from 'pinia';
import StoreId from '@/common/enums/store-id.js';

export const useRolesStore = defineStore(StoreId.Roles, {
  state: () => ({
    userEvents: [],
    userEventsIds: [],
    userEventsCash: {},

    userRoles: [],
    userRolesIds: [],
    userRolesCash: {},
  }),
  getters: {
    accessToEvent(state) {
      return (event) => {
        if (state.userEventsCash[event] !== undefined) {
          return state.userEventsCash[event];
        }

        if (EVENTS[event] === undefined) {
          throw new Error(`Not found event: '${event}'`);
        }
        if (!state.userEventsIds.length) {
          return false;
        }

        const eventId = EVENTS[event];

        const access = state.userEventsIds.includes(eventId);

        // FIXME: Side effect
        state.userEventsCash[event] = access;

        return access;
      };
    },
    userInRole(state) {
      return (role) => {
        if (state.userRolesCash[role] !== undefined) {
          return state.userRolesCash[role];
        }

        if (ROLES[role] === undefined) {
          throw new Error(`Not found role: '${role}'`);
        }
        if (!state.userRolesIds.length) {
          return false;
        }

        const eventId = ROLES[role];

        const access = state.userRolesIds.includes(eventId);

        // FIXME: Side effect
        state.userRolesCash[role] = access;

        return access;
      };
    },
  },
  actions: {
    fetch() {
      return Promise.all([
        Api.post(SYSTEM_INTERFACE_ELEMENTS_GET).then((response) => {
          this.userEventsIds = [];
          this.userEvents = response.items;
          this.userEvents.forEach((value) => {
            this.userEventsIds.push(Number(value.GUIE_ID));
          });
        }),
        Api.post(USER_ROLES_GET).then((response) => {
          this.userRolesIds = [];
          this.userRoles = response.items;
          this.userRoles.forEach((value) => {
            this.userRolesIds.push(Number(value.ROLE_ID));
          });
        }),
      ]);
    },
  },
  persist: true,
});
