<template>
  <div>
    <filters-header />

    <div
      v-for="(condition, index) in conditions"
      :key.prop="condition"
      class="fields"
      :hidden="!condition"
    >
      <switch-input-form
        v-if="index !== 0"
        v-model="condition.algorithm"
        small
        class="item switch-input-filter-table"
      />

      <combobox-ui
        v-model="condition.select"
        class="item"
        :options="options"
        no-clear
      />

      <InputUi
        v-if="showInput"
        v-model="condition.input"
        class="item"
      />
    </div>

    <filter-buttons
      @clear="cleanFilter"
      @apply="applyFilter"
    />

    <order-by
      :data="data"
      :col="col"
      :key_column="key_column"
    >
      <template #variant-asc> А-Я<br />По возрастанию</template>
      <template #variant-desc> Я-А<br />По убыванию</template>
    </order-by>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import FilterMixin from '@/mixins/table/filter-mixin.js';
import InputUi from '@/components/ui/InputUi.vue';

export default defineComponent({
  name: 'StringFilter',
  components: {
    InputUi,
  },
  mixins: [FilterMixin],
  data: () => ({
    onlySelect: ['is not null', 'is null'],
    options: [
      {
        code: 'LIKE',
        label: 'Содержит',
      },
      {
        code: 'MATCH LIKE',
        label: 'Соответствует',
      },
      {
        code: 'START LIKE',
        label: 'Начинается с',
      },
      {
        code: 'END LIKE',
        label: 'Заканчивается',
      },
      {
        code: 'NOT LIKE',
        label: 'Не содержит',
      },
      {
        code: 'is not null',
        label: 'Заполнено',
      },
      {
        code: 'is null',
        label: 'Не заполнено',
      },
    ],
    sqlGenerator: 'stringSql',
  }),
  methods: {
    showInput(condition) {
      return condition.select === null || !this.onlySelect.includes(condition.select.code);
    },
  },
});
</script>

<style scoped lang="scss">
.fields {
  margin-bottom: 12px;
}

.item {
  &:not(:last-child) {
    margin-bottom: 8px;
  }
}
</style>
