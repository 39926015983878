<template>
  <div
    class="loader-ui"
    :class="[`_position-${position}`, `_color-${color}`, `_size-${size}`]"
  >
    <SpinnerUi :size="spinnerSize" />
    <span
      v-if="text"
      class="text"
    >
      {{ text }}
    </span>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import SpinnerUi from '@/components/ui/SpinnerUi.vue';
import { only } from '@/common/utils/props-validators';

export default defineComponent({
  name: 'LoaderUi',
  components: {
    SpinnerUi,
  },
  props: {
    color: {
      type: String,
      default: 'gray',
      validator: only('gray', 'white', 'transparent'),
    },
    position: {
      type: String,
      default: 'absolute',
      validator: only('absolute', 'static'),
    },
    size: {
      type: String,
      default: 'm',
      validator: only('s', 'm'),
    },
    text: String,
  },
  computed: {
    spinnerSize() {
      switch (this.size) {
        case 's':
          return 'm';
        case 'm':
        default:
          return 'l';
      }
    },
  },
});
</script>

<style scoped lang="scss">
.loader-ui {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &._position-static {
    height: 100%;
  }

  &._position-absolute {
    position: absolute;
    inset: 0;
  }

  &._color-gray {
    background-color: var(--color-loader);
    backdrop-filter: blur(4px);
  }

  &._color-white {
    background-color: var(--color-white);
  }

  &._color-transparent {
    background-color: var(--color-transparent);
  }

  &._size-s {
    .text {
      font-size: var(--font-size-s);
      line-height: var(--line-height-s);
    }
  }
}

.spinner-ui {
  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.text {
  color: var(--color-gray-600);
  text-align: center;
}
</style>
