import { DeadlineType } from '@/common/enums/deadline-type.ts';
import { defineStore } from 'pinia';
import StoreId from '@/common/enums/store-id.js';

export const useMasterDeadlineStore = defineStore(StoreId.MasterDeadline, {
  state: () => ({
    type: DeadlineType.None,
    workingDays: 1,
    workingDaysDate: null,
    days: 1,
    daysDate: null,
    date: null,
  }),
  getters: {
    deadline(state) {
      switch (state.type) {
        case DeadlineType.WorkingDays:
          return state.workingDaysDate;
        case DeadlineType.Days:
          return state.daysDate;
        case DeadlineType.Date:
          return state.date;
        default:
          return null;
      }
    },
    hasDeadline(state) {
      return state.type !== DeadlineType.None;
    },
  },
  actions: {
    set(payload) {
      this.type = payload.type;
      this.workingDays = payload.workingDays;
      this.workingDaysDate = payload.workingDaysDate;
      this.days = payload.days;
      this.daysDate = payload.daysDate;
      this.date = payload.date;
    },
  },
});
