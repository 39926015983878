<template>
  <FormBuilder
    ref="form"
    v-model="model"
    :fields="fields"
    :error="warning"
  />
</template>

<script>
import { defineComponent } from 'vue';
import StepMixin from '@/mixins/document-master/step-mixin.js';
import FormBuilder from '@/components/form/FormBuilder.vue';
import { mapActions, mapState } from 'pinia';
import { COUNTERPARTIES_REGIONS_SEGMENTS__VIEW } from '@/configs/events';
import DocumentMasterFields from '@/common/consts/document-master/fields.js';
import { useMasterControllerStore } from '@/stores/master-controller.js';
import { useMasterDeadlineStore } from '@/stores/master-deadline.js';
import { useMasterStepsStore } from '@/stores/master-steps.js';
import { useRolesStore } from '@/stores/roles.js';

export default defineComponent({
  name: 'StepDeadline',
  components: {
    FormBuilder,
  },
  mixins: [StepMixin],
  computed: {
    ...mapState(useRolesStore, ['accessToEvent']),
    ...mapState(useMasterControllerStore, ['controller']),
    ...mapState(useMasterStepsStore, ['steps', 'warning']),
    ...mapState(useMasterDeadlineStore, { deadline: '$state' }),
    fields() {
      return [
        DocumentMasterFields.CONTROLLER,
        DocumentMasterFields.DEADLINE,
        DocumentMasterFields.steps(this.accessToEvent(COUNTERPARTIES_REGIONS_SEGMENTS__VIEW)),
      ];
    },
    model: {
      get() {
        return {
          controller: this.controller,
          deadline: this.deadline,
          steps: this.steps,
        };
      },
      set(value) {
        this.setController(value.controller);
        this.setDeadline(value.deadline);
        this.setSteps(value.steps);
      },
    },
  },
  methods: {
    ...mapActions(useMasterControllerStore, { setController: 'set' }),
    ...mapActions(useMasterDeadlineStore, { setDeadline: 'set' }),
    ...mapActions(useMasterStepsStore, { setSteps: 'set' }),
  },
});
</script>

<style scoped lang="scss">
.form-builder {
  height: 100%;
}

// TODO: Отказаться от перекрытия стилей
:deep(.form__row--1) {
  margin-bottom: auto !important;
}
</style>
