<template>
  <div
    v-show="optionsLength > 1 || load"
    class="filter-dictionary"
  >
    <LoaderUi v-if="load" />
    <div class="header">
      <div class="title">
        {{ col.title }}
      </div>
      <div class="search">
        <input
          v-if="showSearch"
          v-model="search"
          placeholder="поиск"
          @keyup="searchInOptions"
        />
      </div>
    </div>
    <div class="filters-form-content">
      <div
        :class="{ selected: !optionsSearchSelected.length }"
        class="filters-form-badge-search"
        @click="clearSelected"
      >
        Все
      </div>
      <div
        v-if="!isTypeFlag"
        :class="{
          selected: reverseFilter,
          disabled: disabledReverseFilter,
        }"
        class="filters-form-badge-search"
        @click="setReverseFilter"
      >
        Кроме
      </div>
      <div
        v-for="(option, key) in options"
        :key="key"
        :class="{
          selected: optionsSearchSelected.some((selected) => selected.code === option.code),
          _hidden: search.length && !searchInOptionsKeys.includes(key),
        }"
        class="filters-form-badge-search"
        @click="selectedSearchFilter(option)"
      >
        {{ option.label }}
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import Constants from '@/configs/tables/constants';
import LoaderUi from '@/components/ui/LoaderUi.vue';
import Emitter from '@/services/emitter.js';
import { useListsStore } from '@/stores/lists.js';

export default defineComponent({
  name: 'DictionaryFilterSmall',
  components: { LoaderUi },
  props: {
    col: Object,
    init_selected: Array,
    init_reverse: Boolean,
  },
  data: () => ({
    optionsSearchSelected: [],
    searchInOptionsKeys: [],
    search: '',
    load: false,
    reverseFilter: false,
    listsStore: useListsStore(),
  }),
  computed: {
    isTypeFlag() {
      return Constants.flag === this.col.type;
    },
    disabledReverseFilter() {
      return !this.optionsSearchSelected.length;
    },
    getter() {
      return this.col.optionSmallFilterGetter || this.col.optionGetter;
    },
    showSearch() {
      return Constants.asyncIdentifier === this.col.type && this.optionsLength > 10;
    },
    options() {
      const options = {};

      switch (this.col.type) {
        case Constants.identifier:
          for (const key in this.col.values) {
            options[key] = {
              code: key,
              label: this.col.values[key].title,
              selected: false,
            };
          }
          break;

        case Constants.flag:
          options['= 1'] = {
            code: '= 1',
            label: 'Да',
            selected: false,
          };
          options['= 0'] = {
            code: '= 0',
            label: 'Нет',
            selected: false,
          };
          break;

        case Constants.asyncIdentifier:
          this.listsStore[this.getter].forEach((option) => {
            if (option.optionKeyValue !== undefined && option.optionKeyValue) {
              options[option.code] = {
                code: "'" + option.label + "'",
                label: option.label,
                selected: false,
              };
              return;
            }
            options[option.code] = {
              code: option.code,
              label: option.label,
              selected: false,
            };
          });
          break;
        default:
          console.error('Неизвестный тип поля: ' + this.col.type);
      }

      return options;
    },
    optionsLength() {
      return Object.keys(this.options).length;
    },
  },
  watch: {
    optionsLength() {
      if (this.optionsLength == this.optionsSearchSelected.length) {
        this.optionsSearchSelected = [];
      }
      this.searchInOptions();
    },
  },
  async mounted() {
    if (Constants.asyncIdentifier === this.col.type) {
      this.load = !this.load;
      await this.listsStore[this.col.optionAction]().then(() => (this.load = !this.load));
    }

    if (this.init_reverse) {
      this.reverseFilter = this.init_reverse;
    }

    if (this.init_selected) {
      this.init_selected.forEach((option) => {
        if (this.options[option.code] !== undefined) {
          this.selectedSearchFilter(this.options[option.code]);
        }
      });
    }

    if (this.optionsLength == this.optionsSearchSelected.length) {
      this.optionsSearchSelected = [];
    }

    Emitter.on('table-active-filters-small-search-clear', () => {
      this.optionsSearchSelected = [];
      this.search = '';
      this.reverseFilter = false;
    });
  },
  unmounted() {
    Emitter.off('table-active-filters-small-search-clear');
  },
  methods: {
    setReverseFilter() {
      if (!this.disabledReverseFilter) {
        this.reverseFilter = !this.reverseFilter;
        this.changeEvent();
      }
    },
    clearSelected() {
      this.reverseFilter = false;
      this.optionsSearchSelected = [];
      this.changeEvent();
    },
    selectedSearchFilter(option) {
      if (this.isTypeFlag && !this.optionsSearchSelected.some((selected) => selected.code === option.code)) {
        this.optionsSearchSelected = [option];
      } else if (this.optionsSearchSelected.some((selected) => selected.code === option.code)) {
        this.optionsSearchSelected = this.optionsSearchSelected.filter((selected) => option.code !== selected.code);
      } else {
        this.optionsSearchSelected.push(option);
      }

      if (this.optionsLength == this.optionsSearchSelected.length) {
        this.optionsSearchSelected = [];
        this.reverseFilter = false;
      }

      this.changeEvent();
    },
    changeEvent() {
      this.$emit('change', {
        optionsSearchSelected: this.optionsSearchSelected,
        reverseFilter: this.reverseFilter,
      });
    },
    searchInOptions() {
      const keys = [];
      if (this.search.length) {
        for (const key in this.options) {
          const label = this.options[key].label.toLowerCase();
          if (label.indexOf(this.search.toLowerCase()) >= 0) {
            keys.push(key);
          }
        }
      }
      this.searchInOptionsKeys = keys;
    },
  },
});
</script>

<style scoped lang="scss">
.header {
  margin-bottom: 4px;
  display: flex;
  align-items: flex-end;
}

.filters-form-badge-search {
  margin-right: 8px;
  margin-top: 4px;

  &._hidden {
    display: none;
  }
}
</style>
