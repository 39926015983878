<template>
  <span class="identifier-column">
    <component
      :is="config.values[data].component"
      v-if="config.values[data].component && config.values[data]"
      :value="config.values[data].title"
      :data="data"
    />
    <span v-else-if="!config.values[data].component && config.values[data]">
      {{ config.values[data].title }}
    </span>
    <span
      v-else
      class="badge badge-danger"
    >
      {{ 'Значение: ' + data + ' не определено' }}
    </span>
  </span>
</template>

<script>
export default {
  name: 'IdentifierColumn',
  props: ['config', 'data'],
};
</script>

<style scoped lang="scss">
.identifier-column {
  display: flex;
}

.badge {
  padding: 0.25em 0.4em;
  border-radius: 0.25em;
}
</style>
