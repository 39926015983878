import { defineStore } from 'pinia';
import { INITIAL_ENABLED_STEPS } from '@/common/consts/document-master/common.js';
import FormError from '@/common/models/form-error.js';
import { useMasterFilesStore } from '@/stores/master-files.js';
import { useMasterLinksStore } from '@/stores/master-links.js';
import { useMasterClientsStore } from '@/stores/master-clients.js';
import StoreId from '@/common/enums/store-id.js';

export const useMasterStepsStore = defineStore(StoreId.MasterSteps, {
  state: () => ({
    steps: INITIAL_ENABLED_STEPS,
  }),
  getters: {
    warning(state) {
      const masterFilesStore = useMasterFilesStore();
      const masterLinksStore = useMasterLinksStore();
      const masterClientsStore = useMasterClientsStore();

      const part = [
        state.steps.files === false && masterFilesStore.files.length && 'прикрепленные файлы',
        state.steps.links === false && masterLinksStore.links.length && 'ссылки на документы',
        state.steps.clients === false && masterClientsStore.clients.length && 'ссылки на контрагентов',
      ]
        .filter(Boolean)
        .join(', ');

      const message = part ? `Внимание! Во время создания документа не будут добавлены ${part}.` : null;

      return new FormError(2, message);
    },
  },
  actions: {
    enable(key) {
      if (this.steps[key] === null) {
        this.steps = {
          ...this.steps,
          [key]: true,
        };
      }
    },
    set(payload) {
      this.steps = payload;
    },
  },
});
