<template>
  <div
    ref="scrollContainer"
    class="table-component-wrapper"
  >
    <div
      :id="'table-component-' + configs.name"
      class="table-component"
    >
      <ErrorAlertUi
        v-if="error && !generalLoader"
        class="table-component__error"
      >
        <p class="error-message">
          {{ error.error_message || 'Ошибка получения данных' }}
        </p>
        <hr class="error-line _red" />
        <p>
          Попробуйте сбросить фильтр или обратитесь в службу поддержки.
          <a
            href="#"
            @click.prevent="clearFilters"
          >
            Сбросить фильтр
          </a>
        </p>
      </ErrorAlertUi>
      <div v-else>
        <template v-if="initialView === 'list'">
          <table class="table">
            <TableActiveFiltersSmall
              v-show="tableInit"
              ref="tableActiveFiltersSmall"
              :configs_table="configs"
            />
            <TableActiveFilters
              v-show="tableInit"
              ref="tableActiveFilters"
              :component_width="componentWidth"
              :is-small="true"
              :configs_table="configs"
            />
            <TableBody
              v-show="tableInit"
              :configs="configs"
              :data-table="listsStore[configs.getter]"
              :component-width="componentWidth"
              :small="true"
            />
            <TableFooter
              :header-height="headerHeight"
              :configs="configs"
            />
          </table>
        </template>
        <template v-else>
          <div
            class="table-wrap"
            :style="tableGeneralLoadStyle"
          >
            <table
              class="table table-design"
              cellspacing="0"
            >
              <TableActiveFilters
                v-show="tableInit"
                ref="tableActiveFilters"
                :configs_table="configs"
              />
              <TableHeadCount
                v-show="tableInit"
                ref="tableHeadCount"
                :configs="configs"
              />
              <TableHead
                v-show="tableInit"
                ref="tableHead"
                :data="configs"
              />
              <TableBody
                v-show="tableInit"
                :configs="configs"
                :data-table="listsStore[configs.getter]"
                :component-width="componentWidth"
                :small="false"
              />
              <TableFooter
                :header-height="headerHeight"
                :table-init-data="tableInit"
                :style="tableGeneralLoadStyle"
                :configs="configs"
              />
            </table>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Constants from '@/configs/constants';
import TableHead from '@/components/table/TableHead';
import TableBody from '@/components/table/TableBody';
import TableFooter from '@/components/table/TableFooter';
import TableActiveFilters from '@/components/table/activeFilters/TableActiveFilters';
import TableActiveFiltersSmall from '@/components/table/activeFilters/TableActiveFilterSmall';
import TableHeadCount from '@/components/table/TableHeadCount';
import VuexAdapter from '@/services/vuex-adapter.js';
import ErrorAlertUi from '@/components/ui/ErrorAlertUi';
import hotkeys from 'hotkeys-js';
import { type } from '@/common/utils/props-validators';
import Emitter from '@/services/emitter.js';
import { useTemplateRef } from 'vue';
import { useInfiniteScroll } from '@vueuse/core';
import { useListsStore } from '@/stores/lists.js';
import { useTableStore } from '@/stores/table.js';

function getPreData(requestData, preFilter) {
  let data = {};
  if (requestData) data = Object.assign(data, requestData);
  if (preFilter) data.where = JSON.parse(JSON.stringify(preFilter));

  return data;
}

export default {
  name: 'TableIndex',
  props: {
    /** Конфигурация таблицы */
    configs: {
      required: true,
      type: Object,
    },
    /** Ширина таблицы */
    componentWidth: {
      required: false,
      type: Number,
    },
    /** Объект микса вызовов акшенов на получение данных */
    requestData: {
      required: false,
    },
    /** Запрос SQL который всегда добавляется к условию WHERE */
    preFilter: {
      required: false,
      type: String,
    },
    hotkeys: {
      type: Array,
      default: () => [],
      validator: type('string'),
    },
  },
  emits: ['hotkey'],
  components: {
    ErrorAlertUi,
    TableHeadCount,
    TableActiveFilters,
    TableActiveFiltersSmall,
    TableHead,
    TableBody,
    TableFooter,
  },
  setup(props) {
    const scrollContainer = useTemplateRef('scrollContainer');

    let isLoading = false;

    useInfiniteScroll(scrollContainer, loadingDataTableAction, {
      canLoadMore: () => !isLoading,
      distance: 200,
    });

    const listsStore = useListsStore();
    const tableStore = useTableStore();

    async function loadingDataTableAction() {
      if (
        isLoading ||
        listsStore[props.configs.getter].length === 0 ||
        listsStore[props.configs.getter].length === listsStore[props.configs.getterCountByFilter]
      ) {
        return;
      }
      isLoading = true;
      try {
        await tableStore[VuexAdapter.loadTableNameAction(props.configs.name)](
          getPreData(props.requestData, props.preFilter),
        );
      } finally {
        isLoading = false;
      }
    }

    return {
      listsStore,
      tableStore,
      scrollContainer,
    };
  },
  data: () => ({
    tableInit: false,
    tableActiveFiltersSmallHeight: 0,
    tableActiveFiltersHeight: 0,
    tableHeadCountHeight: 0,
    tableHeadHeight: 0,
  }),
  computed: {
    headerHeight() {
      return (
        this.tableHeadHeight +
        this.tableHeadCountHeight +
        this.tableActiveFiltersHeight +
        this.tableActiveFiltersSmallHeight
      );
    },
    error() {
      return this.tableStore[VuexAdapter.errorTableNameGetter(this.configs.name)];
    },
    tableActiveRow() {
      return this.tableStore[VuexAdapter.activeRowTableNameGetter(this.configs.name)];
    },
    tableActiveRowIndex() {
      return this.tableStore[VuexAdapter.activeRowIndexTableNameGetter(this.configs.name)];
    },
    tableWhereSQLQuery() {
      return this.tableStore[VuexAdapter.sqlQueryTableNameGetter(this.configs.name)];
    },
    tableOrderBy() {
      return this.tableStore[VuexAdapter.orderByTableNameGetter(this.configs.name)];
    },
    tableFixCols() {
      return this.tableStore[VuexAdapter.fixColsTableNameGetter(this.configs.name)];
    },
    generalLoader() {
      return this.tableStore[VuexAdapter.loaderGeneralTableNameGetter(this.configs.name)];
    },
    initialView() {
      return this.componentWidth <= Constants.tableIndexPoints.md ? 'list' : 'table';
    },
    tableGeneralLoadStyle() {
      if (this.generalLoader && !this.tableInit) {
        return {
          background: 'rgba(226, 231, 239, .4)',
          width: '100%',
        };
      }

      return {};
    },
  },
  watch: {
    tableWhereSQLQuery() {
      this.getDataTableAction();
    },
    tableOrderBy() {
      this.getDataTableAction();
    },
  },
  async mounted() {
    this.$refs.scrollContainer.scrollIntoView({
      behavior: 'smooth',
      inline: 'start',
    });

    Emitter.on('table-start-reload-' + this.configs.name, () => this.getDataTableAction());

    Emitter.on('table-trigger-refresh-only-state', () => this.refreshOnlyStateTableAction());

    Emitter.on('remove-row-' + this.configs.name, (index) => this.removeRow(index));

    if (!this.listsStore[this.configs.getterCountByFilter]) {
      await this.getDataTableAction();
    }

    this.bindHotkeys();

    this.tableInit = true;
  },
  updated() {
    this.setHigthHeader();
  },
  unmounted() {
    this.unbindHotkeys();
    this.listsStore[this.configs.actionAbort]();
    Emitter.off('table-start-reload-' + this.configs.name);
    Emitter.off('table-trigger-refresh-only-state');
    Emitter.off('remove-row-' + this.configs.name);
  },
  methods: {
    setHigthHeader() {
      this.tableActiveFiltersSmallHeight = this.$refs?.tableActiveFiltersSmall?.$el?.clientHeight || 0;
      this.tableActiveFiltersHeight = this.$refs?.tableActiveFilters?.$el?.clientHeight || 0;
      this.tableHeadCountHeight = this.$refs?.tableHeadCount?.$el?.clientHeight || 0;
      this.tableHeadHeight = this.$refs?.tableHead?.$el?.clientHeight || 0;
    },
    tableDeleteALLFiltersAndOrderBy() {
      this.tableStore[VuexAdapter.delFilterAndOrderByTableNameAction(this.configs.name)]();
    },
    async getDataTableAction() {
      return this.tableStore[VuexAdapter.getDataTableNameAction(this.configs.name)](
        getPreData(this.requestData, this.preFilter),
      );
    },
    async refreshOnlyStateTableAction() {
      await this.tableStore[VuexAdapter.refreshOnlyStateTableNameAction(this.configs.name)](
        getPreData(this.requestData, this.preFilter),
      );
    },
    removeRow(index) {
      this.listsStore[this.configs.getter].splice(index, 1);
      this.refreshOnlyStateTableAction();
    },
    clearFilters() {
      this.tableDeleteALLFiltersAndOrderBy();
    },
    bindHotkeys() {
      if (!this.hotkeys.length) {
        return;
      }

      hotkeys.setScope(this.configs.name);

      this.hotkeys.forEach((key) => {
        hotkeys(key, this.configs.name, (event) => {
          this.$emit('hotkey', { event, key, row: this.tableActiveRow });
        });
      });
    },
    unbindHotkeys() {
      hotkeys.deleteScope(this.configs.name);
    },
  },
};
</script>

<style scoped lang="scss">
.table {
  width: 100%;
}

.table-design {
  position: relative;
}

.error-message,
.error-line {
  margin-bottom: 12px;
}
</style>
