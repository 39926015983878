<template>
  <div class="sign--table">
    <HeaderTableSign />
    <BodyTableSign />
  </div>
</template>

<script>
import HeaderTableSign from '@/components/sign/HeaderTableSign';
import BodyTableSign from '@/components/sign/BodyTableSign';

export default {
  name: 'TableSign',
  components: {
    BodyTableSign,
    HeaderTableSign,
  },
};
</script>
