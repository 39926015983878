<template>
  <thead
    class="table-active-filter-small"
    :class="{ _active: isSearchActive }"
  >
    <tr>
      <td class="cell">
        <InputUi
          ref="input"
          v-model.trim="searchValue"
          class="item item_search"
          color="white"
          size="l"
          placeholder="Поиск"
          inputmode="search"
          autocomplete="off"
          no-clear
          @focus="enableSearch"
        >
          <template #postfix>
            <ButtonIconUi @click="applyFilter">
              <FontAwesomeIcon
                class="icon"
                :icon="['fas', 'search']"
              />
            </ButtonIconUi>
          </template>
        </InputUi>

        <button
          v-if="showClearFilterButton"
          class="item item_clear"
          @click="clearFilters"
        >
          <FontAwesomeIcon
            class="icon"
            :icon="['fas', 'times']"
          />
        </button>

        <ComboboxUi
          v-model="sortField"
          class="item item_sort"
          :class="{ _hidden: sortHidden }"
          placeholder="Сортировка"
          size="l"
          color="white"
          :options="optionsSort"
        >
          <template #prefix>
            <ButtonIconUi @click.stop="sort">
              <FontAwesomeIcon
                class="icon"
                :icon="['fas', sortable === TABLE_SORT_ASC ? 'sort-amount-desc' : 'sort-amount-asc']"
              />
            </ButtonIconUi>
          </template>
        </ComboboxUi>
      </td>
    </tr>

    <TableActiveFilterSmallSearch
      v-show="isSearchActive"
      :search-value="searchValue"
      :configs_table="configs_table"
      @clear="searchValue = ''"
    />

    <Teleport to="body">
      <Transition name="opacity">
        <div
          v-if="isSearchActive"
          class="backdrop"
          @click="disableSearch"
        />
      </Transition>
    </Teleport>
  </thead>
</template>

<script>
import { defineComponent } from 'vue';
import { useEventListener } from '@vueuse/core';
import TableActiveFilterSmallSearch from '@/components/table/activeFilters/TableActiveFilterSmallSearch';
import VuexAdapter from '@/services/vuex-adapter.js';
import { TABLE_SORT_ASC, TABLE_SORT_DESC } from '@/configs/tables/table';
import ComboboxUi from '@/components/ui/ComboboxUi.vue';
import InputUi from '@/components/ui/InputUi.vue';
import ButtonIconUi from '@/components/ui/ButtonIconUi.vue';
import Emitter from '@/services/emitter.js';
import { useTableStore } from '@/stores/table.js';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default defineComponent({
  name: 'TableActiveFilterSmall',
  components: {
    FontAwesomeIcon,
    ButtonIconUi,
    InputUi,
    ComboboxUi,
    TableActiveFilterSmallSearch,
  },
  inject: ['app', 'layout'],
  props: ['configs_table'],
  data: () => ({
    searchKey: 'search',
    searchValue: '',
    keyFilter: 'LIKE',
    sortable: TABLE_SORT_ASC,
    sortField: null,
    sortableShow: true,
    sortableInit: false,
    TABLE_SORT_ASC,
    isSearchActive: false,
    tableStore: useTableStore(),
  }),
  computed: {
    tableFilterData() {
      return this.tableStore[VuexAdapter.filterTableNameGetter(this.configs_table.name)];
    },
    tableOrderBy() {
      return this.tableStore[VuexAdapter.orderByTableNameGetter(this.configs_table.name)];
    },
    hasFilters() {
      return Object.keys(this.tableFilterData).length > 0;
    },
    showClearFilterButton() {
      return !this.sortableShow && this.hasFilters;
    },
    optionsSort() {
      return Object.entries(this.configs_table.info)
        .filter((entry) => entry[1].visible && !!entry[1].title)
        .map(([key, value]) => ({ label: value.title, code: key }));
    },
    sortHidden() {
      return !(this.sortableShow || this.isSearchActive);
    },
  },
  watch: {
    sortField() {
      if (this.sortableInit) {
        this.applySort();
      }
    },
  },
  mounted() {
    for (const key in this.tableFilterData) {
      if (this.tableFilterData[key][0] === undefined) {
        continue;
      }

      const data = this.tableFilterData[key][0];

      if (key == this.searchKey) {
        this.searchValue = data.search_value;
        break;
      }
    }

    if (this.tableOrderBy.key !== undefined && this.tableOrderBy.value !== undefined) {
      this.sortable = this.tableOrderBy.value;
      this.optionsSort.forEach((option) => {
        if (this.tableOrderBy.key === option.code) {
          this.sortField = option;
        }
      });
    }

    Emitter.on('toolbar-click', this.disableSearch);
    document.addEventListener('keydown', this.onKeydown);

    setTimeout(() => (this.sortableInit = true), 500);
    useEventListener(this.layout.frameLeft.element.children[0], 'scroll', (event) => {
      this.scrollAnimation(event);
    });
  },
  unmounted() {
    Emitter.off('toolbar-click', this.disableSearch);
    document.removeEventListener('keydown', this.onKeydown);
  },
  methods: {
    tableDelOrderBy() {
      this.tableStore[VuexAdapter.delOrderByTableNameAction(this.configs_table.name)]();
    },
    tableDeleteALLFiltersAndOrderBy() {
      this.tableStore[VuexAdapter.delFilterAndOrderByTableNameAction(this.configs_table.name)]();
    },
    tableSetOrderBy(data) {
      this.tableStore[VuexAdapter.orderByTableNameAction(this.configs_table.name)](data);
    },
    tableSortableStatus(data) {
      this.tableStore[VuexAdapter.sortableStatusTableNameMutation(this.configs_table.name)](data);
    },
    scrollAnimation(event) {
      const scrollOffset = event.target.scrollTop;
      const width = this.app.appWidth;

      if (scrollOffset >= 170 && width <= 440) {
        this.sortableShow = false;
        this.tableSortableStatus(false);
      } else if (scrollOffset < 110 && width <= 440) {
        this.sortableShow = true;
        this.tableSortableStatus(true);
      }
    },
    clearFilters() {
      this.searchValue = '';
      this.tableDeleteALLFiltersAndOrderBy();
    },
    sort() {
      this.sortable = this.sortable === TABLE_SORT_ASC ? TABLE_SORT_DESC : TABLE_SORT_ASC;
      if (this.sortField !== null) {
        this.applySort();
      }
    },
    applySort() {
      if (this.sortField !== null) {
        this.tableSetOrderBy({
          key: this.sortField.code,
          value: this.sortable,
        });
        return;
      }

      this.tableDelOrderBy();
    },
    applyFilter() {
      if (this.isSearchActive) {
        Emitter.emit('table-active-filter-small-apply-filter');
        setTimeout(() => this.disableSearch());
      } else {
        this.enableSearch();
      }
    },
    enableSearch() {
      this.$refs.input.focus();
      this.isSearchActive = true;
      Emitter.emit('search-active-change', true);
    },
    disableSearch() {
      this.$refs.input.blur();
      this.isSearchActive = false;
      Emitter.emit('search-active-change', false);
    },
    onKeydown(event) {
      if (!this.isSearchActive) {
        return;
      }

      switch (event.key) {
        case 'Escape':
          this.disableSearch();
          break;
        case 'Enter':
          this.applyFilter();
          break;
      }
    },
  },
});
</script>

<style scoped lang="scss">
.table-active-filter-small {
  position: sticky;
  z-index: 11;
  top: 0;
  width: 100%;

  &._active {
    z-index: 1001;

    .item {
      &_search {
        position: absolute;
        left: 0;
        right: 0;
      }
    }
  }
}

.cell {
  position: relative;

  margin-bottom: 6px;
  margin-left: -12px;
  padding-top: 6px;

  display: flex;
  flex-wrap: wrap;

  &::before {
    content: '';
    display: block;

    position: absolute;
    top: 0;
    left: 0;
    right: -8px;
    bottom: -8px;

    backdrop-filter: blur(6px);
    mask: linear-gradient(to bottom, rgba(0, 0, 0, 1) 75%, rgba(0, 0, 0, 0) 100%);

    @include respond-down(s) {
      right: 0;
    }
  }
}

.item {
  margin-bottom: 6px;
  margin-left: 12px;

  &_search {
    position: relative;
    z-index: 2;

    flex-grow: 2;
    min-width: 227px;
  }

  &_clear {
    position: relative;
    z-index: 1;

    flex-shrink: 0;
    width: 48px;
    height: 48px;

    background-color: var(--color-white);
    border-radius: 8px;
    box-shadow: var(--shadow);
  }

  &_sort {
    flex-grow: 1;
    min-width: 200px;

    margin-bottom: 6px;

    transition:
      opacity var(--transition),
      margin-top var(--transition);

    &._hidden {
      opacity: 0;
      margin-top: -56px;
    }
  }
}

.icon {
  width: 16px;
  height: 16px;

  /* TODO: Отказаться от перекрытия стилей */
  transition: color var(--transition);
  color: var(--color-gray-500);

  @media (hover: hover) {
    &:hover {
      color: var(--color-gray-600);
    }
  }
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;

  background-color: var(--color-backdrop);
  backdrop-filter: blur(5px);
}
</style>
