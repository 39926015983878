import { defineStore } from 'pinia';
import StoreId from '@/common/enums/store-id.js';
import { NotifyTypes } from '@/configs/notify-types.js';
import UpdatedFile from '@/common/models/file/updated-file';
import FileEditorScope from '@/common/enums/file-editor-scope.js';
import { notify } from '@kyvg/vue3-notification';
import { Route } from '@/common/enums/route.js';

function isEqual(a, b) {
  return a.documentId === b.documentId && a.name === b.name;
}

export const useFileEditorStore = defineStore(StoreId.FileEditor, {
  state: () => ({
    files: [],
  }),
  getters: {
    getFile(state) {
      return (id) => state.files.find((file) => file.id === id);
    },
    getUpdatedFiles(state) {
      return (scope) =>
        state.files.filter((file) => file.scope === scope && file.url).map((file) => new UpdatedFile(file));
    },
  },
  actions: {
    addFile({ file: newFile, router, scope = FileEditorScope.Global, openingWindow = window.open('', '_blank') }) {
      const newStateFile = {
        id: newFile.id.toString(),
        fileId: newFile.fileId,
        documentId: scope === FileEditorScope.Global ? newFile.documentId : undefined,
        name: newFile.name,
        timestamp: Date.now(),
        scope,
        isOpen: false,
      };

      const oldStateFile = this.files.find((file) => isEqual(file, newStateFile));

      if (oldStateFile?.isOpen) {
        notify({
          type: NotifyTypes.Warn,
          text: `Файл "${newStateFile.name}" уже редактируется в другом окне`,
        });
        return;
      }

      if (oldStateFile) {
        this.files = this.files.map((file) => (isEqual(file, newStateFile) ? newStateFile : file));
      } else {
        this.files = [...this.files, newStateFile];
      }

      const route = router.resolve({
        name: Route.FileEditorPage,
        params: {
          id: newStateFile.id,
        },
      });

      openingWindow.location.href = route.href;
    },
    setFileState({ id, isOpen }) {
      this.files = this.files.map((file) => (file.id === id ? { ...file, isOpen } : file));
    },
    setFileUrl({ id, url }) {
      this.files = this.files.map((file) => (file.id === id ? { ...file, url } : file));
    },
    clearFileUrl(id) {
      this.files = this.files.map((file) => (file.id === id ? { ...file, url: undefined } : file));
    },
    clearScope(scope = FileEditorScope.Global) {
      this.files = this.files.filter((file) => file.scope !== scope);
    },
  },
  persist: true,
});
