<template>
  <div
    v-if="commissionsCount.count_new > 0"
    class="indicator-badge-count-new"
  />
  <div
    v-else-if="commissionsCount.count_expired > 0"
    class="indicator-badge-count-expired"
  />
</template>

<script>
import { mapState } from 'pinia';
import { useCommissionsStore } from '@/stores/commissions.js';

export default {
  name: 'CounterCommissionsIconSmall',
  computed: {
    ...mapState(useCommissionsStore, ['commissionsCount']),
  },
};
</script>
