<template>
  <Transition name="opacity-fast">
    <div
      v-if="text"
      v-center
      class="error-ui"
    >
      <CancelIcon class="icon" />
      <span>{{ text }}</span>
    </div>
  </Transition>
</template>

<script>
import { defineComponent } from 'vue';
import Center from '@/directives/center';
import CancelIcon from '@/assets/icons/cancel.svg';

export default defineComponent({
  name: 'ErrorUi',
  components: {
    CancelIcon,
  },
  directives: {
    Center,
  },
  props: {
    text: String,
  },
});
</script>

<style scoped lang="scss">
.error-ui {
  padding: 12px 16px;

  display: flex;
  align-items: center;

  background-color: var(--color-red-100);
  border-radius: 8px;

  color: var(--color-red-1000);
  font-size: var(--font-size-l);
  line-height: var(--line-height-l);
}

.icon {
  flex-shrink: 0;
  margin-right: 8px;
}
</style>
