<template>
  <ModalUi
    ref="modal"
    title="Запустить документ"
    :error-message="errorMessage"
    popup
  >
    <template #body> Вы собираетесь отправить документ в работу. Продолжить?</template>

    <template #footer>
      <CancelButton @click="hide" />

      <ButtonUi @click="apply">
        <PlayCircleIcon v-prefix />
        <span>Запустить</span>
      </ButtonUi>
    </template>
  </ModalUi>
</template>

<script>
import { defineComponent } from 'vue';
import { mapActions } from 'pinia';
import CancelButton from '@/components/buttons/CancelButton.vue';
import VuexAdapter from '@/services/vuex-adapter.js';
import { DOC_ACTIVATION_SET, DOC_GANTT_ITEMS, DOCS_GET } from '@/configs/end-points.js';
import PlayCircleIcon from '@/assets/icons/circle/play.svg';
import ModalUi from '@/components/ui/ModalUi.vue';
import ModalMixin from '@/mixins/modal-mixin.js';
import ButtonUi from '@/components/ui/ButtonUi.vue';
import Emitter from '@/services/emitter.js';
import { useActionsStore } from '@/stores/actions.js';
import { usePreviewStore } from '@/stores/preview.js';

export default defineComponent({
  name: 'StartDocModal',
  components: {
    ButtonUi,
    ModalUi,
    PlayCircleIcon,
    CancelButton,
  },
  mixins: [ModalMixin],
  props: {
    docId: {
      type: Number,
      required: true,
    },
    events: {
      type: Array,
    },
  },
  methods: {
    ...mapActions(useActionsStore, {
      controlsDocActivationAction: VuexAdapter.getNameAction(DOC_ACTIVATION_SET),
    }),
    ...mapActions(usePreviewStore, ['refreshPartlyPreviewAction']),
    apply() {
      this.errorMessage = null;
      this.showLoader();

      this.controlsDocActivationAction({
        doc_id: this.docId,
        active: 1,
      })
        .then(() => {
          this.throwEvents();
        })
        .catch((e) => {
          this.errorMessage = e.error_message || 'Ошибка: не удалось запустить документ';
          this.hideLoader();
        });
    },
    throwEvents() {
      this.events.forEach((value) => Emitter.emit(value));
      this.refreshPartlyPreviewAction(DOCS_GET);
      this.refreshPartlyPreviewAction(DOC_GANTT_ITEMS);
      this.hide();
    },
  },
});
</script>
