import Api from '@/services/api/api.js';
import { COMMISSIONS_COUNT } from '@/configs/end-points.js';
import { defineStore } from 'pinia';
import { useTableStore } from '@/stores/table.js';
import { COMMISSIONS_TABLE_NAME } from '@/configs/tables/table.js';
import StoreId from '@/common/enums/store-id.js';

// FIXME: Дубль @/stores/controls.js
export const useCommissionsStore = defineStore(StoreId.Commissions, {
  state: () => ({
    commissionsCount: {
      count: 0,
      count_new: 0,
      count_expired: 0,
      ids: null,
      ids_new: null,
    },
  }),
  getters: {
    commissionsHasNew(state) {
      return state.commissionsCount.count_new > 0;
    },
  },
  actions: {
    /** Счетчик поручений */
    async getCommissionsCountAction(data = {}) {
      return await Api.post(COMMISSIONS_COUNT, data).then((response) => {
        if (
          (this.commissionsCount.ids !== null && response.ids !== this.commissionsCount.ids) ||
          (this.commissionsCount.ids_new !== null && response.ids_new !== this.commissionsCount.ids_new)
        ) {
          const tableStore = useTableStore();
          tableStore.refreshDataTable(COMMISSIONS_TABLE_NAME);
        }
        // TODO: Нужно ли условие?
        if (response) {
          this.commissionsCount = {
            count: response.count,
            count_new: response.count_new,
            count_expired: response.count_expired,
            ids: response.ids,
            ids_new: response.ids_new,
          };
        }
      });
    },
  },
});
