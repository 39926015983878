export default {
  ACTION: 'Action',
  ABORT: 'Abort',
  MUTATION: 'Mutation',
  ERROR: 'Error',
  STATE: 'State',
  COUNT: 'Count',
  GETTER: 'Getter',
  OBJECT: 'Obj',
  SELECT: 'Select',
  SIMPLE: 'Simple',
  LOADING: 'Loading',
  REFRESH: 'Refresh',
  ONLY_STATE: 'OnlyState',
  VISIBLE_COLUMNS: 'VisibleColumns',
  SORT_COLUMNS: 'SortColumns',
  CLEAR: 'Clear',
  ONE: 'One',
  ONLY_FETCH: 'OnlyFetch',
  LIST: 'List',
  LOADER: 'Loader',
  LOADER_GENERAL: 'LoaderGeneral',
  ROW: 'Row',
  TABLE: 'Table',
  FILTER: 'Filter',
  DEL: 'Del',
  FILTER_AND_DELETE_OVERS: 'FilterAndDeleteOvers',
  ORDER_BY: 'OrderBy',
  FIX_COLS: 'FixCols',
  CONDITION: 'Condition',
  SQL_QUERY: 'SqlQuery',
  ACTIVE_ROW: 'ActiveRow',
  INDEX: 'Index',
  SORTABLE_STATUS: 'SortableStatus',
};
