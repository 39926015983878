<template>
  <DropdownUi
    tag="ul"
    close-if-inner-click
    close-if-outside-click
  >
    <template #anchor="{ toggle }">
      <MenuButton
        :is-gray="!small"
        @click.stop="toggle"
      />

      <DeleteDocModal
        ref="modal"
        :doc-id="dataRow['DOC_ID']"
        :index-row="indexRow"
        :table-name="config_g.name"
      />
    </template>

    <DropdownItemUi
      tag="li"
      @click="open"
    >
      <DocumentTwoIcon v-prefix />
      Документ
    </DropdownItemUi>

    <DropdownItemUi
      v-if="showRemove"
      tag="li"
      @click.prevent="remove"
    >
      <DeleteIcon v-prefix />
      Удалить
    </DropdownItemUi>
  </DropdownUi>
</template>

<script>
import { defineComponent } from 'vue';
import DocumentTwoIcon from '@/components/icons/DocumentTwoIcon';
import { mapState } from 'pinia';
import { ADMINISTRATOR__ROLE } from '@/configs/roles';
import DeleteDocModal from '@/components/modals/Doc/DeleteDocModal.vue';
import DeleteIcon from '@/components/icons/DeleteIcon';
import DropdownUi from '@/components/ui/DropdownUi.vue';
import DropdownItemUi from '@/components/ui/DropdownItemUi.vue';
import MenuButton from '@/components/buttons/MenuButton.vue';
import { useRolesStore } from '@/stores/roles.js';

export default defineComponent({
  name: 'EyeDocuments',
  components: { MenuButton, DropdownItemUi, DropdownUi, DeleteIcon, DeleteDocModal, DocumentTwoIcon },
  props: ['dataRow', 'indexRow', 'config_g', 'small'],
  computed: {
    ...mapState(useRolesStore, ['userInRole']),
    showRemove() {
      return this.userInRole(ADMINISTRATOR__ROLE);
    },
  },
  methods: {
    open() {
      window.open('/document/' + this.dataRow['DOC_ID'], '_blank');
    },
    remove() {
      this.$refs.modal.show();
    },
  },
});
</script>
