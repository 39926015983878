<template>
  <PreviewBlock
    title="Дополнительная информация"
    :loading="loading"
  >
    <TabsUi ref="tabs">
      <template #title="{ title, data }">
        <span class="title">{{ title }}</span>
        <ReducedBadge :value="data" />
      </template>

      <TabUi
        v-if="showClientsTab"
        title="Контрагенты"
        :data="docContractorsCountGetter"
      >
        <UploaderBlockDocumentPreview
          :end-point="DOCS_CLIENTS_GET"
          :doc-id="doc_id"
          :component="ContractorRow"
          empty-text="Список контрагентов пуст"
        />
      </TabUi>

      <TabUi
        v-if="showDocumentsViewTab"
        title="Просмотры"
        :data="docViewsCountGetter"
      >
        <UploaderBlockDocumentPreview
          :end-point="DOCS_VIEWS_ALL"
          :doc-id="doc_id"
          :component="ViewRowDocumentPreview"
          empty-text="Список просмотров пуст"
        />
      </TabUi>

      <TabUi
        title="Документы"
        :data="documentsLinksCountGetter"
      >
        <UploaderBlockDocumentPreview
          :end-point="DOC_LINKS_GET"
          :doc-id="doc_id"
          :component="DocumentsLinks"
          empty-text="Список связанных документов пуст"
        />
      </TabUi>
    </TabsUi>
  </PreviewBlock>
</template>

<script>
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import UploaderBlockDocumentPreview from '@/components/doc/preview/UploaderBlockDocumentPreview';
import ReducedBadge from '@/components/common/ReducedBadge.vue';
import VuexAdapter from '@/services/vuex-adapter.js';
import { DOC_LINKS_GET, DOCS_CLIENTS_GET, DOCS_VIEWS_ALL } from '@/configs/end-points.js';
import { COUNTERPARTIES_REGIONS_SEGMENTS__VIEW, DOCUMENTS__VIEWS } from '@/configs/events';
import TabsUi from '@/components/ui/TabsUi.vue';
import TabUi from '@/components/ui/TabUi.vue';
import PreviewBlock from '@/components/doc/preview/PreviewBlock.vue';
import ContractorRow from '@/components/doc/contractors/ContractorRow.vue';
import ViewRowDocumentPreview from '@/components/doc/preview/rows/ViewRowDocumentPreview.vue';
import DocumentsLinks from '@/components/table/grouped/DocumentsLinks.vue';
import { useRolesStore } from '@/stores/roles.js';
import { useListsStore } from '@/stores/lists.js';

export default defineComponent({
  name: 'AdditionalInfoDocumentPreview',
  components: {
    PreviewBlock,
    TabsUi,
    TabUi,
    ReducedBadge,
    UploaderBlockDocumentPreview,
  },
  props: {
    doc_id: {
      type: Number,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      DOCS_CLIENTS_GET,
      DOCS_VIEWS_ALL,
      DOC_LINKS_GET,
    };
  },
  computed: {
    ...mapState(useRolesStore, ['accessToEvent']),
    ...mapState(useListsStore, {
      docViewsCountGetter: VuexAdapter.getNameCountGetter(DOCS_VIEWS_ALL),
      documentsLinksCountGetter: VuexAdapter.getNameCountGetter(DOC_LINKS_GET),
      docContractorsCountGetter: VuexAdapter.getNameCountGetter(DOCS_CLIENTS_GET),
    }),
    DocumentsLinks() {
      return DocumentsLinks;
    },
    ViewRowDocumentPreview() {
      return ViewRowDocumentPreview;
    },
    ContractorRow() {
      return ContractorRow;
    },
    showDocumentsViewTab() {
      return this.accessToEvent(DOCUMENTS__VIEWS);
    },
    showClientsTab() {
      return this.accessToEvent(COUNTERPARTIES_REGIONS_SEGMENTS__VIEW);
    },
  },
  watch: {
    doc_id() {
      this.$nextTick(() => this.$refs.tabs.calcContentHeight());
    },
  },
});
</script>

<style scoped lang="scss">
.title {
  &:not(:last-child) {
    margin-right: 4px;
  }
}
</style>
