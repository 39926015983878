import { DateTime } from 'luxon';

const mssql = {
  integerSql(Vue) {
    let sqlGeneral = '';
    for (let n in Vue.conditions) {
      let sql = '';

      if (Vue.onlySelect.includes(Vue.conditions[n].select.code)) {
        sql += ` ${Vue.key_column} ${Vue.conditions[n].select.code} `;
      } else if (Vue.conditions[n].input) {
        sql += ` ${Vue.key_column} ${Vue.conditions[n].select.code} ${Number(Vue.conditions[n].input)} `;
      }

      if (sql.length > 0) {
        if (Vue.conditions[n].algorithm !== null) {
          sqlGeneral += Vue.conditions[n].algorithm ? ' OR ' : ' AND ';
        }
        sqlGeneral += sql;
      } else {
        Vue.conditions.splice(n, 1);
      }
    }
    if (Vue.conditions.length === 0) {
      Vue.cleanConditions();
    }

    return sqlGeneral;
  },
  stringSqlSearch(Vue, code) {
    let sql = '';

    if (Vue.searchValue.length) {
      sql += ` [${code}] LIKE '%${Vue.searchValue}%' `;
    }

    return sql;
  },
  intSqlSearch(Vue, code, contradiction = '=') {
    let sql = '';

    if (Vue.searchValue.length && !isNaN(Number(Vue.searchValue))) {
      sql += ` [${code}] ${contradiction} ${Number(Vue.searchValue)} `;
    }

    return sql;
  },
  identifierSqlSearch(Vue, code, values) {
    return ` [${code}] in (${values.join(',')}) `;
  },
  identifierSqlNotInSearch(Vue, code, values) {
    return ` [${code}] not in (${values.join(',')}) `;
  },
  flagSqlSearch(Vue, code, value) {
    return ` [${code}] ${value} `;
  },
  stringSql(Vue) {
    let sqlGeneral = '';
    for (let n in Vue.conditions) {
      let sql = '';

      if (Vue.onlySelect.includes(Vue.conditions[n].select.code)) {
        sql += ` ${Vue.key_column} ${Vue.conditions[n].select.code} `;
      } else if (Vue.conditions[n].input) {
        switch (Vue.conditions[n].select.code) {
          case 'NOT LIKE':
            sql += ` [${Vue.key_column}] NOT LIKE '%${Vue.conditions[n].input}%' `;
            break;
          case 'LIKE':
            sql += ` [${Vue.key_column}] LIKE '%${Vue.conditions[n].input}%' `;
            break;
          case 'MATCH LIKE':
            sql += ` [${Vue.key_column}] LIKE '${Vue.conditions[n].input}' `;
            break;
          case 'START LIKE':
            sql += ` [${Vue.key_column}] LIKE '${Vue.conditions[n].input}%' `;
            break;
          case 'END LIKE':
            sql += ` [${Vue.key_column}] LIKE '%${Vue.conditions[n].input}' `;
            break;
        }
      }

      if (sql.length > 0) {
        if (Vue.conditions[n].algorithm !== null) {
          sqlGeneral += Vue.conditions[n].algorithm ? ' OR ' : ' AND ';
        }
        sqlGeneral += sql;
      } else {
        Vue.conditions.splice(n, 1);
      }
    }
    if (Vue.conditions.length === 0) {
      Vue.cleanConditions();
    }

    return sqlGeneral;
  },
  identifierSql(Vue) {
    let sqlGeneral = '';
    for (let n in Vue.conditions) {
      if (Vue.onlySelect.includes(Vue.conditions[n].select.code)) {
        sqlGeneral += ` ${Vue.key_column} ${Vue.conditions[n].select.code} `;
      } else if (Vue.conditions[n].input && Vue.conditions[n].input.length > 0) {
        let options = [];
        for (let optionN in Vue.conditions[n].input) {
          options.push(Vue.conditions[n].input[optionN].code);
        }

        sqlGeneral += ` ${Vue.key_column} ${Vue.conditions[n].select.code} (${options.join(',')}) `;
      }

      if (sqlGeneral.length === 0) {
        Vue.cleanConditions();
      }
    }

    return sqlGeneral;
  },
  flagSQL(Vue) {
    let sqlGeneral = '';
    for (let n in Vue.conditions) {
      if (Vue.conditions[n].select) {
        sqlGeneral += ` ${Vue.key_column} ${Vue.conditions[n].select.code} `;
      }
      if (sqlGeneral.length === 0) {
        Vue.cleanConditions();
      }
    }
    return sqlGeneral;
  },
  dateSQL(Vue) {
    let sqlGeneral = '';

    for (let n in Vue.conditions) {
      let sql = '';

      switch (Vue.conditions[n].select.code) {
        case 'between':
          var startDate = DateTime.fromJSDate(
            Vue.conditions[n].input.startDate instanceof Date
              ? Vue.conditions[n].input.startDate
              : new Date(Vue.conditions[n].input.startDate),
          ).toFormat('dd.MM.yyyy');

          var endDate = DateTime.fromJSDate(
            Vue.conditions[n].input.endDate instanceof Date
              ? Vue.conditions[n].input.endDate
              : new Date(Vue.conditions[n].input.endDate),
          ).toFormat('dd.MM.yyyy');

          sql += ` (${Vue.key_column} >= '${startDate}' AND ${Vue.key_column} < DATEADD(dd, 1, '${endDate}') ) `;
          break;
        case 'date_today':
          sql += ` ( ${Vue.key_column} >= DATEADD(dd, 0, DATEDIFF(dd, 0, GETDATE())) AND ${Vue.key_column} < DATEADD(dd, 1, DATEDIFF(dd, 0, GETDATE())) ) `;
          break;
        case 'date_yesterday':
          sql += ` ( ${Vue.key_column} >= DATEADD(dd, -1, DATEDIFF(dd, 0, GETDATE())) AND ${Vue.key_column} < DATEADD(dd, 0, DATEDIFF(dd, 0, GETDATE())) ) `;
          break;
        case 'date_seven_days':
          sql += ` ( ${Vue.key_column} >= DATEADD(dd, -7, DATEDIFF(dd, 0, GETDATE())) AND ${Vue.key_column} < DATEADD(dd, 1, DATEDIFF(dd, 0, GETDATE())) ) `;
          break;
        case 'date_thirty_days':
          sql += ` ( ${Vue.key_column} >= DATEADD(dd, -30, DATEDIFF(dd, 0, GETDATE())) AND ${Vue.key_column} < DATEADD(dd, 1, DATEDIFF(dd, 0, GETDATE())) ) `;
          break;
        case 'is not null':
          sql += ` ${Vue.key_column} is not null `;
          break;
        case 'is null':
          sql += ` ${Vue.key_column} is null `;
          break;
      }

      if (sql.length > 0) {
        if (Vue.conditions[n].algorithm !== null) {
          sqlGeneral += Vue.conditions[n].algorithm ? ' OR ' : ' AND ';
        }
        sqlGeneral += sql;
      } else {
        Vue.conditions.splice(n, 1);
      }
    }
    if (Vue.conditions.length === 0) {
      Vue.cleanConditions();
    }

    return sqlGeneral;
  },

  generateKey(key_column) {
    return `[${key_column}]`;
  },

  getOrder(key_column, sort) {
    return ` [${key_column}] ${sort} `;
  },

  getBoolValue(value) {
    return `${value}`;
  },
};

export default mssql;
