<!-- FIXME: Объединить все Badge -->
<template>
  <span class="table-badge badge-grey">{{ value }}</span>
</template>

<script>
export default {
  name: 'BadgeSecondary',
  props: ['value'],
};
</script>
