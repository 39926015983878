<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.99996 1.33325C8.36815 1.33325 8.66663 1.63173 8.66663 1.99992V13.9999C8.66663 14.3681 8.36815 14.6666 7.99996 14.6666C7.63177 14.6666 7.33329 14.3681 7.33329 13.9999V1.99992C7.33329 1.63173 7.63177 1.33325 7.99996 1.33325ZM2.52855 4.86185C2.7889 4.6015 3.21101 4.6015 3.47136 4.86185L6.13803 7.52851C6.39838 7.78886 6.39838 8.21097 6.13803 8.47132L3.47136 11.138C3.21101 11.3983 2.7889 11.3983 2.52855 11.138C2.26821 10.8776 2.26821 10.4555 2.52855 10.1952L4.05715 8.66659H1.33329C0.965103 8.66659 0.666626 8.36811 0.666626 7.99992C0.666626 7.63173 0.965103 7.33325 1.33329 7.33325H4.05715L2.52855 5.80466C2.26821 5.54431 2.26821 5.1222 2.52855 4.86185ZM13.4714 4.86185C13.7317 5.1222 13.7317 5.54431 13.4714 5.80466L11.9428 7.33325H14.6666C15.0348 7.33325 15.3333 7.63173 15.3333 7.99992C15.3333 8.36811 15.0348 8.66659 14.6666 8.66659H11.9428L13.4714 10.1952C13.7317 10.4555 13.7317 10.8776 13.4714 11.138C13.211 11.3983 12.7889 11.3983 12.5286 11.138L9.86189 8.47132C9.60154 8.21097 9.60154 7.78886 9.86189 7.52851L12.5286 4.86185C12.7889 4.6015 13.211 4.6015 13.4714 4.86185Z"
    />
  </svg>
</template>

<script>
/** @deprecated */
export default {
  name: 'TodayIcon',
};
</script>
