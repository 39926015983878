<template>
  <thead class="table-component-thead sticky">
    <tr class="columns">
      <th
        v-for="(col, index) in columns"
        :id="'col-sticky-head-' + index"
        :key.prop="col"
        class="th"
        scope="col"
        :colspan="getColSpan(col, index)"
        :class="{ ...hideTableHeader(col), ...getTableFixColClasses(index) }"
        :style.camel="{ left: col.left }"
      >
        <TableHeadDropdown
          :data="data"
          :col="col"
        />
      </th>
    </tr>
  </thead>
</template>

<script>
import TableHeadDropdown from '@/components/table/TableHeadDropdown';
import VuexAdapter from '@/services/vuex-adapter.js';
import { useTableStore } from '@/stores/table.js';

export default {
  name: 'TableHead',
  components: {
    TableHeadDropdown,
  },
  props: ['data'],
  data: () => ({
    startFixWidth: 0,
    tableStore: useTableStore(),
  }),
  computed: {
    tableFixCols() {
      return this.tableStore[VuexAdapter.fixColsTableNameGetter(this.data.name)];
    },
    preferences() {
      return this.tableStore[VuexAdapter.visibleColumnsTableNameGetter(this.data.name)] || {};
    },
    sortColumns() {
      return this.tableStore[VuexAdapter.sortColumnsTableNameGetter(this.data.name)] || [];
    },
    columns() {
      const array = [...this.sortColumns],
        result = {};

      if (!array.length) {
        for (const key in this.data.info) {
          let order = !this.data.info[key]?.order ? 100 : parseInt(this.data.info[key].order);
          do {
            order += 1;
          } while (array[order + 1] !== undefined);
          array[order + 1] = key;
        }
      }
      for (const keyRow in this.data.info) {
        if (this.data.info[keyRow]?.configTitle) {
          result[0] = {
            keyRow: keyRow,
            value: this.data.info[keyRow],
            left: 0,
          };
          continue;
        }
        if (
          (this.preferences[keyRow] !== undefined && this.preferences[keyRow] === false) ||
          (!this.data.info[keyRow].visible && this.preferences[keyRow] === undefined)
        ) {
          continue;
        }

        array.forEach((valueOrder, keyOrder) => {
          if (valueOrder === keyRow) {
            result[keyOrder + 1] = {
              keyRow: keyRow,
              value: this.data.info[keyRow],
            };
          }
        });
      }
      return result;
    },
  },
  methods: {
    getTableFixColClasses(keyOrder) {
      if (this.tableFixCols && keyOrder < this.tableFixCols.code) {
        return {
          'col-sticky-head': true,
          ['col-sticky-num-' + keyOrder]: true,
        };
      }
      return {};
    },

    getColSpan(col) {
      return this.data.info[col.keyRow].colspan;
    },

    hideTableHeader(col) {
      return {
        'table-design-hide-th': !!this.data.info[col.keyRow].invisibleTh,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.th {
  text-align: left;
  background-color: var(--color-gray-075);

  &:not(:first-child) {
    border-left: var(--border-gray-100);
  }
}
</style>
