import { defineStore } from 'pinia';
import { useMasterStepsStore } from '@/stores/master-steps.js';
import StoreId from '@/common/enums/store-id.js';

export const useMasterFilesStore = defineStore(StoreId.MasterFiles, {
  state: () => ({
    files: [],
  }),
  getters: {
    length(state) {
      return state.files.length;
    },
  },
  actions: {
    set(payload) {
      if (payload.length > 0) {
        const masterStepsStore = useMasterStepsStore();
        masterStepsStore.enable('files');
      }
      this.files = payload;
    },
  },
});
