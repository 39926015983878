<template>
  <Transition name="opacity-replaced">
    <div
      v-show="isActive"
      ref="resize"
      class="tab-ui"
    >
      <slot />
    </div>
  </Transition>
</template>

<script>
import { computed, defineComponent } from 'vue';
import { uniqueId } from 'lodash-es';
import ResizeMixin from '@/mixins/resize-mixin.js';

export default defineComponent({
  name: 'TabUi',
  mixins: [ResizeMixin],
  inject: ['onTabResize', 'addTab', 'removeTab', 'activeId'],
  props: {
    title: {
      type: String,
      default: 'Неизвестная вкладка',
    },
    newTab: {
      type: Boolean,
      default: false,
    },
    data: null,
  },
  data() {
    return {
      id: null,
    };
  },
  computed: {
    isActive() {
      return this.id === this.activeId;
    },
  },
  mounted() {
    if (!this.newTab) {
      this.id = uniqueId('tab-ui-');
    }

    this.addTab({
      id: this.id,
      title: this.title,
      newTab: this.newTab,
      data: computed(() => this.data),
    });
  },
  unmounted() {
    this.removeTab(this.id);
  },
  methods: {
    onResize(sizes) {
      this.onTabResize(sizes);
    },
  },
});
</script>

<style scoped lang="scss">
.tab-ui {
  width: 100%;
  top: 0;
}
</style>
