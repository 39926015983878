<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.33398 13.1995C5.33398 12.8314 5.63246 12.5329 6.00065 12.5329L12.8752 12.5329L10.7493 8.65324C10.5724 8.33035 10.6907 7.92516 11.0136 7.74823C11.3365 7.5713 11.7417 7.68963 11.9186 8.01252L14.5853 12.8792C14.6985 13.0857 14.6943 13.3367 14.5743 13.5393C14.4542 13.7419 14.2362 13.8662 14.0007 13.8662L6.00065 13.8662C5.63246 13.8662 5.33398 13.5677 5.33398 13.1995Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.13379 6.50515L1.13379 7.33268C1.13379 7.70087 1.43227 7.99935 1.80046 7.99935C2.16865 7.99935 2.46712 7.70087 2.46712 7.33268L2.46712 6.53268C2.46712 5.96163 2.46764 5.57344 2.49216 5.27338C2.51604 4.9811 2.55932 4.83163 2.61245 4.72736C2.74028 4.47648 2.94425 4.27251 3.19514 4.14468C3.2994 4.09155 3.44887 4.04827 3.74115 4.02439C4.04121 3.99987 4.4294 3.99935 5.00046 3.99935L12.8894 3.99935L10.7455 8.01895C10.5723 8.34383 10.6952 8.74765 11.0201 8.92092C11.3449 9.09418 11.7487 8.97128 11.922 8.64641L14.5887 3.64641C14.6989 3.43978 14.6926 3.19049 14.5721 2.98969C14.4517 2.78888 14.2347 2.66601 14.0005 2.66602L4.97292 2.66602C4.43628 2.66601 3.99337 2.666 3.63257 2.69548C3.25784 2.7261 2.91337 2.79181 2.58982 2.95667C2.08805 3.21233 1.6801 3.62028 1.42444 4.12204C1.25958 4.4456 1.19387 4.79007 1.16325 5.1648C1.13377 5.5256 1.13378 5.96851 1.13379 6.50515Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.80441 8.19526C7.06476 8.45561 7.06476 8.87772 6.80441 9.13807L3.80441 12.1381C3.54406 12.3984 3.12195 12.3984 2.8616 12.1381L1.52827 10.8047C1.26792 10.5444 1.26792 10.1223 1.52827 9.86193C1.78862 9.60158 2.21073 9.60158 2.47108 9.86193L3.33301 10.7239L5.8616 8.19526C6.12195 7.93491 6.54406 7.93491 6.80441 8.19526Z"
    />
  </svg>
</template>

<script>
/** @deprecated */
export default {
  name: 'ReportBackIcon',
};
</script>
