<template>
  <SearchMultipleUi
    v-bind="field"
    v-model="model"
    scroll
  />
</template>

<script>
import { defineComponent } from 'vue';
import SearchMultipleUi from '@/components/ui/SearchMultipleUi.vue';
import StepMixin from '@/mixins/document-master/step-mixin.js';
import DocumentMasterFields from '@/common/consts/document-master/fields.js';
import { mapActions, mapState } from 'pinia';
import { omit } from 'lodash-es';
import { useMasterLinksStore } from '@/stores/master-links.js';

export default defineComponent({
  name: 'StepLinks',
  components: {
    SearchMultipleUi,
  },
  mixins: [StepMixin],
  computed: {
    ...mapState(useMasterLinksStore, ['links']),
    field() {
      return omit(DocumentMasterFields.LINKS, ['type', 'name', 'spoiler']);
    },
    model: {
      get() {
        return this.links;
      },
      set(value) {
        this.set(value);
      },
    },
  },
  methods: {
    ...mapActions(useMasterLinksStore, ['set']),
  },
});
</script>

<style scoped lang="scss">
.search-multiple-ui {
  height: 100%;
}
</style>
