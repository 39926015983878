<script setup>
import commissions from '@/configs/tables/commissions';
import { onMounted } from 'vue';
import useMarkCommissions from '@/composables/use-mark-commissions.js';
import Emitter from '@/services/emitter.js';
import EyeCommissions from '@/components/table/columns/EyeCommissions.vue';
import { useRolesStore } from '@/stores/roles.js';
import { DOCUMENTS__REGISTRATION_DATA } from '@/configs/events.js';
import TableIndex from '@/components/table/TableIndex.vue';

defineProps(['componentWidth']);

const rolesStore = useRolesStore();

const COMMISSIONS_CONFIGS = commissions(rolesStore.accessToEvent(DOCUMENTS__REGISTRATION_DATA), EyeCommissions);
const HOTKEYS = ['ctrl+m, command+m', 'ctrl+u, command+u'];

const { markAsRead, markAsUnread } = useMarkCommissions();

onMounted(() => Emitter.emit('document-preview-clear', true));

function onHotkey({ event, key, row }) {
  event.preventDefault();

  if (!row) {
    return;
  }

  switch (key) {
    case HOTKEYS[0]: {
      markAsRead(row);
      break;
    }
    case HOTKEYS[1]: {
      markAsUnread(row);
      break;
    }
  }
}
</script>

<template>
  <TableIndex
    :configs="COMMISSIONS_CONFIGS"
    :component-width="componentWidth"
    :hotkeys="HOTKEYS"
    @hotkey="onHotkey"
  />
</template>
