<template>
  <div
    class="file-icon-info"
    data-tooltip-position="bottom-left"
    data-tooltip-size="auto"
    data-tooltip-text-align="left"
    role="tooltip"
  >
    <NoteFileIcon :fill="fill" />
    <div class="tooltip-aria-label">
      {{ note }}
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import NoteFileIcon from '@/components/icons/NoteFileIcon.vue';

export default defineComponent({
  name: 'FileNote',
  components: {
    NoteFileIcon,
  },
  props: {
    note: {
      type: String,
      required: true,
    },
    sub: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    fill() {
      return this.sub ? 'grey' : 'var(--color-green-800)';
    },
  },
});
</script>
