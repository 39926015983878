<template>
  <div
    v-if="buttons.length"
    class="solution-controls-buttons"
  >
    <StartDocModal
      ref="startDocModal"
      :events="events"
      :doc-id="doc['DOC_ID']"
    />
    <PauseDocModal
      ref="pauseDocModal"
      :events="events"
      :doc-id="doc['DOC_ID']"
    />
    <StopDocModal
      ref="stopDocModal"
      :events="events"
      :doc-id="doc['DOC_ID']"
    />

    <ButtonUi
      v-for="{ key, title, icon, color, mode, method } in buttons"
      :key="key"
      size="s"
      :color="color"
      :mode="mode"
      @click="method"
    >
      <component
        :is="icon"
        v-prefix="'s'"
      />
      <span>{{ title }}</span>
    </ButtonUi>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import StartDocModal from '@/components/modals/Doc/StartDocModal';
import PauseDocModal from '@/components/modals/Doc/PauseDocModal';
import StopDocModal from '@/components/modals/Doc/StopDocModal';
import { mapState } from 'pinia';
import ButtonUi from '@/components/ui/ButtonUi.vue';
import { useUserStore } from '@/stores/user.js';
import ControlsDocSolutionMixin from '@/mixins/controls-doc-solution-mixin.js';

export default defineComponent({
  name: 'SolutionControlsButtons',
  components: {
    ButtonUi,
    StopDocModal,
    PauseDocModal,
    StartDocModal,
  },
  mixins: [ControlsDocSolutionMixin],
  props: {
    doc: Object,
  },
  computed: {
    ...mapState(useUserStore, { userId: 'id' }),
    buttons() {
      if (!this.doc['DOC_ID'] || this.doc['CONTROLLER_ID'] !== this.userId) {
        return [];
      }

      return Object.entries(this.route_point_solution)
        .filter(([key, value]) => {
          return (
            value['routePointType'] === this.doc['RPT_ID'] &&
            !(key === 'start' && this.doc['Активен'] === 1) &&
            !(key === 'pause' && this.doc['Активен'] === 0)
          );
        })
        .map(([key, value]) => {
          return {
            key,
            icon: value['icon'],
            title: value['title'],
            color: value['color'],
            mode: value['mode'],
            method: value['method'],
          };
        });
    },
  },
});
</script>

<style scoped lang="scss">
.solution-controls-buttons {
  display: flex;
}

.button-ui {
  &:not(:last-child) {
    margin-right: 8px;
  }
}
</style>
