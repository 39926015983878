import { defineComponent } from 'vue';

/** @deprecated Используйте composables/use-modal */
export default defineComponent({
  data() {
    return {
      preventClosure: false,
      /** @deprecated Используйте this.$notify */
      errorMessage: null,
    };
  },
  methods: {
    show() {
      this.$refs.modal.show();
    },
    hide() {
      this.$refs.modal.hide();
      this.errorMessage = null;
      this.preventClosure = false;
    },
    showLoader() {
      this.$refs.modal.showLoader();
    },
    hideLoader() {
      this.$refs.modal.hideLoader();
    },
    enableClosure() {
      this.preventClosure = false;
    },
    disableClosure() {
      this.preventClosure = true;
    },
    close() {
      if (!this.preventClosure) {
        this.hide();
      }
    },
  },
});
