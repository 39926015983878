import { defineComponent } from 'vue';
import OrderBy from '@/components/table/filters/common/OrderBy';
import FiltersHeader from '@/components/table/filters/common/FiltersHeader';
import FilterButtons from '@/components/table/filters/common/FilterButtons.vue';
import DbAdapterMixin from '@/mixins/db-adapter-mixin.js';
import VuexAdapter from '@/services/vuex-adapter.js';
import ComboboxUi from '@/components/ui/ComboboxUi.vue';
import SwitchInputForm from '@/components/form/SwitchInputForm';
import NumberInputForm from '@/components/form/NumberInputForm';
import { useTableStore } from '@/stores/table.js';

const CONDITION_COUNT = 3;

/** @deprecated Используйте composables */
export default defineComponent({
  components: {
    FilterButtons,
    OrderBy,
    FiltersHeader,
    ComboboxUi,
    SwitchInputForm,
    NumberInputForm,
  },
  mixins: [DbAdapterMixin],
  props: ['data', 'col', 'key_column', 'col_filters'],
  data() {
    return {
      conditions: [
        {
          algorithm: null,
          select: null,
          input: null,
        },
      ],
      options: [],
      tableStore: useTableStore(),
    };
  },
  computed: {
    conditionButtonDisabled() {
      return this.conditions?.length >= CONDITION_COUNT;
    },
  },
  watch: {
    col_filters(col_filters) {
      if (!col_filters) {
        this.cleanConditions();
      }
    },
  },
  mounted() {
    if (this.col_filters) {
      this.conditions = JSON.parse(JSON.stringify(this.col_filters));
    } else {
      if (this.options[0] !== undefined && this.conditions[0].select === null) {
        this.conditions[0].select = this.options[0];
      }
    }
  },
  methods: {
    async tableSetFilter(data) {
      await this.tableStore[VuexAdapter.filterTableNameAction(this.data.name)](data);
    },
    async tableDeleteFilter(key_column) {
      await this.tableStore[VuexAdapter.delFilterTableNameAction(this.data.name)](key_column);
    },
    addConditions() {
      for (let i = 0; i < CONDITION_COUNT; i++) {
        if (this.conditions[i]) {
          continue;
        }
        this.conditions[i] = {
          algorithm: false,
          select: null,
          input: null,
        };
        break;
      }
    },
    async applyFilter() {
      const whereSQL = await this.getSQL();
      if (whereSQL?.length) {
        await this.tableSetFilter({
          key: this.key_column,
          whereSQL,
          whereData: this.conditions,
        });
      }
      document.body.click();
    },
    async cleanFilter() {
      await this.tableDeleteFilter(this.key_column);
      this.cleanConditions();
      document.body.click();
    },
    cleanConditions() {
      this.conditions = [
        {
          algorithm: null,
          select: this.options[0] ?? null,
          input: null,
        },
      ];
    },
    async getSQL() {
      if (this.sqlGenerator === undefined) {
        console.error('Свойство "sqlGenerator" для генерации SQL не определено');
      }

      return this.dbAdapter[this.sqlGenerator](this);
    },
  },
});
