<template>
  <header class="document-master-header">
    <Transition
      :name="animation"
      mode="out-in"
    >
      <div
        :key="header.title"
        class="tooltip _title"
        :aria-label="header.title"
        data-tooltip-position="bottom"
        data-tooltip-size="auto"
        :role="showTitleTooltip ? 'tooltip' : null"
      >
        <DotUi
          v-if="header.dot"
          :color="header.dot"
        />
        <h2
          ref="title"
          class="title"
        >
          {{ header.title }}
        </h2>
      </div>
    </Transition>

    <div
      v-if="header.tooltip"
      class="tooltip _icon"
      :aria-label="header.tooltip"
      data-tooltip-position="bottom-left"
      data-tooltip-size="auto"
      role="tooltip"
    >
      <InfoIcon />
    </div>
  </header>
</template>

<script>
import { defineComponent } from 'vue';
import InfoIcon from '@/assets/icons/info.svg';
import { mapState } from 'pinia';
import DotUi from '@/components/ui/DotUi.vue';
import { only } from '@/common/utils/props-validators';
import { useMasterStore } from '@/stores/master.js';

export default defineComponent({
  name: 'DocumentMasterHeader',
  components: {
    DotUi,
    InfoIcon,
  },
  props: {
    animation: {
      type: String,
      required: true,
      validator: only('opacity', 'slide'),
    },
  },
  data() {
    return {
      showTitleTooltip: false,
    };
  },
  computed: {
    ...mapState(useMasterStore, ['header']),
  },
  mounted() {
    const title = this.$refs.title;
    this.showTitleTooltip = title && title.scrollWidth > title.offsetWidth;
  },
});
</script>

<style scoped lang="scss">
.document-master-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dot-ui {
  margin-right: 8px;
  transform: translateY(-1px);
}

/* TODO: Перекрытие стилей */
.tooltip {
  &::after {
    font-size: var(--font-size-l) !important;
    line-height: var(--line-height-xl) !important;
  }

  &._title {
    min-width: 0;
    display: flex;
    align-items: center;

    &::after {
      white-space: nowrap !important;
    }
  }

  &._icon {
    cursor: pointer;

    &::after {
      width: 350px !important;
      text-align: left;
    }
  }
}

.title {
  margin-right: 8px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
