<template>
  <div
    ref="resize"
    class="tabs-ui"
    :class="{ '_full-height': fullHeight }"
  >
    <header class="header">
      <div
        v-for="(tab, index) in allTabs"
        :key="tab.id"
        class="wrapper"
      >
        <div
          class="tab"
          :class="{ _active: tab.id === activeId }"
        >
          <button
            class="button"
            :disabled="tab.id === activeId"
            @click="activeId = tab.id"
          >
            <slot
              name="title"
              v-bind="tab"
              :active="tab.id === activeId"
            >
              <span class="title">{{ tab.title }}</span>
            </slot>
          </button>

          <ButtonIconUi
            v-if="showRemove(tab)"
            class="close"
            color="gray"
            size="s"
            @click="close(tab)"
          >
            <CloseSmallIcon />
          </ButtonIconUi>
        </div>

        <ButtonIconUi
          v-if="showAdd(index)"
          color="gray"
          @click="add"
        >
          <PlusFillIcon />
        </ButtonIconUi>
      </div>
    </header>

    <div
      ref="content"
      class="content"
    >
      <slot />

      <TabUi
        title="Новая вкладка"
        new-tab
      >
        <slot name="new-tab" />
      </TabUi>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue';
import ButtonIconUi from '@/components/ui/ButtonIconUi.vue';
import PlusFillIcon from '@/assets/icons/plus-fill.svg';
import CloseSmallIcon from '@/assets/icons/close-small.svg';
import TabUi from '@/components/ui/TabUi.vue';
import ResizeMixin from '@/mixins/resize-mixin.js';

export default defineComponent({
  name: 'TabsUi',
  components: {
    TabUi,
    CloseSmallIcon,
    ButtonIconUi,
    PlusFillIcon,
  },
  mixins: [ResizeMixin],
  provide() {
    return {
      onTabResize: () => {
        this.calcContentHeight();
      },
      addTab: (tab) => {
        if (!this.activeId) {
          this.activeId = tab.id;
        }

        if (tab.newTab) {
          this.newTab = tab;
        } else {
          this.tabs = [...this.tabs, tab];
        }
      },
      removeTab: (id) => {
        if (this.activeId === id) {
          const activeIndex = this.tabs.findIndex((tab) => tab.id === id);
          const activeTab = this.tabs[activeIndex + 1] || this.tabs[activeIndex - 1];
          this.activeId = activeTab?.id || null;
        }

        this.tabs = this.tabs.filter((tab) => tab.id !== id);
      },
      activeId: computed(() => this.activeId),
    };
  },
  props: {
    dynamic: {
      type: Boolean,
      default: false,
    },
    fullHeight: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['add', 'remove'],
  data() {
    return {
      activeId: null,
      tabs: [],
      newTab: null,
      contentHeight: null,
    };
  },
  computed: {
    allTabs() {
      return this.showNewTab ? [...this.tabs, this.newTab] : this.tabs;
    },
    showNewTab() {
      return this.newTab && !this.activeId;
    },
    contentHeightStyle() {
      return this.contentHeight && `${this.contentHeight}px`;
    },
  },
  watch: {
    activeId() {
      this.$nextTick(() => this.calcContentHeight());
    },
  },
  methods: {
    setActive(value) {
      const index = value === 'last' ? this.tabs.length - 1 : value;
      const tab = this.tabs[index];

      if (tab) {
        this.activeId = tab.id;
      }
    },
    showRemove(tab) {
      return this.dynamic && (this.tabs.length || !tab.newTab);
    },
    showAdd(index) {
      return this.dynamic && !this.showNewTab && index === this.tabs.length - 1;
    },
    close(tab) {
      if (tab.newTab) {
        this.activeId = this.tabs[this.tabs.length - 1].id;
      } else {
        this.$emit('remove', tab);
      }
    },
    add() {
      if (this.$slots['new-tab']) {
        this.activeId = null;
      } else {
        this.$emit('add');
      }
    },
    onResize() {
      this.calcContentHeight();
    },
    calcContentHeight() {
      if (this.fullHeight) {
        this.contentHeight = null;
        return;
      }

      const array = Array.from(this.$refs.content.children);
      const activeIndex = this.tabs.findIndex((tab) => tab.id === this.activeId);
      this.contentHeight = array[activeIndex]?.getBoundingClientRect().height || null;
    },
  },
});
</script>

<style scoped lang="scss">
.tabs-ui {
  position: relative;

  display: flex;
  flex-direction: column;

  &._full-height {
    height: 100%;

    .content {
      min-height: 0;

      > * {
        height: 100%;
      }
    }
  }
}

.header {
  flex-shrink: 0;
  margin: 0 -8px 8px 0;

  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.wrapper {
  margin: 0 8px 8px 0;

  display: flex;
  align-items: center;
}

.tab {
  height: 24px;
  max-width: 244px;

  display: flex;

  border-radius: 4px;

  transition: background-color var(--transition-fast);

  &:not(:last-child) {
    margin-right: 8px;
  }

  &._active {
    background-color: var(--color-gray-075);
  }

  &:not(._active) {
    @media (hover: hover) {
      &:hover {
        .button {
          color: var(--color-gray-600);

          :deep(svg) {
            fill: var(--color-gray-300);
          }
        }
      }
    }
  }
}

.button {
  padding: 0 8px;
  min-width: 0;

  display: flex;
  align-items: center;

  font-size: var(--font-size);
  line-height: var(--line-height);
  font-weight: var(--font-weight-extra-bold);

  transition: color var(--transition-fast);

  :deep(svg) {
    fill: var(--color-gray-500);
    transition: fill var(--transition-fast);
  }
}

.close {
  align-self: center;
  margin-left: -8px;
  margin-right: 2px;
}

.title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.content {
  flex-grow: 1;
  position: relative;

  height: v-bind(contentHeightStyle);

  transition: height var(--transition);
}
</style>
