import Api from '@/services/api/api.js';
import { AUTO_REPLACES_GET } from '@/configs/end-points.js';
import { COMMENT_AUTOCORRECT_COUNT, TEMPLATE_AUTOCORRECT_COUNT, USER_AUTOCORRECT_COUNT } from '@/configs/autocorrect';
import { defineStore } from 'pinia';
import StoreId from '@/common/enums/store-id.js';

export const useAutocorrectStore = defineStore(StoreId.Autocorrect, {
  state: () => ({
    usersAutocorrect: [],
    commentAutocorrect: [],
    autocorrectReplaces: [],
    autocorrectReplacesSelect: [],
  }),
  getters: {
    commentAutocorrectReversed(state) {
      return [...state.commentAutocorrect].reverse();
    },
    autocorrectReplaceSelectGetter(state) {
      let select = [];
      for (let n in state.autocorrectReplacesSelect) {
        select.push({
          label: state.autocorrectReplacesSelect[n]['Замена'],
          code: state.autocorrectReplacesSelect[n]['FAV_ID'],
        });
      }
      return select;
    },
    autocorrectReplaceObjListGetter(state) {
      let obg = {};
      for (let n in state.autocorrectReplacesSelect) {
        obg[state.autocorrectReplacesSelect[n]['FAV_ID']] = state.autocorrectReplacesSelect[n];
      }
      return obg;
    },
  },
  actions: {
    addUserAutocorrect(userId) {
      if (userId > 0 && !this.usersAutocorrect.includes(userId)) {
        this.usersAutocorrect.unshift(userId);
        this.usersAutocorrect = this.usersAutocorrect.slice(0, USER_AUTOCORRECT_COUNT);
      }
    },
    delUserAutocorrect(userId) {
      this.usersAutocorrect = this.usersAutocorrect.filter((v) => v !== userId);
    },
    addReplaceAutocorrect(favId) {
      if (favId > 0 && !this.autocorrectReplaces.includes(favId)) {
        this.autocorrectReplaces.unshift(favId);
        this.autocorrectReplaces = this.autocorrectReplaces.slice(0, TEMPLATE_AUTOCORRECT_COUNT);
      }
    },
    delReplaceAutocorrect(favId) {
      this.autocorrectReplaces = this.autocorrectReplaces.filter((v) => v !== favId);
    },
    addCommentAutocorrect(comment) {
      this.commentAutocorrect = this.commentAutocorrect.filter((c) => comment !== c);
      this.commentAutocorrect.unshift(comment);
      this.commentAutocorrect = this.commentAutocorrect.slice(0, COMMENT_AUTOCORRECT_COUNT);
    },
    delCommentAutocorrect(comment) {
      this.commentAutocorrect = this.commentAutocorrect.filter((v) => v !== comment);
    },
    async setAutocorrectReplacesSelectAction(data) {
      if (!this.autocorrectReplacesSelect?.length) {
        return await Api.post(AUTO_REPLACES_GET, data).then((response) => {
          this.autocorrectReplacesSelect = response.items;
        });
      }
    },
  },
  persist: true,
});
