<template>
  <ModalUi
    ref="modal"
    :z-index="11_000"
    popup
    :visible="showModal"
    @update:visible="setShowModal"
  >
    <template #header>
      <div class="header">
        <LogoIcon class="icon" />
        <h2>Обновление системы</h2>
      </div>
    </template>

    <template #body>
      <p class="paragraph">Доступно обновление<br />с версии {{ current?.version }} до версии {{ next?.version }}</p>
      <p class="paragraph">Для использования новых функций необходимо перезагрузить приложение</p>
    </template>

    <template #footer>
      <CloseButton @click="hide" />

      <ButtonUi @click="update">
        <ReloadIcon v-prefix />
        <span>Обновить</span>
      </ButtonUi>
    </template>
  </ModalUi>
</template>

<script>
import { defineComponent } from 'vue';
import ReloadIcon from '@/assets/icons/reload.svg';
import { mapActions, mapState } from 'pinia';
import ModalUi from '@/components/ui/ModalUi.vue';
import ModalMixin from '@/mixins/modal-mixin.js';
import ButtonUi from '@/components/ui/ButtonUi.vue';
import CloseButton from '@/components/buttons/CloseButton.vue';
import LogoIcon from '@/assets/icons/logo.svg';
import { useVersionStore } from '@/stores/version.js';
import { useCommonStore } from '@/stores/common.js';

export default defineComponent({
  name: 'UpdateModal',
  components: {
    LogoIcon,
    CloseButton,
    ButtonUi,
    ModalUi,
    ReloadIcon,
  },
  mixins: [ModalMixin],
  computed: {
    ...mapState(useVersionStore, ['showModal', 'current', 'next']),
  },
  mounted() {
    this.watch();
  },
  methods: {
    ...mapActions(useCommonStore, ['cleanAllStoresAction']),
    ...mapActions(useVersionStore, ['watch', 'setShowModal']),
    update() {
      this.cleanAllStoresAction(false);
      window.location.reload();
    },
  },
});
</script>

<style scoped lang="scss">
.header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon {
  margin-bottom: 8px;

  width: 80px;
  height: auto;
}

.paragraph {
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.button-ui {
  &:not(:last-child) {
    margin-right: 8px;
  }
}
</style>
