<template>
  <span v-if="data == 1">Да</span>
  <span v-else>Нет</span>
</template>

<script>
export default {
  name: 'DefaultFlag',
  props: ['data'],
};
</script>
