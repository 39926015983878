import STEP_FILES from '@/common/consts/document-master/steps/step-files.js';
import STEP_LINKS from '@/common/consts/document-master/steps/step-links.js';
import STEP_BARCODE from '@/common/consts/document-master/steps/step-barcode.js';
import STEP_CLIENTS from '@/common/consts/document-master/steps/step-clients.js';
import STEP_RESOLUTION from '@/common/consts/document-master/steps/step-resolution.js';
import STEP_TYPE from '@/common/consts/document-master/steps/step-type.js';
import StepContentCreator from '@/common/consts/document-master/steps/step-content.js';
import STEP_DEADLINE from '@/common/consts/document-master/steps/step-deadline.js';
import STEP_ROUTE from '@/common/consts/document-master/steps/step-route.js';
import StepContractorCreator from '@/common/consts/document-master/steps/step-contractor.js';
import STEP_EXECUTION from '@/common/consts/document-master/steps/step-execution.js';
import STEP_LAUNCH from '@/common/consts/document-master/steps/step-launch.js';
import { useCacheStore } from '@/stores/cache.js';
import CacheGroup from '@/common/enums/cache-group.js';
import { defineStore } from 'pinia';
import { useMasterTypeStore } from '@/stores/master-type.js';
import { useMasterContentStore } from '@/stores/master-content.js';
import { useMasterParametersStore } from '@/stores/master-parameters.js';
import { useMasterDeadlineStore } from '@/stores/master-deadline.js';
import { useMasterLinksStore } from '@/stores/master-links.js';
import { useMasterClientsStore } from '@/stores/master-clients.js';
import { useMasterCommentStore } from '@/stores/master-comment.js';
import { useMasterFilesStore } from '@/stores/master-files.js';
import { useMasterControllerStore } from '@/stores/master-controller.js';
import { useMasterStepsStore } from '@/stores/master-steps.js';
import { useMasterContractorsStore } from '@/stores/master-contractors.js';
import StoreId from '@/common/enums/store-id.js';

export const useMasterStore = defineStore(StoreId.Master, {
  state: () => ({
    isModalOpen: false,
    isWarningOpen: false,
    isLoading: false, // TODO: Отказаться от использования общего лоадера
    page: 0,
    controlsDisabled: false,
    isCreated: false,
    showForceClose: false,
    functions: {},
  }),
  getters: {
    steps() {
      const masterTypeStore = useMasterTypeStore();
      const masterStepsStore = useMasterStepsStore();
      const masterContractorsStore = useMasterContractorsStore();

      return [
        STEP_TYPE,
        StepContentCreator.create(masterTypeStore.contentTitle),
        STEP_DEADLINE,
        masterStepsStore.steps.barcode && STEP_BARCODE,
        masterStepsStore.steps.files && STEP_FILES,
        masterStepsStore.steps.links && STEP_LINKS,
        masterStepsStore.steps.clients && STEP_CLIENTS,
        masterStepsStore.steps.resolution && STEP_RESOLUTION,
        STEP_ROUTE,
        ...StepContractorCreator.createList(masterContractorsStore.contractors),
        STEP_EXECUTION,
        STEP_LAUNCH,
      ].filter((step) => !!step);
    },
    step(state) {
      return this.steps[state.page];
    },
    header() {
      return {
        title: this.step.title,
        tooltip: this.step.tooltip,
        dot: this.step.dot,
      };
    },
    menu() {
      return this.steps.map((step) => step.menuTitle);
    },
    badges() {
      const masterClientsStore = useMasterClientsStore();
      const masterFilesStore = useMasterFilesStore();
      const masterLinksStore = useMasterLinksStore();

      return {
        [STEP_CLIENTS.menuTitle]: masterClientsStore.length,
        [STEP_FILES.menuTitle]: masterFilesStore.length,
        [STEP_LINKS.menuTitle]: masterLinksStore.length,
      };
    },
    isFirstPage(state) {
      return state.page === 0;
    },
    isLastPage(state) {
      return state.page === this.steps.length - 1;
    },
    isDirty(state) {
      const masterTypeStore = useMasterTypeStore();
      return state.page > 0 || masterTypeStore.isDirty;
    },
  },
  actions: {
    show(files) {
      const masterControllerStore = useMasterControllerStore();
      masterControllerStore.setUser();

      if (files) {
        const masterFilesStore = useMasterFilesStore();
        masterFilesStore.set(files);
      }

      this.isModalOpen = true;
    },
    cancel() {
      if (this.isDirty) {
        this.setWarningOpen(true);
      } else {
        this.clear();
      }
    },
    back() {
      this.page = this.page - 1;
    },
    next() {
      if (this.functions.validate()) {
        this.page = this.page + 1;
      }
    },
    ready() {
      this.functions.ready();
    },
    goTo(page) {
      if (page === this.page) {
        return;
      }

      if (page < this.page) {
        this.page = page;
      } else {
        if (this.functions.validate()) {
          this.page = page;
        }
      }
    },
    clear() {
      const cacheStore = useCacheStore();
      cacheStore.remove({ group: CacheGroup.DocumentMaster });

      [
        useMasterTypeStore(),
        useMasterContentStore(),
        useMasterParametersStore(),
        useMasterDeadlineStore(),
        useMasterLinksStore(),
        useMasterClientsStore(),
        useMasterCommentStore(),
        useMasterFilesStore(),
        useMasterControllerStore(),
        useMasterStepsStore(),
        useMasterContractorsStore(),
      ].forEach((store) => store.$reset());

      this.$reset();
    },
    setWarningOpen(payload) {
      this.isWarningOpen = payload;
    },
    setLoading(payload) {
      this.isLoading = payload;
    },
    setControlsDisabled(payload) {
      this.controlsDisabled = payload;
    },
    setCreated() {
      this.isCreated = true;
    },
    setShowForceClose(payload) {
      this.showForceClose = payload;
    },
    setFunctions(payload) {
      this.functions = payload;
    },
  },
});
