<template>
  <button class="collapse-button">Свернуть</button>
</template>

<style scoped lang="scss">
.collapse-button {
  height: 32px;
  width: 100%;

  font-size: var(--font-size-l);
  line-height: var(--line-height-l);
  font-weight: var(--font-weight-bold);

  transition: color var(--transition);

  @media (hover: hover) {
    &:hover {
      color: var(--color-gray-600);
    }
  }
}
</style>
