import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import { useSystemStore } from '@/stores/system.js';

/** @deprecated Используйте composables */
export default defineComponent({
  data: () => ({
    stringSqlSearch: 'stringSqlSearch',
    flagSqlSearch: 'flagSqlSearch',
    identifierSqlSearch: 'identifierSqlSearch',
    identifierSqlNotInSearch: 'identifierSqlNotInSearch',
    intSqlSearch: 'intSqlSearch',
    generateKey: 'generateKey',
    getBoolValue: 'getBoolValue',
    getOrder: 'getOrder',
  }),
  computed: {
    ...mapState(useSystemStore, ['dbAdapter']),
  },
});
