import {
  RADIO_BUTTONS,
  TYPE_BOOL,
  TYPE_DEADLINE,
  TYPE_SEARCH_MULTIPLE,
  TYPE_SELECT,
  TYPE_SUB_TEXT,
  TYPE_TEXT,
  TYPE_TILE_LIST,
} from '@/configs/form';
import DocumentApi from '@/services/api/document-api.js';
import DocumentMasterApi from '@/services/api/document-master-api.js';
import AddLinkIcon from '@/assets/icons/add-link.svg';
import AddClientIcon from '@/assets/icons/add-client.svg';
import AttachmentIcon from '@/assets/icons/attachment.svg';
import FileLinkIcon from '@/assets/icons/file-link.svg';
import BarcodeIcon from '@/assets/icons/barcode.svg';
import ClientLinkIcon from '@/assets/icons/client-link.svg';
import ResolutionIcon from '@/assets/icons/resolution.svg';
import ClientApi from '@/services/api/client-api.js';
import CardLinkTypes from '@/components/document-master/CardLinkTypes.vue';
import { LaunchType } from '@/common/enums/launch-type.js';
import { StartType } from '@/common/enums/start-type.js';
import { DeadlineType } from '@/common/enums/deadline-type.ts';

export default class DocumentMasterFields {
  static TYPE = {
    type: TYPE_SELECT,
    name: 'type',
    placeholder: 'Тип',
    getOptions: DocumentMasterApi.getTypes,
    noClear: true,
    updateAfterLoad: true,
  };

  static CONTENT = {
    type: TYPE_TEXT,
    name: 'content',
    placeholder: 'Введите содержание документа',
    required: true,
    length: 255,
    rows: 12,
  };

  static DEADLINE = {
    type: TYPE_DEADLINE,
    name: 'deadline',
    required: true,
  };

  static LINKS = {
    type: TYPE_SEARCH_MULTIPLE,
    name: 'links',
    spoiler: 'Связанные документы',
    placeholder: 'Введите номер документа',
    addIcon: AddLinkIcon,
    addText: 'Добавить ссылки',
    getOptions: DocumentApi.getOptions,
    getCard: DocumentApi.getDocument,
    cardFooter: CardLinkTypes,
    showBadge: true,
  };

  static CLIENTS = {
    type: TYPE_SEARCH_MULTIPLE,
    name: 'clients',
    spoiler: 'Контрагенты',
    placeholder: 'Поиск контрагента',
    addIcon: AddClientIcon,
    addText: 'Добавить контрагента',
    getOptions: ClientApi.getOptions,
    getCard: ClientApi.getClient,
    cardFooter: CardLinkTypes,
    showBadge: true,
  };

  static COMMENT = {
    type: TYPE_TEXT,
    name: 'comment',
    spoiler: 'Примечание',
    length: 255,
    rows: 7,
    placeholder: 'Введите комментарий к документу',
  };

  static CONTROLLER = {
    type: TYPE_SELECT,
    name: 'controller',
    getOptions: DocumentMasterApi.getActiveUsers,
    updateAfterLoad: true,
    noClear: true,
    required: true,
  };

  static project(filter) {
    return {
      type: TYPE_SELECT,
      name: 'project',
      placeholder: 'Проект',
      getOptions: DocumentMasterApi.getProjects,
      filter,
      required: true,
    };
  }

  static parameters(parameters, typeId) {
    return parameters.map((parameter) => [parameter.getFormField(typeId)]);
  }

  static steps(withClients) {
    return {
      type: TYPE_TILE_LIST,
      name: 'steps',
      options: [
        {
          code: 'files',
          label: 'Прикрепить<br>файлы',
          icon: AttachmentIcon,
        },
        {
          code: 'links',
          label: 'Связать этот документ',
          icon: FileLinkIcon,
        },
        {
          code: 'barcode',
          label: 'Назначить штрих код',
          icon: BarcodeIcon,
        },
        withClients && {
          code: 'clients',
          label: 'Добавить ссылки на контрагентов',
          icon: ClientLinkIcon,
        },
        {
          code: 'resolution',
          label: 'Добавить резолюцию',
          icon: ResolutionIcon,
        },
      ].filter((step) => !!step),
    };
  }

  static launchType(getParams) {
    const { hidden, disabled } = getParams();

    return {
      type: RADIO_BUTTONS,
      name: 'launchType',
      hidden,
      disabled,
      options: [
        {
          code: LaunchType.Now,
          label: 'Запустить сразу и поставить на контроль',
        },
        {
          code: LaunchType.Delayed,
          label: 'Отложенный запуск',
          activeColor: '#FFDB65', // TODO: Использовать css-переменные
        },
        {
          code: LaunchType.None,
          label: 'Не запускать',
          activeColor: '#FFBCBC', // TODO: Использовать css-переменные
        },
      ],
    };
  }

  // TODO: Требуется рефакторинг
  static launchTypeNoneWarning(hidden) {
    return {
      type: TYPE_SUB_TEXT,
      style: { marginTop: '6px' }, // TODO: Отказаться от передачи стилей
      classes: ['message-warning'],
      label: `
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="24" height="24" rx="8" fill="none"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M19.4298 17.7511L12.4329 5.74819C12.24 5.41727 11.7619 5.41727 11.569 5.7482L4.57214 17.7511C4.37783 18.0845 4.61828 18.5029 5.00411 18.5029H18.9978C19.3836 18.5029 19.6241 18.0845 19.4298 17.7511ZM13.7288 4.99278C12.9572 3.66907 11.0447 3.66908 10.2731 4.99278L3.27625 16.9957C2.49902 18.329 3.46079 20.0029 5.00411 20.0029H18.9978C20.5411 20.0029 21.5029 18.329 20.7257 16.9957L13.7288 4.99278Z" fill="#e3ae00"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.8551 9.74664C12.9236 9.35705 12.6239 9 12.2283 9C11.9096 9 11.64 9.23578 11.5976 9.55166L11 14H12.107L12.8551 9.74664ZM11.5 17C12.3284 17 13 16.5523 13 16C13 15.4477 12.3284 15 11.5 15C10.6716 15 10 15.4477 10 16C10 16.5523 10.6716 17 11.5 17Z" fill="#e3ae00"/>
        </svg>
        Внимание, документ не будет запущен в работу, запустите его в карточке.
      `,
      hidden,
    };
  }

  static launchStartType(getParams) {
    const { showBefore, hidden } = getParams();

    return {
      type: TYPE_SELECT,
      name: 'startType',
      options: () =>
        [
          {
            label: 'Сразу',
            code: StartType.Immediately,
          },
          {
            label: 'Через',
            code: StartType.After,
          },
          showBefore && {
            label: 'За',
            code: StartType.Before,
          },
        ].filter(Boolean),
      required: true,
      noClear: true,
      style: { marginTop: '6px' }, // TODO: Отказаться от передачи стилей
      hidden,
    };
  }

  static launchDeadline(getParams) {
    const { hidden, countBackwards, startDate } = getParams();

    return {
      type: TYPE_DEADLINE,
      name: 'deadline',
      required: true,
      hidden,
      countBackwards,
      excludeOptions: [DeadlineType.None],
      startDate,
      style: { marginTop: '6px' }, // TODO: Отказаться от передачи стилей
    };
  }

  static stopDocument(hidden) {
    return {
      type: TYPE_BOOL,
      name: 'stopDocument',
      label: 'Снять документ с контроля до момента запуска',
      hidden,
    };
  }
}
