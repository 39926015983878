import { Preview } from '@/common/models/preview/preview.js';
import VuexAdapter from '@/services/vuex-adapter.js';
import {
  DOC_COMMENTS_GET,
  DOC_FILES_GET,
  DOC_GANTT_ITEMS,
  DOC_LINKS_GET,
  DOCS_CLIENTS_GET,
  DOCS_GET,
  DOCS_VIEWS_ALL,
} from '@/configs/end-points.js';
import { usePreviewFilesStore } from '@/stores/preview-files.js';
import { useRowStore } from '@/stores/row.js';
import { useListsStore } from '@/stores/lists.js';

export class DocumentPreview extends Preview {
  static intefaceIdent = 'DOCUMENT_PREVIEW';

  fetchDataPreview() {
    return new Promise((resolve, reject) => {
      this._abort().finally(() => {
        this._fetch()
          .then(() => resolve())
          .catch((e) => reject(e));
      });
    });
  }

  fetchPartlyPreview(endPoint) {
    const rowStore = useRowStore();
    const listsStore = useListsStore();

    switch (endPoint) {
      case DOCS_GET:
        return rowStore[VuexAdapter.getNameRowAction(endPoint)](this._entityId);
      case DOC_GANTT_ITEMS:
        return listsStore[VuexAdapter.getNameAction(DOC_GANTT_ITEMS)]({ doc_id: this._entityId });
      case DOC_COMMENTS_GET:
        return listsStore[VuexAdapter.getNameAction(DOC_COMMENTS_GET)]({
          doc_id: this._entityId,
          limit: this._limitLoad,
        });
      case DOC_FILES_GET: {
        const previewFilesStore = usePreviewFilesStore();
        return previewFilesStore.fetch(this._entityId);
      }
    }
  }

  _fetch() {
    const rowStore = useRowStore();
    const previewFilesStore = usePreviewFilesStore();
    const listsStore = useListsStore();

    const promices = [
      rowStore[VuexAdapter.getNameRowAction(DOCS_GET)](this._entityId),
      listsStore[VuexAdapter.getNameAction(DOCS_VIEWS_ALL)]({ doc_id: this._entityId, limit: this._limitLoad }),
      listsStore[VuexAdapter.getNameAction(DOC_COMMENTS_GET)]({
        doc_id: this._entityId,
        limit: this._limitLoad,
      }),
      listsStore[VuexAdapter.getNameAction(DOCS_CLIENTS_GET)]({
        doc_id: this._entityId,
        limit: this._limitLoad,
      }),
      listsStore[VuexAdapter.getNameAction(DOC_LINKS_GET)]({ doc_id: this._entityId, limit: this._limitLoad }),
      previewFilesStore.fetch(this._entityId),
      listsStore[VuexAdapter.getNameAction(DOC_GANTT_ITEMS)]({ doc_id: this._entityId }),
    ];

    return Promise.all(promices);
  }

  _abort() {
    const rowStore = useRowStore();
    const listsStore = useListsStore();
    const promices = [
      rowStore[VuexAdapter.getNameAbortRowAction(DOCS_GET)](),
      listsStore[VuexAdapter.getNameAbortAction(DOCS_VIEWS_ALL)](),
      listsStore[VuexAdapter.getNameAbortAction(DOC_COMMENTS_GET)](),
      listsStore[VuexAdapter.getNameAbortAction(DOCS_CLIENTS_GET)](),
      listsStore[VuexAdapter.getNameAbortAction(DOC_LINKS_GET)](),
      listsStore[VuexAdapter.getNameAbortAction(DOC_GANTT_ITEMS)](),
    ];

    return Promise.all(promices);
  }
}
