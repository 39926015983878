import Api from '@/services/api/api.js';
import axios from 'axios';
import {
  DOC_FILES_GET,
  DOC_SIGNATURES_FILES_GET,
  SYSTEM_FILE_GET,
  SYSTEM_FILE_SIGN_MULTIPLE,
} from '@/configs/end-points.js';
import FileApi from '@/services/api/file-api.js';
import { crc32 } from 'js-crc';
import { encode, toUint8Array } from 'js-base64';
import { defineStore } from 'pinia';
import StoreId from '@/common/enums/store-id.js';

export const useSignStore = defineStore(StoreId.Sign, {
  state: () => ({
    filesForSign: [],
    filesForSignAbortController: null,
  }),
  getters: {
    filesForSignHasRemarkGetter(state) {
      return state.filesForSign.some((file) => !!file['Примечание']);
    },
    filesForSignLengthGetter(state) {
      return state.filesForSign.length;
    },
    filesForSignAllLoadGetter(state) {
      return state.filesForSign.length === state.filesForSign.filter((file) => file?.base64).length;
    },
    filesForSignConfigAxiosGetter(state) {
      // FIXME: Side effect
      state.filesForSignAbortController = new AbortController();
      return state.filesForSignAbortController.signal;
    },
    filesForSignSelectedGetter(state) {
      return state.filesForSign.filter((file) => file.active);
    },
    hasFilesForSignSelectedGetter(state) {
      return state.filesForSign.filter((file) => file.active).length > 0;
    },
  },
  actions: {
    /* Получение списка фалов для подписания */
    async getFilesForSignAction(data) {
      return await Api.post(DOC_FILES_GET, data, this.filesForSignConfigAxiosGetter).then((response) => {
        const length = response.items.length;

        for (let i = 0; i < length; i++) {
          const file = response.items[i];
          file.sign = null;
          file.active = false;
          file.base64 = null;

          this.filesForSign.push(file);
          this.getFileForSignCreateHashAction(file);
        }
      });
    },
    async applyFilesForSignAction(doc_id) {
      const files = this.filesForSignSelectedGetter;

      if (!files.length) {
        return;
      }

      const items = [];
      files.forEach((file) => {
        items.push({
          f_id: file.F_ID,
          sign_content: file.base64,
          sign_type: 'CRC32',
        });
      });

      const response = await Api.post(SYSTEM_FILE_SIGN_MULTIPLE, { items }, this.filesForSignConfigAxiosGetter);

      const file_ids = response.file_ids;

      const where = `F_ID in (${file_ids.join(',')})`;

      const limit = 40;

      let offset = 40;

      let i = 0;

      let lastCount = 0;

      return new Promise((resolve, reject) => {
        const interval = setInterval(() => {
          if (offset < i) {
            clearInterval(interval);

            reject('Ошибка подписания документов');
          }

          i++;

          Api.post(
            DOC_SIGNATURES_FILES_GET,
            {
              doc_id,
              where,
            },
            this.filesForSignConfigAxiosGetter,
          ).then((response) => {
            if (lastCount !== response.count) {
              lastCount = response.count;
              offset += limit;
            }
            if (response.count === file_ids.length) {
              clearInterval(interval);
              resolve();
            }
          });
        }, 1000);
      });
    },
    async getFileForSignCreateHashAction(file) {
      const limit = 30;

      let i = 0;
      let addPreview = false;

      const getFile = () => {
        Api.post(
          SYSTEM_FILE_GET,
          {
            file_id: file.F_ID,
          },
          this.filesForSignConfigAxiosGetter,
        )
          .then((response) => {
            const data = toUint8Array(response.file_base64);
            const crc32code = crc32(data);
            const base64 = encode(crc32code);

            this.filesForSignSetHashMutations({ file_id: file['F_ID'], base64 });
          })
          .catch((e) => {
            i++;
            if (!addPreview && !axios.isCancel(e)) {
              FileApi.addView(file['F_ID']);
              addPreview = true;
            }

            if (limit < i) {
              return;
            }

            setTimeout(getFile, 2000);
          });
      };

      getFile();
    },
    clear() {
      if (this.filesForSignAbortController) {
        this.filesForSignAbortController.abort();
      }

      this.$reset();
    },
    filesForSignSetHashMutations({ file_id, base64 }) {
      this.filesForSign = this.filesForSign.map((file) => (file['F_ID'] === file_id ? { ...file, base64 } : file));
    },
    filesForSinSetActive(bool) {
      this.filesForSign = this.filesForSign.map((file) => (file.base64 ? { ...file, active: bool } : file));
    },
  },
});
