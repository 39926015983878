import UserApi from '@/services/api/user-api.js';
import { defineStore } from 'pinia';
import StoreId from '@/common/enums/store-id.js';

export const useUserStore = defineStore(StoreId.User, {
  state: () => ({
    id: null,
    name: null,
  }),
  actions: {
    async fetch() {
      this.id = await UserApi.getId();
      this.name = await UserApi.getName(this.id);
    },
  },
  persist: true,
});
