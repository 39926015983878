import Constants from '@/configs/tables/constants';
import Project from '@/configs/tables/cols/Project';
import Registrator from '@/configs/tables/cols/Registrator';
import Controller from '@/configs/tables/cols/Controller';
import DT_ID from '@/configs/tables/cols/DT_ID';
import VuexAdapter from '@/services/vuex-adapter.js';
import { CONTROL_GET } from '@/configs/end-points.js';
import { CONTROLS_TABLE_NAME } from '@/configs/tables/table';
import EyeControls from '@/components/table/columns/EyeControls.vue';
import BadgeLight from '@/components/table/badges/BadgeLight.vue';
import BadgeSecondary from '@/components/table/badges/BadgeSecondary.vue';
import BadgeSuccess from '@/components/table/badges/BadgeSuccess.vue';
import BadgeDanger from '@/components/table/badges/BadgeDanger.vue';
import BadgeInfo from '@/components/table/badges/BadgeInfo.vue';
import BadgeCoordination from '@/components/table/badges/BadgeCoordination.vue';
import DefaultFlag from '@/components/table/flags/DefaultFlag.vue';
import IndicatorFlag from '@/components/table/flags/IndicatorFlag.vue';
import ProgressFlag from '@/components/table/flags/ProgressFlag.vue';
import NoveltyFlag from '@/components/table/flags/NoveltyFlag.vue';
import Controls from '@/components/table/grouped/Controls.vue';

export default (accessToRegistrationData = false) => {
  const info = {
    TABLE_ID: {
      visible: false,
      system: true,
      type: Constants.integer,
      description: 'ID таблицы',
      classes: {},
      order: 1,
    },
    DOC_ID: {
      visible: true,
      configTitle: true,
      type: Constants.eye,
      component: EyeControls,
      classes: {
        'table-design-rpID': true,
      },
      description: 'ID документа',
      order: 0,
      conditions: 1,
    },
    DS_ID: {
      visible: true,
      searchSmallFieldDictionary: true,
      title: 'Статус',
      classes: { 'table-design-type': true },
      type: Constants.identifier,
      description: 'ID статуса документа',
      values: {
        1: {
          title: 'Черновик',
          component: BadgeLight,
        },
        2: {
          title: 'На паузе',
          component: BadgeSecondary,
        },
        3: {
          title: 'Выполняется',
          component: BadgeSuccess,
        },
        4: {
          title: 'Просрочен',
          component: BadgeDanger,
        },
        5: {
          title: 'Остановлен',
          component: BadgeInfo,
        },
        6: {
          title: 'Стандартный',
          component: BadgeCoordination,
        },
      },
      order: 2,
    },
    DT_ID: DT_ID({ order: 3 }),
    DOC_CONTROLLER_ID: {
      system: true,
      visible: false,
      type: Constants.integer,
      classes: {},
      description: 'ID контроллера документа // NULL только для ИРИС',
      order: 5,
    },
    Активен: {
      visible: true,
      title: 'Активен',
      classes: {},
      type: Constants.flag,
      component: DefaultFlag,
      description: 'Флаг: активен ли документ или нет (документ может быть неактивным, если это черновик или на паузе)',
      conditions: 1,
      order: 6,
    },
    Индикатор: {
      visible: true,
      title: 'Индикатор',
      classes: ['text-center table-design-indicator'],
      type: Constants.integer,
      component: IndicatorFlag,
      description:
        'Число отображающее давность изменений в документе, возможные значения 0..1 или -1. Чем ближе значение к 1, тем более давно происходили изменения в документе. Если поле равно -1, то это означает что в документе есть события требующие внимания контролера документа',
      conditions: 3,
      order: 7,
    },
    Тип: {
      visible: false,
      system: true,
      title: 'Тип',
      classes: {},
      type: Constants.string,
      description: 'Тип документа',
      conditions: 3,
      order: 8,
    },
    Номер: {
      visible: true,
      searchSmallField: true,
      title: 'Номер',
      type: Constants.integer,
      classes: {},
      description: 'Внутренний номер документа (равен ID документа)',
      order: 9,
    },
    Дата: {
      visible: true,
      title: 'Дата',
      classes: {},
      type: Constants.date,
      format: 'dd.LL.yyyy, HH:mm',
      description: 'Дата создания черновика документа',
      conditions: 3,
      order: 10,
    },
    Статус: {
      visible: false,
      system: true,
      title: 'Статус',
      classes: {},
      type: Constants.string,
      description: 'Статус документа',
      conditions: 3,
      order: 11,
    },
    Контролер: Controller({ order: 12 }),
    Регистратор: Registrator({ order: 13 }),
    Проект: Project({ order: 14 }),
    Содержание: {
      visible: true,
      searchSmallField: true,
      title: 'Содержание',
      classes: ['table-design-content'],
      type: Constants.string,
      maskField: 'Содержание_MASK',
      description: 'Содержание документа',
      conditions: 3,
      order: 15,
    },
    Срок: {
      visible: true,
      title: 'Срок',
      classes: {},
      type: Constants.date,
      format: 'dd.LL.yyyy',
      description: 'Общий срок документа (не путать со сроком поручения)',
      conditions: 3,
      order: 16,
    },
    Выполнение: {
      visible: true,
      title: 'Выполнение',
      classes: ['text-center table-design-process'],
      type: Constants.string,
      component: ProgressFlag,
      description: 'Процент выполнения маршрута документа, отношение выполненных точек маршрута к общему числу',
      conditions: 3,
      order: 19,
    },
    Исполнители: {
      visible: true,
      searchSmallField: true,
      title: 'Исполнители',
      classes: {},
      type: Constants.string,
      description: 'ФИО текущих исполнителей по документу через запятую, до 3 человек',
      conditions: 3,
      order: 20,
    },
    Комментарий: {
      visible: true,
      searchSmallField: true,
      title: 'Комментарий',
      classes: {},
      type: Constants.string,
      description: 'Комментарий документа',
      conditions: 3,
      order: 21,
    },
    Содержание_MASK: {
      system: true,
      visible: false,
      classes: {},
      type: Constants.string,
      description: 'Маска поля ввода "Содержание", используется для форматированного ввода текста в поле содержание',
      order: 22,
    },
    Новое: {
      visible: false,
      searchSmallFieldDictionary: true,
      title: 'Новое',
      classes: { 'text-center': true },
      component: NoveltyFlag,
      type: Constants.flag,
      description: 'Флаг новое',
      order: 23,
      conditions: 1,
    },
  };

  if (accessToRegistrationData) {
    info['Регистрация'] = {
      visible: true,
      title: 'Регистрация',
      classes: {},
      type: Constants.date,
      format: 'dd.LL.yyyy',
      description: 'Дата регистрации документа, если эта функция используется',
      conditions: 3,
      order: 17,
    };
    info['Рег.номер'] = {
      visible: true,
      searchSmallField: true,
      title: 'Рег. номер',
      classes: {},
      type: Constants.string,
      description: 'Регистрационный номер документа, если эта функция используется',
      conditions: 3,
      order: 18,
    };
  }

  return {
    name: CONTROLS_TABLE_NAME,
    title: 'Список документов на контроле',
    action: VuexAdapter.getNameAction(CONTROL_GET),
    actionLoading: VuexAdapter.getNameLoadingAction(CONTROL_GET),
    getter: VuexAdapter.getNameGetter(CONTROL_GET),
    getterCountByFilter: VuexAdapter.getNameCountGetter(CONTROL_GET),
    actionClearStore: VuexAdapter.getNameClearAction(CONTROL_GET),
    actionAbort: VuexAdapter.getNameAbortAction(CONTROL_GET),
    getterError: VuexAdapter.getNameErrorGetter(CONTROL_GET),
    generalField: 'DOC_ID',
    previewField: 'DOC_ID',
    smallGroped: Controls,
    actionColor: 'controlsColorAction',
    getterColor: 'controlsColors',
    searchSmallSelectedField: 'Содержание',
    limit: 20,
    info,
  };
};
