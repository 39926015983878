<template>
  <span>
    <span class="field-title">{{ configs_table.title }}:</span>
    <span
      v-for="selected in config"
      :key.prop="selected"
      class="field-value"
    >
      <span class="label">{{ getTextByKey(selected.select).label }}</span>
    </span>
  </span>
</template>

<script>
import FlagFilter from '@/components/table/filters/FlagFilter';

export default {
  name: 'FlagFilterBadge',
  props: ['key_column', 'config', 'configs_table'],
  data: () => ({
    IdentifierFilter: FlagFilter,
  }),
  mounted() {},
  methods: {
    getTextByKey(selected) {
      return FlagFilter.data().options.find((obg) => {
        return obg.code === selected.code;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.field-title,
.label {
  margin-right: 4px;
}
</style>
