import { defineComponent } from 'vue';
import { NotifyTypes } from '@/configs/notify-types.js';
import State from '@/common/enums/state.js';
import FileApi from '@/services/api/file-api.js';
import { DOC_FILES_GET } from '@/configs/end-points.js';
import { mapActions } from 'pinia';
import { fileSize } from '@/common/utils/utils.js';
import { usePreviewStore } from '@/stores/preview.js';

/** @deprecated Используйте composables/use-upload */
export default defineComponent({
  data() {
    return {
      isUploading: false,
    };
  },
  methods: {
    ...mapActions(usePreviewStore, ['refreshPartlyPreviewAction']),
    async uploadFiles(files, params) {
      const notUploaded = files.filter((file) => file.state !== State.Defined);
      notUploaded.forEach((file) => (file.state = State.Loading));

      if (!notUploaded.length) {
        return false;
      }

      this.isUploading = true;

      const responses = await FileApi.uploadList({
        ...params,
        files: notUploaded,
      });

      this.isUploading = false;

      let hasSuccess = false;
      let hasError = false;

      responses.forEach((response, index) => {
        const file = notUploaded[index];
        if (response.status === 'fulfilled') {
          file.state = State.Defined;
          hasSuccess = true;
        } else {
          file.state = State.Error;
          file.error = response.reason;
          this.$notify({
            type: NotifyTypes.Error,
            text: `При загрузке файла "${file.name}" (${fileSize(file.size)}) возникла ошибка.`,
            // тут бек не стандартно отвечает, но в этом случае в response будет стандартный объект ошибки
            data: response.reason,
          });
          hasError = true;
        }
      });

      if (hasSuccess) {
        void this.refreshPartlyPreviewAction(DOC_FILES_GET);
      }

      if (!hasError) {
        this.$notify({
          type: NotifyTypes.Success,
          text: 'Файлы успешно загружены',
        });
      }

      return hasError;
    },
  },
});
