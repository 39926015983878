<template>
  <div>
    <filters-header />

    <div
      v-for="condition in conditions"
      :key.prop="condition"
      class="fields"
      :hidden="!condition"
    >
      <combobox-ui
        v-model="condition.select"
        class="item"
        :options="options"
        no-clear
      />

      <combobox-ui
        v-if="showAsyncSelect(condition)"
        class="item"
        :options="optionsMultiple"
        :model-value="condition.input || []"
        no-clear
        multiple
        @update:model-value="condition.input = $event.length ? $event : null"
      />

      <filter-buttons
        class="item"
        @clear="cleanFilter"
        @apply="applyFilter"
      />
    </div>

    <order-by
      :data="data"
      :col="col"
      :key_column="key_column"
    >
      <template #variant-asc> А-Я<br />По возрастанию</template>
      <template #variant-desc> Я-А<br />По убыванию</template>
    </order-by>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import FilterMixin from '@/mixins/table/filter-mixin.js';
import { useListsStore } from '@/stores/lists.js';

export default defineComponent({
  name: 'AsyncIdentifier',
  mixins: [FilterMixin],
  data() {
    return {
      initialInput: [],
      onlySelect: ['is not null', 'is null'],
      options: [
        {
          code: 'in',
          label: 'Один из',
        },
        {
          code: 'not in',
          label: 'Ни один из',
        },
        {
          code: 'is not null',
          label: 'Заполнено',
        },
        {
          code: 'is null',
          label: 'Не заполнено',
        },
      ],
      sqlGenerator: 'identifierSql',
      listsStore: useListsStore(),
    };
  },
  computed: {
    optionsMultiple() {
      if (!this.data.info[this.key_column].optionKeyValue) {
        return this.listsStore[this.data.info[this.key_column].optionGetter];
      }

      return this.listsStore[this.data.info[this.key_column].optionGetter].map((value) => ({
        code: `'${value.label}'`,
        label: value.label,
      }));
    },
  },
  mounted() {
    if (!this.listsStore[this.data.info[this.key_column].optionGetter].length) {
      this.listsStore[this.data.info[this.key_column].optionAction]();
    }
  },
  methods: {
    showAsyncSelect(condition) {
      return condition.select !== null && !this.onlySelect.includes(condition.select.code);
    },
  },
});
</script>

<style scoped lang="scss">
.fields {
  margin-bottom: 12px;
}

.item {
  &:not(:last-child) {
    margin-bottom: 8px;
  }
}
</style>
