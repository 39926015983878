<template>
  <FieldUi
    :id="id"
    class="search-ui"
    :label="label"
    :required="required"
    :error="errorMessage"
    :hint="hint"
  >
    <DropdownUi
      ref="dropdown"
      :offset="4"
      shrink
      full-width
      close-if-inner-click
      close-if-outside-click
    >
      <template #anchor>
        <InputUi
          :id="id"
          ref="input"
          v-model.trim="input"
          :placeholder="placeholder"
          :color="color"
          :size="size"
          :disabled="disabled"
          :error="!!error"
          :no-clear="noClear"
          autocomplete="off"
          @update:model-value="fetchOptions"
          @focus="onFocus"
          @blur="onBlur"
          @clear="onClear"
          @keydown="onKeydown"
        >
          <template #postfix>
            <ButtonIconUi
              color="gray"
              @click="fetchOptions(input)"
            >
              <SearchIcon />
            </ButtonIconUi>
          </template>
        </InputUi>
      </template>

      <div class="body">
        <div
          v-if="isLoading"
          class="state"
        >
          <SpinnerUi />
        </div>

        <div
          v-else-if="!options.length"
          class="state"
        >
          Ничего не найдено
        </div>

        <DropdownListUi
          v-else
          ref="list"
          :options="options"
          :checked-options="modelValue"
          @select="onSelect"
        />
      </div>

      <CollapseButton @click="close" />
    </DropdownUi>
  </FieldUi>
</template>

<script>
import { defineComponent } from 'vue';
import SearchIcon from '@/assets/icons/search.svg';
import DropdownUi from '@/components/ui/DropdownUi.vue';
import { debounce, uniqueId } from 'lodash-es';
import FieldUi from '@/components/ui/FieldUi.vue';
import InputUi from '@/components/ui/InputUi.vue';
import ButtonIconUi from '@/components/ui/ButtonIconUi.vue';
import FieldMixin from '@/mixins/form/field-mixin.js';
import { keys, only } from '@/common/utils/props-validators';
import CollapseButton from '@/components/buttons/CollapseButton.vue';
import SpinnerUi from '@/components/ui/SpinnerUi.vue';
import DropdownListUi from '@/components/ui/DropdownListUi.vue';
import { INPUT_DEBOUNCE } from '@/common/consts/app.js';
import { NotifyTypes } from '@/configs/notify-types.js';

export default defineComponent({
  name: 'SearchUi',
  components: {
    DropdownListUi,
    SpinnerUi,
    CollapseButton,
    ButtonIconUi,
    InputUi,
    FieldUi,
    DropdownUi,
    SearchIcon,
  },
  mixins: [FieldMixin],
  props: {
    modelValue: {
      type: Object,
      default: null,
      validator: keys('label', 'code'),
    },
    color: {
      type: String,
      default: 'gray',
      validator: only('gray', 'white'),
    },
    size: {
      type: String,
      default: 'm',
      validator: only('m', 'l'),
    },
    getOptions: {
      type: Function,
      required: true,
    },
    placeholder: {
      type: String,
      default: 'Выберите значение',
    },
    noClear: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'focus', 'blur'],
  data() {
    return {
      id: uniqueId('search-ui-'),
      input: '',
      options: [],
      isLoading: false,
    };
  },
  watch: {
    modelValue(option) {
      this.input = option?.label || '';
    },
    input() {
      this.$refs.list?.setActive(null);
    },
  },
  methods: {
    onFocus() {
      this.open();
      this.$emit('focus');
    },
    onBlur() {
      this.input = this.modelValue?.label || '';
      this.$emit('blur');
    },
    onClear() {
      this.$emit('update:modelValue', null);
    },
    onKeydown(event) {
      if (event.code === 'Tab') {
        this.close();
      }
    },
    onSelect({ option }) {
      this.input = option.label;
      this.close();
      this.$emit('update:modelValue', option);
    },
    fetchOptions(query) {
      if (!query) {
        this.close();
        return;
      }

      this.isLoading = true;
      this.open();
      void this.getOptionsDebounced(query);
    },
    getOptionsDebounced: debounce(async function (query) {
      try {
        this.options = await this.getOptions(query);
      } catch (error) {
        this.$notify({
          type: NotifyTypes.Error,
          text: `При получении списка опций для поля "${this.label || this.placeholder}" возникла ошибка.`,
          data: error,
        });
      } finally {
        this.isLoading = false;
      }
    }, INPUT_DEBOUNCE),
    open() {
      if (this.isLoading || this.input) {
        this.$refs.dropdown.show();
      }
    },
    close() {
      this.$refs.dropdown.hide();
    },
    focus() {
      this.$refs.input.focus();
    },
  },
});
</script>

<style scoped lang="scss">
.body {
  overflow: hidden;

  display: flex;
  flex-direction: column;

  box-shadow: var(--shadow);
  border-radius: 8px;
}

.state {
  min-height: 48px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.collapse-button {
  flex-shrink: 0;
}
</style>
