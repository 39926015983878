<script setup>
import CLIENTS_CONFIGS from '@/configs/tables/clients';
import { onMounted } from 'vue';
import Emitter from '@/services/emitter.js';
import TableIndex from '@/components/table/TableIndex.vue';

defineProps(['componentWidth']);

onMounted(() => {
  Emitter.emit('document-preview-clear', true);
});
</script>

<template>
  <TableIndex
    :configs="CLIENTS_CONFIGS"
    :component-width="componentWidth"
  />
</template>
