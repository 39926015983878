import Api from '@/services/api/api.js';
import { CLIENTS_GET, DOCS_GET } from '@/configs/end-points.js';
import VuexAdapter from '@/services/vuex-adapter.js';
import axios from 'axios';
import { fullTrim } from '@/common/utils/utils.js';
import { defineStore } from 'pinia';
import StoreId from '@/common/enums/store-id.js';

const mixActions = {
  [DOCS_GET]: (DOC_ID) => {
    return { where: `DOC_ID = ${DOC_ID}` };
  },
  [CLIENTS_GET]: (CLIENT_ID) => {
    return { where: `CLIENT_ID = ${CLIENT_ID}` };
  },
};

const mixMutations = {
  [DOCS_GET]: (data) => {
    data = data.data.items[0] || {};
    if (data['Комментарий'] !== undefined && data['Комментарий']) {
      data['Комментарий'] = fullTrim(data['Комментарий']);
    }

    return data;
  },
};

const actions = {};
const states = {};
const getters = {};
const clearMutations = [];

const END_POINT_ROW = [DOCS_GET, CLIENTS_GET];

END_POINT_ROW.forEach((endPoint) => {
  const actionRowName = VuexAdapter.getNameRowAction(endPoint);

  const actionRowOnlyFetchName = VuexAdapter.getNameRowOnlyFetchAction(endPoint);

  const stateRowLoaderName = VuexAdapter.getLoaderRowGeneralState(endPoint);

  const mutationRowName = VuexAdapter.getNameRowMutation(endPoint);

  const actionAbortRowName = VuexAdapter.getNameAbortRowAction(endPoint);

  const stateRowName = VuexAdapter.getNameRowState(endPoint);

  const mutationClearRowName = VuexAdapter.getNameClearRowMutation(endPoint);

  const getterRowName = VuexAdapter.getNameRowGetter(endPoint);

  const getterLoaderRowName = VuexAdapter.getNameRowLoaderGeneralGetter(endPoint);

  /**
   * Actions get
   * @param data
   * @returns {Promise<unknown>}
   */
  actions[actionRowName] = function (data) {
    data = mixActions[endPoint] ? mixActions[endPoint](data) : data;

    this.abortControllersRowState[actionRowName] = new AbortController();
    this[stateRowLoaderName] = true;

    return new Promise((resolve, reject) => {
      return Api.post(endPoint, data, this.abortControllersRowState[actionRowName].signal, false)
        .then((r) => {
          this[mutationRowName](r);
          resolve(r);
        })
        .catch((e) => {
          if (!axios.isCancel(e)) {
            reject(e);
          }
        })
        .finally(() => {
          this[stateRowLoaderName] = false;
        });
    });
  };

  /**
   * Action only fetch
   * @param data
   * @returns {Promise<unknown>}
   */
  actions[actionRowOnlyFetchName] = function (data) {
    data = mixActions[endPoint] ? mixActions[endPoint](data) : data;

    return new Promise((resolve, reject) => {
      return Api.post(endPoint, data)
        .then((response) => {
          if (response.items[0]) {
            return resolve(response.items[0]);
          }
          reject('Entity not found');
        })
        .catch((e) => {
          reject(e);
        });
    });
  };

  /**
   * Actions abort
   */
  actions[actionAbortRowName] = function () {
    this[stateRowLoaderName] = false;
    if (this.abortControllersRowState[actionRowName]) {
      this.abortControllersRowState[actionRowName].abort();
    }
  };

  /**
   * action row
   * @param data
   */
  actions[mutationRowName] = function (data) {
    data = mixMutations[endPoint] ? mixMutations[endPoint](data) : data.data.items[0] || {};

    this[stateRowName] = { ...data };
  };

  /**
   * action clear
   */
  actions[mutationClearRowName] = function () {
    this[stateRowName] = {};
  };
  clearMutations.push(mutationClearRowName);

  /**
   * State
   * @type {*[]}
   */
  states[stateRowName] = [];

  /**
   * Getter row
   * @param state
   * @returns {*}
   */
  getters[getterRowName] = (state) => {
    return state[stateRowName];
  };

  /**
   * Getter loader
   * @param state
   * @returns {*}
   */
  getters[getterLoaderRowName] = (state) => {
    return state[stateRowLoaderName];
  };
});

/** @deprecated */
export const useRowStore = defineStore(StoreId.Row, {
  state: () => ({
    abortControllersRowState: {},
    ...states,
  }),
  getters: {
    ...getters,
  },
  actions: {
    ...actions,
    clear() {
      clearMutations.forEach((mutation) => this[mutation]());
      this.abortControllersRowState = {};
    },
  },
});
