import Constants from '@/configs/tables/constants';
import VuexAdapter from '@/services/vuex-adapter.js';
import { USERS_GET } from '@/configs/end-points.js';

const data = {
  visible: true,
  title: 'Регистратор',
  classes: {},
  type: Constants.asyncIdentifier,
  optionAction: VuexAdapter.getNameAction(USERS_GET),
  optionGetter: VuexAdapter.getNameSelectGetter(USERS_GET),
  description: 'Ф.И.О пользователя регистратора по документу',
  conditions: 1,
  fieldKeyValue: 'Регистратор',
  optionKeyValue: false,
};

export default function (mix = {}) {
  return Object.assign(Object.create(data), mix);
}
