<!-- FIXME: Объединить все Badge -->
<template>
  <span class="table-badge badge-warning">{{ value }}</span>
</template>

<script>
export default {
  name: 'BadgeWarning',
  props: ['value'],
};
</script>

<style scoped lang="scss">
.badge-warning {
  background-color: var(--color-yellow-600);
  color: var(--color-black);
}
</style>
