<template>
  <div>
    <div class="info-header">
      <div class="type">
        <TypeDoc
          :data="row['RPT_ID']"
          :config="configs.info['RPT_ID']"
          :config_g="configs"
          :data-row="row"
        />
      </div>

      <div class="indicator">
        <Indicator
          :config="configs.info['RPS_ID']"
          :data-id="row['RPS_ID']"
          :data-value="row['Индикатор']"
        />
      </div>

      <div
        v-if="row['Контролер'] && !isTinySMode"
        class="controller"
      >
        <span
          v-if="!isTinyMode"
          class="label _muted"
        >
          Контролер:
        </span>
        <b class="_extra">{{ row['Контролер'] }}</b>
      </div>

      <div
        v-if="row['Срок']"
        class="deadline"
      >
        <span
          v-if="!isTinyMode"
          class="label _muted"
        >
          Срок:
        </span>
        <div class="deadline-value">
          <Date
            class="date"
            :class="{ 'badge badge-danger-outline': isExpiredTerm }"
            :config="configs.info['Срок']"
            :data="row['Срок']"
          />
          <span
            v-if="row['Дней']"
            class="days-term-badge"
            :style="rowStyles"
          >
            {{ row['Дней'] }}
          </span>
        </div>
      </div>
    </div>

    <div
      v-if="row['Контролер'] && isTinySMode"
      class="controller-tiny"
    >
      <b class="_extra">{{ row['Контролер'] }}</b>
    </div>

    <div class="top-row">
      <div
        v-if="accessToRegistrationData && row['Рег.номер']"
        class="item"
      >
        <span
          v-if="!isTinyMode"
          class="label _spread _muted"
        >
          Рег.номер:
        </span>
        <span>{{ row['Рег.номер'] }}</span>
      </div>

      <div
        v-if="accessToRegistrationData && row['Регистрация']"
        class="item"
      >
        <span
          v-if="!isTinyMode"
          class="label _spread _muted"
        >
          Регистрация:
        </span>
        <Date
          :config="configs.info['Регистрация']"
          :data="row['Регистрация']"
        />
      </div>

      <div
        v-if="row['Дата']"
        class="item"
      >
        <span
          v-if="!isTinyMode"
          class="label _spread _muted"
        >
          Дата:
        </span>
        <Date
          :config="configs.info['Дата']"
          :data="row['Дата']"
        />
      </div>

      <div class="item">
        <span
          v-if="!isTinyMode"
          class="label _spread _muted"
        >
          №:
        </span>
        <span>{{ row['DOC_ID'] }}</span>
      </div>
    </div>

    <div
      v-if="showDescription"
      class="row"
    >
      <span
        v-if="!isTinyMode"
        class="_muted col-info"
      >
        Описание:
      </span>
      <span>{{ row['Описание'] }}</span>
    </div>

    <div
      v-if="row['Проект']"
      class="row"
    >
      <span
        v-if="!isTinyMode"
        class="_muted col-info"
      >
        Проект:
      </span>
      <span>{{ row['Проект'] }}</span>
    </div>

    <div
      v-if="row['Примечание']"
      class="row"
    >
      <span
        v-if="!isTinyMode"
        class="_muted col-info"
      >
        Примечание:
      </span>
      <span>{{ row['Примечание'] }}</span>
    </div>

    <div
      v-if="row['Содержание']"
      class="row _spread"
    >
      <span
        v-if="!isTinyMode"
        class="_muted col-info"
      >
        Содержание:
      </span>
      <span v-html="content" />
    </div>
  </div>
</template>

<script>
import Constants from '@/configs/constants';
import TypeDoc from '@/components/table/columns/doc/TypeDoc';
import { DateTime } from 'luxon';
import { mapActions, mapState } from 'pinia';
import { DOCUMENTS__REGISTRATION_DATA } from '@/configs/events';
import DateColumn from '@/components/table/columns/DateColumn.vue';
import IndicatorColumn from '@/components/table/columns/IndicatorColumn.vue';
import { useMaskStore } from '@/stores/mask.js';
import { useRolesStore } from '@/stores/roles.js';

export default {
  name: 'TableGroupedCommissions',
  props: ['dataTable', 'configs', 'row', 'componentWidth', 'rowStyles'],
  data: () => ({
    isTinyMode: false,
    isTinySMode: false,
  }),
  updated() {
    this.fetchMaskAction(this.row['Содержание_MASK']);
  },
  mounted() {
    this.fetchMaskAction(this.row['Содержание_MASK']);
    this.isTinyMode = this.componentWidth < Constants.resolutionPoints.tiny;
    this.isTinySMode = this.componentWidth < Constants.resolutionPoints.tinys;
  },
  computed: {
    ...mapState(useRolesStore, ['accessToEvent']),
    ...mapState(useMaskStore, ['maskStringGetter']),
    accessToRegistrationData() {
      return this.accessToEvent(DOCUMENTS__REGISTRATION_DATA);
    },
    content() {
      return this.maskStringGetter(this.row['Содержание_MASK'], this.row['Содержание']);
    },
    showDescription() {
      if (!this.row['Описание']) {
        return false;
      }
      if (!this.row['Содержание']) {
        return true;
      }
      const description = this.row['Описание'].replace('...', '').replace(/\s+/g, ' ').trim();
      const data = this.row['Содержание'].replace(/\s+/g, ' ').trim();

      return !(data.indexOf(description) > -1);
    },
    isExpiredTerm() {
      const c = DateTime.local().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
        d = DateTime.fromSQL(this.row['Срок']);
      return d.isValid && d < c;
    },
  },
  components: {
    TypeDoc,
    Date: DateColumn,
    Indicator: IndicatorColumn,
  },
  methods: {
    ...mapActions(useMaskStore, ['fetchMaskAction']),
  },
  watch: {
    componentWidth(val) {
      this.isTinyMode = val < Constants.resolutionPoints.tiny;
      this.isTinySMode = val < Constants.resolutionPoints.tinys;
    },
  },
};
</script>

<style scoped lang="scss">
@use '@/assets/styles/deprecated/colors' as *;

.type,
.indicator {
  flex-grow: 0;
  margin-right: 8px;
}

.controller {
  flex-grow: 1;
  margin-right: 8px;
  width: 50px;

  display: flex;
  align-items: center;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.label {
  margin-right: 4px;

  &._spread {
    margin-right: 8px;
  }
}

.deadline {
  flex-grow: 0;

  display: flex;
  align-items: center;
}

.deadline-value {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.date {
  &:not(:last-child) {
    margin-right: 12px;
  }
}

.badge-danger-outline {
  border: 1px solid $red-danger-color;
  background-color: transparent;
  color: $red-danger-color;
}

.controller-tiny {
  margin-top: 8px;
}

.top-row {
  margin-top: 12px;
  margin-bottom: 12px;
  margin-right: 16px;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}

.item {
  margin-right: 16px;
}

.row {
  display: flex;
  justify-content: flex-start;

  &:not(:last-child) {
    margin-bottom: 4px;
  }

  &._spread {
    margin-top: 12px;
  }
}

.badge {
  padding: 0.25em 0.4em;
  border-radius: 0.25em;
}
</style>
