<template>
  <div class="sign--table--header">
    <div class="sign--table--col--actions">
      <label
        class="checkbox-container-custom"
        for="select-sign-files-all"
      >
        <input
          id="select-sign-files-all"
          v-model="checkbox"
          type="checkbox"
          @change="checkboxChange"
        />
        <span class="checkmark" />
      </label>
    </div>
    <div class="sign--table--col--description">Описание</div>
    <div class="sign--table--col--redaction">Редакция</div>
    <div
      v-if="filesForSignHasRemarkGetter"
      class="sign--table--col--remark"
    >
      Примечание
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { useSignStore } from '@/stores/sign.js';

export default {
  name: 'HeaderTableSign',
  data: () => ({
    checkbox: false,
  }),
  methods: {
    ...mapActions(useSignStore, ['filesForSinSetActive']),
    checkboxChange() {
      this.filesForSinSetActive(this.checkbox);
    },
  },
  computed: {
    ...mapState(useSignStore, ['filesForSignHasRemarkGetter']),
  },
};
</script>
