export const AuthEndPoint = {
  Login: 'auth/org/',
  Logout: 'logout/',
  Refresh: 'auth/refresh/',
};

export const UserEndPoint = {
  GetId: 'user/id/',
  GetName: 'user/fio/',
  GetListActive: 'users/active/',
};

export const SystemEndPoint = {
  GetDeadline: 'system/get/deadline/',
  SetUnread: 'system/set/unread/',
  SetReadAll: 'system/set/read/',
  GetServerId: 'system/server/id/',
  GetAutoSingStatus: 'system/autosign/status/',
  CheckReplicationSystem: 'system/rplobjects/check/',
};

export const FileEndPoint = {
  GetUserFiles: 'system/user/files/get/',
  GetEdition: 'system/file/edition/get/',
  GetMeta: 'system/file/meta/get/',
  Add: 'system/file/add/with/content/',
  AddEdits: 'system/file/add/with/edits/',
  AddView: 'system/file/addview/',
  Move: 'doc/file/move/',
  GetEditConfig: 'onlyoffice/config/get/',
};

export const ProjectEndPoint = {
  GetList: 'projects/get/',
};

export const DocumentEndPoint = {
  GetList: 'docs/get/',
  GetOptions: 'doc/check/',
  GetFiles: 'doc/files/get/',
  GetTypes: 'doc/types/active/get/',
  GetTypeParameters: 'doc/types/mask/params/get/',
  GetTypeParameterOptions: 'doc/type/mask/param/values/get/',
  GetTypeParameterOptionHints: 'doc/type/mask/param/values/frequent/',
  Stop: 'doc/stop/',
  SetRead: 'doc/mark/view/',
  SetRouteRead: 'doc/routepoint/mark/read/',
};

export const ClientEndPoint = {
  GetList: 'clients/get/',
  GetOptions: 'client/check/',
};

export const LinkEndPoint = {
  GetLinkTypes: 'link/types/active/',
};

export const RouteEndPoint = {
  GetFileTemplates: 'routes/files/templates/',
};
