<template>
  <ModalUi
    ref="modal"
    :visible="isModalOpen"
    :width="step.width || 'm'"
    :height="step.height || '510px'"
    min-height="510px"
    prevent-closure
    @close="cancel"
  >
    <template #body>
      <Transition
        name="opacity-delayed"
        mode="out-in"
      >
        <component
          :is="component"
          v-if="step.noWrapper"
          v-model="formData"
        />

        <DocumentMasterWrapper v-else>
          <component
            :is="component"
            ref="step"
            :key="step.title"
            v-model="formData"
            :step="step"
          />
        </DocumentMasterWrapper>
      </Transition>

      <AttentionModal
        :visible="isWarningOpen"
        @update:visible="setWarningOpen"
        @agree="close"
      >
        <p>Вы собираетесь остановить процесс создания документа.</p>
        <p>Данная операция не обратима!</p>
        <p>Продолжить?</p>
      </AttentionModal>
    </template>
  </ModalUi>
</template>

<script>
import { defineComponent } from 'vue';
import ModalUi from '@/components/ui/ModalUi.vue';
import AttentionModal from '@/components/modals/AttentionModal.vue';
import DocumentMasterWrapper from '@/components/document-master/wrapper/DocumentMasterWrapper.vue';
import { mapActions, mapState } from 'pinia';
import FORM_DATA from '@/common/consts/document-master/form-value.js';
import { useMasterStore } from '@/stores/master.js';
import { DOCUMENT_MASTER_COMPONENT } from '@/common/consts/document-master/component.js';

export default defineComponent({
  name: 'CreateDocModal',
  components: {
    AttentionModal,
    DocumentMasterWrapper,
    ModalUi,
  },
  data() {
    return {
      formData: JSON.parse(JSON.stringify(FORM_DATA)), // TODO: Перенести в store
    };
  },
  computed: {
    ...mapState(useMasterStore, ['isModalOpen', 'isWarningOpen', 'isLoading', 'step']),
    component() {
      return DOCUMENT_MASTER_COMPONENT[this.step.code];
    },
  },
  methods: {
    ...mapActions(useMasterStore, { setWarningOpen: 'setWarningOpen', cancel: 'cancel', close: 'clear' }),
  },
  watch: {
    // TODO: Отказаться от использования общего лоадера
    isLoading(value) {
      if (value) {
        this.$refs.modal.showLoader();
      } else {
        this.$refs.modal.hideLoader();
      }
    },
    isModalOpen(value) {
      if (!value) {
        this.formData = JSON.parse(JSON.stringify(FORM_DATA)); // TODO: Перенести в store
      }
    },
  },
});
</script>
