import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import { COUNTERPARTIES__EDIT } from '@/configs/events';
import VuexAdapter from '@/services/vuex-adapter.js';
import { useRolesStore } from '@/stores/roles.js';
import { useListsStore } from '@/stores/lists.js';

/** @deprecated Используйте composables */
export default defineComponent({
  data() {
    return {
      listsStore: useListsStore(),
    };
  },
  computed: {
    ...mapState(useRolesStore, ['accessToEvent']),
    showAdd() {
      return this.accessToEvent(COUNTERPARTIES__EDIT);
    },
    amount() {
      return this.listsStore[VuexAdapter.getNameCountGetter(this.endPoint)] || 0;
    },
  },
  methods: {
    add() {
      this.$refs.addModal.show();
    },
  },
});
