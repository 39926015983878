import { defineComponent } from 'vue';
import { useMasterStore } from '@/stores/master.js';
import { mapActions } from 'pinia';

/** @deprecated Используйте composables */
export default defineComponent({
  props: {
    /** @deprecated Использовать store */
    modelValue: Object,
  },
  emits: ['update:modelValue'],
  data() {
    return {
      /** @deprecated Использовать store */
      formDataProxy: this.modelValue,
      isLoading: false,
    };
  },
  mounted() {
    this.setFunctions({ validate: this.validate, ready: this.ready });
  },
  methods: {
    ...mapActions(useMasterStore, ['back', 'setLoading', 'setFunctions']),
    validate() {
      return !this.isLoading && (!this.$refs.form || this.$refs.form.validate());
    },
    ready() {
      throw new Error('Функция ready не определена');
    },
    /** @deprecated Использовать store */
    change() {
      this.$emit('update:modelValue', this.formDataProxy);
    },
    /** @deprecated Не использовать общий loader */
    showLoader() {
      this.setLoading(true);
    },
    /** @deprecated Не использовать общий loader */
    hideLoader() {
      this.setLoading(false);
    },
  },
  watch: {
    modelValue: {
      handler(value) {
        this.formDataProxy = value;
      },
      deep: true,
      immediate: true,
    },
  },
});
