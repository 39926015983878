import { createRouter, createWebHistory } from 'vue-router';
import {
  COUNTERPARTIES_REGIONS_SEGMENTS__VIEW,
  DOCUMENTS__CONTROLS,
  DOCUMENTS__EDIT,
  DOCUMENTS__VIEW,
} from '@/configs/events.js';
import {
  CLIENTS_TABLE_NAME,
  COMMISSIONS_TABLE_NAME,
  CONTROLS_TABLE_NAME,
  DOCUMENTS_TABLE_NAME,
} from '@/configs/tables/table.js';
import CounterCommissions from '@/components/widgets/CounterCommissions.vue';
import CounterControls from '@/components/widgets/CounterControls.vue';
import ForbiddenPage from '@/pages/ForbiddenPage.vue';
import NotFound from '@/pages/NotFound.vue';
import DocumentNotFoundPage from '@/pages/DocumentNotFoundPage.vue';
import LoginPage from '@/pages/LoginPage.vue';
import CommissionsPage from '@/pages/CommissionsPage.vue';
import ControlsPage from '@/pages/ControlsPage.vue';
import DocumentsPage from '@/pages/DocumentsPage.vue';
import ClientsPage from '@/pages/ClientsPage.vue';
import DocumentPage from '@/pages/DocumentPage.vue';
import ClientPage from '@/pages/ClientPage.vue';
import FileEditorPage from '@/pages/FileEditorPage.vue';
import { useAuthStore } from '@/stores/auth.js';
import { usePreviewStore } from '@/stores/preview.js';
import { useRolesStore } from '@/stores/roles.js';
import { Route } from '@/common/enums/route.ts';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      redirect: '/commissions',
    },
    {
      path: '/commissions',
      name: Route.CommissionsPage,
      meta: {
        layout: 'extended-view',
        auth: true,
        informer: CounterCommissions,
        title: 'Список поручений',
        tabTitle: 'Список поручений',
        smallTitle: 'Поручения',
        table: COMMISSIONS_TABLE_NAME,
      },
      component: CommissionsPage,
    },
    {
      path: '/controls',
      name: Route.ControlsPage,
      meta: {
        layout: 'extended-view',
        auth: true,
        access: DOCUMENTS__CONTROLS,
        informer: CounterControls,
        title: 'Документы на контроле',
        tabTitle: 'Документы на контроле',
        smallTitle: 'Контроль',
        table: CONTROLS_TABLE_NAME,
      },
      component: ControlsPage,
    },
    {
      path: '/search',
      name: Route.DocumentsPage,
      meta: {
        layout: 'extended-view',
        auth: true,
        access: DOCUMENTS__VIEW,
        title: 'Поиск документов',
        tabTitle: 'Поиск документов',
        smallTitle: 'Поиск',
        table: DOCUMENTS_TABLE_NAME,
      },
      component: DocumentsPage,
    },
    {
      path: '/clients',
      name: Route.ClientsPage,
      meta: {
        layout: 'extended-view',
        auth: true,
        access: COUNTERPARTIES_REGIONS_SEGMENTS__VIEW,
        preview: 'client',
        title: 'Контрагенты',
        tabTitle: 'Контрагенты',
        smallTitle: 'Контрагенты',
        table: CLIENTS_TABLE_NAME,
      },
      component: ClientsPage,
    },
    {
      path: '/document/:id',
      name: Route.DocumentPage,
      meta: {
        layout: 'empty',
        auth: true,
        access: DOCUMENTS__VIEW,
        title: 'Документ',
        smallTitle: 'Документ',
      },
      component: DocumentPage,
    },
    {
      path: '/client/:id',
      name: Route.ClientPage,
      meta: {
        layout: 'empty',
        auth: true,
        access: COUNTERPARTIES_REGIONS_SEGMENTS__VIEW,
        title: 'Контрагент',
        smallTitle: 'Контрагент',
      },
      component: ClientPage,
    },
    {
      path: '/file-editor/:id',
      name: Route.FileEditorPage,
      meta: {
        layout: 'empty',
        auth: true,
        access: DOCUMENTS__EDIT,
        tabTitle: 'Редактирование документа',
        disableFileEditorWatcher: true,
      },
      component: FileEditorPage,
    },
    {
      path: '/login',
      name: Route.LoginPage,
      meta: {
        layout: 'empty',
        auth: false,
        title: 'Авторизация',
        tabTitle: 'Авторизация',
        smallTitle: 'Авторизация',
      },
      component: LoginPage,
    },
    {
      path: '/forbidden',
      name: Route.ForbiddenPage,
      meta: {
        layout: 'empty',
        auth: true,
        title: 'Нет доступа к разделу',
        tabTitle: 'Нет доступа к разделу',
        smallTitle: 'Нет доступа',
      },
      component: ForbiddenPage,
    },
    {
      path: '/:pathMatch(.*)*',
      name: Route.NotFoundPage,
      component: NotFound,
      meta: {
        title: 'Страница не найдена',
        tabTitle: 'Страница не найдена',
        smallTitle: 'Не найдено',
      },
    },
    {
      path: '/404',
      name: Route.DocumentNotFoundPage,
      component: DocumentNotFoundPage,
      meta: {
        title: 'Документ не найден',
        tabTitle: 'Документ не найден',
        smallTitle: 'Не найдено',
      },
    },
  ],
});

router.beforeEach((to, _, next) => {
  const previewStore = usePreviewStore();

  previewStore.setEntityIdPreviewAction(null);

  const authStore = useAuthStore();

  if (to.matched.some((record) => record.meta.auth === true)) {
    const rolesStore = useRolesStore();

    if (!authStore.isAuthorized) {
      next({
        path: '/login',
        query: {
          redirect: to.fullPath,
        },
      });
      return;
    } else if (to.meta.access !== undefined && !rolesStore.accessToEvent(to.meta.access)) {
      next({
        path: '/forbidden',
        query: {
          redirect: to.fullPath,
        },
      });
      return;
    }
  } else if (authStore.isAuthorized && to.matched.some((record) => record.meta.auth === false)) {
    next({
      path: '/',
      query: {
        redirect: to.fullPath,
      },
    });
    return;
  }

  next();
});

export default router;
