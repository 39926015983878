import { TYPE_DATE, TYPE_INTEGER, TYPE_PROMPT, TYPE_SELECT, TYPE_STRING } from '@/configs/form';
import ParameterType from '@/common/enums/parameter-type.js';
import { Validator } from '@/common/utils/validator';
import DocumentMasterApi from '@/services/api/document-master-api.js';

export class Parameter {
  constructor(response) {
    this.type = this._getType(response['Тип']);
    this.title = response['Название'];
    this.number = response['Номер'];
    this.maxLength = response['Длина'];
    this.isList = response['Справочник'] === 1;
    this.isRequired = response['Обязательный'] === 1;
  }

  getFormField(documentTypeId) {
    return {
      name: `parameters.${this.number}`,
      required: this.isRequired,
      placeholder: this.title,
      ...this._getFormFieldUniq(documentTypeId),
    };
  }

  _getFormFieldUniq(documentTypeId) {
    switch (this.type) {
      case ParameterType.Date:
        return {
          type: TYPE_DATE,
        };
      case ParameterType.Integer:
        return {
          type: TYPE_INTEGER,
          length: this.maxLength,
        };
      case ParameterType.Time:
        return {
          type: TYPE_STRING,
          mask: '##:##',
          validator: Validator.time,
        };
      case ParameterType.String:
        if (this.isList) {
          return {
            type: TYPE_SELECT,
            noClear: this.isRequired,
            getOptions: (signal) => DocumentMasterApi.getParameterOptions(documentTypeId, this.number, signal),
          };
        } else {
          return {
            type: TYPE_PROMPT,
            field: this.maxLength > 25 ? 'textarea' : 'input',
            maxLength: this.maxLength,
            resize: false,
            getHints: (query, signal) =>
              DocumentMasterApi.getHints(
                {
                  id: documentTypeId,
                  number: this.number,
                  query,
                },
                signal,
              ),
          };
        }
    }
  }

  _getType(type) {
    switch (type) {
      case 'Строка':
        return ParameterType.String;
      case 'Целочисленное число':
        return ParameterType.Integer;
      case 'Время':
        return ParameterType.Time;
      case 'Дата':
        return ParameterType.Date;
      default:
        throw new Error('Неожиданный тип параметра');
    }
  }
}
