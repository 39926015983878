<template>
  <span>{{ data }}</span>
</template>

<script>
export default {
  name: 'IntegerColumn',
  props: ['data'],
};
</script>
