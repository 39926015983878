<template>
  <div>
    <filters-header />

    <div
      v-for="condition in conditions"
      :key.prop="condition"
      class="fields"
      :hidden="!condition"
    >
      <combobox-ui
        v-model="condition.select"
        :options="options"
        no-clear
      />
    </div>

    <filter-buttons
      @clear="cleanFilter"
      @apply="applyFilter"
    />

    <order-by
      :data="data"
      :col="col"
      :key_column="key_column"
    >
      <template #variant-asc> {{ col.title }} снизу</template>
      <template #variant-desc> {{ col.title }} сверху</template>
    </order-by>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import FilterMixin from '@/mixins/table/filter-mixin.js';

export default defineComponent({
  name: 'FlagFilter',
  mixins: [FilterMixin],
  data: () => ({
    options: [
      {
        label: 'Да',
        code: '= 1',
      },
      {
        label: 'Нет',
        code: '= 0',
      },
      {
        label: 'Заполнено',
        code: 'is not null',
      },
      {
        label: 'Не заполнено',
        code: 'is null',
      },
    ],
    sqlGenerator: 'flagSQL',
  }),
});
</script>

<style scoped lang="scss">
.fields {
  margin-bottom: 12px;
}
</style>
