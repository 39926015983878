import { defineComponent } from 'vue';

/** @deprecated Используйте composables */
export default defineComponent({
  props: {
    label: String,
    hint: String,
    error: {
      type: [String, Boolean],
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    errorMessage() {
      return typeof this.error === 'string' ? this.error : '';
    },
  },
});
