<template>
  <div class="comment-preview-row">
    <div>
      <span class="comment-preview-row--user">{{ row['Пользователь'] }}</span>
      <span class="comment-preview-row--date">{{ getDate(row['Дата время'], 'dd.MM.yyyy, HH:mm') }}</span>
    </div>
    <div class="formatter-comment">
      {{ row['Комментарий'] }}
    </div>
  </div>
</template>

<script>
import { getDate } from '@/common/utils/utils.js';

export default {
  name: 'CommentRow',
  props: ['row'],
  methods: {
    getDate,
  },
};
</script>

<style scoped lang="scss">
.formatter-comment {
  white-space: pre-wrap !important;
}
</style>
