<template>
  <span>
    <span class="field-title">{{ configs_table.title }}:</span>
    <span
      v-for="selected in config"
      :key.prop="selected"
      class="field-value"
    >
      <span class="label">{{ getTextByKey(selected.select).label }}</span>
      <span
        v-if="selected.input !== null"
        class="value"
      >
        {{ getValues(selected) }}
      </span>
    </span>
  </span>
</template>

<script>
import IdentifierFilter from '@/components/table/filters/IdentifierFilter';

export default {
  name: 'AsyncIdentifier',
  props: ['key_column', 'config', 'configs_table'],
  data: () => ({
    IdentifierFilter: IdentifierFilter,
  }),
  methods: {
    getTextByKey(selected) {
      if (selected.code === 'LIKE') {
        return selected;
      }
      return IdentifierFilter.data().options.find((obg) => {
        return obg.code === selected.code;
      });
    },
    getValues(selected) {
      if (selected.select.code === 'LIKE') {
        return selected.input;
      }
      if (selected.input !== undefined) {
        const res = [];
        selected.input.forEach((option) => {
          res.push(option.label);
        });
        return '(' + res.join(', ') + ')';
      }
    },
  },
};
</script>

<style scoped lang="scss">
.field-title,
.label,
.value {
  margin-right: 4px;
}
</style>
