<script setup>
import { computed, markRaw } from 'vue';
import DropdownButtonUi from '@/components/ui/DropdownButtonUi.vue';
import EditIcon from '@/assets/icons/edit.svg';
import ReviewIcon from '@/assets/icons/review.svg';
import { keys } from '@/common/utils/props-validators';
import { useSystemStore } from '@/stores/system.js';

const props = defineProps({
  compact: {
    type: Boolean,
    default: false,
  },
  permissions: {
    type: Object,
    required: true,
    validator: keys('edit', 'review'),
  },
});

const emit = defineEmits(['edit', 'review']);

const systemStore = useSystemStore();

const options = computed(() =>
  [
    props.permissions.edit && {
      code: 'edit',
      title: 'Редактировать',
      icon: markRaw(EditIcon),
      onClick: () => emit('edit'),
    },
    props.permissions.review && {
      code: 'review',
      title: 'Рецензировать',
      icon: markRaw(ReviewIcon),
      onClick: () => emit('review'),
    },
  ].filter(Boolean),
);

const selected = computed({
  get() {
    return systemStore.fileWorkingType;
  },
  set(value) {
    systemStore.setFileWorkingType(value);
  },
});
</script>

<template>
  <DropdownButtonUi
    v-model="selected"
    :compact="compact"
    :options="options"
  />
</template>
