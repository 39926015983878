import { INITIAL_TYPE, TypeCode } from '@/common/consts/document-master/common.js';
import { defineStore } from 'pinia';
import { useMasterParametersStore } from '@/stores/master-parameters.js';
import { useMasterLinksStore } from '@/stores/master-links.js';
import { useMasterClientsStore } from '@/stores/master-clients.js';
import StoreId from '@/common/enums/store-id.js';

export const useMasterTypeStore = defineStore(StoreId.MasterType, {
  state: () => ({
    type: INITIAL_TYPE,
    project: null,
  }),
  getters: {
    isDirty(state) {
      return state.type?.code !== TypeCode.Document || state.project !== null;
    },
    contentTitle(state) {
      return state.type.code === TypeCode.Document ? 'Создание документа' : `Создание "${state.type.label}"`;
    },
    showWarning(state) {
      const defaultProjectId = state.type.defaultProjectId;
      const projectId = state.project?.code;
      return defaultProjectId && defaultProjectId >= 0 && projectId && projectId !== defaultProjectId;
    },
    linkTypesIds(state) {
      return state.type.linkTypesIds || [];
    },
  },
  actions: {
    setType(type, force = false) {
      if (!force && this.type.code === type.code) {
        return;
      }

      this.type = type;

      const masterParametersStore = useMasterParametersStore();
      masterParametersStore.$reset();

      const masterLinksStore = useMasterLinksStore();
      masterLinksStore.clearLinkTypes();

      const masterClientsStore = useMasterClientsStore();
      masterClientsStore.clearLinkTypes();
    },
    setProject(project) {
      if (this.project?.code !== project?.code) {
        this.project = project;
      }
    },
  },
});
