<template>
  <div class="document-page-container">
    <div
      v-if="showClient"
      class="document-page"
    >
      <ClientPreviewComponent :component-width="componentWidth" />
    </div>
    <LoaderUi v-else />
  </div>
</template>

<script>
import ClientPreviewComponent from '@/components/clients/ClientPreview.vue';
import { ClientPreview } from '@/common/models/preview/client-preview.js';
import { mapActions, mapState } from 'pinia';
import VuexAdapter from '@/services/vuex-adapter.js';
import { CLIENTS_GET } from '@/configs/end-points.js';
import { getDate } from '@/common/utils/utils.js';
import LoaderUi from '@/components/ui/LoaderUi.vue';
import { usePreviewStore } from '@/stores/preview.js';
import { useRowStore } from '@/stores/row.js';
import { useTitle } from '@vueuse/core';

export default {
  name: 'ClientPage',
  components: {
    LoaderUi,
    ClientPreviewComponent,
  },
  props: ['componentWidth'],
  setup() {
    const title = useTitle();
    return { title };
  },
  data: () => ({
    error_message: false,
  }),
  mounted() {
    this.initData();
  },
  unmounted() {
    this.clearClientAction();
  },
  computed: {
    ...mapState(usePreviewStore, {
      hasEntityIdPreview: 'hasEntityIdPreviewGetter',
    }),
    ...mapState(useRowStore, {
      clientGetter: VuexAdapter.getNameRowGetter(CLIENTS_GET),
      clientLoaderStatusGetter: VuexAdapter.getNameRowLoaderGeneralGetter(CLIENTS_GET),
    }),
    clientId() {
      return Number(this.$route.params.id);
    },
    showClient() {
      return !this.clientNotFound && this.clientGetter.CLIENT_ID === this.clientId && this.hasEntityIdPreview;
    },
    validClientId() {
      return this.clientId && this.clientId > 0;
    },
    clientNotFound() {
      return !this.validClientId || (!this.clientLoaderStatusGetter && !this.clientGetter?.CLIENT_ID);
    },
    formattedTitle() {
      if (this.validClientId && this.showClient) {
        return `${this.clientGetter['Название']} - АЛЬФА ДОК`;
      }
      return 'АЛЬФА ДОК - Автоматизация бизнес-процессов';
    },
  },
  methods: {
    ...mapActions(usePreviewStore, {
      initPreview: 'initPreviewAction',
      setEntityIdPreview: 'setEntityIdPreviewAction',
    }),
    ...mapActions(useRowStore, {
      clearClientAction: VuexAdapter.getNameClearRowMutation(CLIENTS_GET),
    }),
    to404() {
      this.$router
        .push({ path: '/404', query: { redirectFrom: this.$route.fullPath } })
        .catch((e) => (this.error_message = e.error_message));
    },
    initData() {
      if (!this.validClientId) {
        this.to404();
      }
      this.setEntityIdPreview(this.clientId);
      this.initPreview(ClientPreview.intefaceIdent)
        .then(() => {})
        .catch((e) => {
          console.error('eee' + e);
          this.setEntityIdPreview(null);
          this.to404();
        });
    },
    getDate,
  },
  watch: {
    clientId() {
      this.clearClientAction();
      this.initData();
    },
    clientNotFound(val) {
      if (val) {
        this.to404();
      }
    },
    formattedTitle: {
      handler(tabTitle) {
        this.title = tabTitle;
      },
      immediate: true,
    },
  },
};
</script>
