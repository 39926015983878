<template>
  <SearchUi
    ref="search"
    :model-value="modelValue"
    :label="label"
    :placeholder="placeholder"
    :hint="!error ? hint : undefined"
    :error="error"
    :disabled="disabled"
    :get-options="getOptions"
    @update:model-value="$emit('update:modelValue', $event)"
  />
</template>

<script>
import { defineComponent } from 'vue';
import SearchUi from '@/components/ui/SearchUi.vue';
import Api from '@/services/api/api.js';
import FieldMixin from '@/mixins/form/field-mixin.js';

/**
 * @deprecated Используйте SearchUi напрямую
 */
export default defineComponent({
  name: 'SearchLegacyUi',
  components: { SearchUi },
  mixins: [FieldMixin],
  props: {
    modelValue: {
      type: Object,
      default: null,
    },
    endPoint: {
      type: String,
      required: true,
    },
    keyField: {
      type: String,
      required: true,
    },
    valueField: {
      type: String,
      required: true,
    },
    disabledFormatter: {
      type: Function,
    },
    placeholder: String,
    queryFormatter: {
      type: Function,
      default: (query) => ({ query }),
    },
    labelFormatter: {
      type: Function,
      default: (item, valueField) => item[valueField],
    },
  },
  emits: ['update:modelValue'],
  methods: {
    async getOptions(query) {
      const response = await Api.post(this.endPoint, this.queryFormatter(query));
      return response.items.map((item) => ({
        code: item[this.keyField],
        label: this.labelFormatter(item, this.valueField),
        disabled: !!this.disabledFormatter?.(item),
      }));
    },
    focus() {
      this.$refs.search.focus();
    },
  },
});
</script>
