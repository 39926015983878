<template>
  <div class="checkbox-form">
    <div class="checkbox-row">
      <label
        class="checkbox-form-element"
        :class="classesAdditional"
      >
        <input
          v-model="proxyValue"
          type="checkbox"
          :disabled="disabled"
          @change="change"
        />
        <span class="checkmark" />
        <span
          v-if="label"
          class="checkbox-label"
          v-html="label"
        />
      </label>
    </div>
    <div
      v-if="hint || error"
      class="form--input-caption"
      :class="error ? 'form--input-caption__error' : ''"
    >
      {{ error ? error : hint }}
    </div>
  </div>
</template>

<script>
import { isObject } from '@/common/utils/utils.js';

/** @deprecated Используйте ui-компоненты */
export default {
  name: 'CheckboxForm',
  props: {
    /**
     * Значение поля
     * @type {any}
     */
    modelValue: {},
    /**
     * Текст для <label>
     * @type {string}
     */
    label: {
      type: String,
      default: '',
    },
    /**
     * Аттрибут [disabled]
     * @type {boolean}
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Сообщение об ошибке.
     */
    error: {
      type: String,
      default: '',
    },
    /**
     * Подсказка.
     */
    hint: {
      type: String,
      default: '',
    },
    /**
     * Подсказка.
     */
    classes: {},
  },
  emits: ['update:modelValue'],
  data: () => ({
    proxyValue: [],
  }),
  computed: {
    classesAdditional() {
      let classes = {
        error: this.error,
        disabled: this.disabled,
      };

      if (this.classes && Array.isArray(this.classes)) {
        this.classes.forEach((c) => (classes[c] = true));
      } else if (this.classes && isObject(this.classes)) {
        classes = {
          ...classes,
          ...this.classes,
        };
      }

      return classes;
    },
  },
  watch: {
    modelValue(modelValue) {
      if (modelValue) {
        this.proxyValue = true;

        return;
      }

      this.proxyValue = false;
    },
  },
  mounted() {
    this.proxyValue = !!this.modelValue;
  },
  methods: {
    change() {
      this.$emit('update:modelValue', this.proxyValue || false);
    },
  },
};
</script>
