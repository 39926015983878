import Api from '@/services/api/api.js';
import {
  ADDRESS_ADD,
  ADDRESS_DEL,
  ADDRESS_EDIT,
  CLIENTS_BANK_DETAILS_ADD,
  CLIENTS_BANK_DETAILS_DEL,
  CLIENTS_BANK_DETAILS_EDIT,
  CONTACT_ADD,
  CONTACT_DEL,
  CONTACT_EDIT,
  DOC_ACTIVATION_SET,
  DOC_ADD,
  DOC_CONTROLLER_SET,
  DOC_DELETE,
  DOC_LINK_ADD,
  DOC_MARK_VIEW,
  DOC_ROUTE_ADD,
  DOC_ROUTE_POINT_ACCEPT,
  DOC_ROUTE_POINT_ADD,
  DOC_ROUTE_POINT_DECLINE,
  DOC_ROUTE_POINT_MARK_CONFIRM,
  DOC_ROUTE_POINT_MARK_FINISH,
  DOC_ROUTE_POINT_MARK_VIEW,
  DOC_STARTDATE_SET,
  ROUTES_RESPONSIBLES_UNSPECIFIED,
  ROUTES_STANDARD,
  SYSTEM_COMMENT_ADD,
  SYSTEM_GET_DEADLINE,
  SYSTEM_USER_ID,
} from '@/configs/end-points.js';
import VuexAdapter from '@/services/vuex-adapter.js';
import { defineStore } from 'pinia';
import StoreId from '@/common/enums/store-id.js';

const ENDPOINTS = [
  DOC_ROUTE_ADD,
  DOC_ROUTE_POINT_ADD,
  DOC_ROUTE_POINT_MARK_CONFIRM,
  DOC_ADD,
  DOC_DELETE,
  DOC_ACTIVATION_SET,
  DOC_ROUTE_POINT_MARK_VIEW,
  DOC_ROUTE_POINT_ACCEPT,
  SYSTEM_COMMENT_ADD,
  ADDRESS_ADD,
  ADDRESS_EDIT,
  ADDRESS_DEL,
  CLIENTS_BANK_DETAILS_ADD,
  CLIENTS_BANK_DETAILS_EDIT,
  CLIENTS_BANK_DETAILS_DEL,
  DOC_ROUTE_POINT_MARK_FINISH,
  DOC_ROUTE_POINT_DECLINE,
  CONTACT_ADD,
  CONTACT_EDIT,
  CONTACT_DEL,
  DOC_MARK_VIEW,
  ROUTES_STANDARD,
  DOC_LINK_ADD,
  SYSTEM_GET_DEADLINE,
  DOC_STARTDATE_SET,
  DOC_CONTROLLER_SET,
  SYSTEM_USER_ID,
  ROUTES_RESPONSIBLES_UNSPECIFIED,
];

/** @deprecated Используйте сервисы */
export const useActionsStore = defineStore(StoreId.Actions, {
  actions: ENDPOINTS.reduce((acc, endPoint) => {
    const actionName = VuexAdapter.getNameAction(endPoint);

    return {
      ...acc,
      [actionName]: (request) => Api.post(endPoint, request, undefined, false),
    };
  }, {}),
});
