<script setup>
import { computed, defineAsyncComponent, ref } from 'vue';
import { DOC_FILES_GET } from '@/configs/end-points.js';
import { NotifyTypes } from '@/configs/notify-types.js';
import FileApi from '@/services/api/file-api.js';
import DocumentFile from '@/common/models/file/document-file';
import ModalUi from '@/components/ui/ModalUi.vue';
import LoaderUi from '@/components/ui/LoaderUi.vue';
import State from '@/common/enums/state.js';
import FileChangesConfirmModal from '@/components/modals/File/FileChangesConfirmModal.vue';
import useAbort from '@/composables/use-abort.js';
import StorageApi from '@/services/api/storage-api.js';
import { usePreviewStore } from '@/stores/preview.js';
import { notify } from '@kyvg/vue3-notification';

const AdAnnotation = defineAsyncComponent({
  loader: () => import('@alphadoc/alphadoc-annotation'),
  loadingComponent: LoaderUi,
});

const props = defineProps({
  file: {
    type: DocumentFile,
    required: true,
  },
  pages: {
    type: Array,
    required: true,
  },
  state: {
    type: String,
    required: true,
  },
  initial: {
    type: Number,
    required: true,
  },
});

defineEmits(['fetch', 'retry']);

const previewStore = usePreviewStore();

const { signal } = useAbort();

const annotationModal = ref();
const confirmModal = ref();
const adAnnotation = ref();

const isDirty = ref(false);
const isLoading = ref(false);

const state = computed(() => (isLoading.value ? State.Loading : props.state));
const loaderText = computed(() => (isLoading.value ? 'Отправка данных' : 'Загрузка данных'));

function show() {
  annotationModal.value.show();
}

function hide() {
  annotationModal.value.hide();
  isDirty.value = false;
  isLoading.value = false;
}

function download() {
  StorageApi.download(
    {
      url: props.file.url,
      name: props.file.downloadName,
      fileId: props.file.fileId,
    },
    signal.value,
  );
}

function onChange(value) {
  isDirty.value = value;
}

async function onApply(annotations) {
  isLoading.value = true;

  try {
    await FileApi.review(props.file, props.pages, annotations);
    void previewStore.refreshPartlyPreviewAction(DOC_FILES_GET);
    notify({
      type: NotifyTypes.Success,
      text: 'Рецензия успешно добавлена',
    });
    hide();
  } catch (error) {
    notify({
      type: NotifyTypes.Error,
      text: 'При рецензировании документа возникла ошибка.',
      data: error,
    });
  } finally {
    isLoading.value = false;
  }
}

function onClose() {
  if (isLoading.value) {
    return;
  }

  if (isDirty.value) {
    confirmModal.value.show();
  } else {
    hide();
  }
}

function onConfirmModalClose() {
  confirmModal.value.hide();
}

function onConfirmModalDiscard() {
  confirmModal.value.hide();
  hide();
}

function onConfirmModalApply() {
  confirmModal.value.hide();
  const annotations = adAnnotation.value.getAnnotations();
  onApply(annotations);
}

defineExpose({ show });
</script>

<template>
  <div hidden>
    <ModalUi
      ref="annotationModal"
      width="l"
      height="100%"
      prevent-closure
      @close="onClose"
    >
      <template #default>
        <AdAnnotation
          ref="adAnnotation"
          :file="file"
          :pages="pages"
          :initial="initial"
          :state="state"
          :loader-text="loaderText"
          @fetch="$emit('fetch', $event)"
          @retry="$emit('retry', $event)"
          @download="download"
          @change="onChange"
          @apply="onApply"
          @close="onClose"
        />
      </template>
    </ModalUi>

    <FileChangesConfirmModal
      ref="confirmModal"
      :file-name="file.name"
      @save="onConfirmModalApply"
      @discard="onConfirmModalDiscard"
      @cancel="onConfirmModalClose"
    />
  </div>
</template>

<style scoped lang="scss">
.annotation {
  height: 100%;
}
</style>
