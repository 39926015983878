<template>
  <ComboboxUi
    ref="combobox"
    v-model="template"
    placeholder="Шаблон"
    :options="templates"
    no-clear
  />
</template>

<script>
import { mapActions, mapState } from 'pinia';
import ComboboxUi from '@/components/ui/ComboboxUi.vue';
import { useAutocorrectStore } from '@/stores/autocorrect.js';

export default {
  name: 'SolutionAutocorrectReplace',
  components: { ComboboxUi },
  data() {
    return {
      template: null,
    };
  },
  mounted() {
    this.setAutocorrectReplacesSelectAction({ limit: 50 }).then(() => {
      this.autocorrectReplaces.forEach((fav_id) => {
        if (this.autocorrectReplaceObjListGetter[fav_id] === undefined) {
          this.delReplaceAutocorrect(+fav_id);
        }
      });
    });
  },
  computed: {
    ...mapState(useAutocorrectStore, [
      'autocorrectReplaces',
      'autocorrectReplaceSelectGetter',
      'autocorrectReplaceObjListGetter',
    ]),
    templates() {
      return this.autocorrectReplaceSelectGetter;
    },
  },
  watch: {
    template(template) {
      if (template) {
        this.addReplaceAutocorrect(+template.code);
        this.$emit('add-solution-autocorrect', template.label);
        this.$refs.combobox.clear();
      }
    },
  },
  methods: {
    ...mapActions(useAutocorrectStore, [
      'setAutocorrectReplacesSelectAction',
      'delReplaceAutocorrect',
      'addReplaceAutocorrect',
    ]),
  },
};
</script>
