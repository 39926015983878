export default {
  direction: 'ltr',
  format: 'dd.mm.yyyy',
  separator: ' - ',
  applyLabel: 'Сохранить',
  Today: 'Сохранить',
  cancelLabel: 'Отмена',
  weekLabel: 'W',
  daysOfWeek: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
  monthNames: [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
  ],
  firstDay: 1,
};
