<template>
  <PageCounters
    :count-all="commissionsCountsAll"
    :count-new="commissionsCountsNew"
    :count-expired="commissionsCountsExpired"
    @new-click="filterNew"
    @expired-click="filterExpired"
  />
</template>

<script>
import { mapActions, mapState } from 'pinia';
import DbAdapterMixin from '@/mixins/db-adapter-mixin.js';
import ConstantsRPS from '@/configs/route-point-statuses.js';
import VuexAdapter from '@/services/vuex-adapter.js';
import { COMMISSIONS_TABLE_NAME } from '@/configs/tables/table';
import Emitter from '@/services/emitter.js';
import { useCommissionsStore } from '@/stores/commissions.js';
import { useTableStore } from '@/stores/table.js';
import PageCounters from '@/components/common/PageCounters.vue';

export default {
  name: 'WidgetsCounterCommissions',
  components: {
    PageCounters,
  },
  mixins: [DbAdapterMixin],
  data: () => ({
    lastCountIdsInit: false,
  }),
  computed: {
    ...mapState(useCommissionsStore, ['commissionsCount']),
    commissionsCountsAll() {
      return this.commissionsCount.count ?? 0;
    },
    commissionsCountsNew() {
      return this.commissionsCount.count_new ?? 0;
    },
    commissionsCountsExpired() {
      return this.commissionsCount.count_expired ?? 0;
    },
    commissionsCountsIds() {
      return this.commissionsCount.ids ?? '';
    },
  },
  watch: {
    commissionsCountsIds(value, oldValue) {
      if (oldValue == '') {
        return;
      }
      Emitter.emit('table-trigger-refresh-only-state');
    },
  },
  mounted() {
    this.counterReloadCommissionsEventAction = () => {
      this.getCommissionsCountAction();
    };

    Emitter.on('counter-reload-commissions', this.counterReloadCommissionsEventAction);
  },
  unmounted() {
    Emitter.off('counter-reload-commissions', this.counterReloadCommissionsEventAction);
  },
  methods: {
    ...mapActions(useCommissionsStore, ['getCommissionsCountAction']),
    ...mapActions(useTableStore, {
      tableSetFilterAndDeleteOvers: VuexAdapter.filterAndDeleteTableNameAction(COMMISSIONS_TABLE_NAME),
    }),
    async filterNew() {
      const key = 'Новое';
      const value = '= 1';
      const whereSQL = this.dbAdapter[this.flagSqlSearch](this, key, value);

      this.tableSetFilterAndDeleteOvers({
        key,
        whereSQL,
        whereData: [
          {
            algorithm: null,
            select: {
              code: value,
              label: 'Да',
            },
            input: null,
          },
        ],
      });
    },
    async filterExpired() {
      const expired = ConstantsRPS.rp_status_type_expired_id;
      const key = 'RPS_ID';
      const values = [expired];
      const whereSQL = this.dbAdapter[this.identifierSqlSearch](this, key, values);

      this.tableSetFilterAndDeleteOvers({
        key,
        whereSQL,
        whereData: [
          {
            algorithm: null,
            select: {
              code: 'in',
              label: 'Один из',
            },
            input: [
              {
                code: expired,
                label: 'Просрочен',
              },
            ],
          },
        ],
      });
    },
  },
};
</script>

<style scoped lang="scss">
.page-counters {
  margin-left: 16px;

  &:not(:last-child) {
    margin-right: 12px;
  }
}
</style>
