<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.83913 0.666992L9.33333 0.666993C9.51015 0.666993 9.67971 0.737231 9.80474 0.862255L13.8047 4.86225C13.9298 4.98728 14 5.15685 14 5.33366V8.40033C14 8.76852 13.7015 9.06699 13.3333 9.06699C12.9651 9.06699 12.6667 8.76852 12.6667 8.40033V5.6098L9.05719 2.00033H5.86667C5.29561 2.00033 4.90742 2.00084 4.60736 2.02536C4.31508 2.04924 4.16561 2.09252 4.06135 2.14565C3.81046 2.27348 3.60649 2.47746 3.47866 2.72834C3.42553 2.83261 3.38225 2.98207 3.35837 3.27435C3.33385 3.57441 3.33333 3.96261 3.33333 4.53366V8.00033C3.33333 8.36852 3.03486 8.66699 2.66667 8.66699C2.29848 8.66699 2 8.36852 2 8.00033L2 4.50613C1.99999 3.96948 1.99998 3.52657 2.02946 3.16578C2.06008 2.79104 2.12579 2.44657 2.29065 2.12302C2.54631 1.62125 2.95426 1.2133 3.45603 0.957642C3.77958 0.792782 4.12405 0.727072 4.49878 0.696455C4.85958 0.666977 5.30249 0.666984 5.83913 0.666992Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.33366 0.84668C9.70185 0.84668 10.0003 1.14516 10.0003 1.51335V4.26704C10.0003 4.46473 10.0008 4.57289 10.0072 4.65062C10.0075 4.65371 10.0077 4.65664 10.008 4.65941C10.0107 4.65966 10.0137 4.65992 10.0168 4.66017C10.0945 4.66652 10.2026 4.66704 10.4003 4.66704H13.154C13.5222 4.66704 13.8207 4.96552 13.8207 5.33371C13.8207 5.7019 13.5222 6.00037 13.154 6.00037H10.4003C10.3932 6.00037 10.3861 6.00037 10.3791 6.00037C10.2108 6.00039 10.0469 6.00041 9.90818 5.98908C9.75578 5.97662 9.57596 5.94725 9.39501 5.85505C9.14412 5.72722 8.94015 5.52324 8.81232 5.27236C8.72012 5.09141 8.69074 4.91159 8.67829 4.75919C8.66695 4.62042 8.66697 4.45656 8.66699 4.28831C8.66699 4.28122 8.667 4.27414 8.667 4.26704V1.51335C8.667 1.14516 8.96547 0.84668 9.33366 0.84668Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.66667 7.33301C3.03486 7.33301 3.33333 7.63148 3.33333 7.99967V11.4663C3.33333 12.0374 3.33385 12.4256 3.35837 12.7256C3.38225 13.0179 3.42553 13.1674 3.47866 13.2717C3.60649 13.5225 3.81046 13.7265 4.06135 13.8543C4.16561 13.9075 4.31508 13.9508 4.60736 13.9746C4.90742 13.9992 5.29561 13.9997 5.86667 13.9997H10.1333C10.7044 13.9997 11.0926 13.9992 11.3926 13.9746C11.6849 13.9508 11.8344 13.9075 11.9387 13.8543C12.1895 13.7265 12.3935 13.5225 12.5213 13.2717C12.5745 13.1674 12.6178 13.0179 12.6416 12.7256C12.6661 12.4256 12.6667 12.0374 12.6667 11.4663V8.39967C12.6667 8.03148 12.9651 7.73301 13.3333 7.73301C13.7015 7.73301 14 8.03148 14 8.39967V11.4939C14 12.0305 14 12.4734 13.9705 12.8342C13.9399 13.209 13.8742 13.5534 13.7094 13.877C13.4537 14.3787 13.0457 14.7867 12.544 15.0424C12.2204 15.2072 11.8759 15.2729 11.5012 15.3035C11.1404 15.333 10.6975 15.333 10.1609 15.333H5.83915C5.3025 15.333 4.85958 15.333 4.49878 15.3035C4.12405 15.2729 3.77958 15.2072 3.45603 15.0424C2.95426 14.7867 2.54631 14.3787 2.29065 13.877C2.12579 13.5534 2.06008 13.209 2.02946 12.8342C1.99998 12.4734 1.99999 12.0305 2 11.4939L2 7.99967C2 7.63148 2.29848 7.33301 2.66667 7.33301Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.66699 5.99967C4.66699 5.63148 4.96547 5.33301 5.33366 5.33301H6.66699C7.03518 5.33301 7.33366 5.63148 7.33366 5.99967C7.33366 6.36786 7.03518 6.66634 6.66699 6.66634H5.33366C4.96547 6.66634 4.66699 6.36786 4.66699 5.99967ZM4.66699 8.66634C4.66699 8.29815 4.96547 7.99967 5.33366 7.99967H10.667C11.0352 7.99967 11.3337 8.29815 11.3337 8.66634C11.3337 9.03453 11.0352 9.33301 10.667 9.33301H5.33366C4.96547 9.33301 4.66699 9.03453 4.66699 8.66634ZM4.66699 11.333C4.66699 10.9648 4.96547 10.6663 5.33366 10.6663H10.667C11.0352 10.6663 11.3337 10.9648 11.3337 11.333C11.3337 11.7012 11.0352 11.9997 10.667 11.9997H5.33366C4.96547 11.9997 4.66699 11.7012 4.66699 11.333Z"
    />
  </svg>
</template>

<script>
/** @deprecated */
export default {
  name: 'DocumentTwoIcon',
};
</script>
