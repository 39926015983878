const setTransform = (element, isRotated, from = 0, to = 180) => {
  element.style.transform = `rotate(${isRotated ? to : from}deg)`;
};

const rotate = (element, value) => {
  if (typeof value === 'boolean') {
    setTransform(element, value);
  } else {
    setTransform(element, value.isRotated, value.from, value.to);
  }
};

export default {
  mounted(element, { value }) {
    element.style.transition = 'all var(--transition)';
    rotate(element, value);
  },
  updated(element, { value }) {
    rotate(element, value);
  },
};
