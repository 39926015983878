import { DOCS_TABLE } from '@/configs/db-tables.js';
import LinkIcon from '@/assets/icons/link.svg';
import LinkCard from '@/common/models/card/link-card';
import { markRaw } from 'vue';

export default class DocumentCard extends LinkCard {
  constructor(response) {
    super(response, 'DOC_ID', 'Содержание', markRaw(LinkIcon), 'document', DOCS_TABLE);
  }
}
