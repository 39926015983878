import { defineComponent } from 'vue';
import PauseCircleIcon from '@/assets/icons/circle/pause.svg';
import PlayCircleIcon from '@/assets/icons/circle/play.svg';
import StopCircleIcon from '@/assets/icons/circle/stop.svg';

/** @deprecated Используйте composables */
export default defineComponent({
  data() {
    const events = ['counter-reload-controls', 'table-trigger-refresh-only-state'];
    return {
      events,
    };
  },
  computed: {
    route_point_solution() {
      return {
        pause: {
          icon: PauseCircleIcon,
          title: ' Поставить на паузу',
          method: () => this.$refs.pauseDocModal.show(),
        },
        start: {
          icon: PlayCircleIcon,
          title: 'Запустить документ',
          method: () => this.$refs.startDocModal.show(),
        },
        stop: {
          icon: StopCircleIcon,
          title: 'Остановить',
          color: 'gray',
          mode: 'outline',
          method: () => this.$refs.stopDocModal.show(),
        },
      };
    },
  },
});
