<template>
  <span
    class="table-badge"
    :class="classBadge"
  >
    {{ config.values[data].title }}
  </span>
</template>

<script>
export default {
  name: 'TypeDoc',
  props: ['config', 'config_g', 'data', 'dataRow'],
  computed: {
    classBadge() {
      if (this.config_g.name === 'commissions' && this.dataRow['RPS_ID'] === 5) {
        return 'badge-notification-finished';
      }
      if (this.config_g.name === 'RoutesPoints' && this.dataRow['RPS_ID'] !== 2 && this.dataRow['RPS_ID'] !== 9) {
        return 'badge-notification-finished';
      }
      return this.config.values[this.data].class;
    },
  },
};
</script>
