<template>
  <div class="deadline-ui">
    <SwitchInputForm
      :options="switchOptionsComputed"
      :model-value="modelValue.type"
      @update:model-value="onTypeChange"
    />

    <div
      v-if="modelValue.type === DeadlineType.WorkingDays"
      class="controls"
    >
      <NumberInputForm
        :min="minDays"
        :max="maxDays"
        :model-value="modelValue.workingDays"
        @update:model-value="onWorkingDaysChange"
      />

      <DateForm
        only-input
        disabled
        :model-value="modelValue.workingDaysDate"
      />
    </div>

    <div
      v-else-if="modelValue.type === DeadlineType.Days"
      class="controls"
    >
      <NumberInputForm
        :min="minDays"
        :max="maxDays"
        :model-value="modelValue.days"
        @update:model-value="onDaysChange"
      />

      <DateForm
        only-input
        disabled
        :model-value="modelValue.daysDate"
      />
    </div>

    <DateForm
      v-else-if="modelValue.type === DeadlineType.Date"
      class="_single"
      :error="error"
      :model-value="modelValue.date"
      :min-date="dateInputMinDate"
      :copy-handler="copyHandler"
      @update:model-value="onDateChange"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import SwitchInputForm from '@/components/form/SwitchInputForm.vue';
import NumberInputForm from '@/components/form/NumberInputForm.vue';
import DateForm from '@/components/form/DateForm.vue';
import { DateTime } from 'luxon';
import DocumentMasterApi from '@/services/api/document-master-api.js';
import AbortMixin from '@/mixins/abort-mixin.js';
import { CanceledError } from 'axios';
import { NotifyTypes } from '@/configs/notify-types.js';
import { debounce } from 'lodash-es';
import { INPUT_DEBOUNCE } from '@/common/consts/app.js';
import { DeadlineType } from '@/common/enums/deadline-type.ts';
import { keys } from '@/common/utils/props-validators';

const switchOptions = Object.freeze([
  {
    label: 'Без срока',
    value: DeadlineType.None,
  },
  {
    label: 'В рабочих днях',
    value: DeadlineType.WorkingDays,
  },
  {
    label: 'В днях',
    value: DeadlineType.Days,
  },
  {
    label: 'Дата',
    value: DeadlineType.Date,
  },
]);

export default defineComponent({
  name: 'DeadlineUi',
  mixins: [AbortMixin],
  props: {
    modelValue: {
      type: Object,
      required: true,
      validator: keys('type', 'workingDays', 'workingDaysDate', 'days', 'daysDate', 'date'),
    },
    excludeOptions: {
      type: Array,
      default: () => [],
    },
    minDays: {
      type: Number,
      default: 1,
    },
    maxDays: {
      type: Number,
      default: 360,
    },
    copyHandler: {
      type: Function,
      required: false,
    },
    // дата, от которой рассчитываются дни для всех типов селекта
    startDate: {
      type: Date,
      default: null,
    },
    countBackwards: Boolean,
    error: String,
  },
  emits: ['update:modelValue'],
  components: {
    DateForm,
    NumberInputForm,
    SwitchInputForm,
  },
  data() {
    return {
      getDeadlineDebounced: this.getDeadline,
      DeadlineType,
      switchOptions,
    };
  },
  computed: {
    switchOptionsComputed() {
      return this.excludeOptions.length
        ? switchOptions.filter(({ value }) => !this.excludeOptions.includes(value))
        : switchOptions;
    },
    dateInputMinDate() {
      if (!this.startDate || this.countBackwards) {
        return;
      }

      return DateTime.fromJSDate(this.startDate).plus({ days: 1 }).toJSDate();
    },
  },
  watch: {
    async startDate(newVal, oldVal) {
      // убрать эту проверку, после того, как выпилим FormBuilder
      if (oldVal?.getDate() === newVal?.getDate()) {
        return;
      }

      this.getDeadlineDebounced();
      await this.$nextTick();
      this.onDaysChange(this.modelValue.days);
      await this.$nextTick();

      if (!newVal) {
        return;
      }

      const newDateTime = DateTime.fromJSDate(newVal).plus({ days: 1 });
      const newDateTimeFormatted = newDateTime.toFormat('yyyy-MM-dd');
      if (!this.modelValue.date) {
        this.onDateChange(newDateTimeFormatted);
        return;
      }

      const currentDateTime = DateTime.fromFormat(this.modelValue.date, 'yyyy-MM-dd');
      const currMs = currentDateTime.toMillis(),
        newMs = newDateTime.toMillis();
      if (currMs >= newMs) {
        return;
      }

      this.onDateChange(newDateTimeFormatted);
      if (this.modelValue.type === DeadlineType.Date) {
        this.$notify({
          type: NotifyTypes.Warn,
          text: `Срок был автоматически изменен на ${newDateTime.toFormat('dd.MM.yyyy')}`,
        });
      }
    },
    async countBackwards(newVal, oldVal) {
      if (oldVal === newVal) {
        return;
      }

      this.getDeadlineDebounced();
      await this.$nextTick();
      this.onDaysChange(this.modelValue.days);
      await this.$nextTick();
    },
  },
  created() {
    this.getDeadlineDebounced = debounce(this.getDeadline, INPUT_DEBOUNCE);
  },
  mounted() {
    if (!this.modelValue.workingDaysDate) {
      this.getDeadlineDebounced(this.modelValue.workingDays);
    }
    if (!this.modelValue.daysDate) {
      this.onDaysChange(this.modelValue.days);
    }
  },
  unmounted() {
    this.getDeadlineDebounced.cancel();
  },
  methods: {
    onTypeChange(type) {
      this.update({ type });
    },
    onWorkingDaysChange(workingDays) {
      this.update({ workingDays });
      this.getDeadlineDebounced(workingDays);
    },
    onDaysChange(days) {
      const daysBeforeOrAfter = this.countBackwards ? -days : days;
      const daysDate = this.startDate
        ? DateTime.fromJSDate(this.startDate).plus({ days: daysBeforeOrAfter }).toFormat('yyyy-MM-dd')
        : DateTime.local().plus({ days: daysBeforeOrAfter }).toFormat('yyyy-MM-dd');

      this.update({ days, daysDate });
    },
    onDateChange(date) {
      this.update({ date });
    },
    update(data) {
      this.$emit('update:modelValue', { ...this.modelValue, ...data });
    },
    async getDeadline(workingDays = this.modelValue.workingDays) {
      this.abort('Получено новое значение');

      const formattedStartDate = this.startDate ? DateTime.fromJSDate(this.startDate).toFormat('yyyy-MM-dd') : null;
      const workingDaysBeforeOrAfter = workingDays && this.countBackwards ? -workingDays : workingDays;

      try {
        const workingDaysDate = await DocumentMasterApi.getDeadline(
          workingDaysBeforeOrAfter,
          formattedStartDate,
          this.abortController.signal,
        );
        this.update({ workingDaysDate });
      } catch (error) {
        if (error instanceof CanceledError) {
          return;
        }

        this.$notify({
          type: NotifyTypes.Error,
          text: 'При получении срока в рабочих днях возникла ошибка.',
          data: error,
        });
      }
    },
  },
});
</script>

<style scoped lang="scss">
.form--switch-input {
  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.controls {
  display: flex;
  gap: 8px;
}

.form--input-number {
  flex: 1 1 50%;

  margin-bottom: 0; // TODO: Отказаться от перекрытия стилей
}

.date-form {
  flex: 1 1 50%;
  min-width: 166px;

  margin-bottom: 0; // TODO: Отказаться от перекрытия стилей

  &._single {
    max-width: 310px;
  }
}
</style>
