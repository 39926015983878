<template>
  <ModalUi
    ref="modal"
    :visible="visible"
    title="Внимание"
    popup
    @update:visible="$emit('update:visible')"
  >
    <template #body>
      <div class="content">
        <slot />
      </div>
    </template>

    <template #footer>
      <CancelButton @click="hide" />

      <ButtonUi @click="next">
        <CheckIcon v-prefix />
        <span>Продолжить</span>
      </ButtonUi>
    </template>
  </ModalUi>
</template>

<script>
import { defineComponent } from 'vue';
import CancelButton from '@/components/buttons/CancelButton.vue';
import CheckIcon from '@/assets/icons/circle/check.svg';
import ModalUi from '@/components/ui/ModalUi.vue';
import ModalMixin from '@/mixins/modal-mixin.js';
import ButtonUi from '@/components/ui/ButtonUi.vue';

export default defineComponent({
  name: 'AttentionModal',
  components: {
    CheckIcon,
    ButtonUi,
    ModalUi,
    CancelButton,
  },
  mixins: [ModalMixin],
  props: {
    visible: Boolean,
  },
  emits: ['update:visible', 'agree'],
  methods: {
    next() {
      this.hide();
      this.$emit('agree');
    },
  },
});
</script>
