<template>
  <component
    :is="config.component"
    :data="data"
  />
</template>

<script>
export default {
  name: 'FlagColumn',
  props: ['config', 'data'],
};
</script>
