import Api from '@/services/api/api.js';
import {
  ADDRESS_TYPES_GET,
  CATEGORIES_GET,
  CLIENTS_ADDRESSES_GET,
  CLIENTS_BANK_DETAILS_GET,
  CLIENTS_COMMENTS_GET,
  CLIENTS_CONTACTS_GET,
  CLIENTS_DOCS_GET,
  CLIENTS_GET,
  CLIENTS_TYPES_GET,
  COMMISSIONS_GET,
  CONTACT_TYPES_GET,
  CONTROL_GET,
  DOC_ACCESS,
  DOC_COMMENTS_GET,
  DOC_FILES_GET,
  DOC_FILES_SIGNATURES_GET,
  DOC_GANTT_ITEMS,
  DOC_LINKS_GET,
  DOC_ROUTES_POINTS_GET,
  DOC_TYPES_GET,
  DOCS_AUDIT_ALL,
  DOCS_CLIENTS_GET,
  DOCS_GANTT_ITEMS,
  DOCS_GET,
  DOCS_VIEWS_ALL,
  MANAGERS_GET,
  PROJECTS_GET,
  RECIPIENTS_GET,
  REGIONS_GET,
  ROUTES_POINTS_RESPONSIBLE_DIRECTORY_GET,
  ROUTESPOINTS_RESPONSIBLES_GET,
  SEGMENTS_GET,
  USERS_ACTIVE,
  USERS_GET,
} from '@/configs/end-points.js';
import VuexAdapter from '@/services/vuex-adapter.js';
import axios from 'axios';
import ConstantsRPS from '@/configs/route-point-statuses.js';
import { crc32 } from 'js-crc';
import { defineStore } from 'pinia';
import StoreId from '@/common/enums/store-id.js';

const actions = {};
const states = {};
const getters = {};

const clearMutations = [];

const END_POINT_LISTS = [
  DOC_COMMENTS_GET,
  DOCS_VIEWS_ALL,
  DOC_LINKS_GET,
  CLIENTS_GET,
  COMMISSIONS_GET,
  CONTROL_GET,
  DOC_ACCESS,
  DOC_FILES_GET,
  DOC_FILES_SIGNATURES_GET,
  DOCS_CLIENTS_GET,
  DOCS_AUDIT_ALL,
  DOCS_GET,
  DOC_ROUTES_POINTS_GET,
  ROUTES_POINTS_RESPONSIBLE_DIRECTORY_GET,
  USERS_GET,
  USERS_ACTIVE,
  CLIENTS_TYPES_GET,
  SEGMENTS_GET,
  REGIONS_GET,
  MANAGERS_GET,
  CLIENTS_ADDRESSES_GET,
  ADDRESS_TYPES_GET,
  CLIENTS_BANK_DETAILS_GET,
  CATEGORIES_GET,
  CLIENTS_COMMENTS_GET,
  CLIENTS_CONTACTS_GET,
  CLIENTS_DOCS_GET,
  CONTACT_TYPES_GET,
  DOC_TYPES_GET,
  PROJECTS_GET,
  RECIPIENTS_GET,
  DOC_GANTT_ITEMS,
  DOCS_GANTT_ITEMS,
  ROUTESPOINTS_RESPONSIBLES_GET,
];

END_POINT_LISTS.forEach((endPoint) => {
  const actionName = VuexAdapter.getNameAction(endPoint);
  const actionAbortName = VuexAdapter.getNameAbortAction(endPoint);
  const actionLoadingName = VuexAdapter.getNameLoadingAction(endPoint);
  const actionClearName = VuexAdapter.getNameClearAction(endPoint);

  const mutationName = VuexAdapter.getNameMutation(endPoint);
  const mutationLoadingName = VuexAdapter.getNameLoadingMutation(endPoint);
  const mutationClearName = VuexAdapter.getNameClearMutation(endPoint);
  const mutationErrorName = VuexAdapter.getNameErrorMutation(endPoint);

  const stateName = VuexAdapter.getNameState(endPoint);
  const stateCountName = VuexAdapter.getNameCountState(endPoint);
  const stateLoaderGeneralName = VuexAdapter.getLoaderGeneralState(endPoint);
  const stateErrorName = VuexAdapter.getNameErrorState(endPoint);
  const stateLoadingName = VuexAdapter.getNameLoadingState(endPoint);

  const getterName = VuexAdapter.getNameGetter(endPoint);
  const getterErrorName = VuexAdapter.getNameErrorGetter(endPoint);
  const getterLoadingName = VuexAdapter.getNameLoadingGetter(endPoint);
  const getterCountName = VuexAdapter.getNameCountGetter(endPoint);
  const getterLoaderGeneralName = VuexAdapter.getNameLoaderGeneralGetter(endPoint);

  const END_POINT_CASH_DATA = [
    ROUTES_POINTS_RESPONSIBLE_DIRECTORY_GET,
    ROUTESPOINTS_RESPONSIBLES_GET,
    USERS_GET,
    USERS_ACTIVE,
    CLIENTS_TYPES_GET,
    SEGMENTS_GET,
    REGIONS_GET,
    MANAGERS_GET,
    CATEGORIES_GET,
    DOC_TYPES_GET,
    PROJECTS_GET,
    RECIPIENTS_GET,
  ];

  const cashDataIsEnable = END_POINT_CASH_DATA.includes(endPoint);

  const END_POINT_LIST_UNIQUE_KEY = {
    [DOCS_GET]: 'DOC_ID',
    [CLIENTS_GET]: 'CLIENT_ID',
    [DOC_COMMENTS_GET]: 'COMMENT_ID',
    [DOC_ACCESS]: 'ACCESS_ID',
    [DOC_COMMENTS_GET]: 'COMMENT_ID',
    [COMMISSIONS_GET]: 'RP_ID',
    [CONTROL_GET]: 'DOC_ID',
    [DOCS_CLIENTS_GET]: 'CLIENT_ID',
    [DOCS_AUDIT_ALL]: 'AUDIT_ID',
    [DOC_LINKS_GET]: 'LINK_ID',
    [DOC_FILES_GET]: 'F_ID',
    [DOC_ROUTES_POINTS_GET]: 'RP_ID',
  };

  const uniqueKey = END_POINT_LIST_UNIQUE_KEY[endPoint] || null;

  /** action получения данных */
  actions[actionName] = function (data) {
    const hash = crc32(JSON.stringify(data) + actionName);

    this.abortControllersListsState[actionName] = new AbortController();
    this[stateLoaderGeneralName] = true;

    return new Promise((resolve, reject) => {
      if (cashDataIsEnable && this.cashListsState[hash] !== undefined) {
        const r = this.cashListsState[hash];
        this[mutationName](r);
        resolve(r);

        this[stateLoaderGeneralName] = false;

        return;
      }

      return Api.post(endPoint, data, this.abortControllersListsState[actionName].signal, false)
        .then((r) => {
          if (cashDataIsEnable) {
            this.cashListsState[hash] = r;
          }

          this[mutationName](r);
          this[mutationErrorName](null);
          resolve(r);
        })
        .catch((e) => {
          if (!axios.isCancel(e)) {
            this[mutationErrorName](e);
            reject(e);
          }
        })
        .finally(() => {
          this[stateLoaderGeneralName] = false;
        });
    });
  };

  /** Остановить запрос action получения данных */
  actions[actionAbortName] = function () {
    if (this.abortControllersListsState[actionName]) {
      this.abortControllersListsState[actionName].abort();
    }
    this[stateLoaderGeneralName] = false;
  };

  /** action подгрузки данных */
  actions[actionLoadingName] = function (data) {
    this[stateLoadingName] = true;
    return new Promise((resolve, reject) => {
      return Api.post(endPoint, data, undefined, false)
        .then((r) => {
          this[mutationLoadingName](r);
          resolve(r);
        })
        .catch((e) => {
          reject(e);
        })
        .finally(() => {
          this[stateLoadingName] = false;
        });
    });
  };

  /** action очистки данных */
  actions[actionClearName] = function () {
    this[mutationClearName]();
  };

  /** action данных */
  actions[mutationName] = function (data) {
    this[stateName] = [...data.data.items];
    if (Number.isInteger(data.data.count)) {
      this[stateCountName] = data.data.count;
    }
  };

  /** action подгрузки данных */
  actions[mutationLoadingName] = function (data) {
    let length = this[stateName].length;
    data.data.items.forEach((value, key) => {
      this[stateName][length + key] = value;
    });
  };

  /** action для обработки ошибок */
  actions[mutationErrorName] = function (error) {
    this[stateErrorName] = error;
  };

  /** action очистки данных */
  actions[mutationClearName] = function () {
    this[stateName] = [];
    this[stateCountName] = null;
    this[stateErrorName] = null;
    this[stateLoadingName] = false;
  };

  clearMutations.push(mutationClearName);

  /** state */
  states[stateName] = [];
  states[stateCountName] = null;
  states[stateErrorName] = null;
  states[stateLoadingName] = false;

  /** getter данных с костылем убирания дублирующих строк */
  getters[getterName] = (state) => {
    if (!uniqueKey) {
      return state[stateName];
    }

    return state[stateName].reduce((o, i) => {
      if (!o.find((v) => v[uniqueKey] == i[uniqueKey])) {
        o.push(i);
      }
      return o;
    }, []);
  };

  /** getter количества */
  getters[getterCountName] = (state) => {
    return state[stateCountName];
  };

  /** getter крутилки запроса */
  getters[getterLoaderGeneralName] = (state) => {
    return state[stateLoaderGeneralName];
  };

  /** getter крутилки подгрузки */
  getters[getterLoadingName] = (state) => {
    return state[stateLoadingName];
  };

  /** getter ошибки */
  getters[getterErrorName] = (state) => {
    return state[stateErrorName];
  };

  let end_point_getter_object_lists = {};

  end_point_getter_object_lists[USERS_GET] = 'LOGIN_ID';
  end_point_getter_object_lists[USERS_ACTIVE] = 'LOGIN_ID';

  const END_POINT_GETTER_OBJECT_LISTS = end_point_getter_object_lists;

  /** getter получения данных в виде объекта */
  if (END_POINT_GETTER_OBJECT_LISTS[endPoint] !== undefined) {
    const getterObgName = VuexAdapter.getNameObjGetter(endPoint);
    const identField = END_POINT_GETTER_OBJECT_LISTS[endPoint];

    getters[getterObgName] = (state) => {
      let obg = {};
      for (let n in state[stateName]) {
        obg[state[stateName][n][identField]] = state[stateName][n];
      }
      return obg;
    };
  }

  const END_POINT_GETTER_SELECT_LISTS = {
    [USERS_GET]: { codeField: 'LOGIN_ID', valueField: 'ФИО' },
    [USERS_ACTIVE]: { codeField: 'LOGIN_ID', valueField: 'ФИО' },
    [CLIENTS_TYPES_GET]: { codeField: 'TYPE_ID', valueField: 'Название' },
    [SEGMENTS_GET]: { codeField: 'Название', valueField: 'Название' },
    [REGIONS_GET]: { codeField: 'Название', valueField: 'Название' },
    [MANAGERS_GET]: { codeField: 'ФИО', valueField: 'ФИО' },
    [CATEGORIES_GET]: { codeField: 'Приоритет', valueField: 'Приоритет' },
    [DOC_TYPES_GET]: { codeField: 'DT_ID', valueField: 'Название' },
    [PROJECTS_GET]: { codeField: 'P_ID', valueField: 'Название' },
    [RECIPIENTS_GET]: { codeField: 'RECIPIENT_ID', valueField: 'Объект' },
    [CONTACT_TYPES_GET]: { codeField: 'Тип', valueField: 'Тип' },
    [ADDRESS_TYPES_GET]: { codeField: 'Тип', valueField: 'Тип' },
  };

  /** getter получения данных для select */
  if (END_POINT_GETTER_SELECT_LISTS[endPoint] !== undefined) {
    const getterSelectName = VuexAdapter.getNameSelectGetter(endPoint);
    const codeField = END_POINT_GETTER_SELECT_LISTS[endPoint]['codeField'];
    const valueField = END_POINT_GETTER_SELECT_LISTS[endPoint]['valueField'];

    getters[getterSelectName] = (state) => {
      let select = [];
      for (let n in state[stateName]) {
        select.push({
          code: state[stateName][n][codeField],
          label: state[stateName][n][valueField],
        });
      }
      return select;
    };
  }

  const END_POINT_GETTER_SELECT_SIMPLE_LISTS = {
    [ADDRESS_TYPES_GET]: 'Тип',
    [CONTACT_TYPES_GET]: 'Тип',
  };

  /** getter получения данных для select как массив значений */
  if (END_POINT_GETTER_SELECT_SIMPLE_LISTS[endPoint] !== undefined) {
    const getterSelectSimpleName = VuexAdapter.getNameSelectSimpleGetter(endPoint);
    const codeFieldSimple = END_POINT_GETTER_SELECT_SIMPLE_LISTS[endPoint];

    getters[getterSelectSimpleName] = (state) => {
      let select = [];

      for (let n in state[stateName]) {
        select.push(state[stateName][n][codeFieldSimple]);
      }

      return select;
    };
  }
});

/** @deprecated */
export const useListsStore = defineStore(StoreId.Lists, {
  state: () => ({
    cashListsState: {},
    abortControllersListsState: {},
    ...states,
  }),
  getters: {
    ...getters,

    docTypesActiveSelectGetter(state) {
      const stateName = VuexAdapter.getNameState(DOC_TYPES_GET);

      let select = [];
      for (let n in state[stateName]) {
        if (state[stateName][n]['Активен']) {
          select.push({
            code: state[stateName][n]['DT_ID'],
            label: state[stateName][n]['Название'],
          });
        }
      }
      return select;
    },

    docLastRoutePointDeclineGetter(state) {
      const stateName = VuexAdapter.getNameState(DOC_GANTT_ITEMS);

      for (let n in state[stateName]) {
        if (state[stateName][n]['RPS_ID'] === ConstantsRPS.rp_status_type_rejected_id) {
          return state[stateName][n]['RP_ID'];
        }
      }

      return false;
    },
  },
  actions: {
    ...actions,
    clear() {
      clearMutations.forEach((mutation) => this[mutation]());
      this.$reset();
    },
  },
});
