import Api from '@/services/api/api.js';
import { ClientEndPoint } from '@/common/enums/end-point.js';
import ClientOption from '@/common/models/option/client-option.js';
import ClientCard from '@/common/models/card/client-card.js';

export default class ClientApi {
  static async getClient(id, signal) {
    const response = await Api.post(
      ClientEndPoint.GetList,
      {
        where: `CLIENT_ID = ${id}`,
      },
      signal,
    );

    return new ClientCard(response.items[0]);
  }

  static async getOptions(query, signal) {
    const response = await Api.post(
      ClientEndPoint.GetOptions,
      {
        query,
      },
      signal,
    );

    return response.items.map((item) => new ClientOption(item));
  }
}
