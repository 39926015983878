import ApiFile from '@/common/models/file/api-file';

export default class DocumentFile extends ApiFile {
  constructor(response) {
    super(response);
    this.parentTableId = response['PARENT_TABLE_ID'];
    this.parentId = response['PARENT_ID'];
    this.user = response['Пользователь'];
    this.signCount = response['ЭП'];
    this.documentId = response['DOC_ID'];
    this.isLast = response['Индикатор'] > 0;
    this.downloadName = `${this.documentId}_${this.revision.value}_${this.name}`;
  }
}
