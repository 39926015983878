import { NotifyTypes } from '@/configs/notify-types.js';
import CircleCloseIcon from '@/assets/icons/circle/close.svg';
import CircleCheckIcon from '@/assets/icons/circle/check.svg';
import CircleErrorIcon from '@/assets/icons/circle/error.svg';

export default {
  [NotifyTypes.Error]: CircleCloseIcon,
  [NotifyTypes.Success]: CircleCheckIcon,
  [NotifyTypes.Warn]: CircleErrorIcon,
};
