<template>
  <span
    v-if="data !== null"
    class="size-file"
  >
    {{ fileSize(data) }}
  </span>
</template>

<script>
import { fileSize } from '@/common/utils/utils.js';

export default {
  name: 'SizeFile',
  props: ['config', 'data'],
  methods: {
    fileSize,
  },
};
</script>

<style scoped lang="scss">
.size-file {
  white-space: nowrap;
}
</style>
