import '@/assets/styles/index.scss';
import { createApp } from 'vue';
import App from '@/App.vue';
import * as Sentry from '@sentry/vue';
import Exception from '@/services/exception';
import hotkeys from 'hotkeys-js';
import pinia from '@/pinia.ts';
import router from '@/router.ts';
import { VueQueryPlugin } from '@tanstack/vue-query';
import notifications from '@kyvg/vue3-notification';
import touchEvents from '@alphadoc/alphadoc-touch-events';
import Prefix from '@/directives/prefix';
import Postfix from '@/directives/postfix';

const app = createApp(App);

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({ app, dsn: import.meta.env.VITE_SENTRY_DSN });
}

if (import.meta.env.PROD) {
  Exception.init(app);
}

hotkeys.filter = () => true;

app
  .use(pinia)
  .use(router)
  .use(VueQueryPlugin)
  .use(notifications)
  .use(touchEvents, { disableClick: false })
  .directive('prefix', Prefix)
  .directive('postfix', Postfix)
  .mount('#app');
