import Api from '@/services/api/api.js';
import { RouteEndPoint } from '@/common/enums/end-point.js';
import TemplateFile from '@/common/models/file/template-file.js';
import DocumentApi from '@/services/api/document-api.js';
import CacheGroup from '@/common/enums/cache-group.js';

export default class RouteApi {
  static async getFileTemplates(ids, signal) {
    const response = await Api.post(RouteEndPoint.GetFileTemplates, { doc_ids: ids }, signal, true, {
      group: CacheGroup.DocumentMaster,
      key: `FILE_TEMPLATES_${ids.join('_')}`,
    });

    return await Promise.all(
      response.items.map(async (template) => {
        const files = await DocumentApi.getFiles(template['DOC_ID'], signal, {
          group: CacheGroup.DocumentMaster,
          key: `DOCUMENT_${template['DOC_ID']}`,
        });
        const file = files.find((file) => file.id === template['F_ID']);
        return new TemplateFile(template, file);
      }),
    );
  }
}
