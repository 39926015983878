<script setup>
import { nextTick, onMounted, ref, watch } from 'vue';
import UploadButton from '@/components/buttons/UploadButton.vue';
import RouteApi from '@/services/api/route-api.js';
import useAbort from '@/composables/use-abort.js';
import { NotifyTypes } from '@/configs/notify-types.js';
import CircleCloseIcon from '@/assets/icons/circle/close.svg';
import ButtonIconUi from '@/components/ui/ButtonIconUi.vue';
import TemplatePreview from '@/components/document-master/TemplatePreview.vue';
import { useMasterTypeStore } from '@/stores/master-type.js';
import { notify } from '@kyvg/vue3-notification';
import axios from 'axios';

defineProps({
  showClose: {
    type: Boolean,
    default: false,
  },
});

defineEmits(['add', 'select', 'close']);

const masterTypeStore = useMasterTypeStore();

const { signal } = useAbort();

const list = ref();
const templates = ref([]);
const showGradient = ref(false);

watch(templates, (value) => {
  if (value.length) {
    nextTick(() => calcShowGradient(list.value));
  }
});

onMounted(async () => {
  try {
    templates.value = await RouteApi.getFileTemplates(masterTypeStore.type.docIds, signal.value);
  } catch (error) {
    if (axios.isCancel(error)) {
      return;
    }

    notify({
      type: NotifyTypes.Error,
      text: 'При получении шаблонов файлов маршрутов возникла ошибка.',
      data: error,
    });
  }
});

function calcShowGradient(list) {
  showGradient.value = list.scrollHeight - list.offsetHeight - list.scrollTop > 1;
}
</script>

<template>
  <div class="upload-previews-new-tab">
    <div
      class="left"
      :class="{ _half: templates.length }"
    >
      <h3 class="header">Загрузите файл с устройства</h3>

      <div
        class="dropzone"
        @click="$emit('add')"
      >
        <UploadButton />

        <p class="dropzone-text">Загрузите файл или перетащите его в это поле</p>
      </div>
    </div>

    <Transition name="right">
      <div
        v-if="templates.length"
        class="right"
        :class="{ _gradient: showGradient }"
      >
        <div class="header">
          <h3>Выберите шаблон</h3>

          <ButtonIconUi color="black">
            <CircleCloseIcon
              v-if="showClose"
              @click="$emit('close')"
            />
          </ButtonIconUi>
        </div>

        <div
          ref="list"
          class="templates"
          @scroll="calcShowGradient($event.target)"
        >
          <TemplatePreview
            v-for="template in templates"
            :id="template.fileId"
            :key="template.id"
            :name="template.name"
            :url="template.url"
            :preview-url="template.previewUrl"
            :can-edit="template.canEdit"
            :can-preview="template.canPreview"
            :icon="template.icon"
            @select="$emit('select', template)"
          />
        </div>
      </div>
    </Transition>
  </div>
</template>

<style scoped lang="scss">
.upload-previews-new-tab {
  margin-bottom: 16px;
  padding: 16px;
  height: 100%;
  overflow: hidden;

  display: flex;
  gap: 16px;

  background-color: var(--color-gray-075);
  border-radius: 8px;
}

.left {
  flex: 1 0 100%;

  display: flex;
  flex-direction: column;

  transition: flex-basis var(--transition-slow);

  &._half {
    flex-basis: calc(50% - 8px);
  }
}

.header {
  margin-bottom: 16px;

  display: flex;
  justify-content: space-between;
}

.dropzone {
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border-radius: 8px;
  border: var(--border-dashed-gray-400);
}

.button-ui {
  margin-bottom: 16px;
}

.dropzone-text {
  width: 230px;

  text-align: center;
  color: var(--color-gray-700);
  font-size: var(--font-size-l);
  line-height: var(--line-height-l);
}

.templates {
  flex-grow: 1;
  margin: -8px -8px 0;
  overflow-y: auto;

  display: grid;
  align-items: start;
  grid-template-columns: repeat(2, 1fr);
}

.template-preview {
  min-width: 0;
}

.right {
  flex: 1 0 calc(50% - 8px);
  position: relative;

  display: flex;
  flex-direction: column;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: -8px;
    right: -8px;
    height: 80px;

    pointer-events: none;
    background: var(--gradient-fade);
    transition: opacity var(--transition);
  }

  &:not(._gradient) {
    &::after {
      opacity: 0;
    }
  }

  &-enter-from,
  &-leave-to {
    transform: translateX(100%);
  }

  &-enter-active,
  &-leave-active {
    transition: transform var(--transition-slow);
  }
}
</style>
