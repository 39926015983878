<script setup>
import ButtonIconUi from '@/components/ui/ButtonIconUi.vue';
import UpdateIcon from '@/assets/icons/update.svg';
import { only } from '@/common/utils/props-validators';
import { useVersionStore } from '@/stores/version.js';
import { storeToRefs } from 'pinia';

defineProps({
  tooltipPosition: {
    type: String,
    default: 'bottom',
    validator: only('bottom', 'right'),
  },
});

const versionStore = useVersionStore();
const { next } = storeToRefs(versionStore);

function showUpdate() {
  versionStore.setShowModal(true);
}
</script>

<template>
  <ButtonIconUi
    v-if="!!next"
    class="update-button"
    size="l"
    :aria-label="`Доступно обновление приложения до версии ${next.version}`"
    :data-tooltip-position="tooltipPosition"
    data-tooltip-size="auto"
    role="tooltip"
    @click="showUpdate"
  >
    <UpdateIcon />
  </ButtonIconUi>
</template>
