<template>
  <div
    v-if="controlsCount.count_new > 0"
    class="indicator-badge-count-new"
  />
  <div
    v-else-if="controlsCount.count_expired > 0"
    class="indicator-badge-count-expired"
  />
</template>

<script>
import { mapState } from 'pinia';
import { useControlsStore } from '@/stores/controls.js';

export default {
  name: 'CounterControlsIconSmall',
  computed: {
    ...mapState(useControlsStore, ['controlsCount']),
  },
};
</script>
