import { ref } from 'vue';

export default function useModal() {
  const modal = ref();

  function show() {
    modal.value.show();
  }

  function hide() {
    modal.value.hide();
  }

  return { modal, show, hide };
}
