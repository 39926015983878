<template>
  <div class="contractor-row">
    <p>
      <span class="_muted">{{ row['Ссылка'] }}</span>
    </p>
    <p>
      <b class="client">
        <a
          class="_black"
          :href="`/client/${row['CLIENT_ID']}`"
          target="_blank"
        >
          {{ row['Название'] }}
        </a>
      </b>
      <span class="_muted">{{ row['Менеджер'] }}</span>
    </p>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ContractorRow',
  props: ['row'],
});
</script>

<style scoped lang="scss">
.client {
  margin-right: 12px;
}
</style>
