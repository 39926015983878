import Option from '@/common/models/option/option.js';
import { defineStore } from 'pinia';
import StoreId from '@/common/enums/store-id.js';

export const useMasterParametersStore = defineStore(StoreId.MasterParameters, {
  state: () => ({
    parameters: [],
    model: {},
  }),
  getters: {
    request(state) {
      if (!state.parameters.length) {
        return null;
      }

      return Object.values(state.model).map((item) => (item instanceof Option ? item.code : item));
    },
  },
  actions: {
    setParameters(payload) {
      this.parameters = payload;
    },
    setModel(payload) {
      this.model = payload;
    },
  },
});
