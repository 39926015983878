<template>
  <div
    ref="componentContainer"
    :class="classDocumentSheetContainer"
  >
    <LoaderUi
      v-if="!initFirstDataPreview"
      class="document-init-attempted"
      color="white"
      position="static"
      text="Загрузка данных"
    />

    <div v-else>
      <SheetDocumentPreview :ready="!clientGetterLoader">
        <HeaderClientPreview :client="clientGetter" />
        <CommonClientPreview :client="clientGetter" />
      </SheetDocumentPreview>

      <AddressesClientPreview
        :client-id="entityIdPreview"
        :loading="clientAddressLoader"
      />

      <ContactsClientPreview
        :client-id="entityIdPreview"
        :loading="clientContactsLoader"
      />

      <CommentsClientPreview
        :client-id="entityIdPreview"
        :loading="clientCommentLoader"
      />

      <BankDetailsClientPreview
        :client-id="entityIdPreview"
        :loading="clientBankDetailsLoader"
      />

      <NearestDocsClientPreview
        :client-id="entityIdPreview"
        :loading="clientDocLoader"
      />
    </div>

    <ScrollToStartButton :element="$refs.componentContainer" />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import PreviewMixin from '@/mixins/preview-mixin.js';
import { mapState } from 'pinia';
import SheetDocumentPreview from '@/components/doc/preview/SheetDocumentPreview';
import HeaderClientPreview from '@/components/clients/HeaderClientPreview';
import CommonClientPreview from '@/components/clients/CommonClientPreview';
import NearestDocsClientPreview from '@/components/clients/NearestDocsClientPreview';
import AddressesClientPreview from '@/components/clients/AddressesClientPreview';
import ContactsClientPreview from '@/components/clients/ContactsClientPreview';
import BankDetailsClientPreview from '@/components/clients/BankDetailsClientPreview';
import VuexAdapter from '@/services/vuex-adapter.js';
import {
  CLIENTS_ADDRESSES_GET,
  CLIENTS_BANK_DETAILS_GET,
  CLIENTS_COMMENTS_GET,
  CLIENTS_CONTACTS_GET,
  CLIENTS_DOCS_GET,
  CLIENTS_GET,
} from '@/configs/end-points.js';
import CommentsClientPreview from '@/components/clients/CommentsClientPreview';
import { ClientPreview } from '@/common/models/preview/client-preview.js';
import LoaderUi from '@/components/ui/LoaderUi.vue';
import { useRowStore } from '@/stores/row.js';
import { useListsStore } from '@/stores/lists.js';
import ScrollToStartButton from '@/components/buttons/ScrollToStartButton.vue';

export default defineComponent({
  name: 'ClientPreview',
  components: {
    ScrollToStartButton,
    LoaderUi,
    CommentsClientPreview,
    BankDetailsClientPreview,
    ContactsClientPreview,
    AddressesClientPreview,
    NearestDocsClientPreview,
    CommonClientPreview,
    HeaderClientPreview,
    SheetDocumentPreview,
  },
  mixins: [PreviewMixin],
  mounted() {
    this.initPreviewAction(ClientPreview.intefaceIdent);
  },
  computed: {
    ...mapState(useListsStore, {
      clientAddressLoader: VuexAdapter.getNameLoaderGeneralGetter(CLIENTS_ADDRESSES_GET),
      clientBankDetailsLoader: VuexAdapter.getNameLoaderGeneralGetter(CLIENTS_BANK_DETAILS_GET),
      clientCommentLoader: VuexAdapter.getNameLoaderGeneralGetter(CLIENTS_COMMENTS_GET),
      clientContactsLoader: VuexAdapter.getNameLoaderGeneralGetter(CLIENTS_CONTACTS_GET),
      clientDocLoader: VuexAdapter.getNameLoaderGeneralGetter(CLIENTS_DOCS_GET),
    }),
    ...mapState(useRowStore, {
      clientGetter: VuexAdapter.getNameRowGetter(CLIENTS_GET),
      clientGetterLoader: VuexAdapter.getNameRowLoaderGeneralGetter(CLIENTS_GET),
    }),
  },
});
</script>

<style scoped lang="scss">
.preview-block {
  margin-bottom: 16px;
}

.scroll-to-start-button {
  position: fixed;
  bottom: 32px;
  right: 3vw;

  @include respond-down(l) {
    bottom: 20px;
  }

  @include respond-down(m) {
    right: 20px;
  }
}
</style>
