import Option from '@/common/models/option/option';

export default class TypeOption extends Option {
  constructor(response) {
    super(response, 'DT_ID', 'Название', 'Активен');
    this.mask = response['Маска'];
    this.defaultDocId = response['DOC_ID_default'];
    this.defaultProjectId = response['P_ID_default'];
    this.docIds = response['DOC_IDS'];
    this.linkTypesIds = response['LT_IDS'];
    this.projectIds = response['P_IDS'];
  }
}
