<!-- TODO: Объединить с UploaderBlockDocumentPreview -->
<template>
  <div>
    <div
      v-for="row in rows"
      :key="keyRow(row)"
      class="item"
    >
      <component
        :is="component"
        :row="row"
      />
    </div>

    <p v-if="error">
      <span class="_muted">{{ errorText }}</span>
    </p>

    <p v-else-if="!rows.length">
      <span class="_muted">{{ emptyText }}</span>
    </p>

    <div
      v-if="loader || showMoreButton"
      class="uploader-block-footer"
    >
      <ProgressBarUi v-if="loader" />
      <ShowMoreButton
        v-if="showMoreButton"
        @click="load"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import ShowMoreButton from '@/components/buttons/ShowMoreButton';
import { NotifyTypes } from '@/configs/notify-types.js';
import ProgressBarUi from '@/components/ui/ProgressBarUi.vue';
import VuexAdapter from '@/services/vuex-adapter.js';
import { useListsStore } from '@/stores/lists.js';

export default defineComponent({
  name: 'UploaderBlockClientPreview',
  components: {
    ProgressBarUi,
    ShowMoreButton,
  },
  props: {
    component: null,
    clientId: null,
    orderBy: null,
    endPoint: null,
    emptyText: {
      type: String,
      default: 'Список пуст',
    },
    errorText: {
      type: String,
      default: 'Ошибка получения данных',
    },
  },
  data: () => ({
    limit: 5,
    listsStore: useListsStore(),
  }),
  computed: {
    keyRow() {
      return (row) => JSON.stringify(row);
    },
    showMoreButton() {
      return !this.loader && this.rows.length < this.count;
    },
    rows() {
      return this.listsStore[VuexAdapter.getNameGetter(this.endPoint)];
    },
    count() {
      return this.listsStore[VuexAdapter.getNameCountGetter(this.endPoint)];
    },
    error() {
      return this.listsStore[VuexAdapter.getNameErrorGetter(this.endPoint)];
    },
    loader() {
      return this.listsStore[VuexAdapter.getNameLoadingGetter(this.endPoint)];
    },
    loadAction() {
      return this.listsStore[VuexAdapter.getNameLoadingAction(this.endPoint)];
    },
  },
  watch: {
    error(error) {
      if (error) {
        this.$notify({
          type: NotifyTypes.Error,
          text: 'При получении данных возникла ошибка.',
          data: error,
        });
      }
    },
  },
  methods: {
    async load() {
      await this.loadAction({
        where: 'CLIENT_ID = ' + this.clientId,
        limit: this.limit,
        offset: this.rows.length,
        orderby: this.orderBy ?? '',
      });
    },
  },
});
</script>

<style scoped lang="scss">
.item {
  &:not(:first-child) {
    margin-top: 12px;
  }
}

.show-more-button {
  width: 100%;
  margin: -12px;
}
</style>
