import RULES from './rules.js';

export function ofRules(rules) {
  return (v) => {
    let errArr = [];
    rules.forEach((r) => {
      if (!r.$validator(v)) {
        errArr.push(r.$message);
      }
    });
    return errArr.length ? errArr.join(' ') : true;
  };
}

export function ofRequired() {
  return ofRules([RULES.required]);
}
