<template>
  <div class="checkbox-form">
    <label
      v-if="label"
      class="label-general"
      v-html="label"
    />
    <div class="checkbox-row">
      <label
        v-for="value in values"
        :key="value.code"
        class="checkbox-form-element"
        :class="classes"
        :for="`checkbox-switch-${value.code}`"
      >
        <input
          :id="`checkbox-switch-${value.code}`"
          v-model="proxyValue"
          type="checkbox"
          :disabled="disabled"
          :value="value.code"
          @change="setResponsibleFrom"
        />
        <span class="checkmark" />
        <span class="checkbox-label">{{ value.label }}</span>
      </label>
    </div>
    <span
      v-if="hint || error"
      class="form--input-caption"
      :class="error ? 'form--input-caption__error' : ''"
    >
      {{ error ? error : hint }}
    </span>
  </div>
</template>

<script>
/** @deprecated Используйте ui-компоненты */
export default {
  name: 'CheckboxSwitchForm',
  props: {
    /**
     * Значение поля
     * @type {any}
     */
    modelValue: {},
    /**
     * Значение чекбоксов [{code, label}]
     * @type {values}
     */
    values: {
      type: Array,
      required: true,
    },
    /**
     * Текст для <label>
     * @type {string}
     */
    label: {
      type: String,
      default: '',
    },
    /**
     * Аттрибут [disabled]
     * @type {boolean}
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Сообщение об ошибке. (отображается под селектом, заменяет hint)
     */
    error: {
      type: String,
      default: '',
    },
    /**
     * Подсказка.
     */
    hint: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue', 'blur'],
  data: () => ({
    proxyValue: [],
  }),
  computed: {
    classes() {
      return {
        error: this.error,
        disabled: this.disabled,
      };
    },
  },
  watch: {
    modelValue(modelValue) {
      if (modelValue) {
        this.proxyValue = [modelValue];

        return;
      }

      this.proxyValue = [];
    },
  },
  mounted() {
    this.proxyValue = [this.modelValue || this.values[0]?.code];
    if (!this.modelValue && this.proxyValue) {
      this.$emit('update:modelValue', this.proxyValue[0] || undefined);
    }
  },
  methods: {
    setResponsibleFrom(e) {
      this.proxyValue = [e.target.value];
      this.$emit('update:modelValue', this.proxyValue[0] || undefined);
      this.$emit('blur');
    },
  },
};
</script>
