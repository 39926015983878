import { DocumentPreview } from '@/common/models/preview/document-preview.js';
import { ClientPreview } from '@/common/models/preview/client-preview.js';
import { defineStore } from 'pinia';
import StoreId from '@/common/enums/store-id.js';

const intefaces = {
  [DocumentPreview.intefaceIdent]: DocumentPreview,
  [ClientPreview.intefaceIdent]: ClientPreview,
};

export const usePreviewStore = defineStore(StoreId.Preview, {
  state: () => ({
    /**
     * ID Сущности превью
     * @property int
     */
    entityIdPreview: null,
    /**
     * Флаг активности для мобильной версии
     * @property bool
     */
    isActivePreview: false,
    /**
     * Флаг первичной инициализации
     * @property bool
     */
    initFirstDataPreview: false,
    /**
     * Реализация класса Preview
     * @property AbstarctPreview
     */
    interfacePreview: null,
  }),
  getters: {
    hasEntityIdPreviewGetter(state) {
      return state.entityIdPreview !== null;
    },
  },
  actions: {
    /**
     * Установка ID сущности
     * @param entityId
     */
    setEntityIdPreviewAction(entityId) {
      this.entityIdPreview = entityId;
      if (!entityId) {
        this.isActivePreview = false;
      }
    },
    /**
     * Установка активности для мобильной версии
     * @param isActive
     */
    setActivePreviewAction(isActive = true) {
      this.isActivePreview = isActive;
    },
    /**
     * Только для первичной инициализации
     * @param interfacePreview
     * @return Promise
     */
    initPreviewAction(interfacePreview) {
      if (!intefaces[interfacePreview]) {
        throw new Error(`Unknown interface ${interfacePreview}`);
      }

      const ClassName = intefaces[interfacePreview];

      this.interfacePreview = new ClassName(this.entityIdPreview);
      this.initFirstDataPreview = false;

      return this.interfacePreview
        .setEntityId(this.entityIdPreview)
        .fetchDataPreview()
        .finally(() => {
          this.initFirstDataPreview = true;
        });
    },
    /**
     * Частичное обновление
     * @param endPoint
     * @return {*}
     */
    refreshPartlyPreviewAction(endPoint) {
      return this.interfacePreview.setEntityId(this.entityIdPreview).fetchPartlyPreview(endPoint);
    },
    /**
     * Обновить превью
     */
    refreshPreviewAction() {
      if (!this.interfacePreview) {
        return;
      }

      return this.interfacePreview
        .setEntityId(this.entityIdPreview)
        .fetchDataPreview()
        .finally(() => {
          this.initFirstDataPreview = true;
        });
    },
  },
});
