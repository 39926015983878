<template>
  <PreviewBlock
    title="Маршрут"
    :amount="tasks.length"
    :loading="loading"
    @resize="onResize"
  >
    <template #header-postfix="{ isOpen }">
      <div class="header-postfix">
        <Transition name="opacity-fast">
          <div
            v-if="tasks.length && isOpen"
            class="buttons"
          >
            <ButtonIconUi
              color="gray"
              @click="ganttZoomIn"
            >
              <ZoomInIcon />
            </ButtonIconUi>
            <ButtonIconUi
              color="gray"
              @click="ganttZoomDefault"
            >
              <DistributeSpacingHorizontalIcon />
            </ButtonIconUi>
            <ButtonIconUi
              color="gray"
              @click="ganttZoomOut"
            >
              <ZoomOutIcon />
            </ButtonIconUi>
            <ButtonIconUi
              color="gray"
              @click="ganttRecenterPosition"
            >
              <TodayIcon />
            </ButtonIconUi>
          </div>
        </Transition>

        <AddPreviewButton
          v-if="showAdd"
          :show-title="!compact"
          @click="add"
        />
      </div>

      <AddRoutePointModal
        v-if="showAdd"
        ref="addModal"
        :doc-id="doc_id"
      />
    </template>

    <Gantt
      v-if="tasks.length"
      ref="ganttBlock"
      :doc="doc"
      :tasks="tasks"
      :rp_id="rp_id"
    />

    <div
      v-else-if="showAdd"
      class="empty"
    >
      <div class="empty-text _center">
        <p>У данного документа нет маршрута.</p>
        <p>Вы можете создать его, используя стандартные маршруты</p>
      </div>

      <ButtonUi
        color="gray"
        :size="compact ? 's' : 'm'"
        mode="outline"
        disabled
      >
        <PlusIcon v-prefix="compact ? 's' : 'm'" />
        <span>Вставить стандартный маршрут</span>
      </ButtonUi>
    </div>

    <div
      v-else
      class="empty-text"
    >
      <p>У данного документа нет маршрута</p>
    </div>
  </PreviewBlock>
</template>

<script>
import { defineComponent } from 'vue';
import Gantt from '@/components/doc/gantt/GanttBlock';
import ZoomInIcon from '@/components/icons/ZoomInIcon';
import DistributeSpacingHorizontalIcon from '@/components/icons/DistributeSpacingHorizontalIcon';
import ZoomOutIcon from '@/components/icons/ZoomOutIcon';
import TodayIcon from '@/components/icons/TodayIcon';
import AddRoutePointModal from '@/components/modals/RoutePoint/AddRoutePointModal';
import PreviewBlock from '@/components/doc/preview/PreviewBlock.vue';
import AddPreviewButton from '@/components/buttons/AddPreviewButton.vue';
import PreviewBlockMixin from '@/mixins/preview-block-mixin.js';
import ButtonIconUi from '@/components/ui/ButtonIconUi.vue';
import ButtonUi from '@/components/ui/ButtonUi.vue';
import PlusIcon from '@/assets/icons/plus.svg';

export default defineComponent({
  name: 'GanttDocumentPreview',
  components: {
    PlusIcon,
    ButtonUi,
    ButtonIconUi,
    AddPreviewButton,
    PreviewBlock,
    AddRoutePointModal,
    TodayIcon,
    ZoomOutIcon,
    DistributeSpacingHorizontalIcon,
    ZoomInIcon,
    Gantt,
  },
  mixins: [PreviewBlockMixin],
  props: {
    doc_id: {
      type: Number,
      required: true,
    },
    showAdd: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    tasks: {
      type: Array,
      required: true,
    },
    rp_id: {
      type: Number,
    },
    doc: {
      type: Object,
      required: true,
    },
  },
  methods: {
    add() {
      this.$refs.addModal.show();
    },
    ganttZoomIn() {
      this.$refs.ganttBlock?.ganttZoomIn();
    },
    ganttZoomDefault() {
      this.$refs.ganttBlock?.ganttZoomDefault();
    },
    ganttZoomOut() {
      this.$refs.ganttBlock?.ganttZoomOut();
    },
    ganttRecenterPosition() {
      this.$refs.ganttBlock?.ganttRecenterPosition();
    },
  },
});
</script>

<style scoped lang="scss">
.header-postfix {
  display: flex;
}

.buttons {
  display: flex;

  &:not(:last-child) {
    margin-right: 12px;
  }
}

.button-icon-ui {
  &:not(:last-child) {
    margin-right: 4px;
  }
}

.empty {
  padding: 40px 24px;

  display: flex;
  flex-direction: column;
  align-items: center;

  border: var(--border-dashed-gray-400);
  border-radius: 8px;
}

.empty-text {
  color: var(--color-gray-500);

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &._center {
    text-align: center;
  }
}
</style>
