<template>
  <div class="sign--table--body">
    <RowTableSign
      v-for="file in filesForSign"
      :key="file.file_id"
      :file="file"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import RowTableSign from '@/components/sign/RowTableSign';
import { useSignStore } from '@/stores/sign.js';

export default defineComponent({
  name: 'BodyTableSign',
  components: { RowTableSign },
  computed: {
    ...mapState(useSignStore, ['filesForSign']),
  },
});
</script>
