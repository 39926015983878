<template>
  <div class="file-signature-icon">
    <svg
      :width="size"
      :height="size"
      :viewBox="viewBox"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 1.4H4C2.56406 1.4 1.4 2.56406 1.4 4V12C1.4 13.4359 2.56406 14.6 4 14.6H12C13.4359 14.6 14.6 13.4359 14.6 12V4C14.6 2.56406 13.4359 1.4 12 1.4ZM4 0C1.79086 0 0 1.79086 0 4V12C0 14.2091 1.79086 16 4 16H12C14.2091 16 16 14.2091 16 12V4C16 1.79086 14.2091 0 12 0H4Z"
        :fill="fill"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.2998 5.9998C3.2998 5.61321 3.61321 5.2998 3.9998 5.2998H5.9998C6.93869 5.2998 7.6998 6.06092 7.6998 6.9998V8.9998C7.6998 9.93869 6.93869 10.6998 5.9998 10.6998H3.9998C3.61321 10.6998 3.2998 10.3864 3.2998 9.9998C3.2998 9.6132 3.61321 9.2998 3.9998 9.2998H5.9998C6.16549 9.2998 6.2998 9.16549 6.2998 8.9998V8.6998H4.9998C4.61321 8.6998 4.2998 8.3864 4.2998 7.9998C4.2998 7.61321 4.61321 7.2998 4.9998 7.2998H6.2998V6.9998C6.2998 6.83412 6.16549 6.6998 5.9998 6.6998H3.9998C3.61321 6.6998 3.2998 6.3864 3.2998 5.9998Z"
        :fill="fill"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.2998 5.9998C8.2998 5.61321 8.61321 5.2998 8.9998 5.2998H12.4998C12.8864 5.2998 13.1998 5.61321 13.1998 5.9998V9.9998C13.1998 10.3864 12.8864 10.6998 12.4998 10.6998C12.1132 10.6998 11.7998 10.3864 11.7998 9.9998V6.6998H9.6998V9.9998C9.6998 10.3864 9.3864 10.6998 8.9998 10.6998C8.61321 10.6998 8.2998 10.3864 8.2998 9.9998V5.9998Z"
        :fill="fill"
      />
    </svg>
    <div
      v-if="count"
      class="file-signature-icon--badge"
    >
      {{ count }}
    </div>
  </div>
</template>

<script>
/** @deprecated */
export default {
  name: 'FileSignatureIcon',
  props: {
    fill: {
      type: String,
      default: '#5F6A7D',
    },
    size: {
      type: Number,
      default: 16,
    },
    count: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    viewBox() {
      return `0 0 ${this.size} ${this.size}`;
    },
  },
};
</script>

<style scoped lang="scss">
.file-signature-icon {
  position: relative;
  margin-right: 12px;

  &--badge {
    position: absolute;
    top: -2px;
    right: -9px;
    height: 8px;
    width: 8px;
    border-radius: 4px;
    background-color: #e6ffd6;
    color: #000;
    font-size: 8px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
}
</style>
