import { defineStore } from 'pinia';
import Api from '@/services/api/api.js';
import { IgnorableApiException } from '@/common/models/exception/ignorable-api-exception.js';
import { AuthEndPoint } from '@/common/enums/end-point.js';
import { useSystemStore } from '@/stores/system.js';
import { useUserStore } from '@/stores/user.js';
import { useRolesStore } from '@/stores/roles.js';
import StoreId from '@/common/enums/store-id.js';

export const useAuthStore = defineStore(StoreId.Auth, {
  state: () => ({
    accessToken: null,
    refreshToken: null,
    refreshPromise: null,
  }),
  getters: {
    isAuthorized(state) {
      return !!state.accessToken;
    },
  },
  actions: {
    async login(request) {
      const response = await Api.post(AuthEndPoint.Login, request, undefined, false);

      this.set(response);

      const systemStore = useSystemStore();
      systemStore.setDBType(response.db_type);
      systemStore.setStorage(response.storage_url);

      const userStore = useUserStore();
      const rolesStore = useRolesStore();

      // TODO: Должны ли эти запросы выполняться здесь?
      // TODO: Что будет, если один из запросов упадет?
      await Promise.all([
        systemStore.fetchServerId(),
        userStore.fetch(),
        rolesStore.fetch(),
        systemStore.fetchAutoSignEnabled(),
      ]);
    },
    async refresh() {
      if (this.refreshPromise) {
        return await this.refreshPromise;
      }

      this.refreshPromise = Api.post(AuthEndPoint.Refresh, { refresh_token: this.refreshToken }, undefined, false);

      try {
        const response = await this.refreshPromise;
        this.set(response);
        this.clearRefreshPromise();
      } catch (error) {
        this.$reset();
        throw error;
      }
    },
    async logout() {
      try {
        await Api.post(AuthEndPoint.Logout);
      } catch (error) {
        throw new IgnorableApiException(error);
      } finally {
        this.$reset();
      }
    },
    clearRefreshPromise() {
      this.refreshPromise = null;
    },
    set(response) {
      this.accessToken = response.token;
      this.refreshToken = response.refresh_token;
    },
  },
  persist: true,
});
