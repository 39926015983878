<template>
  <ProgressCircleUi
    v-if="hasCompletePercent"
    :value="progress"
    completed-tooltip="Документ полностью исполнен, можно снять с контроля"
  />
</template>

<script>
import ProgressCircleUi from '@/components/ui/ProgressCircleUi.vue';

export default {
  name: 'ProgressFlag',
  components: { ProgressCircleUi },
  props: ['data'],
  data: () => ({
    progress: 0,
  }),
  computed: {
    hasCompletePercent() {
      return this.progress !== 0;
    },
  },
  mounted() {
    if (this.data !== null) {
      this.progress = this.data;
    }
  },
};
</script>

<style scoped lang="scss">
.progress-circle-ui {
  display: inline-block;
}
</style>
