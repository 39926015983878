<template>
  <div>
    <filters-header />

    <div
      v-for="condition in conditions"
      :key.prop="condition"
      class="fields"
      :hidden="!condition"
    >
      <combobox-ui
        v-model="condition.select"
        class="item"
        :options="options"
        no-clear
      />

      <combobox-ui
        v-if="condition.select !== null && !onlySelect.includes(condition.select.code)"
        class="item"
        :options="multiple_data"
        :model-value="condition.input || []"
        no-clear
        multiple
        @update:model-value="condition.input = $event.length ? $event : null"
      />

      <filter-buttons
        class="item"
        @clear="cleanFilter"
        @apply="applyFilter"
      />
    </div>

    <order-by
      :data="data"
      :col="col"
      :key_column="key_column"
    >
      <template #variant-asc> А-Я<br />По возрастанию</template>
      <template #variant-desc> Я-А<br />По убыванию</template>
    </order-by>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import FilterMixin from '@/mixins/table/filter-mixin.js';

export default defineComponent({
  name: 'IdentifierFilter',
  mixins: [FilterMixin],
  data: () => ({
    onlySelect: ['is not null', 'is null'],
    options: [
      {
        code: 'in',
        label: 'Один из',
      },
      {
        code: 'not in',
        label: 'Ни один из',
      },
      {
        code: 'is not null',
        label: 'Заполнено',
      },
      {
        code: 'is null',
        label: 'Не заполнено',
      },
    ],
    sqlGenerator: 'identifierSql',
  }),
  computed: {
    multiple_data() {
      const data = [];
      let i = 0;
      for (const key in this.data.info[this.key_column].values) {
        data[i] = {
          code: key,
          label: this.data.info[this.key_column].values[key].title,
        };
        i++;
      }
      return data;
    },
  },
});
</script>

<style scoped lang="scss">
.fields {
  margin-bottom: 12px;
}

.item {
  &:not(:last-child) {
    margin-bottom: 8px;
  }
}
</style>
