<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.52864 3.52851C5.78899 3.26816 6.2111 3.26816 6.47145 3.52851L10.4714 7.52851C10.7318 7.78886 10.7318 8.21097 10.4714 8.47132L6.47145 12.4713C6.2111 12.7317 5.78899 12.7317 5.52864 12.4713C5.26829 12.211 5.26829 11.7889 5.52864 11.5285L9.05723 7.99992L5.52864 4.47132C5.26829 4.21097 5.26829 3.78886 5.52864 3.52851Z"
    />
  </svg>
</template>

<script>
/** @deprecated */
export default {
  name: 'ChevronRightIcon',
};
</script>
