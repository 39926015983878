<template>
  <div class="performance-step-master">
    <div class="performance-step-master__items">
      <div
        v-for="{ model, label } in performanceShowed"
        :key="model"
        class="performance-step-master__item"
      >
        <div class="performance-step-master__item--icon">
          <SpinnerUi v-if="status[model] === ExecutionStatus.Loading" />
          <StopStatusMasterIcon v-if="status[model] === ExecutionStatus.Stop" />
          <ReadyStatusMasterIcon v-if="status[model] === ExecutionStatus.Ready" />
          <ErrorStatusMasterIcon v-if="status[model] === ExecutionStatus.Error" />
        </div>

        <div class="performance-step-master__item--label">
          {{ label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import StepMixin from '@/mixins/document-master/step-mixin.js';
import { ExecutionStatus } from '@/common/enums/execution-status.ts';
import StopStatusMasterIcon from '@/components/icons/master/statuses/StopStatusMasterIcon.vue';
import ReadyStatusMasterIcon from '@/components/icons/master/statuses/ReadyStatusMasterIcon.vue';
import ErrorStatusMasterIcon from '@/components/icons/master/statuses/ErrorStatusMasterIcon.vue';
import { mapActions, mapState } from 'pinia';
import VuexAdapter from '@/services/vuex-adapter.js';
import { DOC_ADD, DOC_CONTROLLER_SET, DOC_LINK_ADD, DOC_ROUTE_ADD, SYSTEM_COMMENT_ADD } from '@/configs/end-points.js';
import { COPY_ROUTE, NONE_ROUTE, STANDARD_ROUTE } from '@/configs/route-types.js';
import { CLIENTS_TABLE, DOCS_TABLE } from '@/configs/db-tables.js';
import { NotifyTypes } from '@/configs/notify-types.js';
import ExternalFile from '@/common/models/file/external-file';
import FileApi from '@/services/api/file-api.js';
import DocumentApi from '@/services/api/document-api.js';
import UpdatedFile from '@/common/models/file/updated-file';
import SpinnerUi from '@/components/ui/SpinnerUi.vue';
import { useUserStore } from '@/stores/user.js';
import { useMasterClientsStore } from '@/stores/master-clients.js';
import { useMasterCommentStore } from '@/stores/master-comment.js';
import { useMasterContentStore } from '@/stores/master-content.js';
import { useMasterControllerStore } from '@/stores/master-controller.js';
import { useMasterDeadlineStore } from '@/stores/master-deadline.js';
import { useMasterFilesStore } from '@/stores/master-files.js';
import { useMasterLinksStore } from '@/stores/master-links.js';
import { useMasterParametersStore } from '@/stores/master-parameters.js';
import { useMasterStepsStore } from '@/stores/master-steps.js';
import { useMasterTypeStore } from '@/stores/master-type.js';
import { useMasterStore } from '@/stores/master.js';
import { useActionsStore } from '@/stores/actions.js';
import { DeadlineType } from '@/common/enums/deadline-type.ts';

export default defineComponent({
  name: 'StepExecution',
  components: {
    SpinnerUi,
    ErrorStatusMasterIcon,
    ReadyStatusMasterIcon,
    StopStatusMasterIcon,
  },
  mixins: [StepMixin],
  data() {
    return {
      errors: [],
      stop: false,
      status: {
        doc: ExecutionStatus.Loading,
        links: ExecutionStatus.Loading,
        temps: ExecutionStatus.Loading,
        resolution: ExecutionStatus.Loading,
        files: ExecutionStatus.Loading,
        routes: ExecutionStatus.Loading,
        copy_route: ExecutionStatus.Loading,
      },
      performance: [
        {
          label: 'Сохранение регистрационной карточки документа',
          model: 'doc',
          required: true,
          handler: async () => {
            const deadline = {};
            if (this.hasDeadline) {
              deadline.plan_end_date = this.deadline;
            }
            const res = await this.addDoc({
              dt_id: this.type.code,
              p_id: this.project.code,
              controller_id: this.userId,
              content: this.content,
              comment: this.comment,
              mask_params: this.parametersRequest,
              ...deadline,
            });

            this.formDataProxy['doc_id'] = res.data.doc_id;
            this.change();
          },
        },
        {
          label: 'Организация связей',
          model: 'links',
          handler: async () => {
            for (const link of this.links) {
              await this.addDocLink({
                doc_id: this.formDataProxy.doc_id,
                parent_table_id: DOCS_TABLE,
                parent_id: link.id,
                lt_id: link.linkType.code,
              });
            }
            for (const client of this.clients) {
              await this.addDocLink({
                doc_id: this.formDataProxy.doc_id,
                parent_table_id: CLIENTS_TABLE,
                parent_id: client.id,
                lt_id: client.linkType.code,
              });
            }
          },
          hide: () => (!this.steps.links || !this.links.length) && (!this.steps.clients || !this.clients.length),
        },
        {
          label: 'Создание маршрута для документа',
          model: 'temps',
          handler: async () => {
            const routePointsResponsibles = [];

            this.formDataProxy.routes_responsible_unspecified_steps.forEach((step, number) => {
              const days =
                this.formDataProxy['routes_responsible_description_term_' + number] === DeadlineType.Days
                  ? this.formDataProxy['routes_responsible_description_term_days' + number]
                  : null;

              const end_date =
                this.formDataProxy['routes_responsible_description_term_' + number] === DeadlineType.Date
                  ? this.formDataProxy['routes_responsible_description_term_date' + number]
                  : null;

              routePointsResponsibles.push({
                RESPONSIBLE_TABLE_ID: this.formDataProxy['routes_responsible_' + number].data.RESPONSIBLE_TABLE_ID,
                RESPONSIBLE_ID: this.formDataProxy['routes_responsible_' + number].data.RESPONSIBLE_ID,
                description: this.formDataProxy['routes_responsible_description_' + number],
                comment: step['Примечание'],
                days,
                end_date,
              });
            });

            await this.addStandardRoute({
              DOC_ID: this.formDataProxy.doc_id,
              PARENT_DOC_ID: this.formDataProxy.route_doc_id.code,
              routePointsResponsibles,
            });
          },
          hide: () => this.formDataProxy.type_route !== STANDARD_ROUTE,
        },
        {
          label: 'Копирование маршрута из документа',
          model: 'copy_route',
          handler: async () => {
            await this.addStandardRoute({
              DOC_ID: this.formDataProxy.doc_id,
              PARENT_DOC_ID: this.formDataProxy.route_copy_doc_id.code,
            });
          },
          hide: () => this.formDataProxy.type_route !== COPY_ROUTE,
        },
        {
          label: 'Добавление резолюций',
          model: 'resolution',
          handler: async () => {
            await this.addCommentsAction({
              parent_table_id: DOCS_TABLE,
              parent_id: this.formDataProxy.doc_id,
              comment: this.formDataProxy.resolution,
            });
          },
          hide: () => !this.formDataProxy.resolution?.length,
        },
        {
          label: 'Добавление файлов',
          model: 'files',
          handler: async () => {
            await Promise.all(
              this.files.map(async (file) => {
                if (file instanceof ExternalFile || file instanceof UpdatedFile) {
                  return await FileApi.upload({
                    parentTableId: DOCS_TABLE,
                    parentId: this.formDataProxy.doc_id,
                    file,
                  });
                } else {
                  return await FileApi.move(file.fileId, this.formDataProxy.doc_id);
                }
              }),
            );
          },
          hide: () => !this.steps.files || !this.files.length,
        },
        {
          label: 'Остановка документа',
          model: 'routes',
          handler: async () =>
            await DocumentApi.stop(this.formDataProxy.doc_id, 'Остановлено автоматически при создании документа'),
          hide: () => this.formDataProxy.type_route !== NONE_ROUTE,
        },
        {
          label: 'Изменение контролера',
          model: 'controller',
          handler: async () => {
            this.setControllerAction({
              doc_id: this.formDataProxy.doc_id,
              login_id: this.controllerId,
            });
          },
          hide: () => !this.controllerDiffUser,
        },
      ],
    };
  },
  async mounted() {
    if (!this.formDataProxy.created) {
      this.setCreated();

      for (const step of this.performanceShowed) {
        this.status[step.model] = ExecutionStatus.Loading;
      }
      this.setControlsDisabled(true);

      await this.load();

      this.setControlsDisabled(false);

      if (!this.stop) {
        this.setCreatedDoc();
      }
    }
  },
  computed: {
    ...mapState(useUserStore, { userId: 'id' }),
    ...mapState(useMasterTypeStore, ['type', 'project']),
    ...mapState(useMasterContentStore, ['content']),
    ...mapState(useMasterFilesStore, ['files']),
    ...mapState(useMasterStepsStore, ['steps']),
    ...mapState(useMasterLinksStore, ['links']),
    ...mapState(useMasterClientsStore, ['clients']),
    ...mapState(useMasterCommentStore, ['comment']),
    ...mapState(useMasterControllerStore, ['controllerId', 'controllerDiffUser']),
    ...mapState(useMasterParametersStore, { parametersRequest: 'request' }),
    ...mapState(useMasterDeadlineStore, ['hasDeadline', 'deadline']),
    performanceShowed() {
      return this.performance.filter((p) => !p?.hide || !p.hide());
    },
    ExecutionStatus() {
      return ExecutionStatus;
    },
  },
  methods: {
    ...mapActions(useMasterStore, ['setControlsDisabled', 'setCreated']),
    ...mapActions(useActionsStore, {
      addDoc: VuexAdapter.getNameAction(DOC_ADD),
      addStandardRoute: VuexAdapter.getNameAction(DOC_ROUTE_ADD),
      addCommentsAction: VuexAdapter.getNameAction(SYSTEM_COMMENT_ADD),
      addDocLink: VuexAdapter.getNameAction(DOC_LINK_ADD),
      setControllerAction: VuexAdapter.getNameAction(DOC_CONTROLLER_SET),
    }),
    async load() {
      this.errors = [];

      for (const step of this.performanceShowed) {
        if (this.stop) {
          this.status[step.model] = ExecutionStatus.Stop;

          continue;
        }
        try {
          await step.handler();

          this.status[step.model] = ExecutionStatus.Ready;
        } catch (e) {
          console.error(e);
          this.errors.push(e.error_message || 'Ошибка в шаге: ' + step.label);

          this.status[step.model] = ExecutionStatus.Error;

          if (step?.required) {
            this.stop = true;
          }
        }
      }

      if (this.errors.length) {
        const one = this.errors.length === 1;
        this.$notify({
          type: NotifyTypes.Error,
          text: `При создании документа возникл${one ? 'а' : 'и'} ошибк${one ? 'а' : 'и'}.<br>${this.errors.join(
            '<br>',
          )}`,
        });
      }
    },
    setCreatedDoc() {
      this.formDataProxy.created = true;
      this.change();
    },
  },
});
</script>
