<template>
  <PreviewBlock
    title="Ближайшие документы"
    :amount="amount"
    :loading="loading"
  >
    <UploaderBlockClientPreview
      :end-point="endPoint"
      :order-by="'DOC_ID DESC'"
      :client-id="clientId"
      :component="DocumentsLinks"
      empty-text="Список контактов пуст"
    />
  </PreviewBlock>
</template>

<script>
import { defineComponent } from 'vue';
import UploaderBlockClientPreview from '@/components/clients/UploaderBlockClientPreview';
import { CLIENTS_DOCS_GET } from '@/configs/end-points.js';
import ClientsPreviewBlockMixin from '@/mixins/clients-preview-block-mixin.js';
import PreviewBlock from '@/components/doc/preview/PreviewBlock.vue';
import DocumentsLinks from '@/components/table/grouped/DocumentsLinks';

export default defineComponent({
  name: 'NearestDocsClientPreview',
  components: {
    PreviewBlock,
    UploaderBlockClientPreview,
  },
  mixins: [ClientsPreviewBlockMixin],
  props: {
    clientId: {
      type: Number,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    DocumentsLinks() {
      return DocumentsLinks;
    },
    endPoint() {
      return CLIENTS_DOCS_GET;
    },
  },
});
</script>
