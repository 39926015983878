<template>
  <div class="client-grouped">
    <div class="info-header">
      <h3>{{ row['Название'] }}</h3>
    </div>
    <div class="row">
      <div
        v-if="row['Номер']"
        class="column"
      >
        <span class="_muted label">№</span>
        <span>{{ row['Номер'] }}</span>
      </div>
      <div
        v-if="row['Создан']"
        class="column"
      >
        <span class="_muted label">создан:</span>
        <span>
          <Date
            :config="configs.info['Создан']"
            :data="row['Создан']"
          />
        </span>
      </div>
    </div>
    <div
      v-if="row['Тип']"
      class="row _spread"
    >
      <span
        v-if="!isTinyMode"
        class="_muted col-info"
      >
        Тип:
      </span>
      <span>{{ row['Тип'] }}</span>
    </div>
    <div
      v-if="row['Менеджер']"
      class="row"
    >
      <span
        v-if="!isTinyMode"
        class="_muted col-info"
      >
        Менеджер:
      </span>
      <span>{{ row['Менеджер'] }}</span>
    </div>
    <div
      v-if="row['Регион']"
      class="row"
    >
      <span
        v-if="!isTinyMode"
        class="_muted col-info"
      >
        Регион:
      </span>
      <span>{{ row['Регион'] }}</span>
    </div>
    <div
      v-if="row['Сегмент']"
      class="row"
    >
      <span
        v-if="!isTinyMode"
        class="_muted col-info"
      >
        Сегмент:
      </span>
      <span>{{ row['Сегмент'] }}</span>
    </div>
  </div>
</template>

<script>
import Constants from '@/configs/constants';
import DateColumn from '@/components/table/columns/DateColumn.vue';

export default {
  name: 'ClientGrouped',
  components: {
    Date: DateColumn,
  },
  props: ['dataTable', 'configs', 'row', 'componentWidth'],
  data: () => ({
    isTinyMode: false,
  }),
  watch: {
    componentWidth(val) {
      this.isTinyMode = val < Constants.resolutionPoints.tiny;
    },
  },
};
</script>

<style scoped lang="scss">
.client-grouped {
  width: 100%;
}

.info-header {
  margin-bottom: 12px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 4px;

    &._spread {
      margin-bottom: 12px;
    }
  }
}

.column {
  margin-right: 16px;
}

.label {
  margin-right: 8px;
}
</style>
