import { defineStore } from 'pinia';
import StoreId from '@/common/enums/store-id.js';

export const useMasterCommentStore = defineStore(StoreId.MasterComment, {
  state: () => ({
    comment: null,
  }),
  actions: {
    set(comment = null) {
      this.comment = comment;
    },
  },
});
