<template>
  <ModalUi
    ref="modal"
    title="Поставить на паузу"
    popup
  >
    <template #body> Вы собираетесь поставить документ на паузу. Продолжить?</template>

    <template #footer>
      <CancelButton @click="hide" />

      <ButtonUi @click="apply">
        <PauseCircleIcon v-prefix />
        <span>На паузу</span>
      </ButtonUi>
    </template>
  </ModalUi>
</template>

<script>
import { defineComponent } from 'vue';
import { mapActions } from 'pinia';
import CancelButton from '@/components/buttons/CancelButton.vue';
import VuexAdapter from '@/services/vuex-adapter.js';
import { DOC_ACTIVATION_SET, DOC_GANTT_ITEMS, DOCS_GET } from '@/configs/end-points.js';
import { NotifyTypes } from '@/configs/notify-types.js';
import ModalUi from '@/components/ui/ModalUi.vue';
import ModalMixin from '@/mixins/modal-mixin.js';
import ButtonUi from '@/components/ui/ButtonUi.vue';
import PauseCircleIcon from '@/assets/icons/circle/pause.svg';
import Emitter from '@/services/emitter.js';
import { useActionsStore } from '@/stores/actions.js';
import { usePreviewStore } from '@/stores/preview.js';

export default defineComponent({
  name: 'PauseDocModal',
  components: {
    PauseCircleIcon,
    ButtonUi,
    ModalUi,
    CancelButton,
  },
  mixins: [ModalMixin],
  props: {
    docId: {
      type: Number,
      required: true,
    },
    events: {
      type: Array,
    },
  },
  methods: {
    ...mapActions(useActionsStore, {
      controlsDocActivationAction: VuexAdapter.getNameAction(DOC_ACTIVATION_SET),
    }),
    ...mapActions(usePreviewStore, ['refreshPartlyPreviewAction']),
    apply() {
      this.showLoader();

      this.controlsDocActivationAction({
        doc_id: this.docId,
        active: 0,
      })
        .then(() => {
          this.throwEvents();
        })
        .catch((error) => {
          this.$notify({
            type: NotifyTypes.Error,
            text: 'При постановке документа на паузу возникла ошибка.',
            data: error,
          });
          this.hideLoader();
        });
    },
    throwEvents() {
      this.events.forEach((value) => Emitter.emit(value));
      this.refreshPartlyPreviewAction(DOCS_GET);
      this.refreshPartlyPreviewAction(DOC_GANTT_ITEMS);
      this.hide();
    },
  },
});
</script>
