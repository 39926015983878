<template>
  <div>
    <div class="info-header">
      <h3 class="title">
        {{ row['Тип'] }}
      </h3>

      <div class="right">
        <Identifier
          class="identifier"
          :config="configs.info['DS_ID']"
          :data="row['DS_ID']"
        />

        <ProgressCircleUi
          v-if="hasCompletePercent"
          :value="completePercent"
          completed-tooltip="Документ полностью исполнен, можно снять с контроля"
        />
      </div>
    </div>

    <div class="top-row">
      <div
        v-if="accessToRegistrationData && row['Рег.номер']"
        class="item"
      >
        <span
          v-if="!isTinyMode"
          class="label _muted"
        >
          Рег.номер:
        </span>
        <span>{{ row['Рег.номер'] }}</span>
      </div>

      <div
        v-if="accessToRegistrationData && row['Регистрация']"
        class="item"
      >
        <span
          v-if="!isTinyMode"
          class="label _muted"
        >
          Регистрация:
        </span>
        <Date
          :config="configs.info['Регистрация']"
          :data="row['Регистрация']"
        />
      </div>

      <div
        v-if="row['Срок']"
        class="item _flex"
      >
        <span
          v-if="!isTinyMode"
          class="label _muted"
        >
          Срок:
        </span>
        <Date
          :config="configs.info['Срок']"
          :data="row['Срок']"
        />
        <span
          v-if="row['Дней']"
          class="days-term-badge"
          :style="rowStyles"
        >
          {{ row['Дней'] }}
        </span>
      </div>

      <div class="item">
        <span
          v-if="!isTinyMode"
          class="label _muted"
        >
          Дата:
        </span>
        <Date
          :data="row['Дата']"
          :config="configs.info['Дата']"
        />
      </div>

      <div class="item">
        <span
          v-if="!isTinyMode"
          class="label _muted"
        >
          №:
        </span>
        <span>{{ row['Номер'] }}</span>
      </div>
    </div>

    <div
      v-if="row['Содержание'] || row['Исполнители']"
      class="center-row"
    >
      <div
        v-if="row['Содержание']"
        class="center-row-item"
      >
        <span
          v-if="!isTinyMode"
          class="_muted col-info"
        >
          Содержание:
        </span>
        <span v-html="content" />
      </div>

      <div
        v-if="row['Исполнители']"
        class="center-row-item"
      >
        <span
          v-if="!isTinyMode"
          class="_muted col-info"
        >
          Исполнители:
        </span>
        <b>{{ row['Исполнители'] }}</b>
      </div>
    </div>

    <div
      v-if="row['Комментарий']"
      class="row"
    >
      <span
        v-if="!isTinyMode"
        class="_muted col-info"
      >
        Комментарий:
      </span>
      <span>{{ row['Комментарий'] }}</span>
    </div>

    <div
      v-if="row['Описание']"
      class="row"
    >
      <span
        v-if="!isTinyMode"
        class="_muted col-info"
      >
        Описание:
      </span>
      <span>{{ row['Описание'] }}</span>
    </div>

    <div
      v-if="row['Проект']"
      class="row"
    >
      <span
        v-if="!isTinyMode"
        class="_muted col-info"
      >
        Проект:
      </span>
      <span>{{ row['Проект'] }}</span>
    </div>

    <div
      v-if="row['Контролер']"
      class="row"
    >
      <span
        v-if="!isTinyMode"
        class="_muted col-info"
      >
        Контролер:
      </span>
      <span>{{ row['Контролер'] }}</span>
    </div>
  </div>
</template>

<script>
import Constants from '@/configs/constants';
import { mapActions, mapState } from 'pinia';
import { DOCUMENTS__REGISTRATION_DATA } from '@/configs/events';
import DateColumn from '@/components/table/columns/DateColumn.vue';
import IdentifierColumn from '@/components/table/columns/IdentifierColumn.vue';
import { useMaskStore } from '@/stores/mask.js';
import { useRolesStore } from '@/stores/roles.js';
import ProgressCircleUi from '@/components/ui/ProgressCircleUi.vue';

export default {
  name: 'TableGroupedControls',
  props: ['dataTable', 'configs', 'row', 'componentWidth', 'rowStyles'],
  data: () => ({
    isTinyMode: false,
  }),
  mounted() {
    this.fetchMaskAction(this.row['Содержание_MASK']);
    this.isTinyMode = this.componentWidth < Constants.resolutionPoints.tiny;
  },
  updated() {
    this.fetchMaskAction(this.row['Содержание_MASK']);
  },
  computed: {
    ...mapState(useRolesStore, ['accessToEvent']),
    ...mapState(useMaskStore, ['maskStringGetter']),
    accessToRegistrationData() {
      return this.accessToEvent(DOCUMENTS__REGISTRATION_DATA);
    },
    content() {
      return this.maskStringGetter(this.row['Содержание_MASK'], this.row['Содержание']);
    },
    hasCompletePercent() {
      return this.row['Выполнение'] !== null;
    },
    completePercent() {
      return this.row['Выполнение'] || 0;
    },
  },
  components: {
    ProgressCircleUi,
    Date: DateColumn,
    Identifier: IdentifierColumn,
  },
  methods: {
    ...mapActions(useMaskStore, ['fetchMaskAction']),
  },
  watch: {
    componentWidth(val) {
      this.isTinyMode = val < Constants.resolutionPoints.tiny;
    },
  },
};
</script>

<style scoped lang="scss">
.title {
  margin-bottom: 12px;
  margin-right: 12px;
}

.right {
  margin-bottom: 8px;

  display: flex;
  align-items: center;
}

.identifier {
  &:not(:last-child) {
    margin-right: 12px;
  }
}

.top-row {
  margin-bottom: 12px;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}

.item {
  margin-right: 16px;
  margin-bottom: 4px;

  &._flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.label {
  margin-right: 8px;
}

.days-term-badge {
  margin-left: 12px;
}

.center-row {
  margin-bottom: 12px;
}

.center-row-item {
  margin-bottom: 8px;

  display: flex;
  justify-content: flex-start;
}

.row {
  margin-top: 8px;

  display: flex;
  justify-content: flex-start;
}
</style>
