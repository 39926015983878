<template>
  <div class="header-document-preview">
    <LogoIcon
      class="logo"
      alt="АЛЬФА ДОК"
    />

    <div class="content">
      <div class="block _left">
        <div class="block _wrap">
          <span class="item _muted">№</span>
          <a
            class="item"
            :href="'/document/' + getNumber"
            target="_blank"
          >
            {{ getNumber }}
          </a>
          <span
            v-if="hasDateDoc"
            class="item _muted"
          >
            от
          </span>
          <span
            v-if="hasDateDoc"
            class="item"
          >
            {{ getDateDoc }}
          </span>
        </div>

        <div
          v-if="accessToRegistrationData"
          class="block _wrap"
        >
          <div class="block">
            <span
              v-if="hasRegDate"
              class="item _muted"
            >
              Регистрация
            </span>
            <span
              v-if="hasRegDate"
              class="item"
            >
              {{ getRegDate }}
            </span>
          </div>

          <div class="block">
            <span
              v-if="hasRegNumber"
              class="item _muted"
            >
              Рег. №
            </span>
            <span
              v-if="hasRegNumber"
              class="item"
            >
              {{ getRegNumber }}
            </span>
          </div>
        </div>
      </div>

      <div class="block">
        <span
          v-if="hasTerm"
          class="item _muted"
        >
          Срок:
        </span>
        <span
          v-if="hasTerm"
          class="item"
        >
          {{ getTerm }}
        </span>
      </div>

      <div class="block">
        <span
          v-if="hasStatus"
          class="item _muted"
        >
          Статус:
        </span>
        <span
          v-if="hasStatus"
          class="item"
        >
          {{ getStatus }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { getDate } from '@/common/utils/utils.js';
import { mapState } from 'pinia';
import { DOCUMENTS__REGISTRATION_DATA } from '@/configs/events';
import LogoIcon from '@/assets/icons/logo.svg';
import { useRolesStore } from '@/stores/roles.js';

export default {
  name: 'HeaderDocumentPreview',
  components: {
    LogoIcon,
  },
  props: ['doc'],
  methods: {
    getDate,
  },
  computed: {
    ...mapState(useRolesStore, ['accessToEvent']),
    accessToRegistrationData() {
      return this.accessToEvent(DOCUMENTS__REGISTRATION_DATA);
    },
    hasRegNumber() {
      return this.doc['Рег.номер'];
    },
    getRegNumber() {
      return this.doc['Рег.номер'];
    },
    hasTerm() {
      return this.doc['Срок'] && this.doc['Срок'] !== '';
    },
    hasStatus() {
      return this.doc['Статус'] && this.doc['Статус'] !== '';
    },
    getNumber() {
      return this.doc['Номер'];
    },
    hasRegDate() {
      return this.doc['Регистрация'] !== null && this.doc['Регистрация'] !== '';
    },
    getRegDate() {
      return this.getDate(this.doc['Регистрация'], 'dd.MM.yyyy');
    },
    hasDateDoc() {
      return this.doc['Дата'] !== null && this.doc['Дата'] !== '';
    },
    getDateDoc() {
      return this.getDate(this.doc['Дата'], 'dd.MM.yyyy, HH:mm');
    },
    getTerm() {
      return this.getDate(this.doc['Срок'], 'dd.MM.yyyy');
    },
    getStatus() {
      return this.doc['Статус'];
    },
  },
};
</script>

<style scoped lang="scss">
.header-document-preview {
  padding-bottom: 4px;
  display: flex;

  border-bottom: 1px solid var(--color-gray-100);
}

.logo {
  margin-right: 24px;
  width: 32px;
  height: 32px;
}

.content {
  flex-grow: 1;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.block {
  display: flex;

  &:not(:last-child) {
    margin-right: 4px;
  }

  &._left {
    max-width: 280px;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  &._wrap {
    flex-wrap: wrap;
  }
}

.item {
  &:not(:last-child) {
    margin-right: 8px;
  }
}
</style>
