<template>
  <ModalUi
    ref="modal"
    title="Добавление адреса"
  >
    <template #body>
      <FormBuilder
        ref="formBuilder"
        v-model="form"
        margins
        :fields="fields"
      />
    </template>

    <template #footer>
      <CancelButton @click="hide" />
      <AddButton @click="apply" />
    </template>
  </ModalUi>
</template>

<script>
import { defineComponent } from 'vue';
import { mapActions, mapState } from 'pinia';
import CancelButton from '@/components/buttons/CancelButton.vue';
import VuexAdapter from '@/services/vuex-adapter.js';
import { ADDRESS_ADD, ADDRESS_TYPES_GET, CLIENTS_ADDRESSES_GET } from '@/configs/end-points.js';
import FormBuilder from '@/components/form/FormBuilder.vue';
import addressClientsForm from '@/configs/forms/clients/address';
import { NotifyTypes } from '@/configs/notify-types.js';
import ModalUi from '@/components/ui/ModalUi.vue';
import ModalMixin from '@/mixins/modal-mixin.js';
import AddButton from '@/components/buttons/AddButton.vue';
import { useActionsStore } from '@/stores/actions.js';
import { usePreviewStore } from '@/stores/preview.js';
import { useListsStore } from '@/stores/lists.js';

const form = {
  comment: '',
  address_type: null,
  address: '',
};

export default defineComponent({
  name: 'AddAddressModal',
  components: {
    AddButton,
    ModalUi,
    FormBuilder,
    CancelButton,
  },
  mixins: [ModalMixin],
  props: {
    parentId: {
      type: Number,
      required: true,
    },
    parentTableId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    form,
    fields: [],
  }),
  mounted() {
    this.getClientAddressTypesAction({
      where: 'PARENT_TABLE_ID = ' + this.parentTableId,
    });
    this.fields = addressClientsForm(this);
  },
  computed: {
    ...mapState(useListsStore, {
      clientAddressTypesGetter: VuexAdapter.getNameSelectGetter(ADDRESS_TYPES_GET),
    }),
  },
  methods: {
    ...mapActions(useActionsStore, {
      addAddressAction: VuexAdapter.getNameAction(ADDRESS_ADD),
    }),
    ...mapActions(useListsStore, {
      getClientAddressTypesAction: VuexAdapter.getNameAction(ADDRESS_TYPES_GET),
    }),
    ...mapActions(usePreviewStore, ['refreshPartlyPreviewAction']),
    apply() {
      if (!this.$refs.formBuilder.validate()) {
        return;
      }

      const data = {
        ...this.form,
        parent_id: this.parentId,
        parent_table_id: this.parentTableId,
        address_type: this.form.address_type?.label,
      };

      this.showLoader();

      this.addAddressAction(data)
        .then(() => {
          this.refreshPartlyPreviewAction(CLIENTS_ADDRESSES_GET);
          this.hide();
          this.form = form;
        })
        .catch((error) => {
          this.$notify({
            type: NotifyTypes.Error,
            text: 'При добавлении адреса возникла ошибка.',
            data: error,
          });
          this.hideLoader();
        });
    },
  },
});
</script>
