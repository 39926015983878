<template>
  <ModalUi
    ref="modal"
    title="Новая точка маршрута"
    prevent-closure
    @shown="onShown"
    @hidden="onHidden"
  >
    <template #body>
      <div class="info">
        Введите информацию о точке маршрута.<br />
        Учтите, что документ начнет двигаться по маршруту только после его отправки в работу.
      </div>

      <FormBuilder
        ref="formBuilder"
        v-model="form"
        margins
        :fields="routePointFormFields"
        @update:model-value="changeFrom = true"
      >
        <template #copy-content>
          <ButtonUi
            color="black"
            mode="outline"
            :disabled="!doc?.['Содержание']?.length"
            @click="copyContent"
          >
            <CopyIcon />
          </ButtonUi>
        </template>

        <template #copy-comment>
          <ButtonUi
            color="black"
            mode="outline"
            :disabled="!doc?.['Комментарий']?.length"
            @click="copyComment"
          >
            <CopyIcon />
          </ButtonUi>
        </template>

        <template #rpt_id:list-option-content="{ option }">
          <div :class="getRoutePointTypeOptionClass(option)">
            {{ option.label }}
          </div>
        </template>
        <template #rpt_id:selected-option="{ option }">
          <div :class="{ ...getRoutePointTypeOptionClass(option), 'rpt-id-selected-option': true }">
            {{ option.label }}
          </div>
        </template>
      </FormBuilder>

      <AttentionModal
        ref="attentionAddRoutePointModal"
        @agree="hide"
      >
        <p>Вы собираетесь закрыть окно</p>
        <p>"Новая точка маршрута"</p>
        <p>Введенные данные не будут сохранены.</p>
        <p>Продолжить?</p>
      </AttentionModal>
    </template>

    <template #footer>
      <CheckboxForm
        v-model="closeForm"
        label="Закрывать форму"
      />

      <div
        class="buttons"
        style="margin: 0 0 0 auto"
      >
        <CancelButton
          class="button"
          @click="showAttentionAddRoutePointModal"
        />
        <AddButton
          class="button"
          @click="apply"
        />
      </div>
    </template>
  </ModalUi>
</template>

<script>
import { defineComponent } from 'vue';
import CancelButton from '@/components/buttons/CancelButton.vue';
import { mapActions, mapState } from 'pinia';
import RoutePointType from '@/configs/route-point-types.js';
import VuexAdapter from '@/services/vuex-adapter.js';
import {
  DOC_GANTT_ITEMS,
  DOC_ROUTE_POINT_ADD,
  DOCS_GET,
  ROUTES_POINTS_RESPONSIBLE_DIRECTORY_GET,
} from '@/configs/end-points.js';
import FormBuilder from '@/components/form/FormBuilder';
import CheckboxForm from '@/components/form/CheckboxForm';
import { fullTrim } from '@/common/utils/utils.js';
import { DateTime } from 'luxon';
import AttentionModal from '@/components/modals/AttentionModal';
import { NotifyTypes } from '@/configs/notify-types.js';
import ModalUi from '@/components/ui/ModalUi.vue';
import ModalMixin from '@/mixins/modal-mixin.js';
import CopyIcon from '@/assets/icons/copy.svg';
import AddButton from '@/components/buttons/AddButton.vue';
import ButtonUi from '@/components/ui/ButtonUi.vue';
import { DeadlineType } from '@/common/enums/deadline-type.ts';
import { getRoutePointFormFields } from './';
import { getStartOfDayDate } from '@/common/utils/date';
import Emitter from '@/services/emitter.js';
import { useAfterRunRoutePointStore } from '@/stores/after-run-route-point.js';
import { useActionsStore } from '@/stores/actions.js';
import { useSystemStore } from '@/stores/system.js';
import { usePreviewStore } from '@/stores/preview.js';
import { useRowStore } from '@/stores/row.js';
import { useListsStore } from '@/stores/lists.js';

const server = 'server';
const all = 'all';

const TermFromEnum = Object.freeze({
  WorkingDays: 'days',
  EndDate: 'end_date',
});

const typesOptions = Object.freeze([
  {
    code: RoutePointType.assignment,
    label: 'Поручение',
  },
  {
    code: RoutePointType.agreement,
    label: 'Согласование',
  },
  {
    code: RoutePointType.notification,
    label: 'Уведомление',
  },
]);

const form = {
  links: [],
  responsible_list: [],
  rpt_id: null,
  deadline: {
    type: DeadlineType.WorkingDays,
    workingDays: 1,
    workingDaysDate: null,
    days: 1,
    daysDate: null,
    date: null,
  },
  content: '',
  comment: '',
  responsibleFrom: server,
};

export default defineComponent({
  name: 'AddRoutePointModal',
  components: {
    ButtonUi,
    AddButton,
    CopyIcon,
    ModalUi,
    AttentionModal,
    CheckboxForm,
    FormBuilder,
    CancelButton,
  },
  mixins: [ModalMixin],
  props: {
    docId: {
      type: Number,
      required: true,
    },
    rptId: {
      type: Number,
      default: null,
    },
    parents: {
      type: String,
      default: null,
    },
  },
  data() {
    const routePointFormFields = getRoutePointFormFields(this);

    return {
      comment: '',
      server,
      all,
      typesOptions: typesOptions,
      form: { ...form },
      routePointFormFields,
      closeForm: true,
      doc: null,
      changeFrom: false,
      TermFromEnum,
      RoutePointType,
    };
  },
  computed: {
    ...mapState(useAfterRunRoutePointStore, ['listAfterRunRoutePoint']),
    ...mapState(useSystemStore, ['serverId', 'systemRPLObjects']),
    ...mapState(useListsStore, {
      routePointResponsibleGetter: VuexAdapter.getNameGetter(ROUTES_POINTS_RESPONSIBLE_DIRECTORY_GET),
    }),
    // Partial<typeof form>
    deadlineToFormDateInfoMap() {
      const { deadline } = this.form;

      if (deadline.type === DeadlineType.WorkingDays) {
        return {
          [TermFromEnum.WorkingDays]: deadline.workingDays,
          termFrom: TermFromEnum.WorkingDays,
          [TermFromEnum.EndDate]: null,
        };
      }

      return {
        [TermFromEnum.EndDate]: deadline.type === DeadlineType.Days ? deadline.daysDate : deadline.date,
        termFrom: TermFromEnum.EndDate,
        [TermFromEnum.WorkingDays]: null,
      };
    },
    fetchAfterRunRoutePointActionParams() {
      return {
        doc_id: this.docId,
      };
    },
    showRunAfter() {
      return !this.parents;
    },
    optionsResponsible() {
      const options = [];
      this.routePointResponsibleGetter.forEach((responsible) => {
        if (this.form.responsibleFrom === responsible['Сервер'] && this.serverId !== responsible['Сервер']) {
          return;
        }
        options.push({
          code: `${responsible.RESPONSIBLE_TABLE_ID}_${responsible.RESPONSIBLE_ID}`,
          label: responsible['Исполнитель'],
          data: {
            responsible_table_id: responsible.RESPONSIBLE_TABLE_ID,
            responsible_id: responsible.RESPONSIBLE_ID,
          },
        });
      });

      return options;
    },
    afterRunRoutePointOptions() {
      return (this.listAfterRunRoutePoint || []).map((item) => {
        const routePointTypeName = this.typesOptions.find(({ code }) => code == item.RPT_ID).label;

        return {
          code: item.RP_ID,
          label: `${item['Номер']}. ${routePointTypeName} - ${item['Исполнитель']}`,
          endDate: new Date(item['Срок']),
        };
      });
    },
    startDate() {
      if (!this.form.links.length) {
        return getStartOfDayDate();
      }

      return this.form.links.reduce((acc, item) => {
        if (!acc) {
          return item.endDate;
        }

        return acc > item.endDate ? acc : item.endDate;
      }, null);
    },
  },
  methods: {
    ...mapActions(useAfterRunRoutePointStore, {
      fetchAfterRunRoutePointAction: 'fetchAfterRunRoutePointAction',
      clearAfterRunRoutePointAction: '$reset',
    }),
    ...mapActions(usePreviewStore, ['refreshPartlyPreviewAction']),
    ...mapActions(useActionsStore, {
      docRoutePointAddAction: VuexAdapter.getNameAction(DOC_ROUTE_POINT_ADD),
    }),
    ...mapActions(useListsStore, {
      getRoutePointResponsibleAction: VuexAdapter.getNameAction(ROUTES_POINTS_RESPONSIBLE_DIRECTORY_GET),
    }),
    ...mapActions(useRowStore, {
      fetchDocumentAction: VuexAdapter.getNameRowOnlyFetchAction(DOCS_GET),
    }),
    ...mapActions(useSystemStore, ['systemRPLObjectsCheckAction']),
    showAttentionAddRoutePointModal() {
      if (!this.changeFrom) {
        this.hide();

        return;
      }

      this.$refs.attentionAddRoutePointModal.show();
    },
    async onShown() {
      this.form = { ...form };
      this.showLoader();

      this.fetchDocumentAction(this.docId).then((r) => (this.doc = r));

      await Promise.all([this.getRoutePointResponsibleAction(), this.systemRPLObjectsCheckAction()]);

      if (this.showRunAfter) {
        await this.fetchAfterRunRoutePointAction(this.fetchAfterRunRoutePointActionParams);
      }

      if (this.rptId) {
        this.typesOptions.forEach((type) => {
          if (type.code == this.rptId) {
            this.form.rpt_id = type;
          }
        });
      }
      this.hideLoader();
      this.changeFrom = false;
    },
    async onHidden() {
      // нужно, чтобы clearAfterRunRoutePointAction не триггерил
      // перевычисление computed свойств завязанных на форме в ее
      // промежуточном состоянии из-за триггера от FormBuilder
      await this.$nextTick();
      this.clearAfterRunRoutePointAction();
    },
    async apply() {
      try {
        if (!this.$refs.formBuilder.validate()) {
          return;
        }
        this.showLoader();

        const { deadline: _, ...newRoutePointData } = this.form;

        const data = {
          ...newRoutePointData,
          ...this.deadlineToFormDateInfoMap,
          doc_id: this.docId,
          rpt_id: this.form.rpt_id.code,
          links:
            this.showRunAfter && this.form.links && this.form.links.length
              ? '#' + this.form.links.map((item) => item.code).join('##') + '#'
              : null,
        };

        if (this.parents) {
          data.parents = this.parents;
        }

        for (let responsible of this.form.responsible_list) {
          await this.docRoutePointAddAction({
            ...data,
            responsible_table_id: responsible.data.responsible_table_id,
            responsible_id: responsible.data.responsible_id,
          });

          this.$notify({
            text: `Новая точка маршрута документа №${this.docId}, исполнитель: ${responsible.label} добавлена успешно`,
            type: NotifyTypes.Success,
          });
        }

        this.refreshPartlyPreviewAction(DOC_GANTT_ITEMS);

        Emitter.emit('table-trigger-refresh-only-state');

        if (this.closeForm) {
          this.hide();
          return;
        }

        if (this.showRunAfter) {
          this.clearAfterRunRoutePointAction();
          await this.fetchAfterRunRoutePointAction(this.fetchAfterRunRoutePointActionParams);
          this.form.links = [];
        }
      } catch (error) {
        this.$notify({
          type: NotifyTypes.Error,
          text: 'При добавлении возникла ошибка.',
          data: error,
        });
      } finally {
        this.hideLoader();
      }
    },
    copyContent() {
      let content = fullTrim(this.doc['Содержание'] || '');
      if (content.length > 100) {
        content = content.slice(0, 97) + '...';
      }

      this.form.content = content;
    },
    copyEndDate() {
      if (this.doc['Срок']) {
        this.form.deadline.date = DateTime.fromFormat(this.doc['Срок'], 'yyyy-MM-dd HH:mm:ss').toFormat('yyyy-MM-dd');
      }
    },
    copyComment() {
      let comment = fullTrim(this.doc['Комментарий'] || '');
      if (comment.length > 250) {
        comment = comment.slice(0, 247) + '...';
      }

      this.form.comment = comment;
    },
    getRoutePointTypeOptionClass(option) {
      return {
        'table-badge': true,
        'badge-assignment': option.code === RoutePointType.assignment,
        'badge-coordination': option.code === RoutePointType.agreement,
        'badge-notification': option.code === RoutePointType.notification,
      };
    },
  },
  watch: {
    fetchAfterRunRoutePointActionParams: {
      handler() {
        this.fetchAfterRunRoutePointAction(this.fetchAfterRunRoutePointActionParams);
      },
    },
  },
});
</script>

<style scoped lang="scss">
.info {
  padding-top: 10px;
  margin-bottom: 24px;

  color: var(--color-gray-1000);
  font-size: 14px;
}

.checkbox-form {
  margin-right: auto !important;
}

.buttons {
  display: flex;
}

.button {
  &:not(:last-child) {
    margin-right: 8px;
  }
}

/* TODO: Отказаться от перекрытия стилей */
:deep(.checkmark) {
  margin-top: -3px;
}

.rpt-id-selected-option {
  width: min-content;
}
</style>
