<template>
  <span>
    <span class="field-title">{{ configs_table.title }}:</span>
    <span
      v-for="selected in config"
      :key.prop="selected"
      class="field-value"
    >
      <span
        v-if="selected.algorithm !== null"
        class="condition"
      >
        {{ selected.algorithm ? 'или' : 'и' }}
      </span>
      <span class="label">{{ getTextByKey(selected.select).label }}</span>
      <span
        v-if="selected.input !== null"
        class="value"
      >
        {{ selected.input }}
      </span>
    </span>
  </span>
</template>

<script>
import IntegerFilter from '@/components/table/filters/IntegerFilter';

export default {
  name: 'IntegerFilterBadge',
  props: ['key_column', 'config', 'configs_table'],
  data: () => ({
    IdentifierFilter: IntegerFilter,
  }),
  methods: {
    getTextByKey(selected) {
      return IntegerFilter.data().options.find((obg) => {
        return obg.code === selected.code;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.field-title,
.condition,
.label,
.value {
  margin-right: 4px;
}
</style>
