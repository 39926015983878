<template>
  <div class="file-preview-pagination">
    <ButtonIconUi
      color="gray"
      :disabled="current === 1"
      @click="$emit('prev')"
    >
      <ChevronIcon class="chevron chevron_left" />
    </ButtonIconUi>

    <ButtonIconUi
      color="gray"
      :disabled="current === count"
      @click="$emit('next')"
    >
      <ChevronIcon class="chevron chevron_right" />
    </ButtonIconUi>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import ButtonIconUi from '@/components/ui/ButtonIconUi.vue';
import ChevronIcon from '@/assets/icons/chevron.svg';

export default defineComponent({
  name: 'FilePreviewPagination',
  components: {
    ButtonIconUi,
    ChevronIcon,
  },
  props: {
    current: {
      type: Number,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
  },
  emits: ['prev', 'next'],
});
</script>

<style scoped lang="scss">
.file-preview-pagination {
  display: flex;

  &:not(:last-child) {
    margin-right: 8px;
  }
}

.chevron {
  &_left {
    transform: rotate(90deg);
  }

  &_right {
    transform: rotate(-90deg);
  }
}
</style>
