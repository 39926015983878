<script setup>
import { nextTick, ref } from 'vue';
import TabUi from '@/components/ui/TabUi.vue';
import UploadPreview from '@/components/document-master/UploadPreview.vue';
import TabsUi from '@/components/ui/TabsUi.vue';
import DropZoneUi from '@/components/ui/DropZoneUi.vue';
import UploadPreviewsNewTab from '@/components/document-master/UploadPreviewsNewTab.vue';
import FileTruncatedName from '@/components/doc/files/FileTruncatedName.vue';
import useFileInput from '@/composables/use-file-input.js';
import { instance } from '@/common/utils/props-validators';
import { BaseFile } from '@/common/models/file/base-file';
import FileEditorScope from '@/common/enums/file-editor-scope.js';
import useFileEditor from '@/composables/use-file-editor.js';
import StorageApi from '@/services/api/storage-api.js';
import useAbort from '@/composables/use-abort.js';
import { useFileEditorStore } from '@/stores/file-editor.js';
import { useRouter } from 'vue-router';

defineProps({
  parentTableId: {
    type: Number,
    required: true,
  },
  parentId: {
    type: Number,
    required: true,
  },
});

const files = defineModel({
  type: Array,
  default: () => [],
  validator: instance(BaseFile),
});

const router = useRouter();

const fileEditorStore = useFileEditorStore();

const { signal } = useAbort();

const tabs = ref();

useFileEditor(onFilesChange, FileEditorScope.Master);

const { input, dispatchChange, onChange, add, replace, remove } = useFileInput({ files, update, afterAdd });

function update(newFiles) {
  files.value = newFiles;
}

function afterAdd(addingIndex) {
  nextTick(() => tabs.value.setActive(addingIndex));
}

function setActiveLastTab() {
  tabs.value.setActive('last');
}

function onFileChange(file) {
  const newValue = files.value.map((current) => (current.id === file.id ? file : current));
  update(newValue);
}

function edit(file) {
  fileEditorStore.addFile({ file, router, scope: FileEditorScope.Master });
}

function select(file) {
  if (file.canEdit) {
    edit(file);
  } else {
    StorageApi.download(file, signal.value);
  }
}

function onFilesChange(files) {
  const file = files[0];

  if (file) {
    replace(file);
    fileEditorStore.clearFileUrl(file.id);
  }
}
</script>

<template>
  <DropZoneUi
    class="upload-previews"
    text-size="l"
    @drop="add"
  >
    <input
      ref="input"
      hidden
      type="file"
      multiple
      @change="onChange"
    />

    <TabsUi
      ref="tabs"
      dynamic
      full-height
      @remove="remove($event.data.id)"
    >
      <template #title="{ active, newTab, data }">
        <template v-if="newTab">Новый файл</template>

        <template v-else>
          <component
            :is="data.icon"
            v-prefix="'s'"
            class="icon"
            :class="{ _active: active }"
          />
          <FileTruncatedName :file="data" />
        </template>
      </template>

      <TabUi
        v-for="file in files"
        :key="file.id"
        :data="file"
      >
        <UploadPreview
          :parent-id="parentId"
          :parent-table-id="parentTableId"
          :file="file"
          @upload="onFileChange"
          @edit="edit"
        />
      </TabUi>

      <template #new-tab>
        <UploadPreviewsNewTab
          :show-close="!!files.length"
          @add="dispatchChange"
          @select="select"
          @close="setActiveLastTab"
        />
      </template>
    </TabsUi>
  </DropZoneUi>
</template>

<style scoped lang="scss">
.icon {
  flex-shrink: 0;
}

.upload-preview {
  height: 100%;
}
</style>
