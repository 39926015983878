import CacheGroup from '@/common/enums/cache-group.js';
import { defineStore } from 'pinia';
import StoreId from '@/common/enums/store-id.js';

export const useCacheStore = defineStore(StoreId.Cache, {
  state: () => ({
    values: {},
  }),
  getters: {
    get(state) {
      return ({ group, key }) => state.values[group || CacheGroup.Common]?.[key];
    },
  },
  actions: {
    add({ group, key, value }) {
      const groupKey = group || CacheGroup.Common;

      this.values = {
        ...this.values,
        [groupKey]: {
          ...this.values[groupKey],
          [key]: value,
        },
      };
    },
    remove({ group, key }) {
      const groupKey = group || CacheGroup.Common;

      const { [groupKey]: groupState, ...rest } = this.values;

      if (!key) {
        this.values = rest;
        return;
      }

      const { [key]: _, ...groupRest } = groupState;

      this.values = {
        ...rest,
        [groupKey]: groupRest,
      };
    },
  },
});
