<template>
  <div class="assign-barcode-step-master">
    <FormBuilder
      ref="form"
      v-model="formDataProxy"
      :fields="fields"
      @update:model-value="change"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import StepMixin from '@/mixins/document-master/step-mixin.js';
import FormBuilder from '@/components/form/FormBuilder.vue';
import { TYPE_BOOL, TYPE_STRING } from '@/configs/form';

export default defineComponent({
  name: 'StepBarcode',
  components: { FormBuilder },
  mixins: [StepMixin],
  data() {
    return {
      fields: [
        [
          {
            type: TYPE_STRING,
            name: 'barcode',
            placeholder: 'Введите значение штрих-кода',
            disabled: true,
          },
        ],
        [
          {
            type: TYPE_BOOL,
            name: 'skip_barcode',
            label: 'Пропустить этот шаг. Вы можете сделать это позднее.',
            disabled: true,
          },
        ],
      ],
    };
  },
});
</script>
