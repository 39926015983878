<script setup>
import DropZoneUi from '@/components/ui/DropZoneUi.vue';
import CardListUi from '@/components/ui/CardListUi.vue';
import FileFooter from '@/components/common/FileFooter.vue';
import UploadButton from '@/components/buttons/UploadButton.vue';
import { instance } from '@/common/utils/props-validators';
import useFileInput from '@/composables/use-file-input.js';
import { computed } from 'vue';
import { BaseFile } from '@/common/models/file/base-file';

const props = defineProps({
  error: String,
  readonly: {
    type: Boolean,
    default: false,
  },
});

const files = defineModel({
  type: Array,
  default: () => [],
  validator: instance(BaseFile),
});

const readonly = computed(() => props.readonly);

const { input, dispatchChange, onChange, add, remove } = useFileInput({ files, readonly, update });

function update(newFiles) {
  files.value = newFiles;
}

defineExpose({ add });
</script>

<template>
  <DropZoneUi
    class="upload-ui"
    :class="{ _error: error }"
    size="s"
    :disabled="readonly"
    @drop="add"
  >
    <input
      ref="input"
      hidden
      type="file"
      multiple
      @change="onChange"
    />

    <CardListUi
      v-if="files.length"
      :list="files"
      :card-footer="FileFooter"
      :readonly="readonly"
      title-key="name"
      with-add
      @add="dispatchChange"
      @remove="remove"
    />

    <div
      v-else
      class="empty"
      @click="dispatchChange"
    >
      <UploadButton :disabled="readonly" />

      <p class="empty-text">Выберите и загрузите файл с компьютера или перетащите в это поле</p>
    </div>

    <Transition name="opacity-fast">
      <span
        v-if="error"
        class="error"
      >
        {{ error }}
      </span>
    </Transition>
  </DropZoneUi>
</template>

<style scoped lang="scss">
.drop-zone-ui {
  display: flex;
  justify-content: center;

  border: var(--border-dashed-gray-400);
  border-radius: 8px;

  transition:
    color var(--transition-fast),
    border var(--transition-fast);

  &._error {
    color: var(--color-red-900);
    border: var(--border-dashed-red-900);
  }
}

.card-list-ui {
  overflow-y: auto;
  min-height: 120px;
}

.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.button-ui {
  margin-bottom: 16px;
}

.empty-text {
  width: 300px;

  text-align: center;
  color: var(--color-gray-600);
  font-size: var(--font-size-l);
  line-height: var(--line-height-l);
}

.error {
  position: absolute;
  left: 8px;
  bottom: 4px;

  font-size: var(--font-size-s);
  line-height: var(--line-height-s);
  color: var(--color-red-900);
}
</style>
