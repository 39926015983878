export default {
  Actions: 'actions',
  AfterRunRoutePoint: 'after-run-route-point',
  Auth: 'auth',
  Autocorrect: 'autocorrect',
  Cache: 'cache',
  Colors: 'color',
  Commissions: 'commissions',
  Common: 'common',
  Controls: 'controls',
  FileEditor: 'file-editor',
  Index: 'index',
  Lists: 'lists',
  Mask: 'mask',
  Master: 'master',
  MasterClients: 'master-clients',
  MasterComment: 'master-comment',
  MasterContent: 'master-content',
  MasterContractors: 'master-contractors',
  MasterController: 'master-controller',
  MasterDeadline: 'master-deadline',
  MasterFiles: 'master-files',
  MasterLinks: 'master-links',
  MasterParameters: 'master-parameters',
  MasterSteps: 'master-steps',
  MasterType: 'master-type',
  Preview: 'preview',
  PreviewFiles: 'preview-files',
  Roles: 'roles',
  Row: 'row',
  Sign: 'sign',
  Split: 'split',
  System: 'system',
  Table: 'table',
  User: 'user',
  Version: 'version',
};
