<template>
  <div class="search-multiple-ui">
    <SearchUi
      ref="search"
      v-model="searchModel"
      :placeholder="placeholder"
      :get-options="getNewOptions"
      @update:model-value="search"
    />

    <div
      class="wrapper"
      :class="{ _scroll: scroll }"
    >
      <CardListUi
        v-if="modelValue.length"
        :list="modelValue"
        :card-footer="cardFooter"
        @change="change"
        @remove="remove"
      />

      <ButtonUi
        v-else
        color="black"
        mode="outline"
        @click="$refs.search.focus()"
      >
        <component
          :is="addIcon"
          v-prefix
        />
        <span>{{ addText }}</span>
      </ButtonUi>

      <LoaderUi v-if="isLoading" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import LoaderUi from '@/components/ui/LoaderUi.vue';
import CardListUi from '@/components/ui/CardListUi.vue';
import ButtonUi from '@/components/ui/ButtonUi.vue';
import SearchUi from '@/components/ui/SearchUi.vue';
import AbortMixin from '@/mixins/abort-mixin.js';
import { CanceledError } from 'axios';
import { NotifyTypes } from '@/configs/notify-types.js';
import { keys } from '@/common/utils/props-validators';

export default defineComponent({
  name: 'SearchMultipleUi',
  components: {
    SearchUi,
    ButtonUi,
    CardListUi,
    LoaderUi,
  },
  mixins: [AbortMixin],
  props: {
    modelValue: {
      type: Array,
      default: () => [],
      validator: keys('id', 'title', 'icon'),
    },
    placeholder: {
      type: String,
      default: 'Выберите значение',
    },
    addIcon: {
      type: Object,
      required: true,
    },
    addText: {
      type: String,
      default: 'Добавить',
    },
    getOptions: {
      type: Function,
      required: true,
    },
    scroll: {
      type: Boolean,
      default: false,
    },
    cardFooter: Object,
    getCard: {
      type: Function,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      searchModel: null,
      isLoading: false,
    };
  },
  methods: {
    async getNewOptions(...args) {
      const options = await this.getOptions(...args);
      return options.filter((option) => this.modelValue.every((current) => current.id !== option.code));
    },
    async search(option) {
      if (!option) {
        return;
      }

      this.$nextTick(() => (this.searchModel = null));
      this.isLoading = true;

      try {
        const item = await this.getCard(option.code, this.abortController.signal);
        this.$emit('update:modelValue', [...this.modelValue, item]);
        this.isLoading = false;
      } catch (error) {
        if (error instanceof CanceledError) {
          return;
        }

        this.$notify({
          type: NotifyTypes.Error,
          text: `При получении опции для поля "${this.placeholder}" возникла ошибка.`,
          data: error,
        });
        this.isLoading = false;
      }
    },
    change({ item, changes }) {
      const newValue = this.modelValue.map((current) => (current.id === item.id ? { ...item, ...changes } : current));
      this.$emit('update:modelValue', newValue);
    },
    remove(id) {
      const newValue = this.modelValue.filter((current) => current.id !== id);
      this.$emit('update:modelValue', newValue);
    },
  },
});
</script>

<style scoped lang="scss">
.search-multiple-ui {
  display: flex;
  flex-direction: column;
}

.search-ui {
  margin-bottom: 8px;
}

.wrapper {
  flex-grow: 1;

  position: relative;

  min-height: 120px;

  display: flex;
  justify-content: center;

  border: var(--border-dashed-gray-400);
  border-radius: 8px;

  &._scroll {
    overflow-y: auto;
  }
}

.button-ui {
  margin: auto;
  align-self: center;
}

.loader-ui {
  border-radius: 8px;
}
</style>
