<!-- FIXME: Объединить все Badge -->
<template>
  <span class="table-badge badge-assignment">{{ value }}</span>
</template>

<script>
export default {
  name: 'BadgeSuccess',
  props: ['value'],
};
</script>
