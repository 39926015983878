import { BaseFile } from '@/common/models/file/base-file';
import State from '@/common/enums/state.js';
import axios from 'axios';

export default class UpdatedFile extends BaseFile {
  constructor(file) {
    super(file.id, file.name);
    this.documentId = file.documentId;
    this.url = file.url;
    this.state = State.Undefined;
  }

  async upload() {
    this._uploadPromise ||= this._setBase64();
    await this._uploadPromise;
  }

  async _setBase64() {
    const { data: blob } = await axios.get(this.url, { responseType: 'blob' });
    this.size = blob.size;
    this.base64 = await BaseFile.toBase64(blob);
  }
}
