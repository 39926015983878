import FileEditorScope from '@/common/enums/file-editor-scope.js';
import { computed, watch } from 'vue';
import { useFileEditorStore } from '@/stores/file-editor.js';

export default function useFileEditor(onFilesChange, scope = FileEditorScope.Global) {
  const fileEditorStore = useFileEditorStore();

  const files = computed(() => fileEditorStore.getUpdatedFiles(scope));
  const urlsSum = computed(() => files.value.map((file) => file.url).join('_'));

  watch(urlsSum, () => onFilesChange(files.value), { immediate: true });
}
