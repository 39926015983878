<template>
  <div class="table-order-by-buttons">
    <div class="table-order-by-buttons__title">
      <slot name="title"> Сортировка </slot>
    </div>
    <div class="table-order-by-buttons__variants">
      <div
        class="table-order-by-buttons__variant"
        :class="{ active: isAscActive }"
        @click="orderByASC"
      >
        <slot name="variant-asc"> По<br />возрастанию </slot>
      </div>
      <div
        class="table-order-by-buttons__variant"
        :class="{ active: isDescActive }"
        @click="orderByDesc"
      >
        <slot name="variant-desc"> По<br />убыванию </slot>
      </div>
    </div>
    <div
      v-if="isOrder"
      class="table-order-by-buttons__clear"
    >
      <a
        href="#"
        @click.prevent="orderByDelete"
      >
        Удалить сортировку
      </a>
    </div>
  </div>
</template>

<script>
import VuexAdapter from '@/services/vuex-adapter.js';
import { TABLE_SORT_ASC, TABLE_SORT_DESC } from '@/configs/tables/table';
import { useTableStore } from '@/stores/table.js';

export default {
  name: 'OrderBy',
  props: ['data', 'col', 'key_column'],
  data: () => ({
    isOrder: false,
    orderValue: '',
    tableStore: useTableStore(),
  }),
  computed: {
    tableOrderBy() {
      return this.tableStore[VuexAdapter.orderByTableNameGetter(this.data.name)];
    },
    isAscActive() {
      return this.isOrder && this.orderValue === TABLE_SORT_ASC;
    },
    isDescActive() {
      return this.isOrder && this.orderValue === TABLE_SORT_DESC;
    },
  },
  watch: {
    tableOrderBy() {
      this.isOrderFun();
    },
  },
  mounted() {
    this.isOrderFun();
  },
  methods: {
    tableDelOrderBy() {
      this.tableStore[VuexAdapter.delOrderByTableNameAction(this.data.name)]();
    },
    tableSetOrderBy(data) {
      this.tableStore[VuexAdapter.orderByTableNameAction(this.data.name)](data);
    },
    orderByDesc() {
      if (this.isDescActive) {
        return;
      }
      this.tableSetOrderBy({
        key: this.key_column,
        value: TABLE_SORT_DESC,
        fieldSort: this.col?.fieldKeyValue,
      });
      document.body.click();
    },
    orderByASC() {
      if (this.isAscActive) {
        return;
      }
      this.tableSetOrderBy({
        key: this.key_column,
        value: TABLE_SORT_ASC,
      });
      document.body.click();
    },
    orderByDelete() {
      this.tableDelOrderBy();
      document.body.click();
    },
    isOrderFun() {
      if (this.key_column === this.tableOrderBy['key']) {
        this.isOrder = true;
        this.orderValue = this.tableOrderBy['value'];
        return;
      }
      this.isOrder = false;
      this.orderValue = '';
    },
  },
};
</script>
