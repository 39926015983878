import { StartType } from '@/common/enums/start-type.js';
import { DeadlineType } from '@/common/enums/deadline-type.js';

export const StepCode = {
  Type: 0,
  Content: 1,
  Deadline: 2,
  Barcode: 3,
  Resolutions: 4,
  Files: 5,
  Links: 6,
  Clients: 7,
  Route: 8,
  Contractor: 9,
  Execution: 10,
  Launch: 11,
};

export const INITIAL_ENABLED_STEPS = {
  barcode: null,
  files: null,
  links: null,
  clients: null,
  resolution: null,
};

export const TypeCode = {
  Document: 1,
  Route: 2,
};

export const INITIAL_TYPE = {
  isInitial: true,
  label: 'Документ',
  code: TypeCode.Document,
  disabled: false,
  mask: null,
  defaultDocId: null,
  defaultProjectId: null,
  docIds: null,
  linkTypesIds: [],
  projectIds: [],
};

export const INITIAL_START_TYPE = {
  label: 'Сразу',
  code: StartType.Immediately,
};

export const INITIAL_LAUNCH_DEADLINE = {
  type: DeadlineType.WorkingDays,
  workingDays: 1,
  workingDaysDate: null,
  days: 1,
  daysDate: null,
  date: null,
};
