<template>
  <div class="form-add-comment">
    <div class="solution-autocorrect">
      <SolutionAutocorrectUsers @add-solution-autocorrect="addText" />
      <SolutionAutocorrectReplace @add-solution-autocorrect="addText" />
      <SolutionAutocorrectDate @add-solution-autocorrect="addText" />
    </div>

    <ChipListUi
      v-if="list.length"
      :options="list"
      clickable
      @click="onChipClick"
      @close.stop="onChipClose"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import SolutionAutocorrectUsers from '@/components/doc/solution/autocorrect/SolutionAutocorrectUsers';
import SolutionAutocorrectReplace from '@/components/doc/solution/autocorrect/SolutionAutocorrectReplace';
import SolutionAutocorrectDate from '@/components/doc/solution/autocorrect/SolutionAutocorrectDate';
import { mapActions, mapState } from 'pinia';
import VuexAdapter from '@/services/vuex-adapter.js';
import { USERS_ACTIVE } from '@/configs/end-points.js';
import ChipListUi from '@/components/ui/ChipListUi.vue';
import { useAutocorrectStore } from '@/stores/autocorrect.js';
import { useListsStore } from '@/stores/lists.js';

const AutocorrectType = {
  User: 'user',
  Replace: 'replace',
  Comment: 'comment',
};

export default defineComponent({
  name: 'SolutionAutocorrect',
  components: {
    ChipListUi,
    SolutionAutocorrectDate,
    SolutionAutocorrectReplace,
    SolutionAutocorrectUsers,
  },
  methods: {
    ...mapActions(useAutocorrectStore, ['delReplaceAutocorrect', 'delUserAutocorrect', 'delCommentAutocorrect']),
    addText(text) {
      this.$emit('add-solution-autocorrect', text);
    },
    onChipClick(_, option) {
      this.addText(option.label);
    },
    onChipClose(_, option) {
      switch (option.type) {
        case AutocorrectType.User:
          this.delUserAutocorrect(+option.code);
          break;
        case AutocorrectType.Replace:
          this.delReplaceAutocorrect(+option.code);
          break;
        case AutocorrectType.Comment:
          this.delCommentAutocorrect(option.code);
          break;
      }
    },
    getOptions(type, codes, objects, labelField) {
      return codes
        .map((code) => {
          const label = objects[code]?.[labelField];
          return label ? { label, code, type } : null;
        })
        .filter(Boolean);
    },
  },
  computed: {
    ...mapState(useAutocorrectStore, [
      'usersAutocorrect',
      'commentAutocorrectReversed',
      'autocorrectReplaces',
      'autocorrectReplaceObjListGetter',
    ]),
    ...mapState(useListsStore, {
      usersActiveObjListGetter: VuexAdapter.getNameObjGetter(USERS_ACTIVE),
    }),
    users() {
      return this.getOptions(AutocorrectType.User, this.usersAutocorrect, this.usersActiveObjListGetter, 'ФИО');
    },
    replaces() {
      return this.getOptions(
        AutocorrectType.Replace,
        this.autocorrectReplaces,
        this.autocorrectReplaceObjListGetter,
        'Замена',
      );
    },
    comments() {
      return this.commentAutocorrectReversed.map((comment) => ({
        type: AutocorrectType.Comment,
        label: comment,
        code: comment,
      }));
    },
    list() {
      return [...this.users, ...this.replaces, ...this.comments];
    },
  },
});
</script>

<style scoped lang="scss">
.solution-autocorrect {
  display: grid;
  grid-template-columns: 4fr 4fr 1fr;
  grid-gap: 10px;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
}
</style>
