<template>
  <div class="sheet-document-preview--common">
    <div class="sheet-document-preview--common--title">
      {{ client['Название'] }}
    </div>
    <p class="sheet-document-preview--common--info">
      <span class="label _muted">Менеджер:</span>
      <span>{{ client['Менеджер'] }}</span>
    </p>
    <p class="sheet-document-preview--common--info">
      <span class="label _muted">Регион:</span>
      <span>{{ client['Регион'] }}</span>
    </p>
    <p class="sheet-document-preview--common--info">
      <span class="label _muted">Сегмент:</span>
      <span>{{ client['Сегмент'] }}</span>
    </p>
    <p
      v-if="client['Примечание']"
      class="sheet-document-preview--common--info"
    >
      <span class="label _muted">Примечание:</span>
      <span>{{ client['Примечание'] }}</span>
    </p>
  </div>
</template>

<script>
export default {
  name: 'CommonClientPreview',
  props: ['client'],
};
</script>

<style scoped lang="scss">
.sheet-document-preview--common--info {
  &:not(:last-child) {
    margin-bottom: 4px;
  }
}

.label {
  min-width: 100px;
}
</style>
