import clients from '@/configs/tables/clients';
import commissions from '@/configs/tables/commissions';
import controls from '@/configs/tables/controls';
import documents from '@/configs/tables/documents';
import VuexAdapter from '@/services/vuex-adapter.js';
import { TABLE_CONDITION_AND } from '@/configs/tables/table';
import { usePreviewStore } from '@/stores/preview.js';
import { defineStore } from 'pinia';
import { useSystemStore } from '@/stores/system.js';
import { useListsStore } from '@/stores/lists.js';
import { useColorsStore } from '@/stores/colors.js';
import StoreId from '@/common/enums/store-id.js';

const tables = [clients, commissions(), controls(), documents()];

const actions = {};
const states = {};
const getters = {};

const clearMutations = [];

tables.forEach((table) => {
  const tableName = table.name;

  const abortTableNameAction = VuexAdapter.abortTableNameAction(tableName);

  /**
   * Действие прерывания запроса
   * @param data
   * @returns {Promise<*>}
   */
  actions[abortTableNameAction] = async function (data) {
    const listsStore = useListsStore();
    return await listsStore[table.actionAbort](data);
  };

  const tableWhereSQLQueryMutations = (state) => {
    const whereSQLArray = [];
    for (let whereSQLKey in state.tableWhereSQL[tableName]) {
      whereSQLArray.push(state.tableWhereSQL[tableName][whereSQLKey]);
    }

    const query = whereSQLArray.length
      ? ' ( ' + whereSQLArray.join(' ) ' + state.tableWhereCondition[tableName] + ' ( ') + ' ) '
      : '';

    state.tableWhereSQLQuery[tableName] = query;
  };

  const tableClearRowMutations = (state) => {
    state.tableActiveRow[tableName] = {};
    state.tableActiveRowIndex[tableName] = null;
  };

  const filterTableNameAction = VuexAdapter.filterTableNameAction(tableName);

  /**
   * Действие установки фильтра
   * @param whereSQL
   * @param whereData
   * @param key
   */
  actions[filterTableNameAction] = function ({ whereSQL, whereData, key }) {
    const listsStore = useListsStore();

    return new Promise((resolve) => {
      listsStore[table.actionClearStore]();

      this.tableWhereSQL[tableName][key] = JSON.parse(JSON.stringify(whereSQL));
      this.tableWhereData[tableName][key] = JSON.parse(JSON.stringify(whereData));

      tableWhereSQLQueryMutations(this);
      tableClearRowMutations(this);
      resolve();
    });
  };

  const delFilterTableNameAction = VuexAdapter.delFilterTableNameAction(tableName);

  /**
   * Действие удаления фильтра
   * @param keyCol
   */
  actions[delFilterTableNameAction] = function (keyCol) {
    const listsStore = useListsStore();

    return new Promise((resolve) => {
      if (this.tableWhereData[tableName][keyCol] === undefined) {
        return resolve();
      }

      listsStore[table.actionClearStore]();

      delete this.tableWhereSQL[tableName][keyCol];
      delete this.tableWhereData[tableName][keyCol];
      tableWhereSQLQueryMutations(this);
      tableClearRowMutations(this);

      resolve();
    });
  };

  const filterAndDeleteTableNameAction = VuexAdapter.filterAndDeleteTableNameAction(tableName);

  /**
   * Действие добавления одного фильтра с удалением других
   * @param whereSQL
   * @param whereData
   * @param key
   */
  actions[filterAndDeleteTableNameAction] = function ({ whereSQL, whereData, key }) {
    const listsStore = useListsStore();

    return new Promise((resolve) => {
      listsStore[table.actionClearStore]();

      this.tableWhereSQL[tableName] = {};
      this.tableWhereData[tableName] = {};
      this.tableWhereCondition[tableName] = TABLE_CONDITION_AND;

      this.tableWhereSQL[tableName][key] = JSON.parse(JSON.stringify(whereSQL));
      this.tableWhereData[tableName][key] = JSON.parse(JSON.stringify(whereData));

      tableWhereSQLQueryMutations(this);
      tableClearRowMutations(this);

      resolve();
    });
  };

  const delOrderByTableNameAction = VuexAdapter.delOrderByTableNameAction(tableName);

  /**
   * Действие удаления сортировки
   */
  actions[delOrderByTableNameAction] = function () {
    const listsStore = useListsStore();

    return new Promise((resolve) => {
      listsStore[table.actionClearStore]();

      this.tableOrderBy[tableName] = {};
      tableClearRowMutations(this);

      resolve();
    });
  };

  const orderByTableNameAction = VuexAdapter.orderByTableNameAction(tableName);

  /**
   * Действие добавления сортировки
   * @param key
   * @param value
   * @param fieldSort
   */
  actions[orderByTableNameAction] = function ({ key, value, fieldSort }) {
    const listsStore = useListsStore();

    return new Promise((resolve) => {
      listsStore[table.actionClearStore]();

      this.tableOrderBy[tableName] = { key, value, fieldSort };
      tableClearRowMutations(this);

      resolve();
    });
  };

  const conditionTableNameAction = VuexAdapter.conditionTableNameAction(tableName);

  /**
   * Действие установки общего условия фильтрации
   * @param condition
   */
  actions[conditionTableNameAction] = function (condition) {
    const listsStore = useListsStore();

    return new Promise((resolve) => {
      listsStore[table.actionClearStore]();

      this.tableWhereCondition[tableName] = condition;

      tableWhereSQLQueryMutations(this);
      tableClearRowMutations(this);

      resolve();
    });
  };

  const delFilterAndOrderByTableNameAction = VuexAdapter.delFilterAndOrderByTableNameAction(tableName);

  /**
   * Действие удаление всех фильтров и сортировки
   */
  actions[delFilterAndOrderByTableNameAction] = function () {
    return new Promise((resolve) => {
      this[conditionTableNameAction](TABLE_CONDITION_AND);
      this[delOrderByTableNameAction]();

      this.tableWhereSQL[tableName] = {};
      this.tableWhereData[tableName] = {};
      this.tableWhereCondition[tableName] = TABLE_CONDITION_AND;

      tableWhereSQLQueryMutations(this);
      tableClearRowMutations(this);

      resolve();
    });
  };

  const fixColsTableNameMutation = VuexAdapter.fixColsTableNameMutation(tableName);

  /**
   * Мутация установки фиксированных колонок
   * @param fix
   */
  actions[fixColsTableNameMutation] = function (fix) {
    this.tableFixCols[tableName] = fix;
  };

  const activeRowTableNameMutation = VuexAdapter.activeRowTableNameMutation(tableName);

  /**
   * Мутация установки активной строки
   * @param row
   * @param index
   */
  actions[activeRowTableNameMutation] = function ({ row, index }) {
    this.tableActiveRow[tableName] = { ...row };
    this.tableActiveRowIndex[tableName] = index;
  };

  const sortableStatusTableNameMutation = VuexAdapter.sortableStatusTableNameMutation(tableName);

  /**
   * Мутация установки статуса сортировки
   * @param bool
   */
  actions[sortableStatusTableNameMutation] = function (bool) {
    this.tableSortableShown[tableName] = bool;
  };

  const clearTableNameMutation = VuexAdapter.clearTableNameMutation(tableName);

  /**
   * Мутация очистки хранилища
   */
  actions[clearTableNameMutation] = function () {
    this.tableActiveRow[tableName] = {};
    this.tableActiveRowIndex[tableName] = null;
    this.tableWhereSQL[tableName] = {};
    this.tableWhereData[tableName] = {};
    this.tableOrderBy[tableName] = {};
    this.tableWhereSQLQuery[tableName] = {};
    this.tableWhereCondition[tableName] = TABLE_CONDITION_AND;
    this.tableFixCols[tableName] = null;
    this.tableLoader[tableName] = true;
    this.tableSortableShown[tableName] = true;
    this.tableVisibleColumns[tableName] = null;
    this.tableSortColumns[tableName] = null;
  };

  clearMutations.push(clearTableNameMutation);

  /**
   * state
   */
  if (!Object.keys(states).length) {
    states.tableActiveRow = {};
    states.tableActiveRowIndex = {};
    states.tableWhereSQL = {};
    states.tableWhereData = {};
    states.tableOrderBy = {};
    states.tableWhereSQLQuery = {};
    states.tableWhereCondition = {};
    states.tableFixCols = {};
    states.tableLoader = {};
    states.tableSubLoader = {};
    states.tableSortableShown = {};
    states.tableVisibleColumns = {};
    states.tableSortColumns = {};
  }

  states.tableActiveRow[tableName] = {};
  states.tableActiveRowIndex[tableName] = null;
  states.tableWhereSQL[tableName] = {};
  states.tableWhereData[tableName] = {};
  states.tableOrderBy[tableName] = {};
  states.tableWhereSQLQuery[tableName] = '';
  states.tableWhereCondition[tableName] = TABLE_CONDITION_AND;
  states.tableFixCols[tableName] = null;
  states.tableLoader[tableName] = true;
  states.tableSubLoader[tableName] = false;
  states.tableSortableShown[tableName] = true;
  states.tableVisibleColumns[tableName] = null;
  states.tableSortColumns[tableName] = null;

  const activeRowTableNameGetter = VuexAdapter.activeRowTableNameGetter(tableName);

  /**
   * Getter получения активной строки
   * @param state
   * @returns {*}
   */
  getters[activeRowTableNameGetter] = (state) => {
    return state.tableActiveRow[tableName];
  };

  const activeRowIndexTableNameGetter = VuexAdapter.activeRowIndexTableNameGetter(tableName);

  /**
   * Getter получения индекса активной строки
   * @param state
   * @returns {*}
   */
  getters[activeRowIndexTableNameGetter] = (state) => {
    return state.tableActiveRowIndex[tableName];
  };

  const filterTableNameGetter = VuexAdapter.filterTableNameGetter(tableName);

  /**
   * Getter получения объекта фильтрации
   * @param state
   * @returns {*}
   */
  getters[filterTableNameGetter] = (state) => {
    return state.tableWhereData[tableName];
  };

  const conditionTableNameGetter = VuexAdapter.conditionTableNameGetter(tableName);

  /**
   * Getter получения условия фильтрации
   * @param state
   * @returns {*}
   */
  getters[conditionTableNameGetter] = (state) => {
    return state.tableWhereCondition[tableName];
  };

  const sqlQueryTableNameGetter = VuexAdapter.sqlQueryTableNameGetter(tableName);

  /**
   * Getter получения условия фильтрации в sql
   * @param state
   * @returns {*}
   */
  getters[sqlQueryTableNameGetter] = (state) => {
    return state.tableWhereSQLQuery[tableName];
  };

  const orderByTableNameGetter = VuexAdapter.orderByTableNameGetter(tableName);

  /**
   * Getter получения объекта сортировки
   * @param state
   * @returns {*}
   */
  getters[orderByTableNameGetter] = (state) => {
    return state.tableOrderBy[tableName];
  };

  const orderByTSqlableNameGetter = VuexAdapter.orderByTSqlableNameGetter(tableName);

  /**
   * Getter получения SQL запроса сортировки
   * @param state
   * @returns {*}
   */
  getters[orderByTSqlableNameGetter] = (state) => {
    const orderBy = state.tableOrderBy[tableName];

    if (Object.keys(orderBy).length) {
      const systemStore = useSystemStore();
      return systemStore.dbAdapter.getOrder(orderBy?.fieldSort || orderBy.key, orderBy.value);
    }
    return '';
  };

  const loaderTableNameGetter = VuexAdapter.loaderGeneralTableNameGetter(tableName);

  /**
   * Getter статуса загрузки
   * @param state
   * @returns {*}
   */
  getters[loaderTableNameGetter] = (state) => {
    return state.tableLoader[tableName];
  };

  const subLoaderTableNameGetter = VuexAdapter.subLoaderTableNameGetter(tableName);

  /**
   * Getter статуса поднрузки
   * @param state
   * @returns {*}
   */
  getters[subLoaderTableNameGetter] = (state) => {
    return state.tableSubLoader[tableName];
  };

  const fixColsTableNameGetter = VuexAdapter.fixColsTableNameGetter(tableName);

  /**
   * Getter фиксированные колонки
   * @param state
   * @returns {*}
   */
  getters[fixColsTableNameGetter] = (state) => {
    return state.tableFixCols[tableName];
  };

  const errorTableNameGetter = VuexAdapter.errorTableNameGetter(tableName);

  /**
   * Getter ошибок
   * @returns {*}
   */
  getters[errorTableNameGetter] = () => {
    const listsStore = useListsStore();
    return listsStore[table.getterError] || null;
  };

  const sortableStatusTableNameGetter = VuexAdapter.sortableStatusTableNameGetter(tableName);

  /**
   * Getter статус сортировки
   * @param state
   * @returns {*}
   */
  getters[sortableStatusTableNameGetter] = (state) => {
    return state.tableSortableShown[tableName];
  };

  const getDataNameAction = VuexAdapter.getDataTableNameAction(tableName);

  /**
   * Проверка наличия активной строки в списке
   * @param state
   * @param data
   */
  const activeRowCheck = (state, data) => {
    const index = state[activeRowIndexTableNameGetter];
    const activeRow = state[activeRowTableNameGetter];
    const items = data.data.items;
    const zeroIndex = 0;

    if (!items.length) {
      state[activeRowTableNameMutation]({ row: {}, index: null });

      return null;
    }

    if (!index) {
      state[activeRowTableNameMutation]({ row: items[zeroIndex], index: zeroIndex });

      return items[zeroIndex][table.previewField];
    }

    if (!items[index]) {
      const newIndex = items.length - 1;
      state[activeRowTableNameMutation]({ row: items[newIndex], index: newIndex });

      return items[newIndex][table.previewField];
    }

    if (items[index] && items[index][table.previewField] !== activeRow[table.previewField]) {
      for (let i = 0; i < items.length; i++) {
        if (items[i][table.previewField] === activeRow[table.previewField]) {
          state[activeRowTableNameMutation]({ row: items[i], index: i });

          return items[i][table.previewField];
        }
      }

      for (let i = index; i >= 0; i--) {
        if (items[i]) {
          state[activeRowTableNameMutation]({ row: items[i], index: i });

          return items[i][table.previewField];
        }
      }
    }

    return items[index][table.previewField] || null;
  };

  /**
   * Действие получение данных
   * @param data
   * @returns {Promise<*>}
   */
  actions[getDataNameAction] = async function (data) {
    let limit = table.limit;
    const where = this[sqlQueryTableNameGetter];
    const orderby = this[orderByTSqlableNameGetter];

    const activeRowIndex = this[activeRowIndexTableNameGetter];

    if (activeRowIndex) {
      let max = Number(activeRowIndex) + 1;
      for (let i = table.limit; i <= max + table.limit; i = i + table.limit) {
        limit = i;
      }
    }

    if (data?.where) {
      data.where = '(' + data.where + ') ' + (where.length ? TABLE_CONDITION_AND + ' ' + where.where : '');
    }

    this.tableLoader[tableName] = true;

    const listsStore = useListsStore();
    const colorsStore = useColorsStore();

    return new Promise((resolve) => {
      return listsStore[table.action]({ limit, where, orderby, ...data })
        .then((r) => {
          /** Закрузка цветов */
          if (listsStore[table.getter].length && table.actionColor) {
            colorsStore[table.actionColor]();
          }

          const id = activeRowCheck(this, r);

          const previewStore = usePreviewStore();
          previewStore.setEntityIdPreviewAction(id);

          resolve(id);
        })
        .finally(() => {
          this.tableLoader[tableName] = false;
        });
    });
  };

  const loadTableNameAction = VuexAdapter.loadTableNameAction(tableName);

  /**
   * Действие подгрузки данных
   * @param data
   * @returns {Promise<*>}
   */
  actions[loadTableNameAction] = async function (data) {
    const listsStore = useListsStore();

    const length = listsStore[table.getter].length;
    const limit = table.limit > length ? table.limit : length;
    const offset = listsStore[table.getter].length;
    const where = this[sqlQueryTableNameGetter];
    const orderby = this[orderByTSqlableNameGetter];

    if (data?.where) {
      data.where = '(' + data.where + ') ' + (where.length ? TABLE_CONDITION_AND + ' ' + where.where : '');
    }

    this.tableSubLoader[tableName] = true;
    return await listsStore[table.actionLoading]({
      limit,
      offset,
      where,
      orderby,
      ...data,
    }).finally(() => {
      this.tableSubLoader[tableName] = false;
    });
  };

  const refreshNameAction = VuexAdapter.refreshOnlyStateTableNameAction(tableName);

  /**
   * Действие обновления данных (фоновая без загрузчика)
   * @param data
   * @returns {Promise<*>}
   */
  actions[refreshNameAction] = async function (data = {}) {
    const listsStore = useListsStore();

    const length = listsStore[table.getter].length;
    const limit = table.limit > length ? table.limit : length;
    const where = this[sqlQueryTableNameGetter];
    const orderby = this[orderByTSqlableNameGetter];

    if (data?.where) {
      data.where = '(' + data.where + ') ' + (where.length ? TABLE_CONDITION_AND + ' ' + where.where : '');
    }

    return new Promise((resolve) => {
      return listsStore[table.action]({ limit, where, orderby, ...data }).then((r) => {
        const id = activeRowCheck(this, r);

        const previewStore = usePreviewStore();
        previewStore.setEntityIdPreviewAction(id);

        resolve(id);
      });
    });
  };

  const visibleColumnsAction = VuexAdapter.visibleColumnsTableNameAction(tableName);

  /**
   * Отображаемые колонки
   * @param data
   * @returns {Promise<*>}
   */
  actions[visibleColumnsAction] = async function (data = {}) {
    this.tableVisibleColumns[tableName] = { ...data };
  };

  const visibleColumnsClearAction = VuexAdapter.visibleColumnsTableClearNameAction(tableName);

  actions[visibleColumnsClearAction] = async function () {
    this.tableVisibleColumns[tableName] = null;
  };

  const visibleColumnsGetter = VuexAdapter.visibleColumnsTableNameGetter(tableName);

  getters[visibleColumnsGetter] = (state) => {
    return state.tableVisibleColumns[tableName];
  };

  const sortColumnsAction = VuexAdapter.sortColumnsTableNameAction(tableName);

  /**
   * Сортировка колонк
   * @param data
   * @returns {Promise<*>}
   */
  actions[sortColumnsAction] = async function (data = []) {
    this.tableSortColumns[tableName] = [...data];
  };

  const sortColumnsClearAction = VuexAdapter.sortColumnsTableClearNameAction(tableName);

  actions[sortColumnsClearAction] = async function () {
    this.tableSortColumns[tableName] = null;
  };

  const sortColumnsGetter = VuexAdapter.sortColumnsTableNameGetter(tableName);

  getters[sortColumnsGetter] = (state) => {
    return state.tableSortColumns[tableName];
  };
});

/** @deprecated */
export const useTableStore = defineStore(StoreId.Table, {
  state: () => ({
    ...states,
  }),
  getters: {
    ...getters,
  },
  actions: {
    ...actions,
    refreshDataTable(tableName) {
      this[VuexAdapter.loadTableNameAction(tableName)]({});
    },
    clear() {
      clearMutations.forEach((mutationName) => this[mutationName]());
    },
  },
  persist: true,
});
