<template>
  <notifications
    position="top right"
    classes="custom-notify"
    :duration="8000"
  >
    <template #body="{ item, close }">
      <div :class="getClasses(item)">
        <component
          :is="getIcon(item)"
          class="notification-icon"
        />
        <div class="notification-wrapper">
          <div class="notification-title">
            {{ getTitle(item) }}
          </div>
          <div class="notification-content">
            <p
              v-if="item.text"
              v-html="item.text"
            />
            <p v-if="item.data?.error_message">
              {{ item.data?.error_message }}
            </p>
            <p v-else-if="!item.text">
              {{ UNKNOWN_ERROR }}
            </p>
          </div>
        </div>
        <CloseIcon
          class="notification-close"
          @click="close"
        />
      </div>
    </template>
  </notifications>
</template>

<script>
import notifyIcons from '@/configs/notify-icons.js';
import CloseIcon from '@/assets/icons/close.svg';
import { NotifyTypes } from '@/configs/notify-types.js';

const UNKNOWN_ERROR = 'Произошла неизвестная ошибка';

export default {
  name: 'NotifyCustom',
  components: {
    CloseIcon,
  },
  data: () => ({
    UNKNOWN_ERROR,
  }),
  methods: {
    getClasses(notification) {
      return [
        'vue-notification-template custom-notify',
        {
          error: notification.type === NotifyTypes.Error,
          success: notification.type === NotifyTypes.Success,
          warn: notification.type === NotifyTypes.Warn,
        },
      ];
    },
    getTitle(notification) {
      switch (notification.type) {
        case NotifyTypes.Error:
          return 'Ошибка';
        case NotifyTypes.Success:
          return 'Успешно';
        case NotifyTypes.Warn:
          return 'Внимание!';
        default:
          console.error('Передан недопустимый тип уведомления');
          return '';
      }
    },
    getIcon(notification) {
      return notifyIcons[notification.type];
    },
  },
};
</script>
