<template>
  <ModalUi
    ref="modal"
    title="Удаление адреса"
    :error-message="errorMessage"
    popup
  >
    <template #body>
      Вы уверены что хотите удалить адрес "{{ address }}". Данная операция необратима! Продолжить?
    </template>

    <template #footer>
      <CancelButton @click="hide" />
      <DeleteButton @click="del" />
    </template>
  </ModalUi>
</template>

<script>
import { defineComponent } from 'vue';
import DeleteButton from '@/components/buttons/DeleteButton';
import CancelButton from '@/components/buttons/CancelButton.vue';
import { mapActions } from 'pinia';
import VuexAdapter from '@/services/vuex-adapter.js';
import { ADDRESS_DEL, CLIENTS_ADDRESSES_GET } from '@/configs/end-points.js';
import ModalUi from '@/components/ui/ModalUi.vue';
import ModalMixin from '@/mixins/modal-mixin.js';
import ErrorHelper from '@/services/error-helper.js';
import { useActionsStore } from '@/stores/actions.js';
import { usePreviewStore } from '@/stores/preview.js';

export default defineComponent({
  name: 'DeleteAddressModal',
  components: {
    ModalUi,
    DeleteButton,
    CancelButton,
  },
  mixins: [ModalMixin],
  props: {
    addressId: {
      type: Number,
      required: true,
    },
    address: {
      type: String,
      required: true,
    },
  },
  methods: {
    ...mapActions(useActionsStore, {
      deleteAddressAction: VuexAdapter.getNameAction(ADDRESS_DEL),
    }),
    ...mapActions(usePreviewStore, ['refreshPartlyPreviewAction']),
    del() {
      this.showLoader();
      this.errorMessage = null;
      this.deleteAddressAction({ address_id: this.addressId })
        .then(() => {
          this.refreshPartlyPreviewAction(CLIENTS_ADDRESSES_GET);
          this.hide();
        })
        .catch((error) => {
          this.errorMessage = ErrorHelper.format(error);
          this.hideLoader();
        });
    },
  },
});
</script>
