<template>
  <div
    v-if="accessToEdit"
    class="edit-block-row"
  >
    <ButtonIconUi
      color="light-gray"
      @click="$emit('edit')"
    >
      <EditIcon />
    </ButtonIconUi>
    <ButtonIconUi
      color="light-gray"
      @click="$emit('delete')"
    >
      <DeleteIcon />
    </ButtonIconUi>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import EditIcon from '@/assets/icons/edit.svg';
import DeleteIcon from '@/assets/icons/trash.svg';
import { mapState } from 'pinia';
import { COUNTERPARTIES__EDIT } from '@/configs/events';
import ButtonIconUi from '@/components/ui/ButtonIconUi.vue';
import { useRolesStore } from '@/stores/roles.js';

export default defineComponent({
  name: 'EditBlockRow',
  components: {
    ButtonIconUi,
    DeleteIcon,
    EditIcon,
  },
  computed: {
    ...mapState(useRolesStore, ['accessToEvent']),
    accessToEdit() {
      return this.accessToEvent(COUNTERPARTIES__EDIT);
    },
  },
});
</script>

<style scoped lang="scss">
.edit-block-row {
  display: flex;
}
</style>
