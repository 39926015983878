export class CommonUtil {
  static getSize(code) {
    switch (code) {
      case 's':
        return '4px';
      case 'm':
        return '8px';
      case 'l':
        return '16px';
      case 'max':
        return 'auto';
      default:
        return null;
    }
  }
}
