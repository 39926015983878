<template>
  <DropdownUi
    tag="ul"
    placement="bottom-end"
    close-if-outside-click
    close-if-inner-click
  >
    <template #anchor="{ toggle, isOpen }">
      <ButtonUi
        size="s"
        color="gray"
        mode="outline"
        @click="toggle"
      >
        <span>Отправить по маршруту повторно</span>
        <FontAwesomeIcon
          v-postfix="'s'"
          class="icon"
          :icon="['fas', 'route']"
        />
        <ChevronIcon
          v-postfix="'s'"
          v-rotate="isOpen"
        />
      </ButtonUi>
    </template>

    <DropdownItemUi
      tag="li"
      @click="restartRoute(true)"
    >
      <FontAwesomeIcon
        v-prefix
        :icon="['fas', 'route']"
      />
      <span>Повторить маршрут полностью</span>
    </DropdownItemUi>

    <DropdownItemUi
      tag="li"
      @click="restartRoute(false)"
    >
      <FontAwesomeIcon
        v-prefix
        :icon="['fas', 'route']"
      />
      <span>Повторить маршрут с отклоненной точки</span>
    </DropdownItemUi>
  </DropdownUi>
</template>

<script>
import { defineComponent } from 'vue';
import { mapActions } from 'pinia';
import VuexAdapter from '@/services/vuex-adapter.js';
import { DOC_ROUTE_ADD, DOCS_GANTT_ITEMS } from '@/configs/end-points.js';
import { NotifyTypes } from '@/configs/notify-types.js';
import DropdownUi from '@/components/ui/DropdownUi.vue';
import DropdownItemUi from '@/components/ui/DropdownItemUi.vue';
import ButtonUi from '@/components/ui/ButtonUi.vue';
import ChevronIcon from '@/assets/icons/chevron.svg';
import Rotate from '@/directives/rotate';
import { useActionsStore } from '@/stores/actions.js';
import { useListsStore } from '@/stores/lists.js';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default defineComponent({
  name: 'ButtonRoutePointRestart',
  components: {
    FontAwesomeIcon,
    ButtonUi,
    DropdownItemUi,
    DropdownUi,
    ChevronIcon,
  },
  directives: {
    Rotate,
  },
  props: ['rp_id', 'doc_id'],
  methods: {
    ...mapActions(useActionsStore, {
      docRouteAddAction: VuexAdapter.getNameAction(DOC_ROUTE_ADD),
    }),
    ...mapActions(useListsStore, {
      docsGanttItemsAction: VuexAdapter.getNameAction(DOCS_GANTT_ITEMS),
    }),
    async restartRoute(full = true) {
      try {
        this.$emit('start');

        const dataGantt = await this.docsGanttItemsAction({ doc_id: this.doc_id });

        const ganttItems = dataGantt?.data?.items || [];

        const routePointsClone = [];

        let parentId = null;

        ganttItems.forEach((item) => {
          if (this.rp_id == item.RP_ID) {
            parentId = item.PARENT_ID;
          }
        });

        if (parentId == null) {
          throw new Error('Отправление повторно, доступно только для стандартного маршрута');
        }

        let startClone = full;

        ganttItems.forEach((item) => {
          if (!startClone && this.rp_id == item.RP_ID) {
            startClone = true;
          }
          if (parentId == item.PARENT_ID && startClone) {
            routePointsClone.push(item.RP_ID);
          }
        });

        const data = {
          DOC_ID: this.doc_id,
          RP_ID: routePointsClone,
          PARENT_DOC_ID: this.doc_id,
        };

        await this.docRouteAddAction(data);

        this.$emit('success');
      } catch (error) {
        this.$notify({
          type: NotifyTypes.Error,
          text: 'При создании маршрута возникла ошибка.',
          data: error,
        });
      }
    },
  },
});
</script>

<style scoped lang="scss">
// TODO: Отказаться от перекрытия стилей заменив иконку на 16
.icon {
  width: 12px !important;
  height: 12px !important;
}
</style>
