<template>
  <tr
    ref="rowTable"
    :key.prop="row"
    :class="{
      'table-row-active': isSelectedRow,
      'table-design-tr': true,
    }"
    @click="clickRow(false)"
  >
    <td
      v-for="value in columns"
      :key.camel="value"
      :style="getRowStyles"
      :class="getClasses(value)"
    >
      <component
        :is="configs.info[value.keyRow].component || configs.info[value.keyRow].type"
        :config="configs.info[value.keyRow]"
        :config_g="configs"
        :small="false"
        :data="value.value"
        :data-row="row"
        :key-row="value.keyRow"
        :index-row="index"
      />
    </td>
  </tr>
</template>

<script>
import VuexAdapter from '@/services/vuex-adapter.js';
import { tColorToRGB } from '@/common/utils/utils.js';
import DateColumn from '@/components/table/columns/DateColumn.vue';
import FlagColumn from '@/components/table/columns/FlagColumn.vue';
import IntegerColumn from '@/components/table/columns/IntegerColumn.vue';
import IdentifierColumn from '@/components/table/columns/IdentifierColumn.vue';
import StringColumn from '@/components/table/columns/StringColumn.vue';
import EyeColumn from '@/components/table/columns/EyeColumn.vue';
import AsyncIdentifier from '@/components/table/columns/AsyncIdentifier.vue';
import Emitter from '@/services/emitter.js';
import { mapActions } from 'pinia';
import { usePreviewStore } from '@/stores/preview.js';
import { useTableStore } from '@/stores/table.js';
import { useColorsStore } from '@/stores/colors.js';

export default {
  name: 'TableRow',
  components: {
    Date: DateColumn,
    Flag: FlagColumn,
    Integer: IntegerColumn,
    Identifier: IdentifierColumn,
    String: StringColumn,
    Eye: EyeColumn,
    AsyncIdentifier: AsyncIdentifier,
  },
  inject: ['layout'],
  props: ['row', 'index', 'configs', 'componentWidth'],
  data() {
    return {
      tableStore: useTableStore(),
      colorsStore: useColorsStore(),
    };
  },
  computed: {
    preferences() {
      return this.tableStore[VuexAdapter.visibleColumnsTableNameGetter(this.configs.name)] || {};
    },
    tableFixCols() {
      return this.tableStore[VuexAdapter.fixColsTableNameGetter(this.configs.name)];
    },
    sortColumns() {
      return this.tableStore[VuexAdapter.sortColumnsTableNameGetter(this.configs.name)] || [];
    },
    getRowStyles() {
      const styles = {};

      if (
        this.configs.getterColor !== undefined &&
        this.configs.previewField !== undefined &&
        this.configs.getterColor
      ) {
        if (
          this.row[this.configs.generalField] !== undefined &&
          this.colorsStore[this.configs.getterColor][this.row[this.configs.generalField]] !== undefined
        ) {
          const colorConfig = this.colorsStore[this.configs.getterColor][this.row[this.configs.generalField]];
          if (!colorConfig['БлокЦвета'] && colorConfig['Цвет']) {
            styles['background-color'] = this.tColorToRGB(`#${colorConfig['Цвет']}`);
          }
        }
      }

      return styles;
    },
    tableActiveRow() {
      return this.tableStore[VuexAdapter.activeRowTableNameGetter(this.configs.name)];
    },
    hasTableActiveRow() {
      return this.tableActiveRow && Object.keys(this.tableActiveRow).length > 0;
    },
    isSelectedRow() {
      return (
        this.hasTableActiveRow && this.tableActiveRow[this.configs.generalField] === this.row[this.configs.generalField]
      );
    },
    columns() {
      const array = [...this.sortColumns],
        result = {};
      if (!array.length) {
        for (const key in this.configs.info) {
          let order = !this.configs.info[key]?.order ? 100 : parseInt(this.configs.info[key].order);
          do {
            order += 1;
          } while (array[order + 1] !== undefined);
          array[order + 1] = key;
        }
      }
      for (const keyRow in this.configs.info) {
        if (this.configs.info[keyRow]?.configTitle) {
          result[0] = {
            keyRow: keyRow,
            value: this.row[keyRow],
            keyOrder: 0,
          };

          continue;
        }

        if (
          (this.preferences[keyRow] !== undefined && this.preferences[keyRow] === false) ||
          (!this.configs.info[keyRow].visible && this.preferences[keyRow] === undefined)
        ) {
          continue;
        }

        array.forEach((valueOrder, keyOrder) => {
          if (valueOrder === keyRow) {
            result[keyOrder + 1] = {
              keyRow: keyRow,
              value: this.row[keyRow],
              keyOrder: keyOrder + 1,
            };
          }
        });
      }
      return result;
    },
  },
  mounted() {
    if (this.isSelectedRow || !this.hasTableActiveRow) {
      this.clickRow(true);
    }
  },
  unmounted() {
    Emitter.off('change_table_preferences_list_info_' + this.configs.name);
  },
  methods: {
    ...mapActions(usePreviewStore, ['setEntityIdPreviewAction']),
    tColorToRGB,
    clickRow(scroll = false) {
      this.tableSetActiveRow({
        row: this.row,
        index: this.index,
      });
      if (this.configs?.previewField && this.row[this.configs?.previewField]) {
        this.setEntityIdPreviewAction(this.row[this.configs.previewField]);
      }
      if (scroll) {
        this.$nextTick(() => {
          if (this.$refs.rowTable?.scrollIntoView) {
            this.$refs.rowTable.scrollIntoView({
              behavior: 'smooth',
              block: 'end',
              inline: 'start',
            });
          }
          if (undefined !== this.layout) {
            this.layout.frameLeft.element.children[0].scrollLeft = -50;
          }
        });
      }
    },
    getTableFixColClasses(keyOrder) {
      if (this.tableFixCols && keyOrder < this.tableFixCols.code) {
        return {
          'col-sticky-body': true,
          ['col-sticky-num-' + keyOrder]: true,
        };
      }
      return {};
    },
    getClasses(value) {
      if (Array.isArray(this.configs.info[value.keyRow].classes)) {
        return (
          this.configs.info[value.keyRow].classes + ' table-design-td ' + this.getTableFixColClasses(value.keyOrder)
        );
      }
      return {
        ...this.configs.info[value.keyRow].classes,
        ...this.getTableFixColClasses(value.keyOrder),
        'table-design-td': true,
      };
    },
    tableSetActiveRow(data) {
      this.tableStore[VuexAdapter.activeRowTableNameMutation(this.configs.name)](data);
    },
  },
};
</script>
