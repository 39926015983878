<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.1377 1.52827C14.3981 1.78862 14.3981 2.21073 14.1377 2.47108L11.8044 4.80441C11.5441 5.06476 11.122 5.06476 10.8616 4.80441L9.52827 3.47108C9.26792 3.21073 9.26792 2.78862 9.52827 2.52827C9.78862 2.26792 10.2107 2.26792 10.4711 2.52827L11.333 3.3902L13.1949 1.52827C13.4553 1.26792 13.8774 1.26792 14.1377 1.52827Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.89643 4.66634C7.51764 4.66634 7.15544 4.70839 6.80967 4.78415C4.92836 5.19631 3.49847 6.6172 2.63774 7.73886C2.39819 8.05104 2.20918 8.33162 2.07324 8.54687C2.04968 8.58418 2.0332 8.61031 2.01943 8.63299C2.01034 8.64796 2.00434 8.65832 2.00033 8.66553C2.00033 8.6658 2.00033 8.66607 2.00033 8.66634C2.00033 8.66661 2.00033 8.66688 2.00033 8.66715C2.00434 8.67437 2.01034 8.68472 2.01943 8.69969C2.0332 8.72237 2.04968 8.7485 2.07324 8.78581C2.42835 9.34809 3.13109 10.333 4.13528 11.1711C5.1379 12.0079 6.40087 12.6663 7.89643 12.6663C8.26462 12.6663 8.56309 12.9648 8.56309 13.333C8.56309 13.7012 8.26462 13.9997 7.89643 13.9997C5.98865 13.9997 4.43333 13.1565 3.28093 12.1947C2.13009 11.2342 1.34107 10.1235 0.945907 9.49778C0.940686 9.48951 0.935325 9.48107 0.929851 9.47246C0.851437 9.34903 0.749627 9.18877 0.698159 8.96423C0.656603 8.78293 0.656603 8.54976 0.698159 8.36845C0.749627 8.14391 0.851437 7.98366 0.92985 7.86023C0.935325 7.85161 0.940685 7.84317 0.945907 7.83491C1.10011 7.59075 1.31213 7.27618 1.57996 6.92715C2.50551 5.72101 4.18018 3.99527 6.52432 3.48171C6.96022 3.38621 7.41807 3.33301 7.89643 3.33301C8.26462 3.33301 8.56309 3.63148 8.56309 3.99967C8.56309 4.36786 8.26462 4.66634 7.89643 4.66634Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.687 6.16985C12.9613 5.92434 13.3828 5.94775 13.6283 6.22214C14.1853 6.84464 14.5943 7.4356 14.8467 7.83523C14.8519 7.8435 14.8573 7.85194 14.8627 7.86056C14.9412 7.98398 15.043 8.14423 15.0944 8.36878C15.136 8.55008 15.136 8.78325 15.0944 8.96455C15.043 9.1891 14.9412 9.34935 14.8627 9.47277C14.8573 9.48139 14.8519 9.48983 14.8467 9.4981C14.4039 10.1992 13.4607 11.5189 12.0618 12.548C10.9669 13.3535 9.56592 14 7.89616 14C7.52797 14 7.22949 13.7015 7.22949 13.3333C7.22949 12.9651 7.52797 12.6667 7.89616 12.6667C9.20607 12.6667 10.3363 12.1621 11.2717 11.474C12.4804 10.5848 13.3184 9.42093 13.7193 8.78613C13.7429 8.74883 13.7594 8.72269 13.7732 8.70001C13.7822 8.68505 13.7882 8.67469 13.7923 8.66747C13.7923 8.6672 13.7923 8.66693 13.7923 8.66666C13.7923 8.66639 13.7923 8.66612 13.7923 8.66586C13.7882 8.65864 13.7822 8.64828 13.7732 8.63332C13.7594 8.61064 13.7429 8.5845 13.7193 8.54719C13.4958 8.19321 13.1304 7.6653 12.6347 7.11121C12.3892 6.83682 12.4126 6.41536 12.687 6.16985Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.33301 8.66634C5.33301 10.1391 6.52691 11.333 7.99967 11.333C8.36786 11.333 8.66634 11.0345 8.66634 10.6663C8.66634 10.2982 8.36786 9.99967 7.99967 9.99967C7.26329 9.99967 6.66634 9.40272 6.66634 8.66634C6.66634 7.92996 7.26329 7.33301 7.99967 7.33301C8.36786 7.33301 8.66634 7.03453 8.66634 6.66634C8.66634 6.29815 8.36786 5.99967 7.99967 5.99967C6.52691 5.99967 5.33301 7.19358 5.33301 8.66634Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.33301 6.66667C7.33301 6.29848 7.63148 6 7.99967 6C9.47243 6 10.6663 7.19391 10.6663 8.66667C10.6663 10.1394 9.47243 11.3333 7.99967 11.3333C7.63148 11.3333 7.33301 11.0349 7.33301 10.6667C7.33301 10.2985 7.63148 10 7.99967 10C8.73605 10 9.33301 9.40305 9.33301 8.66667C9.33301 7.93029 8.73605 7.33333 7.99967 7.33333C7.63148 7.33333 7.33301 7.03486 7.33301 6.66667Z"
    />
  </svg>
</template>

<script>
/** @deprecated */
export default {
  name: 'ConfirmFamiliarizationIcon',
};
</script>
