import { defineStore } from 'pinia';
import DocumentApi from '@/services/api/document-api.js';
import { CAN_PREVIEW_FILE_EXTENSIONS } from '@/common/consts/file.js';
import axios from 'axios';
import StoreId from '@/common/enums/store-id.js';

export const usePreviewFilesStore = defineStore(StoreId.PreviewFiles, {
  state: () => ({
    list: [],
    isLoading: false,
    error: null,
    controller: null,
    lastRevisionFilesState: {},
  }),
  getters: {
    isReady(state) {
      return !state.isLoading && !state.error;
    },
    lastRevisionList(state) {
      return state.list.filter((file) => file.isLast);
    },
    oldRevisionList(state) {
      return state.list.filter((file) => !file.isLast);
    },
    getOldRevisionListByName() {
      return (name) => {
        return this.oldRevisionList.filter((file) => file.name === name);
      };
    },
    previewLastRevisionList() {
      return this.lastRevisionList.filter((file) => CAN_PREVIEW_FILE_EXTENSIONS.includes(file.extension));
    },
  },
  actions: {
    async fetch(request) {
      this.controller?.abort();
      this.controller = new AbortController();

      this.list = [];
      this.error = null;
      this.isLoading = true;

      try {
        this.list = await DocumentApi.getFiles(request, this.controller.signal);
      } catch (error) {
        if (!axios.isCancel(error)) {
          this.error = error;
        }
      } finally {
        this.isLoading = false;
        this.controller = null;
      }
    },
    updateLastRevisionFilesState({ fileId, state }) {
      this.lastRevisionFilesState = {
        ...this.lastRevisionFilesState,
        [fileId]: state,
      };
    },
  },
});
