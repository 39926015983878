<template>
  <div class="address-row">
    <EditAddressModal
      ref="addressEditModal"
      :config="row"
    />

    <DeleteAddressModal
      ref="addressDeleteModal"
      :address-id="row['ADDRESS_ID']"
      :address="row['Адрес']"
    />

    <div class="info">
      <p
        v-if="row['Примечание'] || row['Тип']"
        class="top"
      >
        <b
          v-if="row['Примечание']"
          class="comment"
        >
          {{ row['Примечание'] }}
        </b>
        <span
          v-if="row['Тип']"
          class="_muted"
        >
          {{ row['Тип'] }}
        </span>
      </p>

      <p
        v-if="row['Адрес']"
        class="address"
      >
        {{ row['Адрес'] }}
      </p>
    </div>

    <EditBlockRow
      @edit="edit"
      @delete="del"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import EditBlockRow from '@/components/clients/rows/EditBlockRow';
import EditAddressModal from '@/components/modals/Address/EditAddressModal.vue';
import DeleteAddressModal from '@/components/modals/Address/DeleteAddressModal.vue';

export default defineComponent({
  name: 'AddressRow',
  components: {
    DeleteAddressModal,
    EditAddressModal,
    EditBlockRow,
  },
  props: ['row'],
  methods: {
    edit() {
      this.$refs.addressEditModal.show();
    },
    del() {
      this.$refs.addressDeleteModal.show();
    },
  },
});
</script>

<style scoped lang="scss">
.address-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.info {
  margin-right: 8px;

  display: flex;
  flex-direction: column;
}

.top {
  display: flex;
  word-break: break-word;
}

.comment {
  &:not(:last-child) {
    margin-right: 8px;
  }
}

.address {
  padding: 4px 8px;
  border-left: var(--border-gray-100);
  word-break: break-word;
}
</style>
