import { defineStore } from 'pinia';
import Api from '@/services/api/api.js';
import { COMMISSIONS_COLORS, CONTROL_COLORS } from '@/configs/end-points.js';
import StoreId from '@/common/enums/store-id.js';

export const useColorsStore = defineStore(StoreId.Colors, {
  state: () => ({
    commissionsColors: {},
    controlsColors: {},
  }),
  actions: {
    /** Цвета поручений */
    async commissionsColorAction(data = {}) {
      return await Api.post(COMMISSIONS_COLORS, data).then((response) => {
        // TODO: Нужно ли условие?
        if (response) {
          this.commissionsColors = Object.fromEntries(response.items.map((item) => [item.RP_ID, item]));
        }
      });
    },
    /** Цвета документов на контроле */
    async controlsColorAction(data = {}) {
      return await Api.post(CONTROL_COLORS, data).then((response) => {
        // TODO: Нужно ли условие?
        if (response) {
          this.controlsColors = Object.fromEntries(response.items.map((item) => [item.DOC_ID, item]));
        }
      });
    },
  },
});
