<template>
  <PreviewBlock
    title="Комментарии"
    :amount="amount"
    :loading="loading"
    @resize="onResize"
  >
    <template #header-postfix>
      <AddPreviewButton
        v-if="showAdd"
        :show-title="!compact"
        @click="add"
      />
      <AddCommentModal
        ref="addModal"
        :parent-id="clientId"
        :parent-table-id="parentTableId"
      />
    </template>

    <UploaderBlockClientPreview
      :end-point="endPoint"
      :client-id="clientId"
      :component="CommentRow"
      empty-text="Список комментариев пуст"
    />
  </PreviewBlock>
</template>

<script>
import { defineComponent } from 'vue';
import UploaderBlockClientPreview from '@/components/clients/UploaderBlockClientPreview';
import { CLIENTS_COMMENTS_GET } from '@/configs/end-points.js';
import AddCommentModal from '@/components/modals/Comment/AddCommentModal';
import { CLIENTS_TABLE } from '@/configs/db-tables.js';
import ClientsPreviewBlockMixin from '@/mixins/clients-preview-block-mixin.js';
import PreviewBlock from '@/components/doc/preview/PreviewBlock.vue';
import AddPreviewButton from '@/components/buttons/AddPreviewButton.vue';
import PreviewBlockMixin from '@/mixins/preview-block-mixin.js';
import CommentRow from '@/components/clients/rows/CommentRow.vue';

export default defineComponent({
  name: 'CommentsClientPreview',
  components: {
    AddPreviewButton,
    PreviewBlock,
    AddCommentModal,
    UploaderBlockClientPreview,
  },
  mixins: [PreviewBlockMixin, ClientsPreviewBlockMixin],
  props: {
    clientId: {
      type: Number,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    CommentRow() {
      return CommentRow;
    },
    parentTableId() {
      return CLIENTS_TABLE;
    },
    endPoint() {
      return CLIENTS_COMMENTS_GET;
    },
  },
});
</script>
