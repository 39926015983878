import { Card } from '@/common/models/card/card';
import DocumentMasterApi from '@/services/api/document-master-api.js';

export default class LinkCard extends Card {
  constructor(response, idKey, titleKey, icon, path, tableId) {
    super(response, idKey, titleKey, icon, 'Номер');

    this.href = `/${path}/${response[idKey]}`;
    this.linkType = DocumentMasterApi.DEFAULT_LINK_TYPE;
    this.isLinkTypeInitial = true;
    this.linkTypeTableId = tableId;
  }

  static resetLinkType(link) {
    return {
      ...link,
      linkType: DocumentMasterApi.DEFAULT_LINK_TYPE,
      isLinkTypeInitial: true,
    };
  }
}
