<template>
  <a
    class="file-truncated-link"
    :class="{ _muted: isMuted, _disabled: isFileDownloading }"
    href="#"
    @click.prevent="downloadFile"
  >
    <FileTruncatedName :file="file" />
  </a>
</template>

<script>
import { defineComponent } from 'vue';
import DocumentFile from '@/common/models/file/document-file';
import FileTruncatedName from '@/components/doc/files/FileTruncatedName.vue';
import StorageApi from '@/services/api/storage-api.js';

export default defineComponent({
  name: 'FileTruncatedLink',
  components: {
    FileTruncatedName,
  },
  props: {
    file: {
      type: DocumentFile,
      required: true,
    },
    isMuted: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:is-loading'],
  data() {
    return {
      isFileDownloading: false,
    };
  },
  watch: {
    isFileDownloading(newVal) {
      this.$emit('update:is-loading', newVal);
    },
  },
  methods: {
    async downloadFile() {
      this.isFileDownloading = true;

      try {
        await StorageApi.download({
          url: this.file.url,
          name: this.file.downloadName,
          fileId: this.file.fileId,
        });
      } finally {
        // сделал небольшой дилей, чтобы иконка не мерцала на долю секунды, если файл небольшого веса
        setTimeout(() => {
          this.isFileDownloading = false;
        }, 300);
      }
    },
  },
});
</script>

<style scoped lang="scss">
.file-truncated-link {
  min-width: 0;

  &._disabled {
    pointer-events: none;

    &:not(._muted) {
      color: var(--color-gray-400);
    }
  }

  &._muted {
    color: var(--color-gray-600);
    font-size: 10px;
    line-height: 14px;
  }
}
</style>
