export const CAN_PREVIEW_IMAGE_FILE_EXTENSIONS = ['jpg', 'jpeg', 'png'];

export const CAN_PREVIEW_DOCUMENT_FILE_EXTENSIONS = ['doc', 'docx', 'pdf', 'rtf', 'odt', 'ods'];

export const CAN_PREVIEW_FILE_EXTENSIONS = [
  ...CAN_PREVIEW_IMAGE_FILE_EXTENSIONS,
  ...CAN_PREVIEW_DOCUMENT_FILE_EXTENSIONS,
];

export const CAN_EDIT_FILE_EXTENSIONS = ['doc', 'docx', 'rtf', 'xls', 'xlsx'];

export const MAX_FILE_SIZE_MB = 25;

export const MAX_FILE_SIZE = 1024 * 1024 * MAX_FILE_SIZE_MB;
