import { computed, ref } from 'vue';
import { BUTTON_TITLE_MIN_WIDTH } from '@/common/consts/preview.js';

export default function usePreviewBlock() {
  const width = ref(0);

  function onResize({ width: newWidth }) {
    width.value = newWidth;
  }

  const isCompact = computed(() => width.value < BUTTON_TITLE_MIN_WIDTH);

  return {
    isCompact,
    onResize,
  };
}
