import { BaseFile } from '@/common/models/file/base-file';
import { uniqueId } from 'lodash-es';
import IdPrefix from '@/common/enums/id-prefix.js';
import { useSystemStore } from '@/stores/system.js';

export default class TemplateFile extends BaseFile {
  constructor(template, file) {
    super(uniqueId(IdPrefix.TemplateFile), template['Название шаблона'], template['Размер']);

    const systemStore = useSystemStore();

    this.fileId = template['F_ID'];
    this.documentId = template['DOC_ID'];
    this.hash = file.hash;
    this.url = `${systemStore.storageUrl}${this.hash}.${this.extension}`;
    this.previewUrl = `${systemStore.storageUrl}${this.hash}_page0001.jpg`;
  }
}
