<script setup>
import controls from '@/configs/tables/controls';
import { onMounted } from 'vue';
import Emitter from '@/services/emitter.js';
import { useRolesStore } from '@/stores/roles.js';
import { DOCUMENTS__REGISTRATION_DATA } from '@/configs/events.js';
import TableIndex from '@/components/table/TableIndex.vue';

defineProps(['componentWidth']);

const rolesStore = useRolesStore();

const CONTROLS_CONFIGS = controls(rolesStore.accessToEvent(DOCUMENTS__REGISTRATION_DATA));

onMounted(() => Emitter.emit('document-preview-clear', true));
</script>

<template>
  <TableIndex
    :configs="CONTROLS_CONFIGS"
    :component-width="componentWidth"
  />
</template>
