<template>
  <div class="sheet-document-preview">
    <LoaderUi v-if="!ready" />
    <slot />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import LoaderUi from '@/components/ui/LoaderUi.vue';

export default defineComponent({
  name: 'SheetDocumentPreview',
  components: { LoaderUi },
  props: {
    ready: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style scoped lang="scss">
.sheet-document-preview {
  position: relative;

  margin-bottom: 16px;
  padding: 16px;

  background: var(--color-white);
  border-radius: 8px;
}
</style>
