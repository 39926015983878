<template>
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M28.264 6.83594H49.9987C50.8271 6.83594 51.4987 7.50751 51.4987 8.33594C51.4987 9.16436 50.8271 9.83594 49.9987 9.83594H28.332C24.807 9.83594 22.2534 9.8371 20.2442 10.0013C18.2526 10.164 16.9284 10.4794 15.8381 11.0349C13.7684 12.0895 12.0856 13.7723 11.031 15.842C10.4755 16.9323 10.1601 18.2565 9.99735 20.2481C9.8332 22.2573 9.83203 24.8109 9.83203 28.3359V71.6693C9.83203 75.1943 9.8332 77.7479 9.99735 79.7571C10.1601 81.7487 10.4755 83.0729 11.031 84.1632C12.0856 86.2329 13.7684 87.9157 15.8381 88.9703C16.9284 89.5258 18.2526 89.8412 20.2442 90.0039C22.2534 90.1681 24.807 90.1693 28.332 90.1693H71.6654C75.1904 90.1693 77.744 90.1681 79.7532 90.0039C81.7448 89.8412 83.069 89.5258 84.1593 88.9703C86.229 87.9157 87.9118 86.2329 88.9664 84.1632C89.5219 83.0729 89.8373 81.7487 90 79.7571C90.1642 77.7479 90.1654 75.1943 90.1654 71.6693V50.0026C90.1654 49.1742 90.8369 48.5026 91.6654 48.5026C92.4938 48.5026 93.1654 49.1742 93.1654 50.0026V71.7373C93.1654 75.1801 93.1654 77.8559 92.9901 80.0014C92.8122 82.1786 92.4464 83.9415 91.6395 85.5251C90.2972 88.1594 88.1555 90.3011 85.5212 91.6434C83.9376 92.4503 82.1746 92.8161 79.9975 92.994C77.852 93.1693 75.1762 93.1693 71.7334 93.1693H28.264C24.8212 93.1693 22.1454 93.1693 19.9999 92.994C17.8227 92.8161 16.0598 92.4503 14.4762 91.6434C11.8419 90.3011 9.70017 88.1594 8.35794 85.5251C7.55103 83.9415 7.1852 82.1786 7.00732 80.0014C6.83202 77.8559 6.83202 75.1801 6.83203 71.7373V28.2679C6.83202 24.8251 6.83202 22.1494 7.00732 20.0038C7.1852 17.8267 7.55103 16.0637 8.35794 14.4801C9.70017 11.8458 11.8419 9.70407 14.4762 8.36185C16.0598 7.55494 17.8227 7.1891 19.9999 7.01122C22.1454 6.83592 24.8212 6.83593 28.264 6.83594Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M61.2856 72.6274C59.857 72.3432 58.1041 72.3359 54.1601 72.3359H24.9934C18.0295 72.3359 12.1582 77.0307 10.3792 83.4307C10.1573 84.2288 9.33041 84.696 8.53224 84.4741C7.73408 84.2523 7.2669 83.4254 7.48877 82.6272C9.61927 74.9629 16.6473 69.3359 24.9934 69.3359L54.3834 69.3359C58.0439 69.3358 60.1149 69.3357 61.8709 69.685C69.0774 71.1185 74.7109 76.7519 76.1443 83.9585C76.4936 85.7145 76.4935 87.7854 76.4934 91.446V91.6693C76.4934 92.4977 75.8218 93.1693 74.9934 93.1693C74.165 93.1693 73.4934 92.4977 73.4934 91.6693C73.4934 87.7253 73.4861 85.9723 73.202 84.5437C72.0052 78.5273 67.3021 73.8241 61.2856 72.6274Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M91.9436 11.9811C92.5395 11.4057 92.5573 10.4549 91.9833 9.85756C91.4093 9.26022 90.4609 9.2425 89.865 9.81797L84.4275 15.0689L79.1715 9.62612C78.5961 9.0302 77.6476 9.01482 77.0531 9.59176C76.4586 10.1687 76.4432 11.1195 77.0187 11.7154L82.2695 17.1528L77.6362 21.6272C77.0403 22.2026 77.0225 23.1534 77.5965 23.7507C78.1705 24.3481 79.1189 24.3658 79.7148 23.7903L84.3535 19.3108L88.8279 23.9442C89.4033 24.5401 90.3518 24.5555 90.9463 23.9786C91.5408 23.4016 91.5562 22.4508 90.9807 21.8549L86.5115 17.2269L91.9436 11.9811Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M41.6667 24.4219C33.2903 24.4219 26.5 31.2122 26.5 39.5885C26.5 47.9649 33.2903 54.7552 41.6667 54.7552C50.043 54.7552 56.8333 47.9649 56.8333 39.5885C56.8333 31.2122 50.043 24.4219 41.6667 24.4219ZM23.5 39.5885C23.5 29.5554 31.6335 21.4219 41.6667 21.4219C51.6998 21.4219 59.8333 29.5554 59.8333 39.5885C59.8333 49.6217 51.6998 57.7552 41.6667 57.7552C31.6335 57.7552 23.5 49.6217 23.5 39.5885Z"
      fill="black"
    />
    <path
      d="M27.1933 74H52.7472C56.1397 74 57.836 74 59.2465 74.2152C65.0391 75.099 69.5672 78.5721 70.7194 83.015C71 84.0969 71.5 85.8979 71.5 88.5H22.2518C11.6671 88.5 10.695 77.3563 20.98 74.855C23.2213 74.31 25.3904 74 27.1933 74Z"
      fill="#C3CADB"
    />
  </svg>
</template>

<script>
/** @deprecated */
export default {
  name: 'ClientsEmptyTableIcon',
};
</script>
