import LinkCard from '@/common/models/card/link-card.js';
import { defineStore } from 'pinia';
import { useMasterStepsStore } from '@/stores/master-steps.js';
import StoreId from '@/common/enums/store-id.js';

export const useMasterClientsStore = defineStore(StoreId.MasterClients, {
  state: () => ({
    clients: [],
  }),
  getters: {
    length(state) {
      return state.clients.length;
    },
  },
  actions: {
    set(payload) {
      if (payload.length > 0) {
        const masterStepsStore = useMasterStepsStore();
        masterStepsStore.enable('clients');
      }
      this.clients = payload;
    },
    clearLinkTypes() {
      this.clients = this.clients.map(LinkCard.resetLinkType);
    },
  },
});
