<template>
  <svg
    style="margin-right: 6px; width: 20px; height: 20px"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.0002 2.49992C5.85803 2.49992 2.50016 5.85778 2.50016 9.99992C2.50016 14.1421 5.85803 17.4999 10.0002 17.4999C10.4604 17.4999 10.8335 17.873 10.8335 18.3333C10.8335 18.7935 10.4604 19.1666 10.0002 19.1666C4.93755 19.1666 0.833496 15.0625 0.833496 9.99992C0.833496 4.93731 4.93755 0.833252 10.0002 0.833252C10.4604 0.833252 10.8335 1.20635 10.8335 1.66659C10.8335 2.12682 10.4604 2.49992 10.0002 2.49992Z"
      fill="#37c43a"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.99984 2.49992C14.142 2.49992 17.4998 5.85778 17.4998 9.99992C17.4998 14.1421 14.142 17.4999 9.99984 17.4999C9.5396 17.4999 9.1665 17.873 9.1665 18.3333C9.1665 18.7935 9.5396 19.1666 9.99984 19.1666C15.0624 19.1666 19.1665 15.0625 19.1665 9.99992C19.1665 4.93731 15.0624 0.833252 9.99984 0.833252C9.5396 0.833252 9.1665 1.20635 9.1665 1.66659C9.1665 2.12682 9.5396 2.49992 9.99984 2.49992Z"
      fill="#37c43a"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.5058 7.74408C13.8312 8.06951 13.8312 8.59715 13.5058 8.92259L9.75576 12.6726C9.43032 12.998 8.90269 12.998 8.57725 12.6726L6.91058 11.0059C6.58514 10.6805 6.58514 10.1528 6.91058 9.82741C7.23602 9.50197 7.76366 9.50197 8.08909 9.82741L9.1665 10.9048L12.3272 7.74408C12.6527 7.41864 13.1803 7.41864 13.5058 7.74408Z"
      fill="#37c43a"
    />
  </svg>
</template>

<script>
/** @deprecated */
export default {
  name: 'ReadyStatusMasterIcon',
};
</script>
