<script setup>
import { computed, ref } from 'vue';
import TextareaUi from '@/components/ui/TextareaUi.vue';
import ModalUi from '@/components/ui/ModalUi.vue';
import UploadUi from '@/components/ui/UploadUi.vue';
import { DOCS_TABLE } from '@/configs/db-tables.js';
import CancelButton from '@/components/buttons/CancelButton.vue';
import ButtonUi from '@/components/ui/ButtonUi.vue';
import UploadIcon from '@/assets/icons/upload.svg';
import useModal from '@/composables/use-modal.js';
import SpinnerUi from '@/components/ui/SpinnerUi.vue';
import useUpload from '@/composables/use-upload.js';
import ReducedBadge from '@/components/common/ReducedBadge.vue';

const props = defineProps({
  documentId: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['hidden']);

const { modal, show, hide } = useModal();

const parentId = computed(() => props.documentId);

const { files, comment, error, isLoading, upload } = useUpload({
  parentTableId: DOCS_TABLE,
  parentId,
  onSuccess: hide,
});

const uploadUi = ref();

function onHidden() {
  files.value = [];
  comment.value = '';
  error.value = '';
  emit('hidden');
}

function showWithInitial(files) {
  show();
  if (files) {
    setTimeout(() => uploadUi.value.add(files));
  }
}

defineExpose({ show: showWithInitial });
</script>

<template>
  <ModalUi
    ref="modal"
    :prevent-closure="isLoading"
    @hidden="onHidden"
  >
    <template #header>
      <div class="header">
        <h2>Загрузка файлов</h2>
        <ReducedBadge
          v-postfix
          color="blue"
          :value="files.length"
        />
      </div>
    </template>

    <template #body>
      <UploadUi
        ref="uploadUi"
        v-model="files"
        :error="error"
        :readonly="isLoading"
      />
      <TextareaUi
        v-model="comment"
        placeholder="Примечание"
        :disabled="isLoading"
      />
    </template>

    <template #footer>
      <CancelButton
        :disabled="isLoading"
        @click="hide"
      />

      <ButtonUi
        :disabled="isLoading"
        @click="upload"
      >
        <SpinnerUi
          v-if="isLoading"
          v-prefix
          color="white"
        />
        <UploadIcon
          v-else
          v-prefix
        />
        <span>Загрузить</span>
      </ButtonUi>
    </template>
  </ModalUi>
</template>

<style scoped lang="scss">
.header {
  display: flex;
  align-items: center;
}

.upload-ui {
  margin-bottom: 16px;
}
</style>
