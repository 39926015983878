<template>
  <tbody class="table-component-tbody">
    <component
      :is="component"
      v-for="(row, index) in dataTable"
      :key="getKey(row)"
      :row="row"
      :index="index"
      :component-width="componentWidth"
      :configs="configs"
    />
  </tbody>
</template>

<script>
import TableRowSmall from '@/components/table/TableRowSmall';
import TableRow from '@/components/table/TableRow';

export default {
  name: 'TableBody',
  components: { TableRow, TableRowSmall },
  props: ['dataTable', 'configs', 'small', 'componentWidth'],
  computed: {
    component() {
      return this.small ? 'table-row-small' : 'TableRow';
    },
  },
  methods: {
    getKey(row) {
      return JSON.stringify(row);
    },
  },
};
</script>
