<template>
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.83337 2.00065C5.83337 1.63246 6.13185 1.33398 6.50004 1.33398H10.5C10.8682 1.33398 11.1667 1.63246 11.1667 2.00065C11.1667 2.36884 10.8682 2.66732 10.5 2.66732H6.50004C6.13185 2.66732 5.83337 2.36884 5.83337 2.00065ZM4.54596 4.66732H14.5C14.8682 4.66732 15.1667 4.36884 15.1667 4.00065C15.1667 3.63246 14.8682 3.33398 14.5 3.33398H3.83987C3.83599 3.33395 3.8321 3.33395 3.8282 3.33398H2.50004C2.13185 3.33398 1.83337 3.63246 1.83337 4.00065C1.83337 4.36884 2.13185 4.66732 2.50004 4.66732H3.20967L3.63747 11.0843C3.67104 11.5879 3.69881 12.0047 3.74862 12.3439C3.80048 12.6971 3.88274 13.0199 4.0541 13.3207C4.32086 13.7889 4.72323 14.1653 5.20819 14.4004C5.51972 14.5513 5.84724 14.612 6.2031 14.6402C6.5449 14.6673 6.96259 14.6673 7.46733 14.6673H8.50004C8.86823 14.6673 9.16671 14.3688 9.16671 14.0007C9.16671 13.6325 8.86823 13.334 8.50004 13.334H7.49381C6.95594 13.334 6.59139 13.3335 6.30861 13.311C6.03395 13.2892 5.89127 13.2497 5.78967 13.2005C5.54719 13.083 5.346 12.8948 5.21262 12.6607C5.15674 12.5626 5.10784 12.4228 5.06781 12.1502C5.0266 11.8696 5.00187 11.5058 4.96609 10.9692L4.54596 4.66732ZM7.16671 6.33398C7.5349 6.33398 7.83337 6.63246 7.83337 7.00065V10.334C7.83337 10.7022 7.5349 11.0007 7.16671 11.0007C6.79852 11.0007 6.50004 10.7022 6.50004 10.334V7.00065C6.50004 6.63246 6.79852 6.33398 7.16671 6.33398ZM9.83337 6.33398C10.2016 6.33398 10.5 6.63246 10.5 7.00065V10.334C10.5 10.7022 10.2016 11.0007 9.83337 11.0007C9.46518 11.0007 9.16671 10.7022 9.16671 10.334V7.00065C9.16671 6.63246 9.46518 6.33398 9.83337 6.33398Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.2111 3.33549C13.5784 3.35998 13.8564 3.67765 13.8319 4.04502L13.3626 11.0843C13.329 11.5879 13.3013 12.0047 13.2515 12.3439C13.1996 12.6971 13.1173 13.0199 12.946 13.3207C12.6792 13.7889 12.2768 14.1654 11.7919 14.4004C11.4804 14.5514 11.1528 14.612 10.797 14.6402C10.4552 14.6674 10.0375 14.6674 9.53275 14.6673H8.50004C8.13185 14.6673 7.83337 14.3689 7.83337 14.0007C7.83337 13.6325 8.13185 13.334 8.50004 13.334H9.50627C10.0441 13.334 10.4087 13.3335 10.6915 13.3111C10.9661 13.2893 11.1088 13.2498 11.2104 13.2005C11.4529 13.083 11.6541 12.8948 11.7875 12.6607C11.8433 12.5626 11.8922 12.4228 11.9323 12.1502C11.9735 11.8696 11.9982 11.5059 12.034 10.9692L12.5015 3.95633C12.526 3.58896 12.8437 3.31099 13.2111 3.33549Z"
    />
  </svg>
</template>

<script>
/** @deprecated */
export default {
  name: 'DeleteIcon',
};
</script>
