<template>
  <component
    :is="tag"
    class="tile-ui"
    :class="{ _checked: checked, _disabled: disabled, _inline: inline }"
    @click="onClick"
  >
    <div
      class="label"
      v-html="label"
    />
    <component
      :is="icon"
      class="icon"
    />
  </component>
</template>

<script>
import { defineComponent } from 'vue';
import { only } from '@/common/utils/props-validators';

export default defineComponent({
  name: 'TileUi',
  props: {
    tag: {
      type: String,
      default: 'div',
      validator: only('div', 'li'),
    },
    label: {
      type: [String, Array],
      required: true,
    },
    icon: {
      type: Object,
      required: true,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  methods: {
    onClick() {
      if (!this.disabled) {
        this.$emit('click');
      }
    },
  },
});
</script>

<style scoped lang="scss">
.tile-ui {
  padding: 8px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  color: var(--color-gray-700);
  background-color: var(--color-gray-075);
  border-radius: 8px;

  transition:
    background-color var(--transition-fast),
    box-shadow var(--transition-fast);

  &._checked {
    background-color: var(--color-gray-100);
  }

  &._disabled {
    opacity: 0.5;
  }

  &._inline {
    flex-direction: row-reverse;

    .icon {
      margin-right: 8px;
    }
  }

  &:not(._disabled) {
    cursor: pointer;

    @media (hover: hover) {
      &:hover {
        box-shadow: var(--shadow-control);
      }
    }
  }
}

.label {
  margin-bottom: 4px;
  text-align: center;
  font-size: var(--font-size-l);
  line-height: var(--line-height-l);
}
</style>
