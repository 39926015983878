<template>
  <div class="filter-buttons">
    <ButtonUi
      color="white"
      @click="$emit('clear')"
    >
      <CircleCloseIcon v-prefix />
      <span>Сбросить</span>
    </ButtonUi>

    <ApplyButton @click="$emit('apply')" />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import ButtonUi from '@/components/ui/ButtonUi.vue';
import CircleCloseIcon from '@/assets/icons/circle/close.svg';
import ApplyButton from '@/components/buttons/ApplyButton.vue';

export default defineComponent({
  name: 'FilterButtons',
  components: {
    ApplyButton,
    CircleCloseIcon,
    ButtonUi,
  },
  emits: ['clear', 'apply'],
});
</script>

<style scoped lang="scss">
.filter-buttons {
  display: flex;
}
</style>
