<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.66663 7.33341C4.66663 6.96522 4.9651 6.66675 5.33329 6.66675H9.33329C9.70148 6.66675 9.99996 6.96522 9.99996 7.33341C9.99996 7.7016 9.70148 8.00008 9.33329 8.00008H5.33329C4.9651 8.00008 4.66663 7.7016 4.66663 7.33341Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.33337 2.66659C4.75604 2.66659 2.66671 4.75592 2.66671 7.33325C2.66671 9.91058 4.75604 11.9999 7.33337 11.9999C7.70156 11.9999 8.00004 12.2984 8.00004 12.6666C8.00004 13.0348 7.70156 13.3333 7.33337 13.3333C4.01967 13.3333 1.33337 10.647 1.33337 7.33325C1.33337 4.01954 4.01967 1.33325 7.33337 1.33325C7.70156 1.33325 8.00004 1.63173 8.00004 1.99992C8.00004 2.36811 7.70156 2.66659 7.33337 2.66659Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.66663 1.99992C6.66663 1.63173 6.9651 1.33325 7.33329 1.33325C10.647 1.33325 13.3333 4.01954 13.3333 7.33325C13.3333 8.74993 12.8423 10.0519 12.0212 11.0784L14.4714 13.5285C14.7317 13.7889 14.7317 14.211 14.4714 14.4713C14.211 14.7317 13.7889 14.7317 13.5286 14.4713L11.0784 12.0212C10.052 12.8423 8.74997 13.3333 7.33329 13.3333C6.9651 13.3333 6.66663 13.0348 6.66663 12.6666C6.66663 12.2984 6.9651 11.9999 7.33329 11.9999C8.59059 11.9999 9.73176 11.5027 10.5709 10.6942C10.5884 10.6714 10.6077 10.6494 10.6286 10.6285C10.6495 10.6076 10.6714 10.5884 10.6942 10.5708C11.5027 9.73171 12 8.59055 12 7.33325C12 4.75592 9.91062 2.66659 7.33329 2.66659C6.9651 2.66659 6.66663 2.36811 6.66663 1.99992Z"
    />
  </svg>
</template>

<script>
/** @deprecated */
export default {
  name: 'ZoomOutIcon',
};
</script>
