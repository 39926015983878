<template>
  <Gantt
    ref="gantt"
    :doc="docComputed"
    :tasks="tasks"
    :options="options"
    :dynamic-style="dynamicStyle"
    :login-id="userId"
  />
</template>

<script>
import Constants from '@/configs/constants';
import { mapState } from 'pinia';
import { useUserStore } from '@/stores/user.js';
import Gantt from '@alphadoc/alphadoc-gantt';

const options = {
  isDebug: false,
  scope: {
    after: 28,
  },
};

const dynamicStyle = {
  fontSize: '12px',
  fontFamily: 'Alpha, Arial, sans-serif',
};

export default {
  name: 'GanttBlock',
  components: {
    Gantt,
  },
  props: ['doc', 'tasks', 'rp_id'],
  data() {
    return {
      options: options,
      dynamicStyle: dynamicStyle,
    };
  },
  computed: {
    ...mapState(useUserStore, { userId: 'id' }),
    docComputed() {
      return Object.assign({ ...this.doc }, { RP_ID: this.rp_id });
    },
  },
  mounted() {
    /* На маленьких экранах - скрываем часть обвеса */
    if (window.innerWidth < Constants.resolutionPoints.small) {
      /*
      this.options.taskList.columns.forEach((v, i) => {
          this.options.taskList.columns[i].width = 0;
      });
      */
    }
  },
  methods: {
    ganttZoomIn() {
      this.$refs.gantt.timeZoomIn();
    },
    ganttZoomOut() {
      this.$refs.gantt.timeZoomOut();
    },
    ganttZoomDefault() {
      this.$refs.gantt.timeZoomDefault();
    },
    ganttRecenterPosition() {
      this.$refs.gantt.recenterPosition();
    },
  },
};
</script>
