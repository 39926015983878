<template>
  <tfoot class="table-footer-component">
    <tr>
      <td
        colspan="100"
        :style="tdGeneralLoadStyle"
      >
        <LoaderUi
          v-if="generalLoader"
          :style="emptyContainerStyle"
          position="static"
        />
        <div
          v-else-if="empty && !generalLoader"
          :style="emptyContainerStyle"
          class="table-footer-component__empty-container"
        >
          <EmptyTable :name="configs.name" />
        </div>
        <ProgressBarUi v-else-if="loader" />
      </td>
    </tr>
  </tfoot>
</template>
<script>
import { defineComponent } from 'vue';
import VuexAdapter from '@/services/vuex-adapter.js';
import EmptyTable from '@/components/table/emptyFooter/EmptyTable.vue';
import LoaderUi from '@/components/ui/LoaderUi.vue';
import ProgressBarUi from '@/components/ui/ProgressBarUi.vue';
import { useListsStore } from '@/stores/lists.js';
import { useTableStore } from '@/stores/table.js';

export default defineComponent({
  name: 'TableFooter',
  components: {
    EmptyTable,
    ProgressBarUi,
    LoaderUi,
  },
  props: {
    configs: {
      type: Object,
      required: true,
    },
    headerHeight: {
      type: Number,
      required: true,
    },
    tableInitData: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      listsStore: useListsStore(),
      tableStore: useTableStore(),
    };
  },
  computed: {
    tdGeneralLoadStyle() {
      if (this.generalLoader && !this.tableInitData) {
        return {
          background: '#E2E7EF',
        };
      }

      return {};
    },
    empty() {
      return this.listsStore[this.configs.getterCountByFilter] === 0;
    },
    loader() {
      return this.tableStore[VuexAdapter.subLoaderTableNameGetter(this.configs.name)];
    },
    generalLoader() {
      return this.tableStore[VuexAdapter.loaderGeneralTableNameGetter(this.configs.name)];
    },
    emptyContainerStyle() {
      return {
        height: `calc(100vh - ${this.headerHeight + 162}px)`,
      };
    },
  },
});
</script>
