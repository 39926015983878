import { computed, onUnmounted, ref } from 'vue';

export default function useAbort() {
  const abortController = ref(new AbortController());

  const signal = computed(() => abortController.value.signal);

  function abort(reason = 'Вызвано прерывание') {
    abortController.value.abort(reason);
    abortController.value = new AbortController();
  }

  onUnmounted(() => abortController.value.abort('Компонент уничтожен'));

  return {
    signal,
    abort,
  };
}
