<template>
  <p class="view-row-document-preview">
    <b class="user">{{ row['Пользователь'] }}</b>
    <span class="_muted">{{ date }}</span>
  </p>
</template>

<script>
import { defineComponent } from 'vue';
import { getDate } from '@/common/utils/utils.js';

export default defineComponent({
  name: 'ViewRowDocumentPreview',
  props: ['row'],
  computed: {
    date() {
      return getDate(this.row['Дата'], 'dd.MM.yyyy, HH:mm');
    },
  },
});
</script>

<style scoped lang="scss">
.user {
  margin-right: 12px;
}
</style>
