<template>
  <FieldUi
    :id="id"
    class="combobox-ui"
    :label="label"
    :required="required"
    :error="errorMessage"
    :hint="hint"
  >
    <DropdownUi
      ref="dropdown"
      :offset="4"
      shrink
      full-width
      close-if-outside-click
      @open="$emit('focus')"
      @close="$emit('blur')"
    >
      <template #anchor="{ isOpen }">
        <div
          class="field"
          :class="[{ _open: isOpen }, className]"
          @click="toggle"
        >
          <div class="content">
            <slot name="prefix" />

            <span
              v-if="showPlaceholder"
              class="value value_placeholder"
            >
              {{ placeholder }}
            </span>
            <slot
              v-else-if="multiple"
              name="selected-options"
              v-bind="{ options: modelValue, multiple, remove }"
            >
              <ChipListUi
                :options="modelValue"
                @close.stop="onChipClose"
              />
            </slot>
            <div
              v-else
              class="value"
            >
              <slot
                name="selected-option"
                v-bind="{ option: modelValue, multiple, remove }"
              >
                {{ modelValue.label }}
              </slot>
            </div>

            <slot name="postfix" />

            <ButtonIconUi
              v-if="showClear"
              color="gray"
              title="Нажмите чтобы очистить"
              @click.stop="clear"
            >
              <CloseIcon />
            </ButtonIconUi>

            <ButtonIconUi
              v-rotate="isOpen"
              color="gray"
            >
              <ChevronIcon />
            </ButtonIconUi>
          </div>
        </div>
      </template>

      <div class="body">
        <div
          v-if="isLoading"
          class="state"
        >
          <SpinnerUi />
        </div>

        <template v-else>
          <div
            v-if="showSearch"
            class="search"
          >
            <input
              ref="search"
              v-model.trim="input"
              v-focus
              class="search-input"
              placeholder="Начните вводить"
            />
            <SearchIcon class="search-icon" />
          </div>

          <div
            v-if="!extendedOptions.length"
            class="state"
          >
            Ничего не найдено
          </div>

          <DropdownListUi
            v-else
            ref="list"
            :checked-options="modelValue"
            :options="extendedOptions"
            @select="onSelect"
          >
            <template #list-option-content="scope">
              <slot
                name="list-option-content"
                v-bind="scope"
              >
              </slot>
            </template>
          </DropdownListUi>
        </template>
      </div>

      <CollapseButton @click="$refs.dropdown.hide()" />
    </DropdownUi>
  </FieldUi>
</template>

<script>
import { defineComponent } from 'vue';
import ChevronIcon from '@/assets/icons/chevron.svg';
import SearchIcon from '@/assets/icons/search.svg';
import CloseIcon from '@/assets/icons/close.svg';
import DropdownUi from '@/components/ui/DropdownUi.vue';
import Focus from '@/directives/focus';
import { isEqual, uniqueId } from 'lodash-es';
import Rotate from '@/directives/rotate';
import ChipListUi from '@/components/ui/ChipListUi.vue';
import { keys, only } from '@/common/utils/props-validators';
import FieldUi from '@/components/ui/FieldUi.vue';
import ButtonIconUi from '@/components/ui/ButtonIconUi.vue';
import FieldMixin from '@/mixins/form/field-mixin.js';
import CollapseButton from '@/components/buttons/CollapseButton.vue';
import DropdownListUi from '@/components/ui/DropdownListUi.vue';
import AbortMixin from '@/mixins/abort-mixin.js';
import { NotifyTypes } from '@/configs/notify-types.js';
import SpinnerUi from '@/components/ui/SpinnerUi.vue';
import { CanceledError } from 'axios';

const SEARCH_MIN_OPTIONS = 7;

export default defineComponent({
  name: 'ComboboxUi',
  components: {
    SpinnerUi,
    DropdownListUi,
    CollapseButton,
    ButtonIconUi,
    FieldUi,
    ChipListUi,
    DropdownUi,
    ChevronIcon,
    SearchIcon,
    CloseIcon,
  },
  directives: {
    Focus,
    Rotate,
  },
  mixins: [AbortMixin, FieldMixin],
  props: {
    modelValue: {
      type: [Object, Array], // TODO: Option
      validator: keys('label', 'code'),
    },
    color: {
      type: String,
      default: 'gray',
      validator: only('gray', 'dark-gray', 'white'),
    },
    size: {
      type: String,
      default: 'm',
      validator: only('m', 'l'),
    },
    options: {
      type: [Function, Array],
      default: () => [],
      validator: keys('label', 'code'),
    },
    filter: {
      type: Function,
      default: (options) => options,
    },
    placeholder: {
      type: String,
      default: 'Выберите значение',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    noClear: {
      type: Boolean,
      default: false,
    },
    withCustomOption: {
      type: Boolean,
      default: false,
    },
    updateAfterLoad: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'focus', 'blur', 'load'],
  data() {
    return {
      id: uniqueId('combobox-ui-'),
      input: '',
      asyncOptions: [],
      isLoading: false,
    };
  },
  computed: {
    className() {
      return [
        {
          _prefix: !!this.$slots.prefix,
          _postfix: !!this.$slots.postfix,
          _disabled: this.innerDisabled,
          _error: !!this.error,
          _multiple: this.multiple,
        },
        `_size-${this.size}`,
        `_color-${this.color}`,
      ];
    },
    showPlaceholder() {
      return this.multiple ? !this.modelValue.length : !this.modelValue;
    },
    innerDisabled() {
      return this.disabled || (!this.isLoading && !this.filteredOptions.length);
    },
    comboboxOptions() {
      return typeof this.options === 'function' ? this.asyncOptions : this.options;
    },
    filteredOptions() {
      return this.filter(this.comboboxOptions);
    },
    foundOptions() {
      return this.filteredOptions.filter((option) => this.normalize(option.label).includes(this.normalize(this.input)));
    },
    extendedOptions() {
      if (!this.withCustomOption || !this.input || this.foundOptions.some((option) => option.label === this.input)) {
        return this.foundOptions;
      }

      return [
        {
          label: this.input,
          code: this.input,
        },
        ...this.foundOptions,
      ];
    },
    showClear() {
      return !this.noClear && !!(this.multiple ? this.modelValue.length : this.modelValue);
    },
    showSearch() {
      return this.withCustomOption || this.filteredOptions.length > SEARCH_MIN_OPTIONS;
    },
  },
  watch: {
    input() {
      this.$refs.list?.setActive(null);
    },
    innerDisabled(disabled) {
      if (disabled) {
        this.$refs.dropdown.hide();
      }
    },
    options(options) {
      if (typeof options === 'function') {
        this.getOptions(true);
      } else {
        this.updateModelValue();
      }
    },
  },
  mounted() {
    if (typeof this.options === 'function') {
      this.getOptions();
    }
  },
  methods: {
    async getOptions(isReloading = false) {
      this.isLoading = true;
      try {
        this.asyncOptions = await this.options(this.abortController.signal);
        if (isReloading || this.updateAfterLoad) {
          this.updateModelValue();
        }
        this.$emit('load', this.asyncOptions);
      } catch (error) {
        if (error instanceof CanceledError) {
          return;
        }

        this.$notify({
          type: NotifyTypes.Error,
          text: `При получении списка опций для поля "${this.label || this.placeholder}" возникла ошибка.`,
          data: error,
        });
      } finally {
        this.isLoading = false;
      }
    },
    choose(codes) {
      const value = this.multiple
        ? this.filteredOptions.filter((option) => codes.some((code) => option.code === code))
        : this.filteredOptions.find((option) => option.code === codes);

      if (!isEqual(this.modelValue, value)) {
        this.$emit('update:modelValue', value);
      }
    },
    updateModelValue() {
      if (this.multiple) {
        if (this.modelValue.length) {
          const codes = this.modelValue.map(({ code }) => code);
          this.choose(codes);
        }
      } else if (this.modelValue) {
        this.choose(this.modelValue.code);
      }
    },
    onSelect({ option, checked }) {
      if (!this.multiple) {
        if (!checked) {
          this.select(option);
        }

        this.$refs.dropdown.hide();
        return;
      }

      if (checked) {
        this.remove(option);
      } else {
        this.add(option);
      }
    },
    onChipClose(_, option) {
      this.remove(option);
    },
    clear() {
      this.$emit('update:modelValue', this.multiple ? [] : null);
    },
    toggle() {
      this.$refs.dropdown.toggle();
      this.input = '';
    },
    normalize(value) {
      return value.toLowerCase().replaceAll('ё', 'е');
    },
    select(option) {
      this.$emit('update:modelValue', option);
    },
    add(option) {
      const newOptions = [...this.modelValue, option];
      this.$emit('update:modelValue', newOptions);
    },
    remove(option) {
      const newOptions = this.modelValue.filter((checkedOption) => checkedOption.code !== option.code);
      this.$emit('update:modelValue', newOptions);
    },
  },
});
</script>

<style scoped lang="scss">
.field {
  display: flex;

  border-radius: 8px;
  cursor: pointer;

  transition: box-shadow var(--transition-fast);

  &._size-m {
    min-height: 36px;
    padding: 0 8px 0 12px;

    &._prefix {
      padding-left: 8px;
    }
  }

  &._size-l {
    min-height: 48px;
    padding: 0 12px 0 16px;

    font-size: var(--font-size-xl);
    line-height: var(--font-size-xl);

    &._prefix {
      padding-left: 12px;
    }
  }

  &._color-gray {
    background-color: var(--color-gray-075);
  }

  &._color-dark-gray {
    background-color: var(--color-gray-100);
  }

  &._color-white {
    box-shadow: var(--shadow);
    background-color: var(--color-white);
  }

  &._prefix {
    .value,
    .chip-list-ui {
      padding-left: 4px;
    }
  }

  &._open {
    box-shadow: var(--shadow-control);
  }

  &._disabled {
    pointer-events: none;

    .content {
      opacity: 0.5;
    }
  }

  &._error {
    box-shadow: var(--shadow-control-error);
  }

  &._multiple {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.content {
  flex-grow: 1;
  min-width: 0;

  display: flex;
  align-items: center;

  > :deep(*) {
    flex-shrink: 0;
  }
}

.value {
  flex: 1 1 auto;
  padding-right: 4px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &_placeholder {
    color: var(--color-gray-500);
  }
}

.chip-list-ui {
  flex: 1 1 auto;
  padding-right: 4px;

  max-height: 180px;
  overflow-y: auto;
}

.search {
  position: relative;
}

.search-input {
  width: 100%;
  height: 36px;

  padding-left: 12px;
  padding-right: 36px;

  color: var(--color-gray-600);

  &::placeholder {
    color: var(--color-gray-500);
  }
}

.search-icon {
  position: absolute;
  top: 50%;
  right: 12px;

  transform: translateY(-50%);

  pointer-events: none;
}

.state {
  min-height: 48px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.body {
  overflow: hidden;

  display: flex;
  flex-direction: column;

  box-shadow: var(--shadow);
  border-radius: 8px;
}

.collapse-button {
  flex-shrink: 0;
}
</style>
