<template>
  <div>
    <filters-header
      :show-add="true"
      :condition-button-disabled="conditionButtonDisabled"
    />

    <div
      v-for="(condition, index) in conditions"
      :key.prop="condition"
      class="fields"
      :hidden="!condition"
    >
      <switch-input-form
        v-if="index !== 0"
        v-model="condition.algorithm"
        class="item switch-input-filter-table"
        small
      />

      <combobox-ui
        v-model="condition.select"
        class="item"
        :options="options"
        no-clear
      />

      <DateRangePicker
        v-show="
          condition.select !== null && condition.select.code !== null && !onlySelect.includes(condition.select.code)
        "
        :locale-data="dateRangePickerConfig"
        :date-range="condition.input || dateRange"
        :linked-calendars="false"
        :show-dropdowns="true"
        :ranges="false"
        opens="left"
        :v-model="condition.input"
        @update:model-value="updateIntervalDate($event, index)"
      />
    </div>

    <filter-buttons
      @clear="cleanFilter"
      @apply="applyFilter"
    />

    <order-by
      :data="data"
      :col="col"
      :key_column="key_column"
    >
      <template #title>Сортировка по дате</template>
    </order-by>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import FilterMixin from '@/mixins/table/filter-mixin.js';
import dateRangePickerConfig from '@/configs/date-range-picker-config.js';
import DateRangePicker from 'vue3-daterange-picker';

export default defineComponent({
  name: 'DateFilter',
  components: {
    DateRangePicker,
  },
  mixins: [FilterMixin],
  data: () => ({
    onlySelect: ['is not null', 'is null', 'date_today', 'date_yesterday', 'date_seven_days', 'date_thirty_days'],
    dateRangePickerConfig: dateRangePickerConfig,
    dateRange: {
      startDate: null,
      endDate: null,
    },
    options: [
      {
        code: 'between',
        label: 'Соответствует',
      },
      {
        code: 'date_today',
        label: 'Динамически: Сегодня',
      },
      {
        code: 'date_yesterday',
        label: 'Динамически: Вчера',
      },
      {
        code: 'date_seven_days',
        label: 'Динамически: Посл. 7 дней',
      },
      {
        code: 'date_thirty_days',
        label: 'Динамически: Посл. 30 дней',
      },
      {
        code: 'is not null',
        label: 'Заполнено',
      },
      {
        code: 'is null',
        label: 'Не заполнено',
      },
    ],
    sqlGenerator: 'dateSQL',
  }),
  methods: {
    updateIntervalDate(value, n) {
      this.conditions[n].input = value;
    },
  },
});
</script>

<style scoped lang="scss">
.fields {
  margin-bottom: 12px;
}

.item {
  &:not(:last-child) {
    margin-bottom: 8px;
  }
}
</style>
