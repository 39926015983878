<template>
  <FatalError
    :title="'404'"
    class="document-not-found"
    :sub-title="'Упс, что-то пошло не так...'"
    :description="'Документ, который Вы ищете, не существует!<br/>Вернитесь назад или перейдите на главную страницу'"
  />
</template>

<script>
import FatalError from '@/components/general/FatalError';

export default {
  name: 'DocumentNotFound',
  components: {
    FatalError,
  },
};
</script>
