import { defineStore } from 'pinia';
import StoreId from '@/common/enums/store-id.js';

export const useSplitStore = defineStore(StoreId.Split, {
  state: () => ({
    ratio: [50, 50],
  }),
  actions: {
    set(ratio) {
      this.ratio = ratio;
    },
  },
  persist: true,
});
