<template>
  <ModalUi
    ref="modal"
    title="Настройки приложения"
  >
    <template #body>
      <TabsUi>
        <TabUi title="Параметры">
          <ComboboxUi
            v-model="colorMode"
            label="Цветовая схема"
            :options="colorModes"
            no-clear
          />
        </TabUi>

        <TabUi title="Разрешения">
          <ul class="list">
            <li
              v-for="(perm, title) in getPermissions"
              :key="title"
              class="item"
            >
              <span>{{ title }}</span>

              <div class="right">
                <BadgeSuccess
                  v-if="perm === 'granted'"
                  :value="'Разрешено'"
                />
                <BadgeWarning
                  v-else-if="perm === 'prompt'"
                  :value="'По запросу'"
                />
                <BadgeLight
                  v-else
                  :value="perm"
                />
              </div>
            </li>
          </ul>
        </TabUi>

        <TabUi title="Статистика">
          <ul class="list">
            <li class="item">
              <span>Статус:</span>

              <div class="right">
                <BadgeSuccess
                  v-if="isOnline"
                  :value="'Онлайн'"
                />
                <BadgeDanger
                  v-else
                  :value="'Оффлайн'"
                />
              </div>
            </li>
            <template v-if="memoryIsSupported && memory">
              <li class="item">
                <b class="_extra">Информация о расходе памяти вкладки</b>
              </li>
              <li class="item">
                <span>Использовано</span>
                <b class="_extra right">{{ size(memory.usedJSHeapSize) }}</b>
              </li>
              <li class="item">
                <span>Выделено</span>
                <b class="_extra right">{{ size(memory.totalJSHeapSize) }}</b>
              </li>
              <li class="item">
                <span>Лимит</span>
                <b class="_extra right">{{ size(memory.jsHeapSizeLimit) }}</b>
              </li>
            </template>
          </ul>
        </TabUi>
      </TabsUi>
    </template>

    <template #footer>
      <CloseButton @click="hide" />
    </template>
  </ModalUi>
</template>

<script>
import { defineComponent } from 'vue';
import { useArrayFind, useMemory, useOnline, usePermission } from '@vueuse/core';
import { fileSize } from '@/common/utils/utils.js';
import COLOR_MODES from '@/configs/colorModes';
import BadgeLight from '@/components/table/badges/BadgeLight';
import BadgeSuccess from '@/components/table/badges/BadgeSuccess';
import BadgeWarning from '@/components/table/badges/BadgeWarning';
import BadgeDanger from '@/components/table/badges/BadgeDanger';
import TabsUi from '@/components/ui/TabsUi.vue';
import TabUi from '@/components/ui/TabUi.vue';
import ModalUi from '@/components/ui/ModalUi.vue';
import ModalMixin from '@/mixins/modal-mixin.js';
import ComboboxUi from '@/components/ui/ComboboxUi.vue';
import CloseButton from '@/components/buttons/CloseButton.vue';

export default defineComponent({
  name: 'SettingsSystemModal',
  components: {
    ComboboxUi,
    ModalUi,
    TabUi,
    TabsUi,
    CloseButton,
    BadgeLight,
    BadgeSuccess,
    BadgeWarning,
    BadgeDanger,
  },
  mixins: [ModalMixin],
  inject: ['app'],
  setup() {
    const { isSupported: memoryIsSupported, memory } = useMemory();

    const accelerometer = usePermission('accelerometer');
    const accessibilityEvents = usePermission('accessibility-events');
    const ambientLightSensor = usePermission('ambient-light-sensor');
    const backgroundSync = usePermission('background-sync');
    const camera = usePermission('camera');
    const clipboardRead = usePermission('clipboard-read');
    const clipboardWrite = usePermission('clipboard-write');
    const gyroscope = usePermission('gyroscope');
    const magnetometer = usePermission('magnetometer');
    const microphone = usePermission('microphone');
    const notifications = usePermission('notifications');
    const paymentHandler = usePermission('payment-handler');
    const persistentStorage = usePermission('persistent-storage');
    const push = usePermission('push');
    const speaker = usePermission('speaker');

    const isOnline = useOnline();

    return {
      memoryIsSupported,
      memory,

      accelerometer,
      accessibilityEvents,
      ambientLightSensor,
      backgroundSync,
      camera,
      clipboardRead,
      clipboardWrite,
      gyroscope,
      magnetometer,
      microphone,
      notifications,
      paymentHandler,
      persistentStorage,
      push,
      speaker,

      isOnline,
    };
  },
  data: () => ({
    comment: '',
    colorMode: null,
    colorModes: COLOR_MODES,
  }),
  computed: {
    getPermissions() {
      return {
        'Акселерометр': this.accelerometer,
        'События доступа': this.accessibilityEvents,
        'Сенсор подсветки': this.ambientLightSensor,
        'Фоновая синхронизация': this.backgroundSync,
        'Камера': this.camera,
        'Чтение буфера обмена': this.clipboardRead,
        'Запись в буфер обмена': this.clipboardWrite,
        'Гироскоп': this.gyroscope,
        'Магнетометр': this.magnetometer,
        'Микрофон': this.microphone,
        'Уведомления': this.notifications,
        'Обработчик платежей': this.paymentHandler,
        'Постоянное хранилище': this.persistentStorage,
        'Пуш-уведомления': this.push,
        'Спикер': this.speaker,
      };
    },
  },
  watch: {
    colorMode(mode) {
      this.app.colorModeStore = mode.code;
    },
  },
  mounted() {
    const appColorMode = useArrayFind(COLOR_MODES, (mode) => mode.code === this.app.colorMode);
    this.colorMode = appColorMode.value;
  },
  methods: {
    size(v) {
      return fileSize(v, 2, '.', ' ');
    },
  },
});
</script>

<style scoped lang="scss">
.list {
  color: var(--color-black);
}

.item {
  padding: 8px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  &:not(:last-child) {
    border-bottom: var(--border-gray-100);
  }

  @media (hover: hover) {
    transition: background-color var(--transition-fast);

    &:hover {
      background-color: var(--color-gray-075);
    }
  }
}

.right {
  flex: 0 0 25%;
}

.badge-light {
  text-transform: capitalize;
}
</style>
