import { BaseFile } from '@/common/models/file/base-file';
import Revision from '@/common/models/revision';
import { DateTime } from 'luxon';
import { useSystemStore } from '@/stores/system.js';

export default class ApiFile extends BaseFile {
  constructor(response, file) {
    super(file?.id || response['F_ID'], file?.name || response['Название'], file?.size || response['Размер']);

    const systemStore = useSystemStore();

    this.fileId = response['F_ID'];
    this.hash = response['Файл'];
    this.url = `${systemStore.storageUrl}${this.hash}.${this.extension}`;
    this.pdfUrl = `${systemStore.storageUrl}${this.hash}.pdf`;
    this.comment = response['Примечание'];
    this.revision = new Revision(response['Редакция']);
    this.createdDate = this._getCreatedDate(response['Создан']);
  }

  _getCreatedDate(date) {
    return DateTime.fromSQL(date).toFormat('dd.MM.yyyy, HH:mm');
  }
}
