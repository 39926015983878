<template>
  <ComboboxUi
    ref="combobox"
    v-model="user"
    placeholder="Исполнитель"
    :options="users"
    no-clear
  />
</template>

<script>
import { mapActions, mapState } from 'pinia';
import VuexAdapter from '@/services/vuex-adapter.js';
import { USERS_ACTIVE } from '@/configs/end-points.js';
import { TABLE_SORT_ASC } from '@/configs/tables/table';
import ComboboxUi from '@/components/ui/ComboboxUi.vue';
import { useAutocorrectStore } from '@/stores/autocorrect.js';
import { useListsStore } from '@/stores/lists.js';
import DbAdapterMixin from '@/mixins/db-adapter-mixin.js';

export default {
  name: 'SolutionAutocorrectUsers',
  components: { ComboboxUi },
  mixins: [DbAdapterMixin],
  data() {
    return {
      user: null,
    };
  },
  mounted() {
    const getFieldName = this.dbAdapter[this.generateKey];
    this.usersActiveGetAction({
      orderby: `${getFieldName('ФИО')} ${TABLE_SORT_ASC}`,
    }).then(() => {
      this.usersAutocorrect.forEach((user_id) => {
        if (this.usersActiveObjListGetter[user_id] === undefined) {
          this.delUserAutocorrect(+user_id);
        }
      });
    });
  },
  computed: {
    ...mapState(useAutocorrectStore, ['usersAutocorrect']),
    ...mapState(useListsStore, {
      users: VuexAdapter.getNameSelectGetter(USERS_ACTIVE),
      usersActiveObjListGetter: VuexAdapter.getNameObjGetter(USERS_ACTIVE),
    }),
  },
  watch: {
    user(user) {
      if (user) {
        this.addUserAutocorrect(+user.code);
        this.$emit('add-solution-autocorrect', user.label);
        this.$refs.combobox.clear();
      }
    },
  },
  methods: {
    ...mapActions(useAutocorrectStore, ['addUserAutocorrect', 'delUserAutocorrect']),
    ...mapActions(useListsStore, {
      usersActiveGetAction: VuexAdapter.getNameAction(USERS_ACTIVE),
    }),
  },
};
</script>
