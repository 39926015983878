export default {
  mounted(el) {
    el.classList.add('no-select');
    el.addEventListener('mousedown', function (event) {
      event.preventDefault();
    });
  },
  unmounted(el) {
    el.removeEventListener('mousedown', function (event) {
      event.preventDefault();
    });
  },
};
