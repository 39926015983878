import { ref } from 'vue';
import SystemApi from '@/services/api/system-api.js';
import Emitter from '@/services/emitter.js';
import { NotifyTypes } from '@/configs/notify-types.js';
import { useCommissionsStore } from '@/stores/commissions.js';
import { notify } from '@kyvg/vue3-notification';

export default function useMarkCommissions(beforeMark) {
  const isLoading = ref(false);
  const commissionsStore = useCommissionsStore();

  async function mark(method, errorTitle) {
    if (isLoading.value) {
      return;
    }

    isLoading.value = true;

    try {
      beforeMark?.();
      await method();
      Emitter.emit('table-start-reload-commissions');
      await commissionsStore.getCommissionsCountAction();
    } catch (error) {
      notify({
        type: NotifyTypes.Error,
        text: errorTitle,
        data: error,
      });
    } finally {
      isLoading.value = false;
    }
  }

  return {
    isLoading,
    markAsRead,
    markAsUnread,
    markAsReadAll,
  };

  async function markAsRead(row) {
    if (!row['Новое']) {
      return;
    }

    await mark(
      () => SystemApi.markRoutePointAsRead(row['RP_ID']),
      'Ошибка при попытке пометить прочитанной точку маршрута',
    );
  }

  async function markAsUnread(row) {
    if (row['Новое']) {
      return;
    }

    await mark(() => SystemApi.markAsUnread(row['RP_ID']), 'Ошибка при попытке пометить документ прочитанным');
  }

  async function markAsReadAll() {
    await mark(() => SystemApi.markAsReadAll(), 'Ошибка при попытке пометить все документы как прочитанные');
  }
}
