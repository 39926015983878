import LinkCard from '@/common/models/card/link-card.js';
import { defineStore } from 'pinia';
import { useMasterStepsStore } from '@/stores/master-steps.js';
import StoreId from '@/common/enums/store-id.js';

export const useMasterLinksStore = defineStore(StoreId.MasterLinks, {
  state: () => ({
    links: [],
  }),
  getters: {
    length(state) {
      return state.links.length;
    },
  },
  actions: {
    set(payload) {
      if (payload.length > 0) {
        const masterStepsStore = useMasterStepsStore();
        masterStepsStore.enable('links');
      }
      this.links = payload;
    },
    clearLinkTypes() {
      this.links = this.links.map(LinkCard.resetLinkType);
    },
  },
});
