import { CommonUtil } from '@/common/utils/common';

export default {
  mounted: (element, { value = 'm' }) => {
    element.style.marginLeft = CommonUtil.getSize(value);
  },
  updated(element, { value = 'm' }) {
    element.style.marginLeft = CommonUtil.getSize(value);
  },
};
