export default {
  Required: 'Заполните обязательное поле',
  Unselected: 'Выберите значение',
  Data: 'Введены некорректные данные',
  Email: 'Указан некорректный email',
  Date: 'Указана некорректная дата',
  Phone: 'Указанный номер должен соответствовать виду: +7 (999) 999 - 99 - 99',
  Percent: 'Неверно указан процент (от 0 до 100)',
  URL: 'Некорректный URL',
};
