<template>
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.52864 5.52827C5.78899 5.26792 6.2111 5.26792 6.47145 5.52827L10.4714 9.52827C10.7318 9.78862 10.7318 10.2107 10.4714 10.4711C10.2111 10.7314 9.78899 10.7314 9.52864 10.4711L5.52864 6.47108C5.26829 6.21073 5.26829 5.78862 5.52864 5.52827Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.4714 5.52827C10.7318 5.78862 10.7318 6.21073 10.4714 6.47108L6.47145 10.4711C6.2111 10.7314 5.78899 10.7314 5.52864 10.4711C5.26829 10.2107 5.26829 9.78862 5.52864 9.52827L9.52864 5.52827C9.78899 5.26792 10.2111 5.26792 10.4714 5.52827Z"
    />
  </svg>
</template>

<script>
/** @deprecated */
export default {
  name: 'CloseOnlyIcon',
};
</script>
