<template>
  <div>
    <FormBuilder
      ref="form"
      v-model="formDataProxy"
      :fields="fields"
      @update:model-value="change"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import StepMixin from '@/mixins/document-master/step-mixin.js';
import FormBuilder from '@/components/form/FormBuilder.vue';
import { TYPE_TEXT } from '@/configs/form';

export default defineComponent({
  name: 'AddResolutionStepMaster',
  components: { FormBuilder },
  mixins: [StepMixin],
  data() {
    return {
      fields: [
        [
          {
            type: TYPE_TEXT,
            name: 'resolution',
            placeholder: 'Введите резолюцию',
            required: true,
            resize: false,
            length: 2048,
            rows: 21,
          },
        ],
      ],
    };
  },
});
</script>
