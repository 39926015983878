<template>
  <tr
    ref="rowTable"
    class="table-small-row"
    :class="{
      'table-row-active': isSelectedRow,
    }"
    @click="clickRowMobile"
  >
    <td
      v-if="configs.generalField !== undefined && configs.info[configs.generalField].component !== undefined"
      class="table-small-row--menu"
      :style="getRowStyles"
    >
      <component
        :is="configs.info[configs.generalField].component"
        :data-row="row"
        :index-row="index"
        :config_g="configs"
        :small="true"
      />
    </td>
    <td class="table-small-row--info">
      <ErrorAlertUi v-if="!configs?.smallGroped"> Компонент не задан </ErrorAlertUi>
      <component
        :is="configs.smallGroped"
        v-else
        :data-table="listsStore[configs.getter]"
        :configs="configs"
        :row-styles="getRowStyles"
        :row="row"
        :component-width="componentWidth"
      />
    </td>
  </tr>
</template>

<script>
import VuexAdapter from '@/services/vuex-adapter.js';
import { tColorToRGB } from '@/common/utils/utils.js';
import ErrorAlertUi from '@/components/ui/ErrorAlertUi';
import { mapActions } from 'pinia';
import { usePreviewStore } from '@/stores/preview.js';
import { useListsStore } from '@/stores/lists.js';
import { useTableStore } from '@/stores/table.js';
import { useColorsStore } from '@/stores/colors.js';

export default {
  name: 'TableRowSmall',
  components: { ErrorAlertUi },
  props: ['row', 'index', 'configs', 'componentWidth'],
  data() {
    return {
      listsStore: useListsStore(),
      tableStore: useTableStore(),
      colorsStore: useColorsStore(),
    };
  },
  computed: {
    getRowStyles() {
      const styles = {};

      if (
        this.configs.getterColor !== undefined &&
        this.configs.previewField !== undefined &&
        this.configs.getterColor
      ) {
        if (
          this.row[this.configs.generalField] !== undefined &&
          this.colorsStore[this.configs.getterColor][this.row[this.configs.generalField]] !== undefined
        ) {
          const colorConfig = this.colorsStore[this.configs.getterColor][this.row[this.configs.generalField]];
          if (!colorConfig['БлокЦвета'] && colorConfig['Цвет']) {
            styles['background-color'] = this.tColorToRGB(`#${colorConfig['Цвет']}`);
          }
        }
      }

      return styles;
    },
    tableActiveRow() {
      return this.tableStore[VuexAdapter.activeRowTableNameGetter(this.configs.name)];
    },
    hasTableActiveRow() {
      return this.tableActiveRow && Object.keys(this.tableActiveRow).length > 0;
    },
    isSelectedRow() {
      return (
        this.hasTableActiveRow && this.tableActiveRow[this.configs.generalField] === this.row[this.configs.generalField]
      );
    },
  },
  mounted() {
    if (this.isSelectedRow || !this.hasTableActiveRow) {
      this.clickRow(true);
    }
  },
  methods: {
    ...mapActions(usePreviewStore, ['setEntityIdPreviewAction', 'setActivePreviewAction']),
    tColorToRGB,
    clickRowMobile() {
      this.setActivePreviewAction();
      this.clickRow(false);
    },
    clickRow(scroll = true) {
      this.tableSetActiveRow({
        row: this.row,
        index: this.index,
      });
      if (this.configs?.previewField && this.row[this.configs?.previewField]) {
        this.setEntityIdPreviewAction(this.row[this.configs.previewField]);
      }
      if (scroll) {
        this.$nextTick(() => {
          if (undefined !== this.$refs.rowTable?.scrollIntoView) {
            this.$refs.rowTable.scrollIntoView({
              behavior: 'smooth',
              block: 'end',
              inline: 'start',
            });
          }
        });
      }
    },
    tableSetActiveRow(data) {
      this.tableStore[VuexAdapter.activeRowTableNameMutation(this.configs.name)](data);
    },
  },
};
</script>
