<template>
  <div class="fatal-error-wrapper">
    <div class="fatal-error-container">
      <div class="fatal-error-card">
        <div
          class="header"
          v-html="title"
        />
        <div
          class="text"
          v-html="subTitle"
        />
        <div class="logo" />
        <div class="rectangle" />
      </div>
    </div>
    <div class="fatal-error-action">
      <div
        class="description"
        v-html="description"
      />
      <div class="buttons">
        <a href="/">На главную</a>
      </div>
    </div>
    <div class="fatal-error-footer">
      <a
        href="//www.alpha-doc.ru"
        target="_blank"
      >
        <LinkIcon class="icon" />
        <span class="title">www.alpha-doc.ru</span>
      </a>
      <a href="mailto:info@alpha-doc.ru">
        <MailIcon class="icon" />
        <span class="title">info@alpha-doc.ru</span>
      </a>
      <a href="tel:+74956618199">
        <PhoneIcon class="icon" />
        <span class="title">+7 495 661-81-99</span>
      </a>
    </div>
  </div>
</template>

<script>
import PhoneIcon from '@/assets/icons/phone.svg';
import LinkIcon from '@/assets/icons/link-s.svg';
import MailIcon from '@/assets/icons/mail.svg';

export default {
  name: 'FatalError',
  components: { MailIcon, LinkIcon, PhoneIcon },
  props: {
    title: [String, Number],
    subTitle: String,
    description: String,
  },
};
</script>

<style scoped lang="scss">
.icon {
  fill: var(--color-gray-1000);
}
</style>
