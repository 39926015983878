import { defineComponent } from 'vue';
import { BUTTON_TITLE_MIN_WIDTH } from '@/common/consts/preview.js';

/** @deprecated Используйте composables/use-preview-block */
export default defineComponent({
  data() {
    return {
      compact: true,
    };
  },
  methods: {
    onResize({ width }) {
      this.compact = width < BUTTON_TITLE_MIN_WIDTH;
    },
  },
});
