import Api from '@/services/api/api.js';
import DocumentFile from '@/common/models/file/document-file.js';
import { DocumentEndPoint } from '@/common/enums/end-point.js';
import DocumentOption from '@/common/models/option/document-option.js';
import DocumentCard from '@/common/models/card/document-card.js';

export default class DocumentApi {
  static GET_HINTS_COUNT = 500;

  static async getDocument(id, signal) {
    const response = await Api.post(DocumentEndPoint.GetList, { where: `DOC_ID = ${id}` }, signal);
    return new DocumentCard(response.items[0]);
  }

  static async getOptions(query, signal) {
    const response = await Api.post(DocumentEndPoint.GetOptions, { query }, signal);
    return response.items.map((item) => new DocumentOption(item));
  }

  static async getFiles(id, signal, cache) {
    const response = await Api.post(DocumentEndPoint.GetFiles, { doc_id: id }, signal, true, cache);
    return response.items.map((item) => new DocumentFile(item));
  }

  static async stop(id, comment, signal) {
    return await Api.post(DocumentEndPoint.Stop, { doc_id: id, comment }, signal);
  }
}
