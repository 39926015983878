<template>
  <PreviewBlock
    title="Резолюции"
    :amount="amount || 0"
    :loading="loading"
    @resize="onResize"
  >
    <template #header-postfix>
      <AddPreviewButton
        v-if="showAdd"
        :show-title="!compact"
        @click="add"
      />
      <AddCommentModal
        ref="addModal"
        :parent-id="doc_id"
        :parent-table-id="parentTableId"
      />
    </template>

    <UploaderBlockDocumentPreview
      :end-point="endPoint"
      :doc-id="doc_id"
      :component="CommentRowDocumentPreview"
      empty-text="Список резолюций пуст"
    />
  </PreviewBlock>
</template>

<script>
import { defineComponent } from 'vue';
import UploaderBlockDocumentPreview from '@/components/doc/preview/UploaderBlockDocumentPreview.vue';
import { DOC_COMMENTS_GET } from '@/configs/end-points.js';
import AddCommentModal from '@/components/modals/Comment/AddCommentModal';
import { DOCS_TABLE } from '@/configs/db-tables.js';
import PreviewBlockMixin from '@/mixins/preview-block-mixin.js';
import PreviewBlock from '@/components/doc/preview/PreviewBlock.vue';
import AddPreviewButton from '@/components/buttons/AddPreviewButton.vue';
import CommentRowDocumentPreview from '@/components/doc/preview/rows/CommentRowDocumentPreview.vue';
import VuexAdapter from '@/services/vuex-adapter.js';
import { useListsStore } from '@/stores/lists.js';
import { mapState } from 'pinia';

export default defineComponent({
  name: 'CommentsDocumentPreview',
  components: {
    AddPreviewButton,
    PreviewBlock,
    AddCommentModal,
    UploaderBlockDocumentPreview,
  },
  mixins: [PreviewBlockMixin],
  props: {
    doc_id: {
      type: Number,
      required: true,
    },
    showAdd: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(useListsStore, { amount: VuexAdapter.getNameCountGetter(DOC_COMMENTS_GET) }),
    CommentRowDocumentPreview() {
      return CommentRowDocumentPreview;
    },
    parentTableId() {
      return DOCS_TABLE;
    },
    endPoint() {
      return DOC_COMMENTS_GET;
    },
  },
  methods: {
    add() {
      this.$refs.addModal.show();
    },
  },
});
</script>
