import { defineStore } from 'pinia';
import StoreId from '@/common/enums/store-id.js';

export const useMasterContractorsStore = defineStore(StoreId.MasterContractors, {
  state: () => ({
    contractors: [],
  }),
  actions: {
    set(payload) {
      this.contractors = payload;
    },
  },
});
