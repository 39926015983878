import { markRaw } from 'vue';
import DOCIcon from '@/assets/icons/files/doc.svg';
import XLSIcon from '@/assets/icons/files/xls.svg';
import JPGIcon from '@/assets/icons/files/jpg.svg';
import ODSIcon from '@/assets/icons/files/ods.svg';
import ODTIcon from '@/assets/icons/files/odt.svg';
import PDFIcon from '@/assets/icons/files/pdf.svg';
import PNGIcon from '@/assets/icons/files/png.svg';
import RTFIcon from '@/assets/icons/files/rtf.svg';
import UnknownIcon from '@/assets/icons/files/unknown.svg';
import {
  CAN_EDIT_FILE_EXTENSIONS,
  CAN_PREVIEW_DOCUMENT_FILE_EXTENSIONS,
  CAN_PREVIEW_FILE_EXTENSIONS,
} from '@/common/consts/file.js';

export class BaseFile {
  constructor(id, name, size) {
    this.id = id;
    this.name = name;
    this.size = size;
    this.title = this._getTitle(name);
    this.extension = this._getExtension(name);
    this.icon = markRaw(this._getIcon(this.extension));
    this.canEdit = this._getCanEdit(this.extension);
    this.canPreview = this._getCanPreview(this.extension);
    this.canPreviewDocument = this._getCanPreviewDocument(this.extension);
  }

  _getTitle(name) {
    return name.split('.').slice(0, -1).join('.');
  }

  _getExtension(name) {
    return name.split('.').pop().toLowerCase();
  }

  _getIcon(extension) {
    switch (extension) {
      case 'doc':
      case 'docx':
        return DOCIcon;
      case 'xls':
      case 'xlsm':
      case 'xlsx':
      case 'xltx':
      case 'xlsb':
      case 'xlam':
        return XLSIcon;
      case 'jpg':
      case 'jpeg':
        return JPGIcon;
      case 'ods':
        return ODSIcon;
      case 'odt':
        return ODTIcon;
      case 'pdf':
        return PDFIcon;
      case 'png':
        return PNGIcon;
      case 'rtf':
        return RTFIcon;
      default:
        return UnknownIcon;
    }
  }

  _getCanEdit(extension) {
    return CAN_EDIT_FILE_EXTENSIONS.includes(extension);
  }

  _getCanPreview(extension) {
    return CAN_PREVIEW_FILE_EXTENSIONS.includes(extension);
  }

  _getCanPreviewDocument(extension) {
    return CAN_PREVIEW_DOCUMENT_FILE_EXTENSIONS.includes(extension);
  }

  static async toBase64(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () => resolve(reader.result.split('base64,')[1]);
      reader.onerror = (error) => reject(error);
    });
  }
}
