<template>
  <FatalError
    :title="'403'"
    :sub-title="'Упс, что-то пошло не так...'"
    :description="'К странице, которую Вы запрашиваете, доступ запрещён!<br/>Вернитесь назад или перейдите на главную страницу.'"
  />
</template>

<script>
import FatalError from '@/components/general/FatalError';

export default {
  name: 'ForbiddenPage',
  components: {
    FatalError,
  },
};
</script>
