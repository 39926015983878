<template>
  <ModalUi
    ref="modal"
    title="Добавление банковских реквизитов"
    :error-message="errorMessage"
  >
    <template #body>
      <FormBuilder
        ref="formBuilder"
        v-model="form"
        margins
        :fields="fields"
      />
    </template>

    <template #footer>
      <CancelButton @click="hide" />
      <AddButton @click="apply" />
    </template>
  </ModalUi>
</template>

<script>
import { defineComponent } from 'vue';
import CancelButton from '@/components/buttons/CancelButton.vue';
import { mapActions } from 'pinia';
import VuexAdapter from '@/services/vuex-adapter.js';
import { CLIENTS_BANK_DETAILS_ADD, CLIENTS_BANK_DETAILS_GET } from '@/configs/end-points.js';
import FormBuilder from '@/components/form/FormBuilder';
import bankDetails from '@/configs/forms/clients/bank-details.js';
import ModalUi from '@/components/ui/ModalUi.vue';
import ModalMixin from '@/mixins/modal-mixin.js';
import ErrorHelper from '@/services/error-helper.js';
import AddButton from '@/components/buttons/AddButton.vue';
import { useActionsStore } from '@/stores/actions.js';
import { usePreviewStore } from '@/stores/preview.js';

export default defineComponent({
  name: 'AddBankDetailsModal',
  components: {
    AddButton,
    ModalUi,
    FormBuilder,
    CancelButton,
  },
  mixins: [ModalMixin],
  props: {
    parentId: {
      type: Number,
      required: true,
    },
    parentTableId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      errorMessage: null,
      fields: bankDetails(this),
      form: {
        parent_id: this.parentId,
        parent_table_id: this.parentTableId,
      },
    };
  },
  methods: {
    ...mapActions(useActionsStore, {
      addClientBankDetailsAction: VuexAdapter.getNameAction(CLIENTS_BANK_DETAILS_ADD),
    }),
    ...mapActions(usePreviewStore, ['refreshPartlyPreviewAction']),
    apply() {
      if (!this.$refs.formBuilder.validate()) {
        return;
      }

      this.showLoader();

      const data = { ...this.form };

      this.addClientBankDetailsAction(data)
        .then(() => {
          this.refreshPartlyPreviewAction(CLIENTS_BANK_DETAILS_GET);
          this.hide();
        })
        .catch((error) => {
          this.errorMessage = ErrorHelper.format(error);
          this.hideLoader();
        });
    },
  },
});
</script>
