<template>
  <div class="step-type">
    <FormBuilder
      ref="form"
      v-model="model"
      :fields="fields"
    />

    <div
      v-if="showWarning"
      class="warning"
    >
      <WarnIcon />
      Данный проект не привязан по умолчанию для данного типа документа.
      <a
        href="#"
        @click.prevent="setDefaultProject"
      >
        Исправить.
      </a>
    </div>

    <div class="background">
      <CreateDocIcon />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import StepMixin from '@/mixins/document-master/step-mixin.js';
import FormBuilder from '@/components/form/FormBuilder.vue';
import { mapActions, mapState } from 'pinia';
import DbAdapterMixin from '@/mixins/db-adapter-mixin.js';
import WarnIcon from '@/assets/icons/warn.svg';
import CreateDocIcon from '@/assets/icons/create-doc.svg';
import DocumentMasterFields from '@/common/consts/document-master/fields.js';
import { useMasterTypeStore } from '@/stores/master-type.js';

export default defineComponent({
  name: 'StepType',
  components: {
    FormBuilder,
    CreateDocIcon,
    WarnIcon,
  },
  mixins: [StepMixin, DbAdapterMixin],
  computed: {
    ...mapState(useMasterTypeStore, ['type', 'project', 'showWarning']),
    filterByType() {
      return (options) => {
        const projectIds = this.type.projectIds;
        return projectIds.length ? options.filter((option) => projectIds.includes(option.code)) : options;
      };
    },
    fields() {
      return [DocumentMasterFields.TYPE, DocumentMasterFields.project(this.filterByType)];
    },
    model: {
      get() {
        return {
          type: this.type,
          project: this.project,
        };
      },
      set(value) {
        if (this.type.isInitial) {
          this.setType(value.type, true);
        } else {
          this.setType(value.type);
          this.setProject(value.project);
        }
      },
    },
  },
  methods: {
    ...mapActions(useMasterTypeStore, ['setType', 'setNewType', 'setProject']),
    setDefaultProject() {
      this.setProjectCode(this.type.defaultProjectId);
    },
    setProjectCode(code) {
      this.$refs.form.$refs.project[0].choose(code);
    },
  },
  watch: {
    type(type) {
      this.setProjectCode(type.defaultProjectId);
    },
  },
});
</script>

<style scoped lang="scss">
.step-type {
  height: 100%;
  position: relative;

  display: flex;
  flex-direction: column;
}

.form-builder {
  margin-bottom: 16px;
  z-index: 2;
}

.warning {
  margin-bottom: -40px;
  z-index: 1;

  font-size: var(--font-size-l);
  line-height: var(--line-height-l);
  color: var(--color-gray-600);
}

.background {
  flex-grow: 1;
  padding-top: 16px;
  padding-bottom: 50px;

  display: flex;
  align-items: flex-end; // TODO: center после того, как форма перестанет менять высоту
  justify-content: center;
}
</style>
