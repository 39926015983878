<template>
  <ul
    class="card-list-ui"
    :class="{ _field: withAdd }"
    @click.self="$emit('add')"
  >
    <li
      v-for="(item, index) in list"
      :key="item.id"
      class="item"
    >
      <CardUi
        :item="item"
        :icon="item.icon"
        :readonly="readonly"
        :title-key="titleKey"
        :footer-component="cardFooter"
        @change="$emit('change', { item, changes: $event })"
        @remove="$emit('remove', item.id)"
      />

      <ButtonIconUi
        v-show="showAdd(index)"
        class="plus"
        color="gray"
        @click="$emit('add')"
      >
        <PlusFillIcon />
      </ButtonIconUi>
    </li>
  </ul>
</template>

<script>
import { defineComponent } from 'vue';
import CardUi from '@/components/ui/CardUi.vue';
import { keys } from '@/common/utils/props-validators';
import ButtonIconUi from '@/components/ui/ButtonIconUi.vue';
import PlusFillIcon from '@/assets/icons/plus-fill.svg';

export default defineComponent({
  name: 'CardListUi',
  components: {
    PlusFillIcon,
    ButtonIconUi,
    CardUi,
  },
  props: {
    list: {
      type: Array,
      required: true,
      validator: keys('id', 'title', 'icon'),
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    withAdd: {
      type: Boolean,
      default: false,
    },
    cardFooter: Object,
    titleKey: String,
  },
  emits: ['change', 'remove', 'add'],
  methods: {
    showAdd(index) {
      return !this.readonly && this.withAdd && index === this.list.length - 1;
    },
  },
});
</script>

<style scoped lang="scss">
.card-list-ui {
  flex-grow: 1;
  padding: 8px;

  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(auto-fill, minmax(125px, 1fr));
  grid-auto-rows: min-content;

  &._field {
    padding-right: 40px;
  }
}

.item {
  position: relative;
}

.plus {
  position: absolute;
  top: 50%;
  right: -32px;

  transform: translateY(-50%);
}

.card-ui {
  height: 100%;
}
</style>
