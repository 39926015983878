import { StepCode } from '@/common/consts/document-master/common.js';
import StepBarcode from '@/components/document-master/steps/StepBarcode.vue';
import StepClients from '@/components/document-master/steps/StepClients.vue';
import StepType from '@/components/document-master/steps/StepType.vue';
import StepContent from '@/components/document-master/steps/StepContent.vue';
import StepDeadline from '@/components/document-master/steps/StepDeadline.vue';
import StepResolution from '@/components/document-master/steps/StepResolution.vue';
import StepFiles from '@/components/document-master/steps/StepFiles.vue';
import StepLinks from '@/components/document-master/steps/StepLinks.vue';
import StepRoute from '@/components/document-master/steps/StepRoute.vue';
import StepContractor from '@/components/document-master/steps/StepContractor.vue';
import StepExecution from '@/components/document-master/steps/StepExecution.vue';
import StepLaunch from '@/components/document-master/steps/StepLaunch.vue';

export const DOCUMENT_MASTER_COMPONENT = {
  [StepCode.Type]: StepType,
  [StepCode.Content]: StepContent,
  [StepCode.Deadline]: StepDeadline,
  [StepCode.Barcode]: StepBarcode,
  [StepCode.Resolutions]: StepResolution,
  [StepCode.Files]: StepFiles,
  [StepCode.Links]: StepLinks,
  [StepCode.Clients]: StepClients,
  [StepCode.Route]: StepRoute,
  [StepCode.Contractor]: StepContractor,
  [StepCode.Execution]: StepExecution,
  [StepCode.Launch]: StepLaunch,
};
