<template>
  <thead class="thead-table-active-filters">
    <tr>
      <td
        class="cell"
        colspan="1000"
      >
        <div
          class="table-active-filters"
          :class="{ small: isSmall, _hidden: !(hasFilters || (hasOrderBy && !isSmall)) }"
        >
          <div
            v-if="hasFilters"
            class="table-active-filters-wrapper"
          >
            <button
              type="button"
              class="table-active-filters-delete-all"
              @click="tableDeleteALLFiltersAndOrderBy"
            >
              <CloseOnlyIcon />
            </button>
            <div class="table-active-filters-title">Фильтр:</div>
            <div
              v-for="(col, key_column, index) in tableFilterData"
              :key.prop="col"
              class="table-active-filters-filter"
              :style="getMaxWidth"
            >
              <div
                v-if="configs_table.info[key_column] !== undefined"
                class="filter-list"
                data-tooltip-position="bottom"
                data-tooltip-size="auto"
                data-tooltip-text-align="center"
                role="tooltip"
              >
                <button
                  type="button"
                  @click="tableDeleteFilter(key_column)"
                >
                  <component
                    :is="configs_table.info[key_column].type"
                    :key_column="key_column"
                    :config="col"
                    :configs_table="configs_table.info[key_column]"
                  />
                  <CloseOnlyIcon />
                </button>
                <div
                  v-if="index < countFilters - 1"
                  class="condition"
                >
                  <b>{{ tableWhereConditionFormatted }}</b>
                </div>
                <div class="tooltip-aria-label">
                  <component
                    :is="configs_table.info[key_column].type"
                    :key_column="key_column"
                    :config="col"
                    :configs_table="configs_table.info[key_column]"
                  />
                </div>
              </div>
              <div
                v-else-if="key_column === 'search'"
                class="filter-list"
                data-tooltip-position="bottom"
                data-tooltip-size="auto"
                data-tooltip-text-align="center"
                role="tooltip"
              >
                <button
                  type="button"
                  @click="tableDeleteFilter(key_column)"
                >
                  <SearchFilterBadge
                    :config="col"
                    :configs_table_info="configs_table.info"
                  />
                  <CloseOnlyIcon />
                </button>
                <div
                  v-if="index < countFilters - 1"
                  class="condition"
                >
                  <b>{{ tableWhereConditionFormatted }}</b>
                </div>
                <div class="tooltip-aria-label">
                  <SearchFilterBadge
                    :config="col"
                    :configs_table_info="configs_table.info"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="!isSmall && hasOrderBy"
            class="sort"
          >
            <div class="table-active-filters-title _sort">Сортировка:</div>
            <div class="table-active-filters-filter sortable-filter _sort">
              <button
                type="button"
                @click="tableDelOrderBy"
              >
                <span class="field-title sortable-field-title">{{ configs_table.info[tableOrderBy.key].title }}</span>
                <span v-if="tableOrderBy.value === 'DESC'">↓</span>
                <span v-else>↑</span>
                <CloseOnlyIcon />
              </button>
            </div>
          </div>
        </div>
      </td>
    </tr>
  </thead>
</template>

<script>
import { defineComponent } from 'vue';
import Constants from '@/configs/constants';
import SearchFilterBadge from '@/components/table/filterBadges/SearchFilterBadge';
import CloseOnlyIcon from '@/components/icons/CloseOnlyIcon';
import VuexAdapter from '@/services/vuex-adapter.js';
import { TABLE_CONDITION_AND } from '@/configs/tables/table';
import DateFilterBadge from '@/components/table/filterBadges/DateFilterBadge.vue';
import FlagFilterBadge from '@/components/table/filterBadges/FlagFilterBadge.vue';
import IdentifierFilterBadge from '@/components/table/filterBadges/IdentifierFilterBadge.vue';
import IntegerFilterBadge from '@/components/table/filterBadges/IntegerFilterBadge.vue';
import StringFilterBadge from '@/components/table/filterBadges/StringFilterBadge.vue';
import AsyncIdentifier from '@/components/table/filterBadges/AsyncIdentifier.vue';
import { useTableStore } from '@/stores/table.js';

export default defineComponent({
  name: 'TableActiveFilters',
  components: {
    CloseOnlyIcon,
    SearchFilterBadge,
    Date: DateFilterBadge,
    Flag: FlagFilterBadge,
    Identifier: IdentifierFilterBadge,
    Integer: IntegerFilterBadge,
    String: StringFilterBadge,
    AsyncIdentifier: AsyncIdentifier,
  },
  props: {
    configs_table: Object,
    component_width: Number,
    isSmall: {
      default: false,
      type: Boolean,
    },
  },
  data: () => ({
    filtersConfig: {},
    tableStore: useTableStore(),
  }),
  computed: {
    tableFilterData() {
      return this.tableStore[VuexAdapter.filterTableNameGetter(this.configs_table.name)];
    },
    tableWhereCondition() {
      return this.tableStore[VuexAdapter.conditionTableNameGetter(this.configs_table.name)];
    },
    tableOrderBy() {
      return this.tableStore[VuexAdapter.orderByTableNameGetter(this.configs_table.name)];
    },
    tableWhereConditionFormatted() {
      return this.tableWhereCondition === TABLE_CONDITION_AND ? 'И' : 'ИЛИ';
    },
    hasFilters() {
      return Object.keys(this.tableFilterData).length > 0;
    },
    hasOrderBy() {
      return Object.keys(this.tableOrderBy).length > 0;
    },
    countFilters() {
      return Object.keys(this.tableFilterData).length;
    },
    getMaxWidth() {
      const mw =
        this.component_width < Constants.resolutionPoints.tiny ? this.component_width / 2 : this.component_width - 15;
      return 'max-width: ' + Math.ceil(mw) + 'px';
    },
  },
  methods: {
    tableDelOrderBy() {
      this.tableStore[VuexAdapter.delOrderByTableNameAction(this.configs_table.name)]();
    },
    tableDeleteALLFiltersAndOrderBy() {
      this.tableStore[VuexAdapter.delFilterAndOrderByTableNameAction(this.configs_table.name)]();
    },
    tableDeleteFilter(key_column) {
      this.tableStore[VuexAdapter.delFilterTableNameAction(this.configs_table.name)](key_column);
    },
  },
});
</script>

<style scoped lang="scss">
.table-active-filters {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &._hidden {
    display: none;
  }
}

.cell {
  padding: 0;
}

.table-active-filters-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.table-active-filters-delete-all {
  margin-right: 12px;
}

.table-active-filters-title {
  margin-right: 12px;

  &._sort {
    margin-right: 12px;
    margin-left: 8px;
  }
}

.table-active-filters-filter {
  margin-right: 4px;

  &._sort {
    margin-right: 8px;
  }
}

.filter-list {
  display: flex;
  align-items: center;
}

.condition {
  margin-left: 8px;
  margin-right: 4px;
}

.sort {
  display: flex;
  align-items: center;
}
</style>
