<template>
  <span>
    <span class="field-title">Поиск</span>
    <span class="field-value">"{{ config[0].search_value || '' }}" {{ '(' + fieldsTitles + ')' }}</span>
  </span>
</template>

<script>
export default {
  name: 'SearchFilterBadge',
  props: ['config', 'configs_table_info'],
  computed: {
    fieldsTitles() {
      const titles = [];

      if (this.config[0].search_fields !== undefined) {
        this.config[0].search_fields.forEach((key) => {
          titles.push(this.configs_table_info[key].title || key);
        });
      }

      return titles.join(', ');
    },
  },
};
</script>

<style scoped lang="scss">
.field-title,
.field-value {
  margin-right: 4px;
}
</style>
