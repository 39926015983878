<template>
  <div class="header-client-preview">
    <LogoIcon
      class="logo"
      alt="АЛЬФА ДОК"
    />

    <div class="content">
      <div class="side">
        <p class="paragraph">
          <span class="item _muted">№</span>
          <a
            class="item"
            :href="`/client/${client['CLIENT_ID']}`"
            target="_blank"
          >
            {{ numberClient }}
          </a>
        </p>

        <p class="paragraph">
          <span class="item _muted">создан</span>
          <span class="item">{{ createDate }}</span>
        </p>
      </div>

      <div class="side">
        <p class="paragraph">
          <span class="item _muted">Приоритет:</span>
          <span class="item">{{ client['Приоритет'] }}</span>
        </p>

        <p class="paragraph">
          <span class="item _muted">Тип:</span>
          <span class="item">{{ client['Тип'] }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { getDate } from '@/common/utils/utils.js';
import LogoIcon from '@/assets/icons/logo.svg';

export default {
  name: 'HeaderClientPreview',
  components: {
    LogoIcon,
  },
  props: ['client'],
  computed: {
    numberClient() {
      return this.client['Номер'] && this.client['Номер'] !== '' ? this.client['Номер'] : '-';
    },
    createDate() {
      return this.getDate(this.client['Создан']);
    },
  },
  methods: {
    getDate,
  },
};
</script>

<style scoped lang="scss">
.header-client-preview {
  padding-bottom: 4px;
  display: flex;

  border-bottom: 1px solid var(--color-gray-100);
}

.logo {
  margin-right: 16px;
  width: 32px;
  height: 32px;
}

.content {
  flex-grow: 1;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.side {
  display: flex;
  align-items: center;
}

.paragraph {
  &:not(:last-child) {
    margin-right: 12px;
  }
}

.item {
  &:not(:last-child) {
    margin-right: 4px;
  }
}
</style>
