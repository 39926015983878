<template>
  <DropdownUi
    tag="ul"
    placement="bottom-end"
    :offset="4"
    close-if-inner-click
    close-if-outside-click
  >
    <template #anchor="{ toggle }">
      <button
        class="toggle"
        :class="{ _small: isCollapsed }"
        @click="toggle"
      >
        <UserIcon class="toggle-icon" />
      </button>

      <SettingsSystemModal ref="settingsSystemModal" />
    </template>

    <DropdownItemUi tag="li">
      <UserIcon v-prefix />
      {{ userName }}
    </DropdownItemUi>

    <DropdownItemUi
      tag="li"
      @click="showSettings"
    >
      <GearIcon v-prefix />
      Настройки
    </DropdownItemUi>

    <DropdownItemUi
      tag="li"
      @click="logout"
    >
      <LogOutIcon v-prefix />
      Выход
    </DropdownItemUi>
  </DropdownUi>
</template>

<script>
import { defineComponent } from 'vue';
import { mapActions, mapState } from 'pinia';
import UserIcon from '@/assets/icons/user.svg';
import GearIcon from '@/assets/icons/gear.svg';
import LogOutIcon from '@/components/icons/LogOutIcon';
import SettingsSystemModal from '@/components/modals/System/SettingsSystemModal';
import DropdownUi from '@/components/ui/DropdownUi.vue';
import DropdownItemUi from '@/components/ui/DropdownItemUi.vue';
import { useAuthStore } from '@/stores/auth.js';
import { useUserStore } from '@/stores/user.js';

export default defineComponent({
  name: 'ToolbarRight',
  components: {
    DropdownItemUi,
    SettingsSystemModal,
    UserIcon,
    GearIcon,
    LogOutIcon,
    DropdownUi,
  },
  props: {
    isCollapsed: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(useUserStore, { userName: 'name' }),
  },
  methods: {
    ...mapActions(useAuthStore, ['logout']),
    showSettings() {
      this.$refs.settingsSystemModal.show();
    },
  },
});
</script>

<style scoped lang="scss">
.toggle {
  width: 40px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  background-color: var(--color-gray-200);
  transition: background-color var(--transition-fast);

  &._small {
    width: 28px;
    height: 28px;
  }

  @media (hover: hover) {
    &:hover {
      background-color: var(--color-gray-300);
    }
  }
}
</style>
