import { IgnorableException } from '@/common/models/exception/ignorable-exception.js';
import { CanceledError } from 'axios';

export default class Exception {
  static init(app) {
    Exception.originalPromiseHandler = window.onunhandledrejection;
    window.onunhandledrejection = Exception.onPromiseError;

    Exception.originalVueHandler = app.config.errorHandler;
    app.config.errorHandler = Exception.onVueError;
  }

  static onPromiseError(event) {
    const error = event.reason;

    if (error instanceof IgnorableException || error instanceof CanceledError) {
      event.preventDefault();
      return;
    }

    if (Exception.originalPromiseHandler) {
      Exception.originalPromiseHandler(event);
    } else {
      throw event;
    }
  }

  static onVueError(error, ...args) {
    if (error instanceof IgnorableException || error instanceof CanceledError) {
      return;
    }

    if (Exception.originalVueHandler) {
      Exception.originalVueHandler(error, ...args);
    } else {
      throw error;
    }
  }
}
