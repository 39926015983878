import { ref, watch } from 'vue';
import { NotifyTypes } from '@/configs/notify-types.js';
import State from '@/common/enums/state.js';
import FileApi from '@/services/api/file-api.js';
import { DOC_FILES_GET } from '@/configs/end-points.js';
import { fileSize } from '@/common/utils/utils.js';
import { usePreviewStore } from '@/stores/preview.js';
import { notify } from '@kyvg/vue3-notification';

export default function useUpload({ parentTableId, parentId, onSuccess }) {
  const previewStore = usePreviewStore();

  const files = ref([]);
  const comment = ref('');
  const isLoading = ref(false);
  const error = ref('');

  watch(files, () => (error.value = ''));

  async function upload() {
    const notUploaded = files.value.filter((file) => file.state !== State.Defined);

    if (!notUploaded.length) {
      error.value = 'Нет выбранных файлов';
      return;
    }

    isLoading.value = true;
    notUploaded.forEach((file) => (file.state = State.Loading));

    const responses = await FileApi.uploadList({
      parentTableId,
      parentId: parentId.value,
      files: notUploaded,
      comment: comment.value,
    });

    isLoading.value = false;

    let hasSuccess = false;
    let hasError = false;

    responses.forEach((response, index) => {
      const file = notUploaded[index];
      if (response.status === 'fulfilled') {
        file.state = State.Defined;
        hasSuccess = true;
      } else {
        file.state = State.Error;
        file.error = response.reason;
        notify({
          type: NotifyTypes.Error,
          text: `При загрузке файла "${file.name}" (${fileSize(file.size)}) возникла ошибка.`,
          // тут бек не стандартно отвечает, но в этом случае в response будет стандартный объект ошибки
          data: response.reason,
        });
        hasError = true;
      }
    });

    if (hasSuccess) {
      void previewStore.refreshPartlyPreviewAction(DOC_FILES_GET);
    }

    if (!hasError) {
      notify({
        type: NotifyTypes.Success,
        text: 'Файлы успешно загружены',
      });
      onSuccess?.();
    }
  }

  return {
    files,
    comment,
    error,
    isLoading,
    upload,
  };
}
