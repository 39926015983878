<template>
  <ModalUi
    ref="modal"
    title="Отменить / снять с контроля"
    @hidden="onHidden"
    @shown="onShown"
  >
    <template #body>
      <TextareaUi
        v-model="comment"
        label="Вы собираетесь остановить (снять документ с контроля). Данная операция необратима! Продолжить?"
        placeholder="Комментарий"
      />

      <CloseDocOrdersModal
        ref="confirmModal"
        @close="onConfirmModalClose"
        @hidden="onConfirmModalHidden"
      />
    </template>

    <template #footer>
      <CancelButton @click="hide" />

      <ButtonUi @click="apply">
        <StopCircleIcon v-prefix />
        <span>Остановить</span>
      </ButtonUi>
    </template>
  </ModalUi>
</template>

<script>
import { defineComponent } from 'vue';
import { notLike } from '@/common/utils/utils.js';
import { mapActions } from 'pinia';
import VuexAdapter from '@/services/vuex-adapter.js';
import { DOC_GANTT_ITEMS, DOC_ROUTE_POINT_MARK_CONFIRM } from '@/configs/end-points.js';
import CancelButton from '@/components/buttons/CancelButton.vue';
import TextareaUi from '@/components/ui/TextareaUi.vue';
import ConstantsRPS from '@/configs/route-point-statuses.js';
import { NotifyTypes } from '@/configs/notify-types.js';
import ModalUi from '@/components/ui/ModalUi.vue';
import ModalMixin from '@/mixins/modal-mixin.js';
import CloseDocOrdersModal from '@/components/modals/Doc/CloseDocOrdersModal.vue';
import DocumentApi from '@/services/api/document-api.js';
import ButtonUi from '@/components/ui/ButtonUi.vue';
import StopCircleIcon from '@/assets/icons/circle/stop.svg';
import Emitter from '@/services/emitter.js';
import { useActionsStore } from '@/stores/actions.js';
import { useListsStore } from '@/stores/lists.js';

export default defineComponent({
  name: 'StopDocModal',
  components: {
    StopCircleIcon,
    ButtonUi,
    CloseDocOrdersModal,
    ModalUi,
    TextareaUi,
    CancelButton,
  },
  mixins: [ModalMixin],
  props: {
    docId: {
      type: Number,
      required: true,
    },
    events: {
      type: Array,
    },
  },
  data: () => ({
    isClosingConfirmed: null,
    comment: '',
    unconfirmedOrdersRpIds: [],
  }),
  methods: {
    ...mapActions(useActionsStore, {
      docRoutePointMarkConfirmAction: VuexAdapter.getNameAction(DOC_ROUTE_POINT_MARK_CONFIRM),
    }),
    ...mapActions(useListsStore, {
      docGanttItemsAction: VuexAdapter.getNameAction(DOC_GANTT_ITEMS),
    }),
    async onShown() {
      this.showLoader();

      setTimeout(async () => {
        const data = await this.docGanttItemsAction({ doc_id: this.docId });

        if (data?.data?.count) {
          for (const item of data.data.items) {
            if (ConstantsRPS.rp_status_type_completed_id == item.RPS_ID && notLike(item.PARENTS, '##')) {
              this.unconfirmedOrdersRpIds.push(item.RP_ID);
            }
          }

          if (this.unconfirmedOrdersRpIds.length) {
            this.$refs.confirmModal.show();
          }
        }
        this.hideLoader();
      });
    },
    async apply() {
      this.showLoader();
      try {
        if (this.isClosingConfirmed && this.unconfirmedOrdersRpIds.length) {
          for (const rp_id of this.unconfirmedOrdersRpIds) {
            await this.docRoutePointMarkConfirmAction({
              rp_id,
              comment: 'Подтверждено при остановке документа',
            });
          }
        }

        await DocumentApi.stop(this.docId, this.comment);

        this.throwEvents();
      } catch (error) {
        this.$notify({
          type: NotifyTypes.Error,
          text: 'При остановке документа возникла ошибка.',
          data: error,
        });
        this.hideLoader();
      }
    },
    throwEvents() {
      this.events.forEach((value) => Emitter.emit(value));
      this.hide();
    },
    onHidden() {
      this.isClosingConfirmed = null;
      this.comment = '';
      this.unconfirmedOrdersRpIds = [];
    },
    onConfirmModalClose(isConfirmed) {
      this.isClosingConfirmed = isConfirmed;
      this.$refs.confirmModal.hide();
    },
    onConfirmModalHidden() {
      if (typeof this.isClosingConfirmed !== 'boolean') {
        this.hide();
      }
    },
  },
});
</script>
