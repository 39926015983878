<template>
  <div>
    <filters-header :show-add="true" />

    <div
      v-for="(condition, index) in conditions"
      :key.prop="condition"
      class="fields"
      :hidden="!condition"
    >
      <switch-input-form
        v-if="index !== 0"
        v-model="condition.algorithm"
        class="item switch-input-filter-table"
        small
      />

      <combobox-ui
        v-model="condition.select"
        class="item"
        :options="options"
        no-clear
      />

      <number-input-form
        v-if="showNumberInput(condition)"
        v-model="condition.input"
        class="item"
        return-string
        :clearable="false"
      />
    </div>

    <filter-buttons
      @clear="cleanFilter"
      @apply="applyFilter"
    />

    <order-by
      :data="data"
      :col="col"
      :key_column="key_column"
    >
      <template #title> Сортировка по числам</template>
      <template #variant-asc> 0-1<br />По возрастанию</template>
      <template #variant-desc> 1-0<br />По убыванию</template>
    </order-by>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import FilterMixin from '@/mixins/table/filter-mixin.js';

export default defineComponent({
  name: 'IntegerFilter',
  mixins: [FilterMixin],
  data: () => ({
    onlySelect: ['is not null', 'is null'],
    options: [
      {
        code: '=',
        label: 'Равно',
      },
      {
        code: '>',
        label: 'Больше',
      },
      {
        code: '>=',
        label: 'Больше или равно',
      },
      {
        code: '<',
        label: 'Меньше',
      },
      {
        code: '<=',
        label: 'Меньше или равно',
      },
      {
        code: '<>',
        label: 'Не равно',
      },
      {
        code: 'is not null',
        label: 'Заполнено',
      },
      {
        code: 'is null',
        label: 'Не заполнено',
      },
    ],
    sqlGenerator: 'integerSql',
  }),
  methods: {
    showNumberInput(condition) {
      return condition.select !== null && !this.onlySelect.includes(condition.select.code);
    },
  },
});
</script>

<style scoped lang="scss">
.fields {
  margin-bottom: 12px;
}

.item {
  &:not(:last-child) {
    margin-bottom: 8px;
  }
}
</style>
