<script setup>
import CircleCheckIcon from '@/assets/icons/circle/check.svg';
import ButtonUi from '@/components/ui/ButtonUi.vue';
import CircleCloseIcon from '@/assets/icons/circle/close.svg';
import CancelButton from '@/components/buttons/CancelButton.vue';
import ModalUi from '@/components/ui/ModalUi.vue';
import useModal from '@/composables/use-modal.js';

defineProps({
  fileName: {
    type: String,
    required: true,
  },
});

defineEmits(['save', 'discard', 'cancel']);

const { modal, show, hide } = useModal();

defineExpose({ show, hide });
</script>

<template>
  <ModalUi
    ref="modal"
    title="Внимание"
    width="xs"
    popup
    @close="$emit('cancel')"
  >
    <template #body>
      <p class="paragraph">
        <strong class="_extra">
          Файл <span class="file-name">«{{ fileName }}»</span> был изменен
        </strong>
      </p>
      <p class="paragraph">Хотите его добавить в документ с новой редакцией?</p>
    </template>

    <template #footer>
      <CancelButton @click="$emit('cancel')" />

      <ButtonUi
        color="black"
        @click="$emit('discard')"
      >
        <CircleCloseIcon v-prefix />
        <span>Hет</span>
      </ButtonUi>

      <ButtonUi @click="$emit('save')">
        <CircleCheckIcon v-prefix />
        <span>Да</span>
      </ButtonUi>
    </template>
  </ModalUi>
</template>

<style scoped lang="scss">
.paragraph {
  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.file-name {
  word-break: break-word;
}
</style>
