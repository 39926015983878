<template>
  <ModalUi
    ref="modal"
    title="Настройки таблицы"
  >
    <template #body>
      <ComboboxUi
        v-model="fix"
        class="item"
        label="Фиксация столбцов"
        :options="fix_options"
        placeholder="Нет фиксированных"
      />

      <CheckboxForm
        v-model="checked"
        label="Выбрать все / снять выделение"
        class="item preferences-modal-toggle-checked-all"
      />

      <PreferencesList
        class="item"
        :configs="configs"
      />
    </template>

    <template #footer>
      <ButtonUi
        color="white"
        @click="setDefault"
      >
        <MenuTableSettingArrowCycleIcon v-prefix />
        <span>По-умолчанию</span>
      </ButtonUi>

      <ApplyButton @click="apply" />
    </template>
  </ModalUi>
</template>

<script>
import { defineComponent } from 'vue';
import PreferencesList from '@/components/table/preferencesTable/PreferencesList';
import MenuTableSettingArrowCycleIcon from '@/components/icons/MenuTableSettingArrowCycleIcon';
import VuexAdapter from '@/services/vuex-adapter.js';
import CheckboxForm from '@/components/form/CheckboxForm';
import ModalUi from '@/components/ui/ModalUi.vue';
import ModalMixin from '@/mixins/modal-mixin.js';
import ComboboxUi from '@/components/ui/ComboboxUi.vue';
import ButtonUi from '@/components/ui/ButtonUi.vue';
import ApplyButton from '@/components/buttons/ApplyButton.vue';
import Emitter from '@/services/emitter.js';
import { useTableStore } from '@/stores/table.js';

export default defineComponent({
  name: 'PreferencesTableModal',
  components: {
    ApplyButton,
    ButtonUi,
    ComboboxUi,
    ModalUi,
    CheckboxForm,
    PreferencesList,
    MenuTableSettingArrowCycleIcon,
  },
  mixins: [ModalMixin],
  props: {
    configs: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    fix: null,
    fix_options: [],
    checked: false,
    tableStore: useTableStore(),
  }),
  computed: {
    tableFixCols() {
      return this.tableStore[VuexAdapter.fixColsTableNameGetter(this.configs.name)];
    },
  },
  watch: {
    checked() {
      this.checked ? this.on_all() : this.off_all();
    },
  },
  mounted() {
    for (let i = 2; i <= 7; i++) {
      this.fix_options[i - 1] = {
        label: 'Колонка: 1-' + i,
        code: i - 1,
      };
    }

    this.fix = this.tableFixCols;
  },
  methods: {
    apply() {
      this.setFixCols();
      Emitter.emit('preferences-table-modal-apply-table-' + this.configs.name);
      this.hide();
    },
    setDefault() {
      this.fix = null;
      this.setFixCols();
      this.$emit('preferences-table-modal-default-table-' + this.configs.name);
      this.hide();
    },
    on_all() {
      this.$emit('preferences-table-modal-on-all-table-' + this.configs.name);
    },
    off_all() {
      this.$emit('preferences-table-modal-off-all-table-' + this.configs.name);
    },
    setFixCols() {
      this.tableSetFixCols(this.fix);
    },
    tableSetFixCols(data) {
      this.tableStore[VuexAdapter.fixColsTableNameMutation(this.configs.name)](data);
    },
  },
});
</script>

<style scoped lang="scss">
.item {
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}
</style>
