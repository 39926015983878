<template>
  <span
    class="string-column"
    v-html="value"
  />
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { useMaskStore } from '@/stores/mask.js';

export default {
  name: 'StringColumn',
  props: ['data', 'config', 'dataRow'],
  mounted() {
    if (this.hasMask) {
      this.fetchMaskAction(this.mask);
    }
  },
  updated() {
    if (this.hasMask) {
      this.fetchMaskAction(this.mask);
    }
  },
  computed: {
    ...mapState(useMaskStore, ['maskStringGetter']),
    hasMask() {
      return this.config?.maskField;
    },
    mask() {
      return this.dataRow[this.config?.maskField];
    },
    value() {
      if (this.hasMask) {
        return this.maskStringGetter(this.mask, this.data);
      }

      return this.data;
    },
  },
  methods: {
    ...mapActions(useMaskStore, ['fetchMaskAction']),
  },
};
</script>

<style scoped lang="scss">
.string-column {
  display: block;
  width: 100%;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
