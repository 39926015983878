<template>
  <ModalUi
    ref="modal"
    title="Добавление комментария"
    :prevent-closure="preventClosure"
    @hidden="onHidden"
  >
    <template #body>
      <SolutionAutocorrect
        class="item"
        @add-solution-autocorrect="addSolutionAutocorrect"
      />

      <TextareaUi
        ref="textarea"
        v-model="comment"
        class="item"
        placeholder="Текст комментария"
        focus-on-mount
      />

      <UploadUi
        v-if="showUpload"
        v-model="files"
        class="item"
        :readonly="isUploading"
      />
    </template>

    <template #footer>
      <CancelButton @click="close" />
      <AddButton @click="apply" />
    </template>
  </ModalUi>
</template>

<script>
import { defineComponent } from 'vue';
import SolutionAutocorrect from '@/components/doc/solution/SolutionAutocorrect';
import { mapActions } from 'pinia';
import VuexAdapter from '@/services/vuex-adapter.js';
import { CLIENTS_COMMENTS_GET, DOC_COMMENTS_GET, SYSTEM_COMMENT_ADD } from '@/configs/end-points.js';
import TextareaUi from '@/components/ui/TextareaUi.vue';
import { DOCS_TABLE } from '@/configs/db-tables.js';
import { NotifyTypes } from '@/configs/notify-types.js';
import ModalUi from '@/components/ui/ModalUi.vue';
import UploadUi from '@/components/ui/UploadUi.vue';
import ModalMixin from '@/mixins/modal-mixin.js';
import UploadMixin from '@/mixins/upload-mixin.js';
import CancelButton from '@/components/buttons/CancelButton.vue';
import AddButton from '@/components/buttons/AddButton.vue';
import { useActionsStore } from '@/stores/actions.js';
import { useAutocorrectStore } from '@/stores/autocorrect.js';
import { usePreviewStore } from '@/stores/preview.js';

export default defineComponent({
  name: 'AddCommentModal',
  components: {
    AddButton,
    CancelButton,
    UploadUi,
    ModalUi,
    TextareaUi,
    SolutionAutocorrect,
  },
  mixins: [ModalMixin, UploadMixin],
  props: {
    parentTableId: {
      type: Number,
      required: true,
    },
    parentId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      files: [],
      comment: '',
    };
  },
  computed: {
    showUpload() {
      return this.parentTableId === DOCS_TABLE;
    },
  },
  methods: {
    ...mapActions(useAutocorrectStore, ['addCommentAutocorrect']),
    ...mapActions(usePreviewStore, ['refreshPartlyPreviewAction']),
    ...mapActions(useActionsStore, {
      addCommentsAction: VuexAdapter.getNameAction(SYSTEM_COMMENT_ADD),
    }),
    onHidden() {
      this.files = [];
      this.comment = '';
    },
    addSolutionAutocorrect(text) {
      this.comment = this.comment + text + ' ';
      this.$refs.textarea.focus();
    },
    async apply() {
      if (!this.comment) {
        // TODO: Использовать валидацию
        this.$notify({
          text: 'Введите комментарий',
          type: NotifyTypes.Error,
        });
        return;
      }

      this.disableClosure();

      if (this.files.length) {
        const isError = await this.uploadFiles(this.files, {
          parentTableId: DOCS_TABLE,
          parentId: this.parentId,
        });

        if (isError) {
          this.enableClosure();
          return;
        }
      }

      this.showLoader();

      try {
        await this.addCommentsAction({
          parent_table_id: this.parentTableId,
          parent_id: this.parentId,
          comment: this.comment,
        });
        void this.refreshPartlyPreviewAction(CLIENTS_COMMENTS_GET);
        void this.refreshPartlyPreviewAction(DOC_COMMENTS_GET);
        void this.addCommentAutocorrect(this.comment);
        this.hide();
      } catch (error) {
        this.$notify({
          type: NotifyTypes.Error,
          text: 'При добавлении комментария возникла ошибка.',
          data: error,
        });
        this.hideLoader();
        this.enableClosure();
      }
    },
  },
});
</script>

<style scoped lang="scss">
.item {
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}
</style>
