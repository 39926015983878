<template>
  <FatalError
    :title="'404'"
    :sub-title="'Упс, что-то пошло не так...'"
    :description="'Страница, которую Вы ищете, не существует!<br/>Вернитесь назад или перейдите на главную страницу.'"
  />
</template>

<script>
import FatalError from '@/components/general/FatalError';

export default {
  name: 'NotFound',
  components: {
    FatalError,
  },
};
</script>
