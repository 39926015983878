<template>
  <ReducedBadge
    :value="counter"
    :color="color"
  />
</template>

<script>
import ReducedBadge from '@/components/common/ReducedBadge.vue';

export default {
  name: 'MenuCounter',
  components: {
    ReducedBadge,
  },
  props: {
    counters: {
      type: Object,
      required: true,
    },
  },
  computed: {
    counterNew() {
      return Math.max(this.counters.count_new, 0);
    },
    counterExpired() {
      return Math.max(this.counters.count_expired, 0);
    },
    counterAll() {
      return Math.max(this.counters.count, 0);
    },
    counter() {
      return this.counterNew || this.counterExpired || this.counterAll;
    },
    color() {
      if (this.counterNew) {
        return 'blue';
      } else if (this.counterExpired) {
        return 'red';
      } else {
        return 'gray';
      }
    },
  },
};
</script>
