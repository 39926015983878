<template>
  <div class="radio-card-form">
    <div class="radio-card-form__container">
      <div
        v-for="option in options"
        :key.prop="option.code"
        class="radio-card-form__container--item"
        :class="{
          'radio-card-form__container--item__disabled': disabled,
        }"
        :style="optionStyle(option)"
        @click="select(option)"
      >
        {{ option.label }}
      </div>
    </div>
    <span
      v-if="hint || error"
      class="form--input-caption"
      :class="error ? 'form--input-caption__error' : ''"
    >
      {{ error ? error : hint }}
    </span>
  </div>
</template>

<script>
/** @deprecated Используйте ui-компоненты */
export default {
  name: 'RadioCardsForm',
  props: {
    /**
     * Значение поля
     * @type {any}
     */
    modelValue: {},
    /**
     * Сообщение об ошибке.
     */
    error: {
      type: String,
      default: '',
    },
    /**
     * Подсказка.
     */
    hint: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      modelProxy: null,
      activeColor: '#DAF6CA',
    };
  },
  computed: {
    optionStyle() {
      return (option) => {
        const styles = {};
        if (option.code === this.modelProxy) {
          styles['background-color'] = option?.activeColor || this.activeColor;
        }

        if (option?.full) {
          styles['grid-column'] = 'span 2';
        }

        return styles;
      };
    },
  },
  mounted() {
    this.modelProxy = this.modelValue;
  },
  methods: {
    select(option) {
      if (this.disabled) {
        return;
      }
      this.modelProxy = option.code;
      this.$emit('update:modelValue', this.modelProxy);
    },
  },
};
</script>
