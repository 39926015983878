/** @deprecated Используйте @/common/enums/end-point */
export const DOC_COMMENTS_GET = 'doc/comments/get/';
export const DOCS_VIEWS_ALL = 'docs/views/all/';
export const DOC_LINKS_GET = 'doc/links/get/';
export const CLIENTS_GET = 'clients/get/';
export const COMMISSIONS_GET = 'commissions/get/';
export const CONTROL_GET = 'control/get/';
export const DOC_ACCESS = 'doc/access/';
export const DOC_FILES_GET = 'doc/files/get/';
export const DOC_FILES_SIGNATURES_GET = 'doc/files/signatures/get/';
export const DOC_SIGNATURES_FILES_GET = 'doc/signatures/files/get/';
export const SYSTEM_DOC_MASK_PARAMS = 'system/doc/mask/params/';
export const DOCS_CLIENTS_GET = 'docs/clients/get/';
export const DOCS_AUDIT_ALL = 'docs/audit/all/';
export const DOCS_GET = 'docs/get/';
export const DOC_ROUTES_POINTS_GET = 'doc/routespoints/get/';
export const ROUTES_POINTS_RESPONSIBLE_DIRECTORY_GET = 'routespoints/responsibles/directory/get/';
export const USERS_GET = 'users/get/';
export const USERS_ACTIVE = 'users/active/';
export const CLIENTS_TYPES_GET = 'clients/types/get/';
export const SEGMENTS_GET = 'segments/get/';
export const REGIONS_GET = 'regions/get/';
export const MANAGERS_GET = 'managers/get/';
export const CLIENTS_ADDRESSES_GET = 'clients/addresses/get/';
export const ADDRESS_TYPES_GET = 'address/types/get/';
export const CLIENTS_BANK_DETAILS_GET = 'clients/bankdetails/get/';
export const CATEGORIES_GET = 'categories/get/';
export const CLIENTS_COMMENTS_GET = 'clients/comments/get/';
export const CLIENTS_CONTACTS_GET = 'clients/contacts/get/';
export const CLIENTS_DOCS_GET = 'clients/docs/get/';
export const CONTACT_TYPES_GET = 'contact/types/get/';
export const DOC_TYPES_GET = 'doc/types/get/';
export const PROJECTS_GET = 'projects/get/';
export const RECIPIENTS_GET = 'recipients/get/';
export const DOC_GANTT_ITEMS = 'doc/gantt/items/';
export const DOC_ROUTESPOINTS_DIRECTORY_GET = 'doc/routespoints/directory/get/';
export const DOCS_GANTT_ITEMS = 'docs/gantt/items/';
export const DOC_ADD = 'doc/add/';
export const DOC_DELETE = 'doc/delete/';
export const CONTROL_COUNT = 'control/count/';
export const CONTROL_COLORS = 'control/colors/';
export const DOC_ROUTE_ADD = 'doc/route/add/';
export const DOC_ROUTE_POINT_ADD = 'doc/routepoint/add/';
export const DOC_ROUTE_POINT_MARK_CONFIRM = 'doc/routepoint/mark/confirm/';
export const DOC_ACTIVATION_SET = 'doc/activation/set/';
export const COMMISSIONS_COUNT = 'commissions/count/';
export const COMMISSIONS_COLORS = 'commissions/colors/';
export const DOC_ROUTE_POINT_MARK_VIEW = 'doc/routepoint/mark/view/';
export const DOC_ROUTE_POINT_ACCEPT = 'doc/routepoint/accept/';
export const DOC_ROUTE_POINT_DECLINE = 'doc/routepoint/decline/';
export const DOC_ROUTE_POINT_MARK_FINISH = 'doc/routepoint/mark/finish/';
export const SYSTEM_COMMENT_ADD = 'system/comment/add/';
export const SYSTEM_INTERFACE_ELEMENTS_GET = 'system/interface/elements/get/';
export const USER_ROLES_GET = 'user/roles/get/';
export const AUTO_REPLACES_GET = 'autoreplaces/get/';
export const ADDRESS_ADD = 'address/add/';
export const ADDRESS_EDIT = 'address/edit/';
export const ADDRESS_DEL = 'address/del/';
export const CLIENTS_BANK_DETAILS_ADD = 'clients/bankdetails/add/';
export const CLIENTS_BANK_DETAILS_EDIT = 'clients/bankdetails/edit/';
export const CLIENTS_BANK_DETAILS_DEL = 'clients/bankdetails/del/';
export const CONTACT_ADD = 'contact/add/';
export const CONTACT_EDIT = 'contact/edit/';
export const CONTACT_DEL = 'contact/del/';
export const DOC_MARK_VIEW = 'doc/mark/view/';
export const ROUTES_STANDARD = 'routes/standard/';
export const DOC_LINK_ADD = 'doc/link/add/';
export const SYSTEM_FILE_GET = 'system/file/get/';
export const SYSTEM_FILE_SIGN_MULTIPLE = 'system/file/sign/multiple/';
export const SYSTEM_GET_DEADLINE = 'system/get/deadline/';
export const DOC_STARTDATE_SET = 'doc/startdate/set/';
export const DOC_CONTROLLER_SET = 'doc/controller/set/';
export const SYSTEM_USER_ID = 'system/user/id/';
export const ROUTES_RESPONSIBLES_UNSPECIFIED = 'routes/responsibles/unspecified/';
export const ROUTESPOINTS_RESPONSIBLES_GET = 'routespoints/responsibles/get/';
