import Api from '@/services/api/api.js';
import { DocumentEndPoint, SystemEndPoint } from '@/common/enums/end-point.js';
import { ROUTES_POINTS_TABLE } from '@/configs/db-tables.js';

export default class SystemApi {
  static async getServerId() {
    const response = await Api.post(SystemEndPoint.GetServerId);
    return response.server_id;
  }

  static async getAutoSignEnabled() {
    const response = await Api.post(SystemEndPoint.GetAutoSingStatus);
    return !!response.status;
  }

  static async markAsReadAll() {
    await Api.post(SystemEndPoint.SetReadAll, {
      table_id: ROUTES_POINTS_TABLE,
    });
  }

  static async markAsUnread(id) {
    await Api.post(SystemEndPoint.SetUnread, {
      parent_table_id: ROUTES_POINTS_TABLE,
      parent_id: id,
    });
  }

  static async markRoutePointAsRead(rpId) {
    await Api.post(DocumentEndPoint.SetRouteRead, { rp_id: rpId });
  }

  static async markDocumentAsRead(docId) {
    await Api.post(DocumentEndPoint.SetRead, { doc_id: docId });
  }

  static async checkReplicationSystem() {
    return await Api.post(SystemEndPoint.CheckReplicationSystem);
  }
}
