<script setup>
import EditIcon from '@/assets/icons/edit.svg';
import ButtonUi from '@/components/ui/ButtonUi.vue';
import { computed } from 'vue';

const props = defineProps({
  icon: {
    type: Object,
    required: true,
  },
  editable: {
    type: Boolean,
    default: false,
  },
});

defineEmits(['edit']);

const text = computed(
  () => 'Предпросмотр для данного типа файла недоступен' + (props.editable ? ', но вы можете его отредактировать' : ''),
);
</script>

<template>
  <div class="file-no-preview">
    <component
      :is="icon"
      class="icon"
    />

    <p class="paragraph">{{ text }}</p>

    <ButtonUi
      v-if="editable"
      class="edit"
      color="gray"
      mode="outline"
      @click="$emit('edit')"
    >
      <EditIcon v-prefix />
      <span>Открыть для редактирования</span>
    </ButtonUi>
  </div>
</template>

<style scoped lang="scss">
.file-no-preview {
  height: 100%;
  display: flex;

  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: var(--color-white);
}

.icon {
  margin-bottom: 24px;

  width: 100px;
  height: 100px;

  fill: var(--color-gray-300);
}

.paragraph {
  max-width: 310px;

  color: var(--color-gray-700);
  text-align: center;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}
</style>
