<template>
  <thead>
    <tr>
      <td
        colspan="1000"
        class="table-head-count"
      >
        <span v-if="isNotReady">Идет подсчет строк...</span>
        <span v-else>Найдено {{ countFormatted }} {{ row }}</span>
      </td>
    </tr>
  </thead>
</template>

<script>
import { morph, number_format } from '@/common/utils/utils.js';
import { useListsStore } from '@/stores/lists.js';

export default {
  name: 'TableHeadCount',
  props: ['configs'],
  data() {
    return {
      listsStore: useListsStore(),
    };
  },
  computed: {
    count() {
      return this.listsStore[this.configs.getterCountByFilter] || 0;
    },
    countFormatted() {
      return number_format(this.count, 0, '.', ' ');
    },
    row() {
      return morph(this.count, ['запись', 'записи', 'записей']);
    },
    isNotReady() {
      return this.listsStore[this.configs.getterCountByFilter] === null;
    },
  },
};
</script>

<style scoped lang="scss">
.table-head-count {
  padding-bottom: 8px;
  color: var(--color-gray-500);
}
</style>
