<template>
  <ComboboxUi
    color="dark-gray"
    no-clear
    :options="getLinkTypes"
    :model-value="linkType"
    @load="onLoad"
    @update:model-value="updateLinkType"
  />
</template>

<script>
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import DocumentMasterApi from '@/services/api/document-master-api.js';
import ComboboxUi from '@/components/ui/ComboboxUi.vue';
import { keys } from '@/common/utils/props-validators';
import { useMasterTypeStore } from '@/stores/master-type.js';

export default defineComponent({
  name: 'CardLinkTypes',
  components: {
    ComboboxUi,
  },
  props: {
    linkType: {
      type: Object,
      required: true,
      validator: keys('label', 'code'),
    },
    linkTypeTableId: {
      type: Number,
      required: true,
    },
    isLinkTypeInitial: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['change'],
  computed: {
    ...mapState(useMasterTypeStore, ['linkTypesIds']),
  },
  methods: {
    async getLinkTypes(signal) {
      return await DocumentMasterApi.getLinkTypes(this.linkTypesIds, this.linkTypeTableId, signal);
    },
    onLoad(options) {
      if (this.isLinkTypeInitial) {
        this.updateLinkType(options[options.length - 1]);
      }
    },
    updateLinkType(linkType) {
      this.$emit('change', { linkType, isLinkTypeInitial: false });
    },
  },
});
</script>
