<template>
  <ModalUi
    ref="modal"
    title="Добавление контакта"
    :error-message="errorMessage"
  >
    <template #body>
      <FormBuilder
        ref="formBuilder"
        v-model="form"
        margins
        :fields="contactForm"
      />
    </template>

    <template #footer>
      <CancelButton @click="hide" />
      <AddButton @click="apply" />
    </template>
  </ModalUi>
</template>

<script>
import { defineComponent } from 'vue';
import CancelButton from '@/components/buttons/CancelButton.vue';
import { mapActions, mapState } from 'pinia';
import VuexAdapter from '@/services/vuex-adapter.js';
import { CLIENTS_CONTACTS_GET, CONTACT_ADD, CONTACT_TYPES_GET } from '@/configs/end-points.js';
import FormBuilder from '@/components/form/FormBuilder';
import contactForm from '@/configs/forms/clients/contact';
import ModalUi from '@/components/ui/ModalUi.vue';
import ModalMixin from '@/mixins/modal-mixin.js';
import ErrorHelper from '@/services/error-helper.js';
import AddButton from '@/components/buttons/AddButton.vue';
import { useActionsStore } from '@/stores/actions.js';
import { usePreviewStore } from '@/stores/preview.js';
import { useListsStore } from '@/stores/lists.js';

export default defineComponent({
  name: 'AddContactModal',
  components: {
    AddButton,
    ModalUi,
    FormBuilder,
    CancelButton,
  },
  mixins: [ModalMixin],
  props: {
    parent_id: {
      type: Number,
      required: true,
    },
    parent_table_id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      contactForm: contactForm(this),
      errorMessage: null,
      form: {
        contact_id: null,
        parent_table_id: this.parent_table_id,
        parent_id: this.parent_id,
        contact_type: null,
        contact: null,
        comment: '',
        mask: '',
      },
    };
  },
  mounted() {
    this.clientContactTypesAction();
  },
  computed: {
    ...mapState(useListsStore, {
      clientContactTypesSelectGetter: VuexAdapter.getNameSelectGetter(CONTACT_TYPES_GET),
    }),
  },
  methods: {
    ...mapActions(useActionsStore, {
      addClientContactsAction: VuexAdapter.getNameAction(CONTACT_ADD),
    }),
    ...mapActions(useListsStore, {
      clientContactTypesAction: VuexAdapter.getNameAction(CONTACT_TYPES_GET),
    }),
    ...mapActions(usePreviewStore, ['refreshPartlyPreviewAction']),
    apply() {
      if (!this.$refs.formBuilder.validate()) {
        return;
      }

      this.errorMessage = null;
      this.showLoader();

      const form = {
        ...this.form,
        contact_type: this.form.contact_type.label,
      };

      this.addClientContactsAction(form)
        .then(() => {
          this.refreshPartlyPreviewAction(CLIENTS_CONTACTS_GET);
          this.hide();
        })
        .catch((error) => {
          this.errorMessage = ErrorHelper.format(error);
          this.hideLoader();
        });
    },
  },
});
</script>
