<template>
  <div
    ref="scrollContainer"
    :class="classDocumentSheetContainer"
  >
    <FileUploadModal
      ref="fileUploadModal"
      :document-id="entityIdPreview"
    />

    <LoaderUi
      v-if="!initFirstDataPreview"
      class="document-init-attempted"
      color="white"
      position="static"
      text="Загрузка данных"
    />

    <div v-else>
      <SheetDocumentPreview :ready="!docGetterLoader">
        <HeaderDocumentPreview :doc="docGetter" />
        <CommonDocumentPreview :doc="docGetter" />
        <SolutionCommissionsButtons
          v-if="!docGetterLoader && showSolutionCommissionsButtons"
          :doc="docGetter"
        />

        <div
          v-if="!docGetterLoader && (showSolutionControlsButtons || showButtonRoutePointRestart)"
          class="buttons"
        >
          <SolutionControlsButtons
            v-if="showSolutionControlsButtons"
            :doc="docGetter"
          />

          <ButtonRoutePointRestart
            v-if="showButtonRoutePointRestart"
            :rp_id="docLastRoutePointDeclineGetter"
            :doc_id="docGetter['DOC_ID']"
          />
        </div>
      </SheetDocumentPreview>

      <CommentsDocumentPreview
        :doc_id="entityIdPreview"
        :show-add="accessToEdit"
        :loading="docCommentsGetGetterLoader"
      />

      <FilesDocumentPreview
        :doc_id="entityIdPreview"
        :show-add="accessToEdit"
        :loading="isFilesLoading"
        @show-preview="$refs.filePreviewList?.showPreview($event)"
      />

      <GanttDocumentPreview
        v-if="showGantt"
        :tasks="docGanttItemsGetter"
        :doc="docGetter"
        :loading="ganttGetLoader"
        :show-add="showRoutPointAdd"
        :doc_id="entityIdPreview"
        :rp_id="rpIdMark"
      />

      <div v-intersection-observer="onIntersectionObserver">
        <AdditionalInfoDocumentPreview
          :doc_id="entityIdPreview"
          :loading="!readyAdditionalInfo"
        />

        <FilePreviewList
          v-if="!isFilesLoading"
          ref="filePreviewList"
        />
      </div>
    </div>

    <ScrollToStartButton :element="$refs.scrollContainer" />
  </div>
</template>

<script>
import { defineComponent, useTemplateRef } from 'vue';
import { mapActions, mapState } from 'pinia';
import FilePreviewList from '@/components/doc/file-preview/FilePreviewList.vue';
import SolutionCommissionsButtons from '@/components/doc/solution/SolutionCommissionsButtons';
import SolutionControlsButtons from '@/components/doc/solution/SolutionControlsButtons';
import PreviewMixin from '@/mixins/preview-mixin.js';
import ButtonRoutePointRestart from '@/components/doc/preview/ButtonRoutePointRestart';
import HeaderDocumentPreview from '@/components/doc/preview/HeaderDocumentPreview.vue';
import CommonDocumentPreview from '@/components/doc/preview/CommonDocumentPreview.vue';
import SheetDocumentPreview from '@/components/doc/preview/SheetDocumentPreview.vue';
import CommentsDocumentPreview from '@/components/doc/preview/CommentsDocumentPreview.vue';
import FilesDocumentPreview from '@/components/doc/preview/FilesDocumentPreview';
import GanttDocumentPreview from '@/components/doc/preview/GanttDocumentPreview';
import AdditionalInfoDocumentPreview from '@/components/doc/preview/AdditionalInfoDocumentPreview';
import VuexAdapter from '@/services/vuex-adapter.js';
import { DocumentPreview } from '@/common/models/preview/document-preview.js';
import { DOCUMENTS__EDIT } from '@/configs/events';
import ScrollToStartButton from '@/components/buttons/ScrollToStartButton.vue';
import {
  DOC_COMMENTS_GET,
  DOC_GANTT_ITEMS,
  DOC_LINKS_GET,
  DOCS_CLIENTS_GET,
  DOCS_GET,
  DOCS_VIEWS_ALL,
} from '@/configs/end-points.js';
import FileUploadModal from '@/components/modals/File/FileUploadModal.vue';
import { vIntersectionObserver } from '@vueuse/components';
import SystemApi from '@/services/api/system-api.js';
import { NotifyTypes } from '@/configs/notify-types.js';
import LoaderUi from '@/components/ui/LoaderUi.vue';
import Emitter from '@/services/emitter.js';
import { useInfiniteScroll } from '@vueuse/core';
import { useUserStore } from '@/stores/user.js';
import { usePreviewFilesStore } from '@/stores/preview-files.js';
import { usePreviewStore } from '@/stores/preview.js';
import { useCommissionsStore } from '@/stores/commissions.js';
import { useRolesStore } from '@/stores/roles.js';
import { useRowStore } from '@/stores/row.js';
import { useListsStore } from '@/stores/lists.js';
import { useTableStore } from '@/stores/table.js';
import { Route } from '@/common/enums/route.js';

const READ_DURATION = 2000;

export default defineComponent({
  name: 'DocumentPreview',
  components: {
    LoaderUi,
    FileUploadModal,
    AdditionalInfoDocumentPreview,
    GanttDocumentPreview,
    FilesDocumentPreview,
    CommentsDocumentPreview,
    SheetDocumentPreview,
    CommonDocumentPreview,
    HeaderDocumentPreview,
    ButtonRoutePointRestart,
    SolutionControlsButtons,
    SolutionCommissionsButtons,
    FilePreviewList,
    ScrollToStartButton,
  },
  directives: {
    intersectionObserver: vIntersectionObserver,
  },
  mixins: [PreviewMixin],
  props: {
    preview: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const scrollContainer = useTemplateRef('scrollContainer');

    useInfiniteScroll(scrollContainer, () => Emitter.emit('document-preview-scroll-bottom'), {
      distance: 200,
    });

    return {
      scrollContainer,
    };
  },
  data: () => ({
    intersectionTimeoutId: null,
    isDocumentRead: false,
    isRoutePointRead: false,
  }),
  mounted() {
    this.initPreviewAction(DocumentPreview.intefaceIdent);

    Emitter.on('document-preview-drop-file', this.onDocumentPreviewDropFile);
  },
  unmounted() {
    Emitter.off('document-preview-drop-file', this.onDocumentPreviewDropFile);
    this.clearFiles();
  },
  computed: {
    ...mapState(useRolesStore, ['accessToEvent']),
    ...mapState(useUserStore, { userId: 'id' }),
    ...mapState(usePreviewFilesStore, {
      isFilesLoading: 'isLoading',
    }),
    ...mapState(usePreviewStore, ['entityIdPreview', 'initFirstDataPreview']),
    ...mapState(useListsStore, {
      docLastRoutePointDeclineGetter: 'docLastRoutePointDeclineGetter',
      docViewsGetGetterLoader: VuexAdapter.getNameLoaderGeneralGetter(DOCS_VIEWS_ALL),
      docCommentsGetGetterLoader: VuexAdapter.getNameLoaderGeneralGetter(DOC_COMMENTS_GET),
      docDocumentsLinksGetterLoader: VuexAdapter.getNameLoaderGeneralGetter(DOC_LINKS_GET),
      docContractorsGetGetterLoader: VuexAdapter.getNameLoaderGeneralGetter(DOCS_CLIENTS_GET),
      docGanttItemsGetter: VuexAdapter.getNameGetter(DOC_GANTT_ITEMS),
      ganttGetLoader: VuexAdapter.getNameLoaderGeneralGetter(DOC_GANTT_ITEMS),
    }),
    ...mapState(useTableStore, {
      commissionsActiveRow: VuexAdapter.activeRowTableNameGetter('commissions'),
    }),
    ...mapState(useRowStore, {
      docGetter: VuexAdapter.getNameRowGetter(DOCS_GET),
      docGetterLoader: VuexAdapter.getNameRowLoaderGeneralGetter(DOCS_GET),
    }),
    showSolutionCommissionsButtons() {
      return (
        this.$route.name === Route.CommissionsPage &&
        !this.docGetterLoader &&
        this.docGetter['DOC_ID'] === this.entityIdPreview
      );
    },
    showSolutionControlsButtons() {
      return (
        this.$route.name === Route.ControlsPage &&
        !this.docGetterLoader &&
        this.docGetter['DOC_ID'] === this.entityIdPreview &&
        this.docGetter['DS_ID'] !== 6
      );
    },
    showButtonRoutePointRestart() {
      return (
        this.$route.name === Route.ControlsPage &&
        !this.ganttGetLoader &&
        this.docGetter['DOC_ID'] === this.entityIdPreview &&
        this.docLastRoutePointDeclineGetter
      );
    },
    showGantt() {
      return !this.stopDocumentStatus || this.docGanttItemsGetter.length;
    },
    readyAdditionalInfo() {
      return (
        !this.docViewsGetGetterLoader && !this.docContractorsGetGetterLoader && !this.docDocumentsLinksGetterLoader
      );
    },
    stopDocumentStatus() {
      return this.docGetter['DS_ID'] === 5;
    },
    accessToEdit() {
      return this.accessToEvent(DOCUMENTS__EDIT) && !this.stopDocumentStatus;
    },
    showRoutPointAdd() {
      return this.docGetter['CONTROLLER_ID'] === this.userId && this.accessToEdit;
    },
    rpIdMark() {
      if (this.$route.meta?.table === 'commissions') {
        return this.commissionsActiveRow?.RP_ID;
      }
      return null;
    },
  },
  methods: {
    ...mapActions(useCommissionsStore, ['getCommissionsCountAction']),
    ...mapActions(usePreviewFilesStore, { clearFiles: '$reset' }),
    onIntersectionObserver([{ isIntersecting }]) {
      if (!isIntersecting) {
        clearTimeout(this.intersectionTimeoutId);
        return;
      }

      this.intersectionTimeoutId = setTimeout(() => {
        this.markDocumentAsRead();
        this.markRoutePointAsRead();
      }, READ_DURATION);
    },
    async markDocumentAsRead() {
      if (this.isDocumentRead) {
        return;
      }

      try {
        this.isDocumentRead = true;
        await SystemApi.markDocumentAsRead(this.docGetter['DOC_ID']);
      } catch (error) {
        this.isDocumentRead = false;
        this.$notify({
          type: NotifyTypes.Error,
          text: 'При попытке пометить документ как непрочитанный возникла ошибка.',
          data: error,
        });
      }
    },
    onDocumentPreviewDropFile(files) {
      if (this.entityIdPreview) {
        this.$refs.fileUploadModal.show(files);
      }
    },
    async markRoutePointAsRead() {
      if (!this.preview || this.isRoutePointRead) {
        return;
      }

      // TODO ALPHAWEB-1598: Проставлять метку без обновления таблицы
      try {
        this.isRoutePointRead = true;
        await SystemApi.markRoutePointAsRead(this.commissionsActiveRow['RP_ID']);
      } catch (error) {
        this.isRoutePointRead = false;
        this.$notify({
          type: NotifyTypes.Error,
          text: 'При попытке пометить прочитанной точку маршрута возникла ошибка.',
          data: error,
        });
      }
    },
  },
  watch: {
    docGetter() {
      this.isDocumentRead = false;
      this.isRoutePointRead = !this.commissionsActiveRow['Новое'];
      this.$refs.scrollContainer.scrollTo(0, 0);
    },
  },
});
</script>

<style scoped lang="scss">
.solution-commissions-buttons,
.buttons {
  margin-top: 16px;
}

.buttons {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.solution-controls-buttons {
  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.preview-block {
  margin-bottom: 16px;
}

.files-preview {
  margin-bottom: 16px;
}

.scroll-to-start-button {
  position: fixed;
  bottom: 32px;
  right: 3vw;

  @include respond-down(l) {
    bottom: 20px;
  }

  @include respond-down(m) {
    right: 20px;
  }
}
</style>
