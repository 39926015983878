<script setup>
import { computed } from 'vue';
import { templateRef, useDropZone } from '@vueuse/core';
import BorderIcon from '@/assets/icons/dropzone-border.svg';
import FileIcon from '@/assets/icons/files/unknown.svg';
import { keys } from '@/common/utils/props-validators';

const props = defineProps({
  subtitle: String,
  size: {
    type: String,
    default: 'm',
    validator: keys('s', 'm'),
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  show: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['drop']);

const dropZoneRef = templateRef('dropZoneRef');

const { isOverDropZone } = useDropZone(dropZoneRef, {
  onDrop: (files) => {
    if (files && !props.disabled) {
      emit('drop', files);
    }
  },
});

const showOverlay = computed(() => !props.disabled && (props.show || isOverDropZone.value));

defineExpose({
  element: dropZoneRef,
});
</script>

<template>
  <div
    ref="dropZoneRef"
    class="drop-zone-ui"
    :class="`_size-${size}`"
  >
    <slot />

    <Transition name="opacity">
      <div
        v-if="showOverlay"
        class="overlay"
      >
        <BorderIcon
          class="icon-border"
          :class="{ _active: isOverDropZone }"
        />

        <div class="sheet">
          <slot name="icon">
            <FileIcon />
          </slot>

          <p class="title">Перетащите сюда файлы</p>
          <p
            v-if="subtitle"
            class="subtitle"
          >
            {{ subtitle }}
          </p>
        </div>
      </div>
    </Transition>
  </div>
</template>

<style scoped lang="scss">
.drop-zone-ui {
  position: relative;

  &._size-s {
    min-height: 240px;

    .overlay {
      min-width: 332px;
    }

    .icon-border {
      width: 300px;
      border-radius: 16px;
    }

    .sheet {
      :deep(svg) {
        margin-bottom: 16px;
        width: 60px;
        height: 60px;
      }
    }

    .title {
      margin-bottom: 2px;

      font-size: var(--font-size-xl);
      line-height: var(--line-height-xl);
    }

    .subtitle {
      font-size: var(--font-size-l);
      line-height: var(--line-height-l);
    }
  }

  &._size-m {
    min-height: 400px;

    .overlay {
      min-width: 596px;
    }

    .title {
      margin-bottom: 4px;

      font-size: var(--font-size-xxl);
      line-height: var(--line-height-xxl);
    }

    .subtitle {
      font-size: var(--font-size-xl);
      line-height: var(--line-height-xl);
    }
  }
}

.overlay {
  position: absolute;
  z-index: 9000;
  inset: 0;

  pointer-events: none; // Исправляет залипание (см. ALPHAWEB-1464)

  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-border {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  background-color: var(--color-white);
  border-radius: 24px;
  box-shadow: var(--shadow);

  animation: border-move var(--duration-slow) linear infinite;
  animation-play-state: paused;

  &._active {
    animation-play-state: running;
  }
}

@keyframes border-move {
  0% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: -24.5;
  }
}

.sheet {
  z-index: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: var(--color-gray-600);

  :deep(svg) {
    margin-bottom: 32px;
    fill: var(--color-gray-600);

    width: 100px;
    height: 100px;
  }
}

.title {
  font-weight: var(--font-weight-bold);
}
</style>
