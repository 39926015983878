<template>
  <span
    class="indicator-flag"
    :class="classname"
  >
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0L9.63161 2.97842L12.7023 1.52786L12.2716 4.89649L15.6085 5.52786L13.28 8L15.6085 10.4721L12.2716 11.1035L12.7023 14.4721L9.63161 13.0216L8 16L6.36839 13.0216L3.29772 14.4721L3.72839 11.1035L0.391548 10.4721L2.72 8L0.391548 5.52786L3.72839 4.89649L3.29772 1.52786L6.36839 2.97842L8 0Z"
      />
    </svg>
  </span>
</template>

<script>
export default {
  name: 'IndicatorFlag',
  props: ['data'],
  data: () => ({
    classname: 'flag-',
    percent: 0,
  }),
  mounted() {
    this.percent = (this.data / 255).toFixed(1) * 100;
    this.classname = this.classname + this.percent;
    if (this.data === -1) {
      this.classname = 'blue-flag';
    } else if (this.data >= 255) {
      this.classname = 'purple-flag';
    } else if (this.percent === 0) {
      this.classname = 'yellow-flag';
    }
  },
};
</script>
