import { defineStore } from 'pinia';
import { useUserStore } from '@/stores/user.js';
import StoreId from '@/common/enums/store-id.js';

export const useMasterControllerStore = defineStore(StoreId.MasterController, {
  state: () => ({
    controller: null,
  }),
  getters: {
    controllerId(state) {
      return state.controller.code;
    },
    isControllerUser(state) {
      const userStore = useUserStore();
      return state.controller.code === userStore.id;
    },
  },
  actions: {
    set(controller) {
      this.controller = controller;
    },
    setUser() {
      const userStore = useUserStore();
      this.set({ code: userStore.id, label: userStore.name });
    },
  },
});
