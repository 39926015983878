<template>
  <Teleport to="body">
    <Transition name="fade">
      <div
        v-if="isOpen"
        class="modal-ui"
        :style="{ zIndex }"
        @mousedown.self="isClosing = true"
        @click.self="isClosing = false"
        @mouseup.self="close"
      >
        <div
          class="content"
          :class="contentClass"
          :style="{ height, minHeight }"
        >
          <slot>
            <div
              class="sheet"
              :class="{ _centered: popup }"
            >
              <header
                v-if="$slots.header || title"
                class="header"
              >
                <slot name="header">
                  <h2 class="title">
                    {{ title }}
                  </h2>
                </slot>
              </header>

              <div
                v-if="$slots.body"
                class="body"
                :class="{ _popup: popup }"
              >
                <ErrorLegacyUi
                  v-if="errorMessage"
                  :text="errorMessage"
                />
                <slot name="body" />
                <LoaderUi
                  v-if="isLoading"
                  :text="loaderText"
                />
              </div>

              <footer
                v-if="$slots.footer"
                class="footer"
              >
                <slot name="footer" />
              </footer>
            </div>
          </slot>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<script>
import { defineComponent } from 'vue';
import LoaderUi from '@/components/ui/LoaderUi.vue';
import ErrorLegacyUi from '@/components/ui/ErrorLegacyUi.vue';
import { only } from '@/common/utils/props-validators';
import hotkeys from 'hotkeys-js';

export default defineComponent({
  name: 'ModalUi',
  components: {
    ErrorLegacyUi,
    LoaderUi,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    width: {
      type: String,
      // default: widthComputed
      validator: only('xxs', 'xs', 's', 'm', 'l', 'xl', 'max'),
    },
    height: String,
    minHeight: String,
    zIndex: {
      type: Number,
    },
    popup: {
      type: Boolean,
      default: false,
    },
    preventClosure: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
    },
    loaderText: {
      type: String,
      default: null,
    },
  },
  emits: ['update:visible', 'close', 'shown', 'hidden'],
  data() {
    return {
      scope: null,
      isOpen: false,
      isLoading: false,
      isClosing: false,
    };
  },
  computed: {
    widthComputed() {
      return this.width || (this.popup ? 'xxs' : 's');
    },
    contentClass() {
      return [`_width-${this.widthComputed}`, { '_with-height': this.height }];
    },
  },
  watch: {
    visible(value) {
      this.isOpen = value;
      this.$emit(value ? 'shown' : 'hidden');
    },
    isOpen(isOpen) {
      if (isOpen) {
        this.onShow();
      } else {
        this.onClose();
      }
    },
  },
  methods: {
    show() {
      this.isOpen = true;
      this.$emit('update:visible', true);
      this.$emit('shown');
    },
    hide() {
      this.isOpen = false;
      this.isLoading = false;
      this.$emit('update:visible', false);
      this.$emit('hidden');
    },
    showLoader() {
      this.isLoading = true;
    },
    hideLoader() {
      this.isLoading = false;
    },
    close() {
      if (!this.isClosing) {
        return;
      }

      if (!this.preventClosure && !this.isLoading) {
        this.hide();
      }

      this.$emit('close');
    },
    onKeydown(event) {
      if (event.key === 'Escape') {
        this.close();
      }
    },
    onShow() {
      this.scope = hotkeys.getScope();
      hotkeys.setScope('modal');

      document.addEventListener('keydown', this.onKeydown);
    },
    onClose() {
      hotkeys.setScope(this.scope);

      document.removeEventListener('keydown', this.onKeydown);
    },
  },
});
</script>

<style scoped lang="scss">
.modal-ui {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  padding: 24px;

  overflow-y: auto;

  background-color: var(--color-backdrop);
  backdrop-filter: blur(5px);

  @include respond-down(xs) {
    padding: 8px;
  }
}

.content {
  margin: 0 auto;

  transition:
    height var(--transition),
    max-width var(--transition);
  transition-delay: var(--duration);

  &._with-height {
    .sheet,
    .body {
      height: 100%;
    }
  }

  &._width-xxs {
    max-width: 300px;
  }

  &._width-xs {
    max-width: 400px;
  }

  &._width-s {
    max-width: 500px;
  }

  &._width-m {
    max-width: 720px;
  }

  &._width-l {
    max-width: 900px;
  }

  &._width-xl {
    max-width: 1140px;
  }
}

.sheet {
  position: relative;

  border-radius: 8px;
  background-color: white;

  &._centered {
    .title,
    .body {
      text-align: center;
    }

    .footer {
      justify-content: center;
    }
  }
}

.header {
  padding: 16px;
}

.body {
  padding: 0 16px;

  &._popup {
    padding: 16px;
    color: var(--color-gray-600);
  }

  &:first-child {
    padding-top: 16px;
  }

  &:last-child {
    padding-bottom: 16px;
  }
}

.footer {
  padding: 16px;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;

  > :not(:last-child) {
    margin-right: 8px;
  }
}

.loader-ui {
  position: relative;
  z-index: 11000;

  border-radius: 8px;
}

.fade {
  &-enter-active,
  &-leave-active {
    transition: opacity var(--transition);

    .content {
      transition: transform var(--transition);
    }
  }

  &-enter-from,
  &-leave-to {
    opacity: 0;

    .content {
      transform: translateY(-32px);
    }
  }
}
</style>
