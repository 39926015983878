<template>
  <div class="sign">
    <div class="sign--title">Подписание файлов ЭП</div>
    <TableSign />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import TableSign from '@/components/sign/TableSign';
import { mapActions } from 'pinia';
import DbAdapterMixin from '@/mixins/db-adapter-mixin.js';
import { useSignStore } from '@/stores/sign.js';

export default defineComponent({
  name: 'IndexSign',
  components: {
    TableSign,
  },
  mixins: [DbAdapterMixin],
  props: {
    docId: {
      required: true,
      type: Number,
    },
  },
  async mounted() {
    const getFieldName = this.dbAdapter[this.generateKey];
    const getBoolValue = this.dbAdapter[this.getBoolValue];

    const where = getFieldName('Индикатор') + ' = ' + getBoolValue(1);

    this.getFilesForSignAction({
      doc_id: this.docId,
      where,
      orderby: this.dbAdapter[this.getOrder]('Создан', 'DESC'),
    })
      .then(() => {})
      .catch(() => {})
      .finally(() => this.$emit('loaded'));
  },
  unmounted() {
    this.clearSignBlockAction();
  },
  methods: {
    ...mapActions(useSignStore, { getFilesForSignAction: 'getFilesForSignAction', clearSignBlockAction: 'clear' }),
  },
});
</script>
