<template>
  <div :class="classWrapper">
    <div
      v-touch:swipe="swipeHandler"
      class="sidebar-content"
      @click="clickHandler"
    >
      <router-link
        class="sidebar-logo"
        :to="'/'"
        data-tooltip-focus="on"
        data-tooltip-position="right"
        data-tooltip-size="auto"
        data-tooltip-text-align="left"
        :role="isCollapsedState || isHeightBaby || isHeightTiny ? 'tooltip' : ''"
      >
        <LogoIcon alt="АЛЬФА ДОК" />
        <span v-if="!isCollapsedState">
          АЛЬФАДОК
          <GitReleaseData class="sidebar-logo-git-release-data" />
        </span>
        <div
          v-if="isCollapsedState || isHeightBaby || isHeightTiny"
          class="tooltip-aria-label"
        >
          <GitReleaseData />
        </div>
      </router-link>

      <UpdateButton
        v-if="showUpdate"
        tooltip-position="right"
      />

      <router-link
        class="sidebar-link"
        to="/commissions"
        data-tooltip-position="bottom"
        data-tooltip-size="auto"
        data-tooltip-text-align="left"
        role="tooltip"
      >
        <CommissionsIcon />
        <CounterCommissionsIconSmall v-if="isCollapsedState" />
        <span
          v-if="!isCollapsedState"
          class="sidebar-link-text"
        >
          Поручения
        </span>
        <MenuCounter
          v-if="!isCollapsedState"
          :counters="commissionsCount"
        />
        <div class="tooltip-aria-label">
          Поручения
          <div
            v-if="commissionsCount.count > 0"
            class="small"
          >
            Всего:&nbsp;<b>{{ commissionsCount.count }}</b>
          </div>
          <div
            v-if="commissionsCount.count_new > 0"
            class="small"
          >
            Новых:&nbsp;<b>{{ commissionsCount.count_new }}</b>
          </div>
          <div
            v-if="commissionsCount.count_expired > 0"
            class="small"
          >
            Просрочены:&nbsp;<b>{{ commissionsCount.count_expired }}</b>
          </div>
        </div>
      </router-link>

      <router-link
        v-if="accessToControls"
        class="sidebar-link"
        to="/controls"
        data-tooltip-position="bottom"
        data-tooltip-size="auto"
        data-tooltip-text-align="left"
        role="tooltip"
        @click.stop
      >
        <ControlsIcon />
        <CounterControlsIconSmall v-if="isCollapsedState" />
        <span
          v-if="!isCollapsedState"
          class="sidebar-link-text"
        >
          Контроль
        </span>
        <MenuCounter
          v-if="!isCollapsedState"
          :counters="controlsCount"
        />
        <div class="tooltip-aria-label">
          Контроль
          <div
            v-if="controlsCount.count > 0"
            class="small"
          >
            Всего:&nbsp;<b>{{ controlsCount.count }}</b>
          </div>
          <div
            v-if="controlsCount.count_new > 0"
            class="small"
          >
            Новых:&nbsp;<b>{{ controlsCount.count_new }}</b>
          </div>
          <div
            v-if="controlsCount.count_expired > 0"
            class="small"
          >
            Просрочены:&nbsp;<b>{{ controlsCount.count_expired }}</b>
          </div>
        </div>
      </router-link>

      <router-link
        v-if="accessToDocuments"
        class="sidebar-link"
        to="/search"
        aria-label="Поиск документов"
        data-tooltip-position="bottom"
        data-tooltip-size="auto"
        role="tooltip"
        @click.stop
      >
        <SearchDocIcon />
        <span
          v-if="!isCollapsedState"
          class="sidebar-link-text"
        >
          Поиск документов
        </span>
      </router-link>

      <router-link
        v-if="accessToClients"
        class="sidebar-link"
        to="/clients"
        aria-label="Контрагенты"
        data-tooltip-position="bottom"
        data-tooltip-size="auto"
        role="tooltip"
        @click.stop
      >
        <ContractorIcon />
        <span
          v-if="!isCollapsedState"
          class="sidebar-link-text"
        >
          Контрагенты
        </span>
      </router-link>

      <a
        v-if="isExpandableState && !isCollapsedState"
        :class="classToggler"
        @click.stop="toggleHandler"
      >
        <span class="sidebar-link-text">Свернуть</span>
        <CollapseIcon />
      </a>

      <a
        v-if="isExpandableState && isCollapsedState"
        :class="classToggler"
        aria-label="Развернуть"
        data-tooltip-position="top"
        data-tooltip-size="auto"
        role="tooltip"
        @click.stop="toggleHandler"
      >
        <ExpandIcon />
      </a>

      <a
        v-if="isCollapsedMinimizerAvailable"
        :class="classCollapsedMinimizer"
        @click.stop="collapsedMinimizerHandler"
      >
        <ExpandIcon />
      </a>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import Constants from '@/configs/constants';
import GitReleaseData from '@/components/common/GitReleaseData.vue';
import CommissionsIcon from '@/components/icons/CommissionsIcon.vue';
import MenuCounter from '@/components/widgets/MenuCounter.vue';
import CounterCommissionsIconSmall from '@/components/widgets/CounterCommissionsIconSmall.vue';
import ControlsIcon from '@/components/icons/ControlsIcon.vue';
import CounterControlsIconSmall from '@/components/widgets/CounterControlsIconSmall.vue';
import SearchDocIcon from '@/components/icons/SearchDocIcon.vue';
import ContractorIcon from '@/components/icons/ContractorIcon';
import CollapseIcon from '@/components/icons/CollapseIcon';
import ExpandIcon from '@/components/icons/ExpandIcon';
import { mapActions, mapState } from 'pinia';
import { COUNTERPARTIES_REGIONS_SEGMENTS__VIEW, DOCUMENTS__CONTROLS, DOCUMENTS__VIEW } from '@/configs/events';
import UpdateButton from '@/components/buttons/UpdateButton.vue';
import LogoIcon from '@/assets/icons/logo.svg';
import Emitter from '@/services/emitter.js';
import { useSystemStore } from '@/stores/system.js';
import { useCommissionsStore } from '@/stores/commissions.js';
import { useControlsStore } from '@/stores/controls.js';
import { useRolesStore } from '@/stores/roles.js';

export default defineComponent({
  name: 'SidebarGeneral',
  components: {
    LogoIcon,
    UpdateButton,
    GitReleaseData,
    CommissionsIcon,
    MenuCounter,
    CounterCommissionsIconSmall,
    ControlsIcon,
    CounterControlsIconSmall,
    SearchDocIcon,
    ContractorIcon,
    CollapseIcon,
    ExpandIcon,
  },
  inject: ['app'],
  data: () => ({
    isCollapsed: false,
    isCollapsedMinimizer: false,
    clickCount: 0,
    clickDelay: 700,
    clickTimer: null,
  }),
  methods: {
    ...mapActions(useSystemStore, ['setIsCollapsed', 'setIsCollapsedMinimizer']),
    init() {
      if (!this.isExpandableState || this.storeIsCollapsed) {
        this.isCollapsed = true;
      }
      if (this.isCollapsedMinimizerAvailable && this.storeIsCollapsedMinimizer) {
        this.isCollapsedMinimizer = true;
      }
    },
    toggleHandler(state = null) {
      if (!this.isExpandableState) {
        return;
      }
      if (state === null || state?.type === 'click') {
        state = !this.isCollapsed;
      }
      this.isCollapsed = !!state;
      this.setIsCollapsed(this.isCollapsed);
      Emitter.emit('layout-resize');
    },
    swipeHandler(direction) {
      switch (direction) {
        case 'left':
          if (!this.isCollapsed) {
            this.toggleHandler(true);
          }
          break;
        case 'right':
          if (this.isCollapsed) {
            this.toggleHandler(false);
          }
          break;
      }
    },
    clickHandler() {
      this.clickCount++;
      if (this.clickCount === 1) {
        this.clickTimer = setTimeout(() => {
          this.clickCount = 0;
        }, this.clickDelay);
      } else {
        clearTimeout(this.clickTimer);
        this.toggleHandler();
        this.clickCount = 0;
      }
    },
    collapsedMinimizerHandler(state = null) {
      if (!this.isCollapsedMinimizerAvailable) {
        return;
      }
      if (state === null || state?.type === 'click') {
        state = !this.isCollapsedMinimizer;
      }
      this.isCollapsedMinimizer = !!state;
      this.setIsCollapsedMinimizer(this.isCollapsedMinimizer);
      Emitter.emit('layout-resize');
    },
  },
  computed: {
    ...mapState(useSystemStore, {
      storeIsCollapsed: 'isCollapsed',
      storeIsCollapsedMinimizer: 'isCollapsedMinimizer',
    }),
    ...mapState(useRolesStore, ['accessToEvent']),
    ...mapState(useCommissionsStore, ['commissionsCount']),
    ...mapState(useControlsStore, ['controlsCount']),
    accessToControls() {
      return this.accessToEvent(DOCUMENTS__CONTROLS);
    },
    accessToDocuments() {
      return this.accessToEvent(DOCUMENTS__VIEW);
    },
    accessToClients() {
      return this.accessToEvent(COUNTERPARTIES_REGIONS_SEGMENTS__VIEW);
    },
    isCollapsedState() {
      if (!this.isExpandableState) {
        return true;
      }
      return this.isCollapsed;
    },
    isCollapsedMinimizerState() {
      return this.isCollapsedMinimizer;
    },
    isCollapsedMinimizerAvailable() {
      return (
        this.app.appWidth > Constants.resolutionPoints.small && this.app.appWidth < Constants.resolutionPoints.medium
      );
    },
    isExpandableState() {
      return this.app.appWidth >= Constants.resolutionPoints.medium;
    },
    isHeightTiny() {
      return this.app.appHeight <= Constants.resolutionPoints.tiny;
    },
    isHeightBaby() {
      return this.app.appHeight <= Constants.resolutionPoints.baby;
    },
    classWrapper() {
      return {
        'sidebar-wrapper': true,
        'sidebar-collapsed': this.isCollapsedState,
        'sidebar-collapsed-full': this.isCollapsedMinimizerAvailable && this.isCollapsedMinimizerState,
        'sidebar-height-tiny': this.isHeightTiny,
        'sidebar-height-baby': this.isHeightBaby,
      };
    },
    classToggler() {
      return {
        'sidebar-link': true,
        'sidebar-toggler': true,
        'sidebar-toggler-baby': this.isHeightBaby,
      };
    },
    classCollapsedMinimizer() {
      return {
        'sidebar-link': true,
        'sidebar-collapsed-minimizer': true,
        'sidebar-collapsed-minimizer-baby': this.isHeightBaby,
        'expand': !this.isCollapsedMinimizerState,
      };
    },
    showUpdate() {
      return this.app.appWidth > Constants.resolutionPoints.small;
    },
  },
  mounted() {
    this.init();
  },
});
</script>

<style scoped lang="scss">
.sidebar-wrapper {
  position: relative;
  z-index: 13;

  .update-button {
    position: absolute;
    top: 34px;
    right: 16px;
  }

  &.sidebar-height-baby {
    .update-button {
      top: 29px;
    }
  }

  &.sidebar-collapsed {
    .update-button {
      top: 92px;
      right: 30px;
    }

    &.sidebar-height-tiny {
      .update-button {
        top: 66px;
      }
    }

    &.sidebar-height-baby {
      .update-button {
        top: 55px;
      }
    }
  }

  &.sidebar-collapsed-full {
    .update-button {
      display: none;
    }
  }
}

.sidebar-link {
  position: relative;
}
</style>
