<template>
  <ModalUi
    ref="modal"
    title="Внимание"
    popup
    @hidden="$emit('hidden')"
  >
    <template #body> У документа есть неподтвержденные поручения. Считать их завершенными?</template>

    <template #footer>
      <ButtonUi
        color="white"
        @click="$emit('close', false)"
      >
        <CircleCloseIcon v-prefix />
        <span>Нет</span>
      </ButtonUi>

      <ButtonUi @click="$emit('close', true)">
        <StopCircleIcon v-prefix />
        <span>Да</span>
      </ButtonUi>
    </template>
  </ModalUi>
</template>

<script>
import ModalUi from '@/components/ui/ModalUi.vue';
import ModalMixin from '@/mixins/modal-mixin.js';
import { defineComponent } from 'vue';
import ButtonUi from '@/components/ui/ButtonUi.vue';
import CircleCloseIcon from '@/assets/icons/circle/close.svg';
import StopCircleIcon from '@/assets/icons/circle/stop.svg';

export default defineComponent({
  name: 'CloseDocOrdersModal',
  components: {
    StopCircleIcon,
    CircleCloseIcon,
    ButtonUi,
    ModalUi,
  },
  mixins: [ModalMixin],
  emits: ['close', 'hidden'],
});
</script>
