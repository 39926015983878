<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.74 18.6574L12.7431 6.65444C12.5502 6.32352 12.0721 6.32352 11.8792 6.65445L4.88232 18.6574C4.68801 18.9907 4.92846 19.4092 5.31429 19.4092H19.308C19.6938 19.4092 19.9343 18.9907 19.74 18.6574ZM14.039 5.89903C13.2674 4.57532 11.3549 4.57533 10.5833 5.89903L3.58643 17.902C2.8092 19.2353 3.77097 20.9092 5.31429 20.9092H19.308C20.8513 20.9092 21.8131 19.2353 21.0359 17.902L14.039 5.89903Z"
      fill="#FF5E5E"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.1654 10.6529C13.2339 10.2633 12.9342 9.90625 12.5386 9.90625C12.2199 9.90625 11.9503 10.142 11.9079 10.4579L11.3103 14.9062H12.4173L13.1654 10.6529ZM11.8103 17.9062C12.6387 17.9062 13.3103 17.4585 13.3103 16.9062C13.3103 16.354 12.6387 15.9062 11.8103 15.9062C10.9819 15.9062 10.3103 16.354 10.3103 16.9062C10.3103 17.4585 10.9819 17.9062 11.8103 17.9062Z"
      fill="#FF5E5E"
    />
  </svg>
</template>

<script>
/** @deprecated */
export default {
  name: 'ErrorStatusMasterIcon',
};
</script>
