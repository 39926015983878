import { defineComponent } from 'vue';

/** @deprecated Используйте composables */
export default defineComponent({
  emits: ['focus', 'blur'],
  data() {
    return {
      isFocused: false,
      ignoreBlur: false,
    };
  },
  methods: {
    onFieldMousedown(event) {
      if (event.target !== this.control) {
        this.ignoreBlur = true;
      }
    },
    onFieldClick(event) {
      if (event.target !== this.control) {
        this.control.focus();
      }
    },
    onFocus(event) {
      this.ignoreBlur = false;

      if (this.isFocused) {
        return;
      }

      this.isFocused = true;
      this.$emit('focus', event);
    },
    onBlur(event) {
      if (this.ignoreBlur) {
        return;
      }

      this.isFocused = false;
      this.$emit('blur', event);
    },
  },
});
