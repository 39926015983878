<template>
  <span>{{ date(data, config.format) }}</span>
</template>

<script>
import { DateTime } from 'luxon';

export default {
  name: 'DateColumn',
  props: ['data', 'config'],
  methods: {
    date(value, format) {
      if (!value) {
        return '';
      }
      if (format === undefined) {
        console.error('Формат даты не определен: ' + this.config.title);
      }
      return DateTime.fromSQL(value).toFormat(format);
    },
  },
};
</script>
