import { DeadlineType } from '@/common/enums/deadline-type.ts';
import { TYPE_DEADLINE, TYPE_SELECT, TYPE_SWITCH, TYPE_TEXT } from '@/configs/form';

const CONTEND_INPUT_MAX_LENGTH = 100;
const COMMENT_INPUT_MAX_LENGTH = 250;

export const getRoutePointFormFields = (Vue) => [
  [
    {
      type: TYPE_SWITCH,
      name: 'responsibleFrom',
      hidden: () => Vue.systemRPLObjects && !Vue.systemRPLObjects.check_rpl_objects,
      options: [
        {
          label: 'Сервер',
          value: 'server',
        },
        {
          label: 'Все',
          value: 'all',
        },
      ],
    },
  ],
  [
    {
      type: TYPE_SELECT,
      name: 'rpt_id',
      placeholder: 'Тип',
      options: () => Vue.typesOptions,
      required: true,
    },
  ],
  [
    {
      type: TYPE_SELECT,
      name: 'responsible_list',
      placeholder: 'Исполнитель',
      options: () => Vue.optionsResponsible,
      required: true,
      multiple: true,
    },
  ],
  [
    {
      type: TYPE_SELECT,
      name: 'links',
      hidden: () => !Vue.showRunAfter,
      placeholder: 'Выполнять после',
      options: () => Vue.afterRunRoutePointOptions,
      disabled: () => !Vue.afterRunRoutePointOptions.length,
      multiple: true,
    },
  ],
  [
    {
      type: TYPE_DEADLINE,
      name: 'deadline',
      excludeOptions: [DeadlineType.None],
      copyHandler: () => Vue.copyEndDate,
      startDate: () => Vue.startDate,
    },
  ],
  [
    {
      type: TYPE_TEXT,
      name: 'content',
      placeholder: `Описание (${CONTEND_INPUT_MAX_LENGTH})`,
      length: CONTEND_INPUT_MAX_LENGTH,
      required: true,
      slot: 'copy-content',
    },
  ],
  [
    {
      type: TYPE_TEXT,
      name: 'comment',
      placeholder: `Примечание (${COMMENT_INPUT_MAX_LENGTH})`,
      length: COMMENT_INPUT_MAX_LENGTH,
      slot: 'copy-comment',
    },
  ],
];
