<template>
  <div
    v-if="[1, 2, 9].includes(parseInt(dataIdUsed))"
    class="indicator-wrapper"
  >
    <template v-if="dataValueUsed === 0">
      <span class="badge-indicator">сейчас</span>
    </template>
    <div class="dots">
      <div
        v-for="indexDataValueEmpty in getDataValueEmpty"
        :key="indexDataValueEmpty"
        class="circle"
      >
        <em
          v-if="dataValueUsed !== 0"
          :style="{ color: getColorEmpty }"
        >
          <FontAwesomeIcon :icon="['fas', 'circle']" />
        </em>
      </div>
    </div>
    <div class="dots">
      <div
        v-for="indexDataValueMin in getDataValueMin"
        :key="indexDataValueMin"
        class="circle"
      >
        <em :style="{ color: getColor }">
          <FontAwesomeIcon :icon="['fas', 'circle']" />
        </em>
      </div>
    </div>
    <div class="dots">
      <div
        v-if="dataValueAbs > 3"
        class="arrow"
        :style="{ color: getColor }"
      >
        <FontAwesomeIcon :icon="['fas', 'angle-right']" />
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  name: 'IndicatorColumn',
  components: {
    FontAwesomeIcon,
  },
  props: ['config', 'dataId', 'dataValue', 'dataRow'],
  data: () => ({
    colorGreen: '#28a745',
    colorRed: '#dc3545',
    colorGray: '#c3cadb',
  }),
  computed: {
    dataIdUsed() {
      if (this.config.fieldDataId !== undefined && this.dataRow[this.config.fieldDataId] !== undefined) {
        return this.dataRow[this.config.fieldDataId];
      }
      return this.dataId;
    },
    dataValueUsed() {
      if (this.config.fieldDataValue !== undefined && this.dataRow[this.config.fieldDataValue] !== undefined) {
        return this.dataRow[this.config.fieldDataValue];
      }
      return this.dataValue;
    },
    dataValueAbs() {
      return Math.abs(parseInt(this.dataValueUsed));
    },
    getColor() {
      return this.dataValueUsed > 0 ? this.colorGreen : this.colorRed;
    },
    getColorEmpty() {
      return this.colorGray;
    },
    getDataValueMin() {
      return Math.min(parseInt(this.dataValueAbs), 3);
    },
    getDataValueEmpty() {
      return 3 - this.getDataValueMin;
    },
  },
};
</script>

<style scoped lang="scss">
.indicator-wrapper {
  display: flex;
}

.dots {
  display: flex;
}
</style>
