import { StepCode } from '@/common/consts/document-master/common';

export default class StepContentCreator {
  static create(title) {
    return {
      code: StepCode.Content,
      title,
      tooltip:
        'Заполните форму и дополнительные характеристики для создания документа. Удобнее заполнять их, если Вы приложите файлы',
      menuTitle: 'Содержание документа',
      noWrapper: true,
      width: 'xl',
      height: '100%',
    };
  }
}
