<template>
  <ModalUi
    ref="modal"
    :title="configs?.title"
    :loader-text="loaderText"
    :prevent-closure="preventClosure"
  >
    <template #body>
      <SolutionAutocorrect
        class="item"
        @add-solution-autocorrect="addSolutionAutocorrect"
      />

      <TextareaUi
        ref="textarea"
        v-model="comment"
        class="item"
        placeholder="Текст резолюции"
        focus-on-mount
      />

      <UploadUi
        v-if="showUpload"
        v-model="files"
        class="item"
        :readonly="isUploading"
      />

      <IndexSign
        v-if="showSignBlock"
        v-show="filesForSignLengthGetter"
        class="item"
        :doc-id="configs.doc_id"
        @loaded="loadedSign = true"
      />
    </template>

    <template #footer>
      <CancelButton @click="close" />

      <ButtonUi
        :color="configs.color"
        :disabled="disableApplyAgreement"
        @click="apply"
      >
        <component
          :is="configs.icon"
          v-prefix
        />
        <span>{{ configs.name_button }}</span>
      </ButtonUi>
    </template>
  </ModalUi>
</template>

<script>
import { defineComponent } from 'vue';
import route_point_types from '@/configs/route-point-types.js';
import SolutionAutocorrect from '@/components/doc/solution/SolutionAutocorrect';
import { mapActions, mapState } from 'pinia';
import IndexSign from '@/components/sign/IndexSign';
import TextareaUi from '@/components/ui/TextareaUi.vue';
import { DOC_COMMENTS_GET, DOC_GANTT_ITEMS, DOCS_GET } from '@/configs/end-points.js';
import ModalUi from '@/components/ui/ModalUi.vue';
import UploadUi from '@/components/ui/UploadUi.vue';
import ModalMixin from '@/mixins/modal-mixin.js';
import { NotifyTypes } from '@/configs/notify-types.js';
import { DOCS_TABLE } from '@/configs/db-tables.js';
import UploadMixin from '@/mixins/upload-mixin.js';
import CancelButton from '@/components/buttons/CancelButton.vue';
import ButtonUi from '@/components/ui/ButtonUi.vue';
import Emitter from '@/services/emitter.js';
import { useSystemStore } from '@/stores/system.js';
import { usePreviewStore } from '@/stores/preview.js';
import { useSignStore } from '@/stores/sign.js';
import { useActionsStore } from '@/stores/actions.js';

export default defineComponent({
  name: 'SolutionRoutePointModal',
  components: {
    ButtonUi,
    CancelButton,
    UploadUi,
    ModalUi,
    TextareaUi,
    IndexSign,
    SolutionAutocorrect,
  },
  mixins: [ModalMixin, UploadMixin],
  props: ['configs'],
  data() {
    return {
      files: [],
      comment: '',
      loadedSign: false,
      actionsStore: useActionsStore(),
    };
  },
  mounted() {
    if (this.isAgreement) {
      this.fetchAutoSignEnabled(); // TODO: Нужно ли получать здесь, если было получено при авторизации / нужно ли получать при авторизации?
    }
  },
  computed: {
    ...mapState(useSystemStore, ['isAutoSignEnabled']),
    ...mapState(useSignStore, [
      'hasFilesForSignSelectedGetter',
      'filesForSignAllLoadGetter',
      'filesForSignLengthGetter',
    ]),
    disableApplyAgreement() {
      if (!this.showSignBlock) {
        return false;
      }

      return !this.filesForSignAllLoadGetter;
    },
    isAgreement() {
      return route_point_types.agreement === this.configs?.routePointType && this.configs?.name_button === 'Одобрить';
    },
    showSignBlock() {
      return this.isAgreement && this.isAutoSignEnabled;
    },
    showUpload() {
      return !this.showSignBlock || (!this.filesForSignLengthGetter && this.loadedSign);
    },
    loaderText() {
      return this.hasFilesForSignSelectedGetter ? 'Подписание файлов / согласование' : null;
    },
  },
  methods: {
    ...mapActions(useSystemStore, ['fetchAutoSignEnabled']),
    ...mapActions(usePreviewStore, ['refreshPartlyPreviewAction']),
    ...mapActions(useSignStore, ['applyFilesForSignAction']),
    addSolutionAutocorrect(text) {
      this.comment = this.comment + text + ' ';
      this.$refs.textarea.focus();
    },
    async apply() {
      if (this.hasFilesForSignSelectedGetter && this.isAgreement && this.isAutoSignEnabled) {
        this.showLoader();
        this.disableClosure();

        try {
          await this.applyFilesForSignAction(this.configs.doc_id);
        } catch (error) {
          this.$notify({
            type: NotifyTypes.Error,
            text: 'При подписании файлов возникла ошибка.',
            data: error,
          });
          this.hideLoader();
          this.enableClosure();
          return;
        }
      }

      this.disableClosure();

      if (this.files.length) {
        const isError = await this.uploadFiles(this.files, {
          parentTableId: DOCS_TABLE,
          parentId: this.configs.doc_id,
        });

        if (isError) {
          this.enableClosure();
          return;
        }
      }

      let comment = '';

      if (this.isAgreement) {
        comment = 'Согласовано';
      }
      if (route_point_types.agreement === this.configs.routePointType && this.configs.name_button === 'Отклонить') {
        comment = 'Не согласовано';
      }
      if (route_point_types.notification === this.configs.routePointType) {
        comment = 'Принято';
      }
      if (route_point_types.assignment === this.configs.routePointType) {
        comment = 'Выполнено';
      }
      if (this.comment.length > 0) {
        comment = comment + ' / ' + this.comment + ' /';
      }

      this.showLoader();
      this.disableClosure();

      try {
        await this.actionsStore[this.configs.action]({
          rp_id: this.configs.rp_id,
          comment,
        });
        this.configs.events.forEach((value) => Emitter.emit(value));
        void this.refreshPartlyPreviewAction(DOCS_GET);
        void this.refreshPartlyPreviewAction(DOC_COMMENTS_GET);
        void this.refreshPartlyPreviewAction(DOC_GANTT_ITEMS);
        this.hide();
      } catch (error) {
        this.$notify({
          type: NotifyTypes.Error,
          text: 'При отправке запроса возникла ошибка.',
          data: error,
        });
        this.hideLoader();
        this.enableClosure();
      }
    },
  },
});
</script>

<style scoped lang="scss">
.item {
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}
</style>
