<template>
  <span v-if="selectOptionValue && selectOptionValue.length > 0">
    {{ selectOptionValue }}
  </span>
</template>

<script>
import { useListsStore } from '@/stores/lists.js';

export default {
  name: 'AsyncIdentifier',
  props: ['config', 'data', 'dataRow'],
  data() {
    return {
      listsStore: useListsStore(),
    };
  },
  computed: {
    selectOptionValue() {
      if (this.config.fieldKeyValue !== undefined && this.config.fieldKeyValue) {
        return this.dataRow[this.config.fieldKeyValue];
      }

      const selectOption = this.listsStore[this.config.optionGetter].find((option) => {
        return option.code === this.data;
      });

      if (selectOption == undefined) {
        return this.data;
      }

      return selectOption.label;
    },
  },
};
</script>
