<template>
  <ModalUi
    ref="modal"
    title="Удаление реквизитов"
    :error-message="errorMessage"
    popup
  >
    <template #body> Вы уверены что хотите удалить банковские реквизиты?</template>

    <template #footer>
      <CancelButton @click="hide" />
      <DeleteButton @click="del" />
    </template>
  </ModalUi>
</template>

<script>
import { defineComponent } from 'vue';
import CancelButton from '@/components/buttons/CancelButton.vue';
import DeleteButton from '@/components/buttons/DeleteButton';
import { mapActions } from 'pinia';
import VuexAdapter from '@/services/vuex-adapter.js';
import { CLIENTS_BANK_DETAILS_DEL, CLIENTS_BANK_DETAILS_GET } from '@/configs/end-points.js';
import ModalUi from '@/components/ui/ModalUi.vue';
import ModalMixin from '@/mixins/modal-mixin.js';
import ErrorHelper from '@/services/error-helper.js';
import { useActionsStore } from '@/stores/actions.js';
import { usePreviewStore } from '@/stores/preview.js';

export default defineComponent({
  name: 'DeleteBankDetailsModal',
  components: {
    ModalUi,
    DeleteButton,
    CancelButton,
  },
  mixins: [ModalMixin],
  props: {
    accountId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    errorMessage: null,
  }),
  methods: {
    ...mapActions(useActionsStore, {
      deleteClientBankDetailsAction: VuexAdapter.getNameAction(CLIENTS_BANK_DETAILS_DEL),
    }),
    ...mapActions(usePreviewStore, ['refreshPartlyPreviewAction']),
    del() {
      this.showLoader();
      this.errorMessage = null;

      this.deleteClientBankDetailsAction({ account_id: this.accountId })
        .then(() => {
          this.refreshPartlyPreviewAction(CLIENTS_BANK_DETAILS_GET);
          this.hide();
        })
        .catch((error) => {
          this.errorMessage = ErrorHelper.format(error);
          this.hideLoader();
        });
    },
  },
});
</script>
