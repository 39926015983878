<template>
  <DateForm
    placeholder="Дата"
    only-calendar
    @update:model-value="updateDate"
  />
</template>

<script>
import { DateTime } from 'luxon';
import DateForm from '@/components/form/DateForm';

export default {
  name: 'SolutionAutocorrectDate',
  components: { DateForm },
  methods: {
    updateDate(value) {
      const date = DateTime.fromJSDate(new Date(value)).toFormat('dd.MM.yyyy');
      this.$emit('add-solution-autocorrect', date);
    },
  },
};
</script>

<style scoped lang="scss">
.date-form {
  margin-bottom: 0;
}
</style>
