<template>
  <ModalUi
    ref="modal"
    title="Удаление документа"
    popup
  >
    <template #body> Вы собираетесь удалить документ. Данная операция не обратима. Продолжить?</template>

    <template #footer>
      <CancelButton @click="hide" />
      <DeleteButton @click="remove" />
    </template>
  </ModalUi>
</template>

<script>
import { defineComponent } from 'vue';
import CancelButton from '@/components/buttons/CancelButton.vue';
import DeleteButton from '@/components/buttons/DeleteButton';
import { mapActions } from 'pinia';
import VuexAdapter from '@/services/vuex-adapter.js';
import { DOC_DELETE } from '@/configs/end-points.js';
import { NotifyTypes } from '@/configs/notify-types.js';
import ModalUi from '@/components/ui/ModalUi.vue';
import ModalMixin from '@/mixins/modal-mixin.js';
import Emitter from '@/services/emitter.js';
import { useActionsStore } from '@/stores/actions.js';

export default defineComponent({
  name: 'DeleteDocModal',
  components: {
    ModalUi,
    CancelButton,
    DeleteButton,
  },
  mixins: [ModalMixin],
  props: {
    docId: {
      type: Number,
      required: true,
    },
    tableName: {
      type: String,
    },
    indexRow: {
      type: Number,
    },
  },
  methods: {
    ...mapActions(useActionsStore, {
      docDelAction: VuexAdapter.getNameAction(DOC_DELETE),
    }),
    remove() {
      this.showLoader();
      this.docDelAction({
        doc_id: this.docId,
      })
        .then(() => {
          if (this.tableName) {
            Emitter.emit('counter-reload-controls');
            Emitter.emit('remove-row-' + this.tableName, this.indexRow);
            Emitter.emit('table-trigger-refresh-only-state');
          }
          this.hide();
        })
        .catch((error) => {
          this.$notify({
            type: NotifyTypes.Error,
            text: 'При удалении документа возникла ошибка.',
            data: error,
          });
          this.hideLoader();
        });
    },
  },
});
</script>
