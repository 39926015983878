<template>
  <div class="date-form">
    <label
      v-if="label"
      v-html="label"
    />

    <div
      class="date-form__wrapper"
      :class="{ 'date-form__wrapper_disabled': disabledAll }"
    >
      <div
        v-if="!onlyCalendar"
        class="date-form__input__date"
        :class="inputClasses"
      >
        <div
          class="date-form__input__date--button"
          :class="{ disabled: disabledMinOneDay }"
          @click="dateTo(-1)"
        >
          <CollapseIcon v-if="!disabledAll" />
        </div>

        <div class="date-form__input__date--input">
          <input
            v-model="valueInput"
            v-maska="'##.##.####'"
            :disabled="disabledAll"
            :placeholder="placeholder"
            @focus="focusInput"
            @blur="blurInput"
            @change="changeInput"
          />
        </div>

        <div
          class="date-form__input__date--button"
          @click="dateTo(1)"
        >
          <ChevronRightIcon v-if="!disabledAll" />
        </div>
      </div>

      <template v-if="!onlyInput">
        <div
          v-if="!onlyCalendar"
          class="date-form__input__date--today"
          @click="setToday"
        >
          <TodayIcon />
        </div>

        <div
          v-if="!onlyCalendar && copyHandler"
          class="date-form__input__date--today"
          @click="copyHandler"
        >
          <CopyIcon />
        </div>

        <DateRangePicker
          v-model="picker"
          :locale-data="dateRangePickerConfig"
          :linked-calendars="false"
          :min-date="minDate"
          :date-range="dateRange"
          :show-dropdowns="false"
          :auto-apply="true"
          :disabled="disabledAll"
          :ranges="false"
          opens="left"
          single-date-picker="default"
          @update:model-value="updateDate($event)"
        >
          <template #input>
            <CalendarPlusIcon />
          </template>
        </DateRangePicker>
      </template>
    </div>
    <span
      v-if="caption || error"
      class="form--input-caption"
      :class="error ? 'form--input-caption__error' : ''"
    >
      {{ error ? error : caption }}
    </span>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import CalendarPlusIcon from '@/components/icons/CalendarPlusIcon';
import CollapseIcon from '@/components/icons/CollapseIcon';
import ChevronRightIcon from '@/components/icons/ChevronRightIcon';
import { DateTime } from 'luxon';
import TodayIcon from '@/components/icons/TodayIcon';
import CopyIcon from '@/components/icons/CopyIcon';
import { vMaska } from 'maska/vue';
import { getStartOfDayDate } from '@/common/utils/date';
import DateRangePicker from 'vue3-daterange-picker';

/** @deprecated Используйте ui-компоненты */
export default defineComponent({
  name: 'DateForm',
  components: {
    DateRangePicker,
    CopyIcon,
    TodayIcon,
    ChevronRightIcon,
    CollapseIcon,
    CalendarPlusIcon,
  },
  directives: {
    Maska: vMaska,
  },
  props: {
    /**
     * Значение поля
     * @type {Object}
     */
    modelValue: {
      type: String,
      default: null,
    },
    /**
     * Текст для <label>
     * @type {string}
     */
    label: {
      type: String,
      default: '',
    },
    /**
     * Аттрибут [placeholder]
     * @type {string}
     */
    placeholder: {
      type: String,
      default: '__.__.____',
    },
    /**
     * Изначальное значение поля
     * @type {Object}
     */
    initialValue: {
      type: Object,
      default: null,
    },
    /**
     * Показывать только кнопку выбора даты
     * @type {Object}
     */
    onlyCalendar: {
      type: Boolean,
      default: false,
    },
    /**
     * Показывать только ввод даты
     * @type {Boolean}
     */
    onlyInput: {
      type: Boolean,
      default: false,
    },
    /**
     * Аттрибут [disabled]
     * @type {boolean}
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Аттрибут [readonly]
     * @type {boolean}
     */
    readonly: {
      type: Boolean,
      default: false,
    },
    /**
     * Сообщение об ошибке
     * @type {string}
     */
    error: {
      type: String,
      default: '',
    },
    /**
     * Подсказка
     * @type {string}
     */
    caption: {
      type: String,
      default: '',
    },
    /**
     * Кнопка копировать
     */
    copyHandler: {
      type: Function,
      default: undefined,
    },
    classes: {
      type: Array,
      default: undefined,
    },
    minDate: {
      type: Date,
      default: null,
    },
  },
  emits: ['update:modelValue', 'blur', 'focus'],
  data() {
    return {
      focus: false,
      valueInput: null,
      picker: {
        startDate: null,
        endDate: null,
      },
      dateRange: {
        startDate: null,
        endDate: null,
      },
      dateRangePickerConfig: {
        direction: 'ltr',
        format: 'dd.mm.yyyy',
        applyLabel: 'Сохранить',
        Today: 'Сохранить',
        cancelLabel: 'Отмена',
        weekLabel: 'W',
        daysOfWeek: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
        monthNames: [
          'Январь',
          'Февраль',
          'Март',
          'Апрель',
          'Май',
          'Июнь',
          'Июль',
          'Август',
          'Сентябрь',
          'Октябрь',
          'Ноябрь',
          'Декабрь',
        ],
        firstDay: 1,
      },
      form: {
        day: '',
        month: '',
        year: '',
      },
      dateTime: null,
    };
  },
  computed: {
    disabledAll() {
      return this.disabled || this.readonly;
    },
    disabledMinOneDay() {
      if (!this.minDate || !this.dateTime) {
        return;
      }

      return DateTime.fromJSDate(this.minDate) >= this.dateTime;
    },
    inputClasses() {
      const res = [];

      if (this.error) res.push('error');
      if (this.focus) res.push('focus');

      if (Array.isArray(this.classes)) {
        this.classes.forEach((c) => {
          res.push(c);
        });
      }

      return res;
    },
  },
  watch: {
    dateTime() {
      if (this.dateTime?.invalid) {
        this.valueInput = null;
        this.dateTime = null;
        this.$emit('update:modelValue', null);

        return;
      }

      this.valueInput = this.dateTime?.toFormat('dd.MM.yyyy');
      this.$emit('update:modelValue', this.dateTime?.toFormat('yyyy-MM-dd'));
    },
    modelValue(modelValue) {
      this.dateTime = modelValue ? DateTime.fromFormat(modelValue, 'yyyy-MM-dd') : null;
    },
  },
  mounted() {
    if (this.initialValue) {
      this.valueInput = this.initialValue;
      this.changeInput();
    }
    if (this.modelValue) {
      this.dateTime = DateTime.fromFormat(this.modelValue, 'yyyy-MM-dd');
    }
  },
  methods: {
    focusInput() {
      this.focus = true;
      this.$emit('focus');
    },
    blurInput() {
      if (this.minDate) {
        const minDate = DateTime.fromJSDate(this.minDate);
        if (minDate > this.dateTime) {
          this.dateTime = minDate;
        }
      }

      this.focus = false;
      this.$emit('blur');
    },
    changeInput() {
      this.dateTime = DateTime.fromFormat(this.valueInput, 'dd.MM.yyyy');
    },
    dateTo(to) {
      if (this.disabledAll || (to === -1 && this.disabledMinOneDay)) {
        return;
      }

      if (!this.dateTime) {
        this.dateTime = DateTime.fromJSDate(this.minDate || getStartOfDayDate());
        return;
      }

      this.dateTime = this.dateTime.plus({ days: to });
    },
    updateDate(value) {
      this.dateTime = DateTime.fromJSDate(new Date(value.startDate));
    },
    setToday() {
      if (this.disabledAll) {
        return;
      }

      const today = DateTime.fromJSDate(getStartOfDayDate());

      if (!this.minDate) {
        this.dateTime = today;
        return;
      }

      const minDate = DateTime.fromJSDate(this.minDate);

      if (minDate > today) {
        this.dateTime = minDate;
      } else {
        this.dateTime = today;
      }
    },
  },
});
</script>
