import { between, helpers, required } from '@vuelidate/validators';
import ErrorMessage from '@/common/enums/error-message.js';

const subtractYears = (count, date = new Date()) => new Date(date.setFullYear(date.getFullYear() - count));

const VALIDATORS = {
  regex: (reg, errorMessage) => {
    return helpers.withMessage(errorMessage, helpers.regex(reg));
  },
  after: (year, errorMessage) => {
    return helpers.withMessage(errorMessage, (v) => new Date(v) > new Date(`${year}`));
  },
  before: (year, errorMessage) => {
    return helpers.withMessage(errorMessage, (v) => new Date(v) < new Date(`${year}`));
  },
};

const RULES = {
  required: helpers.withMessage(ErrorMessage.Required, required),
  email: VALIDATORS.regex(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, ErrorMessage.Email),
  phone: VALIDATORS.regex(/^(\+?\d{1,3}( )?)?((\(\d{3}\))|\d{3})[- .]?\d{3}[- .]?\d{2}[-]?\d{2}$/, ErrorMessage.Phone),
  date: helpers.withMessage(ErrorMessage.Date, (v) => new Date(v) > subtractYears(120) && new Date(v) < new Date()),
  percent: helpers.withMessage(ErrorMessage.Percent, between(0, 100)),
  url: VALIDATORS.regex(/^(http(s)?:\/\/)?[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)+([/?#][^\s]*)?$/, ErrorMessage.URL),
  truthy: helpers.withMessage(ErrorMessage.Data, (value) => value === true),
};

export default RULES;

export { VALIDATORS };
